import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig, FileConfig, TeklifOnayiFileConfig } from "./TeklifMasterConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import TeklifDetay from "./TeklifDetay";
import FiyatListesiInputForm from "../../components/inputs/custom-form-inputs/FiyatListesiInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { CARI_TIPI, PARA_BIRIMI, TEKLIF_DURUMU } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import PdfDialog from "../../components/pdf/PdfDialog";
import TopluIskontoDialog from "./TopluIskontoDialog";
import PotansiyelMusteriEklemeliInputForm from "../../components/inputs/custom-form-inputs/PotansiyelMusteriEklemeliInputForm";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import SatisTeklifiOnayliPdfDosyasiButton from "../../components/buttons/custom/SatisTeklifiOnayliPdfDosyasiButton";
import PopoverButton from "../../components/buttons/PopoverButton";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import TeklifiReddedildiDialog from "./TeklifiReddedildiDialog";
import TeklifiDovizliyeCevirDialog from "./TeklifiDovizliyeCevirDialog";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DiscountIcon from "@mui/icons-material/Discount";
import PrintIcon from "@mui/icons-material/Print";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import TeklifProjeMasterRecord from "../TeklifProje/TeklifProjeMasterRecord";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import TeklifOdemePlaniDialog from "./TeklifOdemePlaniDialog";
import PaymentIcon from "@mui/icons-material/Payment";
import PdfDialogNew from "../../components/pdf/PdfDialogNew";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import RestoreIcon from '@mui/icons-material/Restore';

const TeklifMasterRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, detailGridRef, gorusmeRef }, ref) => {
    const context = React.useContext(AppContext);

    const cariTipiInputRef = useRef();
    const pdfDialogRef = useRef();
    const topluIskontoDialogRef = useRef();
    const teklifReddetAlertDialogRef = useRef();
    const teklifReddiniGeriAlAlertDialogRef = useRef();
    const teklifiDovizliyeCevirDialogRef = useRef();
    const teklifProjeBilgileriRef = useRef();
    const teklifOdemeBilgileriDialogRef = useRef();

    var initialState = {
      // aktif: true,
      teklifTarihi: dayjs(),
      kayitTarihi: dayjs(),
      degisiklikTarihi: dayjs(),
      degisiklikYapan: context?.user?.id,
      kayitEden: context?.user?.id,
      cariTipi: 1,
      teklifDurumu: 1,
    };

    // if (!gorusmeRef) {
    initialState = {
      ...initialState,
      fiyatListeid: context?.user?.fiyatListesiid,
      fiyatListeidStr: context?.user?.fiyatListesiidStr,
    };
    // }

    if (gorusmeRef && gorusmeRef.potFirmaid > 0) {
      initialState = {
        ...initialState,
        cariTipi: 2,
        potFirmaid: gorusmeRef.potFirmaid,
        gorusmeid: gorusmeRef.id,
        potFirmaidStr: gorusmeRef.potFirmaidStr,
      };
    }

    const editGridRef = useRef();

    // !Emirhan
    const handleChangeAllItems = (iskontoOrani) => {
      const dataGrid = editGridRef.current.instance;
      let rows = dataGrid.getVisibleRows();
      rows.map((row) => {
        dataGrid.cellValue(row.rowIndex, "iskontoOrani", iskontoOrani);
      });
    };

    const handleSubmitPrepareData = (values) => {
      let aa = {
        ...values,
        detay: editGridRef.current.instance.getVisibleRows().map((row) => {
          if (row.data.masterid == "undefined" || row.data.masterid == "") {
            row.data.masterid = 0;
          }
          return row.data;
        }),
        dipToplam: [],
      };

      return aa;
    };

    // const teklifiDovizliyeCevirAfterSubmit = (id) => {
    //   var xid = rowData.id;

    //   ref.current.openRecord(xid);
    // };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowDataFnc,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      () => {
        editGridRef.current?.instance?.cancelEditData();
      },
      null,
      null,
      null,
      handleSubmitPrepareData
    );

    const setRowDataWithGetById = (id) => {
      ref?.current?.openRecord(id);
    };

    const validationSchema = yup.object().shape({
      kullaniciSayisi: yup.number().required(),
      //   listeKodu: yup.string().required(),
      //   listeAdi: yup.string().required(),
      //   baslangicTarihi: yup.date().required(),
      //   bitisTarihi: yup.date().required(),
    });

    const validate = validateFormValues(validationSchema);

    const cariTipiSirketli = () => {
      return cariTipiInputRef?.current?.value === CARI_TIPI.MUSTERI.Value;
    };

    const duzenlemeYapamaz =
      context.user?.anaBayiKullanicisi === true &&
      rowData.bayiid > 0 &&
      !context.user?.kullaniciParametreleri
        ?.tumTekliflerinBilgileriniDegistirebilir;

    // console.log(rowData.detay, "rowData.detay");

    const kullaniciSayisiDegistirilemez =
      rowData.detay !== null && rowData.detay?.length > 0;

    // console.log(rowData.teklifDurumu, "rowData.teklifDurumu");

    const teklifDuzenlenemez =
      rowData.teklifDurumu === TEKLIF_DURUMU.ONAYLANDI.Value ||
      rowData.teklifDurumu === TEKLIF_DURUMU.REDDEDILDI.Value;

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Satış Teklifi Ver"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Teklif No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Teklif Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="teklifTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet || duzenlemeYapamaz}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={4}>
                          <FieldWrapper
                            label="Müşteri"
                            modalLoading={modalLoading}
                          >
                            <Field
                              items={Object.values(CARI_TIPI)}
                              rowData={rowData}
                              name="cariTipi"
                              component={AutocomplateInputForm}
                              ref={cariTipiInputRef}
                              readOnly={
                                !yetki.kaydet ||
                                duzenlemeYapamaz ||
                                teklifDuzenlenemez
                              }
                            />
                          </FieldWrapper>
                        </Grid>
                        <Grid item xs={8}>
                          {cariTipiInputRef && cariTipiSirketli() ? (
                            <Field
                              name="firmaid"
                              rowData={rowData}
                              component={SirketInputForm}
                              readOnly={
                                !yetki.kaydet ||
                                duzenlemeYapamaz ||
                                teklifDuzenlenemez
                              }
                            />
                          ) : cariTipiInputRef ? (
                            <Field
                              name="potFirmaid"
                              rowData={rowData}
                              component={PotansiyelMusteriEklemeliInputForm}
                              readOnly={
                                !yetki.kaydet ||
                                duzenlemeYapamaz ||
                                teklifDuzenlenemez
                              }
                              ekranKodu={ekranKodu}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                      <FieldWrapper
                        label="Liste Kodu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="fiyatListeid"
                          rowData={rowData}
                          component={FiyatListesiInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            context?.user?.bayiKullanicisi ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        />
                      </FieldWrapper>
                      {/* <Grid container direction="row" spacing={2}>
                        <Grid item xs={10}> */}
                      <FieldWrapper
                        label="Teklif Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          items={Object.values(TEKLIF_DURUMU)}
                          name="teklifDurumu"
                          component={AutocomplateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <Grid container direction="row" spacing={0} padding={1}>
                        <Grid item xs={4}>
                          <SatisTeklifiOnayliPdfDosyasiButton
                            routes={TeklifOnayiFileConfig}
                            reqData={{
                              id: rowData.id,
                            }}
                            ekranKodu={ekranKodu}
                            parentRowData={rowData}
                            deleteDisabled={teklifDuzenlenemez}
                            disabled={
                              !rowData?.id ||
                              duzenlemeYapamaz ||
                              teklifDuzenlenemez
                            }
                          >
                            {" "}
                            Teklifi Onayla
                          </SatisTeklifiOnayliPdfDosyasiButton>
                        </Grid>
                        <Grid item xs={4}>
                          {rowData.teklifDurumu === TEKLIF_DURUMU.REDDEDILDI.Value ? (
                            <CustomColoredButton
                              startIcon={<RestoreIcon />}
                              onClick={() => {
                                teklifReddiniGeriAlAlertDialogRef?.current?.openRecord();
                              }}
                              ekranKodu={ekranKodu}
                              disabled={
                                !rowData?.id ||
                                duzenlemeYapamaz
                              }
                              color="teal"
                            >
                              Reddi Geri Al
                            </CustomColoredButton>) : (<CustomColoredButton
                              startIcon={<DoNotDisturbIcon />}
                              onClick={() => {
                                teklifReddetAlertDialogRef?.current?.openRecord();
                              }}
                              ekranKodu={ekranKodu}
                              disabled={
                                !rowData?.id ||
                                duzenlemeYapamaz
                              }
                              color="red"
                            >
                              Teklifi Reddet
                            </CustomColoredButton>)}

                        </Grid>
                        <Grid item xs={4}>
                          <EkDosyaButton
                            reqData={{
                              id: rowData.id,
                            }}
                            routes={FileConfig}
                            ekranKodu={ekranKodu}
                            parentRowData={rowData}
                            disabled={!rowData?.id}
                            deleteDisabled={teklifDuzenlenemez}
                          ></EkDosyaButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Geçerlilik Günü"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="gecerlilikGunu"
                          component={NumberInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Kullanıcı Sayısı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kullaniciSayisi"
                          component={NumberInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez ||
                            kullaniciSayisiDegistirilemez
                          }
                        />
                      </FieldWrapper>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={8}>
                          <FieldWrapper
                            label="Döviz Kodu"
                            modalLoading={modalLoading}
                            width={"47%"}
                            textWidth={"53%"}
                          >
                            <Field
                              items={Object.values(PARA_BIRIMI)}
                              rowData={rowData}
                              name="dovizKod"
                              component={AutocomplateInputForm}
                              readOnly={true}
                              clearable={false}
                            />
                          </FieldWrapper>
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            name="dovizKuru"
                            rowData={rowData}
                            component={NumberInputForm}
                            readOnly={true}
                          />
                        </Grid>
                      </Grid>
                      <FieldWrapper
                        label="Döviz Kuru Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="dovizKuruTarihi"
                          component={DateInputForm2}
                          readOnly={
                            !yetki.kaydet ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Açıklama"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aciklama"
                          type="text"
                          minRows={3}
                          maxRows={4}
                          component={MultilineTextInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        />
                      </FieldWrapper>
                      {/* <FieldWrapper label="Aktif" modalLoading={modalLoading}>
                        <Field
                          name="aktif"
                          component={CheckboxForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper> */}
                    </Grid>
                  </DialogContentTop>
                  <DialogActionsWrapper
                    sx={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <ButtonWrapper
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={async (e) => {
                          form.submit().then(() => {
                            pdfDialogRef?.current?.openRecord(rowData.id);
                          });
                        }}
                        disabled={!rowData.id}
                      >
                        Yazdır
                      </ButtonWrapper>
                      <PopoverButton
                        label="Diğer Detaylar"
                        disabled={
                          !rowData?.id || duzenlemeYapamaz || teklifDuzenlenemez
                        }
                      >

                        <ButtonWrapper
                          color="primary"
                          startIcon={<DiscountIcon />}
                          onClick={(e) => {
                            topluIskontoDialogRef?.current?.openRecord();
                            // handleChangeAllItems();
                          }}
                          disabled={
                            !rowData.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          Toplu İskonto
                        </ButtonWrapper>
                        <ButtonWrapper
                          startIcon={<CurrencyExchangeIcon />}
                          color="warning"
                          onClick={(e) => {
                            teklifiDovizliyeCevirDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          disabled={
                            !rowData.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          DÖVİZLİ TEKLİFE ÇEVİR
                        </ButtonWrapper>
                        <ButtonWrapper
                          startIcon={<StackedLineChartIcon />}
                          color="secondary"
                          onClick={(e) => {
                            teklifProjeBilgileriRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          disabled={
                            !rowData.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          PROJE BİLGİLERİ
                        </ButtonWrapper>
                        <ButtonWrapper
                          startIcon={<PaymentIcon />}
                          color="info"
                          onClick={(e) => {
                            teklifOdemeBilgileriDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          disabled={
                            !rowData.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          ÖDEME BİLGİLERİ
                        </ButtonWrapper>
                      </PopoverButton>
                    </Grid>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                      submitCondition={!duzenlemeYapamaz && !teklifDuzenlenemez}
                      deleteCondition={!duzenlemeYapamaz && !teklifDuzenlenemez}
                      cancelCondition={!duzenlemeYapamaz && !teklifDuzenlenemez}
                      submitButtonText={"Teklifi Kaydet"}
                      deleteButtonText={"Teklifi Sil"}
                    />
                  </DialogActionsWrapper>
                  <DialogContentWrapper>
                    <TeklifDetay
                      ekranKodu={ekranKodu}
                      rowData={rowData}
                      editGridRef={editGridRef}
                      listGridRef={editGridRef}
                      detailGridRef={editGridRef}
                      duzenlemeYapamaz={duzenlemeYapamaz || teklifDuzenlenemez}

                    ></TeklifDetay>
                  </DialogContentWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <PdfDialog
          ref={pdfDialogRef}
          title="Satiş Teklifi"
          url="TeklifMaster/SatisTeklifiPdfNew"
        ></PdfDialog>
        <TopluIskontoDialog
          customHandleSubmit={handleChangeAllItems}
          ref={topluIskontoDialogRef}
        />
        <TeklifiDovizliyeCevirDialog
          ref={teklifiDovizliyeCevirDialogRef}
          ekranKodu={ekranKodu}
          isEditable={true}
          setRowDataFnc={setRowDataFnc}
          listGridRef={listGridRef}
          // afterSubmit={teklifiDovizliyeCevirAfterSubmit}
          // afterSubmitFnc={() => listGridRef.current.openRecord(rowData.id)}
          masterRef={ref}
          setRowDataWithGetById={setRowDataWithGetById}
        />
        <TeklifProjeMasterRecord
          ref={teklifProjeBilgileriRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
        />
        <TeklifiReddedildiDialog
          teklifReddetAlertDialogRef={teklifReddetAlertDialogRef}
          teklifReddiniGeriAlAlertDialogRef={teklifReddiniGeriAlAlertDialogRef}
          rowData={rowData}
          setRowDataFnc={setRowDataFnc}
          listGridRef={listGridRef}
          reddet={true}
        />
        <TeklifiReddedildiDialog
          teklifReddetAlertDialogRef={teklifReddetAlertDialogRef}
          teklifReddiniGeriAlAlertDialogRef={teklifReddiniGeriAlAlertDialogRef}
          rowData={rowData}
          setRowDataFnc={setRowDataFnc}
          listGridRef={listGridRef}
          reddet={false}
        />
        <TeklifOdemePlaniDialog
          ref={teklifOdemeBilgileriDialogRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          isEditable={true}
        />
      </>
    );
  }
);
export default TeklifMasterRecord;
