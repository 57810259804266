import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import {
  GORUSME_DURUMU,
  GORUSME_MAIL_OLAYLARI,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import { ANALIZ_SONUCU_WIDTH, DATE_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Görüşme Mail Template No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "mailOlayi",
    label: "Mail Olayı",
    dataType: "select",
    dataField: "mailOlayi",
    visible: ["grid"],
    selectItems: Object.values(GORUSME_MAIL_OLAYLARI),
  },
  {
    name: "mailKonu",
    label: "Mail Konu",
    dataType: "text",
    dataField: "mailKonu",
    visible: ["grid"],
  },
  {
    name: "mailAciklama",
    label: "Mail İçeriği",
    dataType: "text",
    dataField: "mailAciklama",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
  },
  {
    name: "mailGidecekKisiler",
    label: "Mail Gidecek Kişiler",
    dataType: "text",
    dataField: "mailGidecekKisiler",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
];

export default entity;
