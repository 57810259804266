import React, { forwardRef, useImperativeHandle } from "react";
import { TextField } from "@mui/material";

const NewTextInputForm = forwardRef(
  (
    {
      input,
      meta,
      label,
      defaultValue,
      numberInput,
      inputRef,
      onChangeExtra,
      ...rest
    },
    ref
  ) => {
    // İç state yönetimi için bir ref'e erişim sağlanır.
    const localRef = React.useRef();

    // Dışarıya metodlar veya özellikler sağlamak için useImperativeHandle kullanılır.
    useImperativeHandle(ref, () => ({
      setValue(value) {
        if (localRef.current) {
          localRef.current.value = value;
          input.onChange(value); // Formik veya Field ile entegre
        }
      },
      getValue() {
        return localRef.current?.value;
      },
    }));

    return (
      <TextField
        {...input}
        {...(inputRef && { inputRef: inputRef })}
        inputRef={localRef} // TextField'in native input elemanını bağlamak
        sx={{ width: "100%" }}
        label={label}
        {...(numberInput && { type: "number" })}
        variant="standard"
        defaultValue={defaultValue}
        onChange={(event) => {
          const value =
            numberInput || input.type === "number"
              ? event.target.value === ""
                ? undefined
                : +event.target.value
              : event.target.value;

          input.onChange(value);
          onChangeExtra && onChangeExtra(value);
        }}
        autoComplete="off"
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        inputProps={{ readOnly: rest.readOnly, maxLength: rest.maxLength }}
        {...rest}
      />
    );
  }
);

export default NewTextInputForm;
