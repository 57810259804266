import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function UrunlerInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  urunTipiRef,
  programRef,
  onChangeExtra,
  fieldsForText,
  fieldLabel,
  ...rest
}) {
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "Urunler/UrunlerListForSelect",
          fildsForText: fieldsForText ? fieldsForText : ["urunKodu", "urunAdi"],
          fieldForValue: "id",
          reqData: {
            ...{
              urunTipi:
                urunTipiRef?.current?.value === ""
                  ? rowData.urunTip
                  : urunTipiRef?.current?.value,
              program:
                programRef?.current?.value === ""
                  ? rowData.program
                  : programRef?.current?.value,
            },
          },
          fieldLabel: fieldLabel ? fieldLabel : "urunKoduAdi",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
