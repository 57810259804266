import { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
// import SirketRecord from "./SirketRecord";
import { PageConfig } from "./PotansiyelMusteriConfig";
import PotansiyelMusteriRecord from "./PotansiyelMusteriRecord";

function PotansiyelMusteri({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="PotansiyelMusteri"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekranKodu={ekranKodu}
      />
      <PotansiyelMusteriRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></PotansiyelMusteriRecord>
    </>
  );
}

export default PotansiyelMusteri;
