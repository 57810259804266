import { useRef } from "react";
import PageGrid from "../../components/grid/PageGrid";
import { PageConfig, PageConfigTeklif } from "./GorusmeMailTemplateConfig";
import PageFilter from "../../components/filter/PageFilter";
import GorusmeMailTemplateRecord from "./GorusmeMailTemplateRecord";

export default function GorusmeMailTemplate({ ekranKodu, listGridRef, tipi }) {
  const filterRef = useRef();
  const recordRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="GorusmeMailTemplateGrid"
        PageConfig={
          tipi === 1 ? PageConfig : tipi === 2 ? PageConfigTeklif : null
        }
        listGridRef={listGridRef}
        // filterRef={filterRef}
        reqData={{ tipi: tipi }}
        recordRef={recordRef}
        isCreatable={true}
        ekranKodu={ekranKodu}
        // CustomColumnButton={CustomColumnButton}
        // height={"45vh"}
        // allowSingleSelection={true}
        // onSelectionChanged={onSelectionChanged}
        editBtnVisible={true}
      />
      <GorusmeMailTemplateRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        tipi={tipi}
      ></GorusmeMailTemplateRecord>
    </>
  );
}
