import React, { useState } from "react";
import { AppContext } from "../common/AppContext";
import { HttpAuthorized } from "../common/common";
import { getEkranKoduArr } from "../common/constant-functions/getEkranKoduArr";
import { loadingVisible } from "../common/constant-functions/loadingVisible";
import { printToast } from "../common/constant-functions/printToast";
import { apiUrl } from "../common/constants";
import PageConfig from "../pages/KullaniciYetki/KullaniciYetkiConfig";
import validateFormValues from "../common/constant-functions/validateFormValues";

function useKullaniciYetkiValues(ref, detailGridRef, listGridRef, ekranKodu) {
  const [open, setOpen] = useState(false);
  const [recId, setRecId] = useState(0);
  const [rowData, setRowData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);

  React.useImperativeHandle(ref, () => ({
    setRecId: setRecId,
    recId: recId,
    openRecord: openRecord,
  }));

  const handleClose = () => {
    setOpen(false);
    setRecId(null);
    setRowData({});
    setModalLoading(true);
  };

  const dataSourceAyarla = (data) => {
    let tumEkranKodlari = getEkranKoduArr();

    let eklenecekEkranKodlari = tumEkranKodlari?.filter(
      (x) => !data?.find((y) => y?.ekranKodu === x)
    );

    data?.push(
      ...eklenecekEkranKodlari?.map((ekranKodu) => {
        return {
          ekranKodu: ekranKodu,
          goruntule: false,
          kaydet: false,
          sil: false,
        };
      })
    );

    return data;
  };

  async function fetchData(recId) {
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PageConfig.getByIdRoute}`,
      data: {
        id: recId,
      },
    })
      .then((res) => {
        printToast(res.data);
        setRowData(dataSourceAyarla(res.data.data));
        setModalLoading(false);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      });
  }

  async function insertOrUpdate(values) {
    loadingVisible(true);

    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${
        values.id ? PageConfig.updateRoute : PageConfig.insertRoute
      }`,
      data: {
        kullaniciid: recId,
        ...values,
      },
    })
      .then((res) => {
        printToast(res.data);
        setRowData(dataSourceAyarla(res.data.data));
        listGridRef?.current?.instance?.refresh();
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  async function handleDelete() {
    loadingVisible(true);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PageConfig.deleteRoute}`,
      data: { id: recId },
    })
      .then((res) => {
        printToast(res.data);
        setRowData({});
        listGridRef?.current?.instance?.refresh();
        handleClose();
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  function openRecord(recId) {
    if (recId > 0) {
      setRecId(recId);
      fetchData(recId);
    } else {
      setRecId(null);
      setRowData(dataSourceAyarla({ yetkiEkranlari: [] }));
      setModalLoading(false);
    }
    setOpen(true);
  }

  const handleSubmit = async (values) => {
    let gridData = detailGridRef.current.instance
      .getVisibleRows()
      .map((row) => row.data);

    values.yetkiEkranlari = gridData;

    await insertOrUpdate(values);
    // await insertOrUpdate(requestDataList);
  };

  const context = React.useContext(AppContext);

  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  return {
    rowData,
    setRowData,
    recId,
    setRecId,
    open,
    openRecord,
    handleClose,
    modalLoading,
    setModalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
  };
}

export default useKullaniciYetkiValues;
