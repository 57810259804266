import React, { useRef } from "react";
import MainTab from "../../components/tab/MainTab";
import { apiUrl } from "../../common/constants";
import BekleyenGorev from "./BekleyenGorev";
import IsYorumList from "./IsYorumList";
import { HttpAuthorized } from "../../common/common";
import Gorev from "./Gorev";
import { AppContext } from "../../common/AppContext";
import BayiBekleyenGorev from "./BayiBekleyenGorev";
import BayiGorev from "./BayiGorev";

export default function Gorevler({ ekranKodu }) {
  const yorumListRef = useRef();
  const listGridRef = useRef();
  const context = React.useContext(AppContext);

  const onSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData && selectedRowsData[0]) {
      let talepId = selectedRowsData[0].talepId;
      var lUrl = "";
      if (context.user.bayiKullanicisi === true) {
        lUrl = apiUrl + "BayiIsYorum/List";
        yorumList(talepId, lUrl);
      } else {
        lUrl = apiUrl + "IsYorum/List";
        yorumList(talepId, lUrl);
      }
    }
  };

  // console.log(context.user.bayiKullanicisi, "context.user.bayiKullanicisi");

  const yorumList = (talepId, lUrl) => {
    HttpAuthorized.post(lUrl, {
      talepId: talepId,
    }).then(async (res) => {
      await yorumListRef.current.setYorumData({
        talepId: talepId,
        yorumlar: res.data.data,
      });

      // await listGridRef.current.instance.refresh();
    });
  };

  const tabOnChangeExtra = () => {
    yorumListRef.current.setYorumData({
      talepId: null,
      yorumlar: null,
    });
  };

  return (
    <>
      <MainTab
        labels={["Bekleyen Görevler", "Tüm Görevler"]}
        tabOnChangeExtra={tabOnChangeExtra}
      >
        {context.user.bayiKullanicisi ? (
          <BayiBekleyenGorev
            ekranKodu={ekranKodu}
            onSelectionChanged={onSelectionChanged}
            yorumListRef={yorumListRef}
            listGridRef={listGridRef}
          ></BayiBekleyenGorev>
        ) : (
          <BekleyenGorev
            ekranKodu={ekranKodu}
            onSelectionChanged={onSelectionChanged}
            yorumListRef={yorumListRef}
            listGridRef={listGridRef}
          ></BekleyenGorev>
        )}
        {context.user.bayiKullanicisi ? (
          <BayiGorev
            ekranKodu={ekranKodu}
            onSelectionChanged={onSelectionChanged}
            listGridRef={listGridRef}
          ></BayiGorev>
        ) : (
          <Gorev
            ekranKodu={ekranKodu}
            onSelectionChanged={onSelectionChanged}
            listGridRef={listGridRef}
          ></Gorev>
        )}
      </MainTab>
      <IsYorumList ref={yorumListRef} listGridRef={listGridRef} />
    </>
  );
}
