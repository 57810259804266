import Type from "../../entities/TahminiTermin";

const PageConfig = {
  title: "TahminiTermin",
  ekranKodu: "TahminiTermin_Res",
  type: Type,
  listRoute: "TahminiTermin/List",
  getByIdRoute: "TahminiTermin/GetById",
  insertRoute: "TahminiTermin/Insert",
  updateRoute: "TahminiTermin/Update",
  deleteRoute: "TahminiTermin/Delete",
};

export default PageConfig;
