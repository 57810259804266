import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Button, Chip, Grid, IconButton, Paper } from "@mui/material";
import { Field, Form } from "react-final-form";
import { Search } from "@mui/icons-material";
import TextInputForm from "../inputs/form-inputs/TextInputForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutocomplateInputForm from "../inputs/form-inputs/AutocomplateInputForm";
import { ctheme } from "../../theme/theme";
import FilterFieldWrapper from "../inputs/form-inputs/FilterFieldWrapper";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";
import { FILTER_TYPE } from "../../common/entityconstants";
import CheckboxForm from "../inputs/form-inputs/CheckboxForm";
import DateInputForm2 from "../inputs/form-inputs/DateInputForm2";
import PopoverViaButton from "../buttons/PopoverViaButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import List from "@mui/material/List";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NavigateListItem from "../buttons/custom/NavigateListItem";
import SirketBazliSureAnaliziSutunInfoDialog from "../../pages/Rapor/SirketBazliSureAnaliziSutunInfoDialog";

const PageFilter = React.forwardRef(
  ({ PageConfig, listGridRef, sirketBazliSureAnalizinden }, ref) => {
    const [filterData, setFilterData] = useState({});
    const [customChipLabels, setCustomChipLabels] = useState({});

    const context = useContext(AppContext);

    useImperativeHandle(ref, () => ({
      filterData: filterData,
      setFilterData: setFilterData,
    }));

    const handleSubmit = (data) => {
      setFilterData(data);
      listGridRef?.current?.instance?.refresh();
    };

    // const filtreVar =
    //   PageConfig.type.filter((x) => x?.visible?.includes("filter")).length > 0;

    // console.log(
    //   PageConfig.type.filter((x) => x?.visible?.includes("filter")).length,
    //   "filtreVar"
    // );

    const sirketBazliSureAnaliziSurunAciklamasiDialogRef = useRef();

    const filtreVar = true;

    // const filtreVar =
    //   PageConfig.type
    //     .filter((x) => x?.visible?.includes("filter"))
    //     .map((i, index) => {
    //       if (
    //         (i.onlyYetkiGrubuTipi &&
    //           context.user.yetkiGrubuTipi !== i.onlyYetkiGrubuTipi) ||
    //         (i.gizlenecekYetkiGrubuTipi &&
    //           (Array.isArray(i.gizlenecekYetkiGrubuTipi)
    //             ? i.gizlenecekYetkiGrubuTipi.includes(context.user.yetkiGrubuTipi)
    //             : context.user.yetkiGrubuTipi === i.gizlenecekYetkiGrubuTipi))
    //       ) {
    //         return null;
    //       }
    //       return i;
    //     })
    //     .filter((item) => item !== null).length > 0;

    useEffect(() => {
      const defaultFilterData = PageConfig.type
        .filter((x) => x?.visible?.includes("filter"))
        .reduce((acc, entity) => {
          if (entity?.defaultValue !== null) {
            acc[entity.dataField] = entity.defaultValue;
          }
          return acc;
        }, {});

      setFilterData((prevState) => ({
        ...prevState,
        ...defaultFilterData,
      }));
    }, []);

    return (
      <>
        <Grid>
          <Form
            initialValues={filterData}
            onSubmit={handleSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} style={{ marginBottom: "3px" }}>
                {!filtreVar ? (
                  <Paper
                    sx={{
                      width: "100%",
                      p: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      boxShadow: "unset",
                      alignItems: "center",
                      bgcolor: "rgb(25, 118, 210, 0)",
                    }}
                  >
                    <Grid className="sag" sx={{ display: "flex" }}>
                      {sirketBazliSureAnalizinden === true ? (
                        <PopoverViaButton
                          PButton={() => (
                            <IconButton color="error" sx={{ mr: 2 }}>
                              <ErrorOutlineIcon />
                            </IconButton>
                          )}
                        >
                          <List sx={{ p: 1 }}>
                            <NavigateListItem
                              onClick={(e) => {
                                sirketBazliSureAnaliziSurunAciklamasiDialogRef?.current?.openRecord();
                              }}
                            >
                              <HelpOutlineIcon
                                sx={{ mr: 1, color: ctheme.colors.navyBlue }}
                                fontSize="small"
                              />
                              Müşteri Bazlı Süre Raporu Sütun Açıklamaları
                            </NavigateListItem>
                          </List>
                        </PopoverViaButton>
                      ) : null}
                    </Grid>
                    <Grid className="sag" sx={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Search />}
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          form.submit();
                        }}
                      >
                        Listele
                      </Button>
                    </Grid>
                  </Paper>
                ) : (
                  <MuiAccordion
                    defaultExpanded
                    variant="outlined"
                    disableGutters={true}
                    elevation={0}
                    sx={{
                      p: 0,
                      bgcolor: ctheme.colors.gridHeaderColor,
                    }}
                    TransitionProps={{ timeout: 0 }}
                    onChange={() => {
                      listGridRef?.current?.instance?.updateDimensions();
                    }}
                  >
                    <MuiAccordionSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                      sx={{
                        p: 0,
                        m: 0,
                        content: { margin: "0px 0px !important" },
                        minHeight: "38px",
                        flexDirection: "row-reverse",
                        bgcolor: ctheme.colors.gridHeaderColor,
                        "& .MuiAccordionSummary-content": {
                          margin: "0px", // Padding'i kaldırıyoruz
                        },
                      }}
                    >
                      <Paper
                        sx={{
                          width: "100%",
                          p: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "unset",
                          alignItems: "center",
                          bgcolor: "rgb(25, 118, 210, 0)",
                        }}
                      >
                        <Grid
                          container
                          className="sol"
                          sx={{ display: "flex", pl: 1 }}
                          spacing={1}
                        >
                          {Object.keys(values)
                            .filter((key) => values[key] || values[key] === 0)
                            .map((key, index) => {
                              let entity = PageConfig.type.find(
                                (x) =>
                                  x?.visible?.includes("filter") &&
                                  x.name === key
                              );

                              let value =
                                entity.dataType === "select"
                                  ? entity?.items?.find(
                                    (x) => x.Value === values[key]
                                  )?.Text
                                  : values[key];

                              return (
                                <React.Fragment key={index}>
                                  <Chip
                                    variant="filled"
                                    color="primary"
                                    sx={{ fontSize: "10pt", height: "26px" }}
                                    label={
                                      <>
                                        <span
                                          style={{
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {entity.label + ": "}
                                        </span>
                                        {entity.dataType === "date"
                                          ? dayjs(value).format("DD.MM.YYYY")
                                          : entity.dataType === "custom"
                                            ? customChipLabels[entity.name]
                                            : value}
                                      </>
                                    }
                                    onDelete={() => {
                                      form.change(key, null);
                                    }}
                                  />
                                </React.Fragment>
                              );
                            })}
                        </Grid>
                        <Grid className="sag" sx={{ display: "flex" }}>
                          {sirketBazliSureAnalizinden === true ? (
                            <PopoverViaButton
                              PButton={() => (
                                <IconButton color="error" sx={{ mr: 2 }}>
                                  <ErrorOutlineIcon />
                                </IconButton>
                              )}
                            >
                              <List sx={{ p: 1 }}>
                                <NavigateListItem
                                  onClick={() => {
                                    sirketBazliSureAnaliziSurunAciklamasiDialogRef?.current?.openRecord();
                                  }}
                                >
                                  <HelpOutlineIcon
                                    sx={{
                                      mr: 1,
                                      color: ctheme.colors.navyBlue,
                                    }}
                                    fontSize="small"
                                  />
                                  Müşteri Bazlı Süre Raporu Sütun Açıklamaları
                                </NavigateListItem>
                              </List>
                            </PopoverViaButton>
                          ) : null}
                        </Grid>
                        <Grid className="sag" sx={{ display: "flex" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            endIcon={<Search />}
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              form.submit();
                            }}
                          >
                            Listele
                          </Button>
                        </Grid>
                      </Paper>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails
                      sx={{
                        p: "4px ",
                        bgcolor: ctheme.colors.gridHeaderColor,
                      }}
                    >
                      <Paper
                        variant="outlined"
                        sx={{
                          display: "flex",
                          p: "4px",
                          bgcolor: "white",
                        }}
                      >
                        {PageConfig.type
                          .filter(
                            (x) => x.visible && x.visible.includes("filter")
                          )
                          .map((i, index) => {
                            if (
                              (i.onlyYetkiGrubuTipi &&
                                context.user.yetkiGrubuTipi !==
                                i.onlyYetkiGrubuTipi) ||
                              (i.gizlenecekYetkiGrubuTipi &&
                                (Array.isArray(i.gizlenecekYetkiGrubuTipi)
                                  ? i.gizlenecekYetkiGrubuTipi.includes(
                                    context.user.yetkiGrubuTipi
                                  )
                                  : context.user.yetkiGrubuTipi ===
                                  i.gizlenecekYetkiGrubuTipi))
                            ) {
                              return null;
                            }
                            return i.dataType === "text" ||
                              i.dataType === "number" ? (
                              <FilterFieldWrapper key={index} label={i.label}>
                                <Field
                                  name={i.name}
                                  component={TextInputForm}
                                  {...(i.dataType === "number" && {
                                    numberInput: true,
                                  })}
                                  onChangeExtra={() => {
                                    if (
                                      i.filterType &&
                                      i.filterType === FILTER_TYPE.FAST_FILTER
                                    ) {
                                      form.submit();
                                    }
                                  }}
                                />
                              </FilterFieldWrapper>
                            ) : i.dataType === "select" ? (
                              <FilterFieldWrapper key={index} label={i.label}>
                                <Field
                                  name={i.name}
                                  component={AutocomplateInputForm}
                                  items={i.items}
                                  defaultValue={i.defaultValue}
                                  onChangeFilter={() => {
                                    form.submit();
                                  }}
                                />
                              </FilterFieldWrapper>
                            ) : i.dataType === "date" ? (
                              <FilterFieldWrapper key={index} label={i.label}>
                                <Field
                                  name={i.name}
                                  component={DateInputForm2}
                                  defaultValue={i.defaultValue}
                                  isFilterPage={true}
                                />
                              </FilterFieldWrapper>
                            ) : i.dataType === "boolean" ? (
                              <FilterFieldWrapper key={index} label={i.label}>
                                <Field
                                  name={i.name}
                                  component={CheckboxForm}
                                  defaultValue={i.defaultValue}
                                />
                              </FilterFieldWrapper>
                            ) : i.dataType === "custom" ? (
                              <FilterFieldWrapper key={index} label={i.label}>
                                <Field
                                  name={i.name}
                                  component={i.customComponent}
                                  {...i.customComponentParams}
                                  rowData={filterData}
                                  onChangeExtra={(oldVal, newOpt) => {
                                    setCustomChipLabels({
                                      ...customChipLabels,
                                      [i.name]: newOpt?.Text,
                                    });
                                  }}
                                  onChangeFilter={() => {
                                    form.submit();
                                  }}
                                />
                              </FilterFieldWrapper>
                            ) : (
                              <>
                                <div key={index}>hatalı dataType</div>
                              </>
                            );
                          })}
                      </Paper>
                    </MuiAccordionDetails>
                  </MuiAccordion>
                )}
              </form>
            )}
          />
        </Grid>
        <SirketBazliSureAnaliziSutunInfoDialog
          ref={sirketBazliSureAnaliziSurunAciklamasiDialogRef}
        ></SirketBazliSureAnaliziSutunInfoDialog>
      </>
    );
  }
);

export default PageFilter;
