import React from "react";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useDialog from "../../hooks/use-dialog";
import * as yup from "yup";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Stack } from "@mui/system";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import { Save } from "@mui/icons-material";
import { TEKLIF_BIRIMI } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import useSubmit from "../../hooks/use-submit";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";

const BayidenMusteriyeDirekTeklifRecord = React.forwardRef(
  (
    { ekranKodu, talepId, setRowDataFnc, listGridRef, yetki, duzenlemeYapamaz },
    ref
  ) => {
    const { open, closeRecord, validateFormValues, data } = useDialog(ref);

    // const afterSubmitFnc = (res) => {
    //   if (res.data.data.analizSonucu == null) {
    //     alertDialogRef?.current?.openRecord();
    //   }
    // };

    const handleSubmit = useSubmit(
      { updateRoute: "Talep/BayidenMusteriyeDirekTeklifInsert" },
      setRowDataFnc,
      listGridRef
      // afterSubmitFnc
    );

    const CustomFieldWrapper = ({ label, modalLoading, children }) => {
      return (
        <FieldWrapper
          label={label}
          modalLoading={modalLoading}
          width={"80%"}
          textWidth={"20%"}
        >
          {children}
        </FieldWrapper>
      );
    };

    const validationSchema = yup.object().shape({
      //   teklifTutari: yup
      //     .number()
      //     .required()
      //     .min(
      //       data?.bayiTeklifTutari +
      //         (data?.bayiTeklifTutari * data?.bayiTeklifKdvOrani) / 100,
      //       "Müşteriye İletilecek Teklif Tutarı Kurumsal Yazılım Teklif Tutarından yüksek olmalıdır."
      //     ),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={closeRecord}
          modalTitle="Teklif Ver"
          maxWidth="md"
          canFullScreen={false}
        >
          <Form
            onSubmit={(values) =>
              handleSubmit({
                ...values,
                id: talepId,
              })
            }
            initialValues={{
              ...data,
              teklifBirimi: data?.teklifBirimi ?? TEKLIF_BIRIMI.TRY.Value,
            }}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    <CustomFieldWrapper
                      label="Teklif Tutarı"
                      //modalLoading={modalLoading}
                    >
                      <Field
                        name="teklifTutari"
                        component={NumberInputForm}
                        readOnly={!yetki.kaydet || duzenlemeYapamaz}
                      />
                    </CustomFieldWrapper>

                    <CustomFieldWrapper
                      label="Teklif Birimi"
                      // modalLoading={modalLoading}
                    >
                      <Field
                        name="teklifBirimi"
                        items={Object.values(TEKLIF_BIRIMI)}
                        //rowData={rowData}
                        component={AutocomplateInputForm}
                        readOnly={true}
                      />
                    </CustomFieldWrapper>

                    <CustomFieldWrapper
                      label="Kdv Oranı (%)"
                      // modalLoading={modalLoading}
                    >
                      <Field
                        name="teklifKdvOrani"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet || duzenlemeYapamaz}
                        numberInput={true}
                        defaultValue={0}
                      />
                    </CustomFieldWrapper>
                    {/* <FieldWrapper label="Analiz Sonucu"> */}
                    <Field
                      name="bayiAnalizSonucu"
                      component={RichTextEditor}
                      label={"Analiz Sonucu"}
                      // minRows={5}
                      // maxRows={5}
                      readOnly={!yetki.kaydet || duzenlemeYapamaz}
                      maxHeight="300px"
                    />
                    {/* </FieldWrapper> */}
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <ButtonWrapper
                    color="success"
                    startIcon={<Save />}
                    onClick={(e) => {
                      form.submit();
                      closeRecord();
                    }}
                    disabled={duzenlemeYapamaz}
                  >
                    Kaydet
                  </ButtonWrapper>
                </DialogActionsWrapper>
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default BayidenMusteriyeDirekTeklifRecord;
