import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form, FormSpy } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./TeklifProjeDetayConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import { AppContext } from "../../common/AppContext";
import dayjs from "dayjs";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";

const TeklifProjeDetayRecord = React.forwardRef(
  (
    {
      ekranKodu,
      listGridRef,
      detailGridRef,
      editGridRef,
      teklifid,
      masterid,
      setGridDs,
    },
    ref
  ) => {
    const context = React.useContext(AppContext);

    const initialState = {
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
      masterid: masterid,
      teklifid: teklifid,
    };

    const customFetchData = (id) => {
      let rows = editGridRef.current.instance.getVisibleRows();
      let currentRow = rows.find((x) => x.key == id);
      setRowData(currentRow.data);
      setModalLoading(false);
    };

    const customInsertOrUpdate = (values) => {
      var tempChanges = editGridRef.current.instance
        .option("editing.changes")
        .slice();
      if (recId) {
        let change = tempChanges.find((x) => x.key == recId);
        if (!change) {
          tempChanges.push({
            key: recId,
            data: {},
            type: "update",
          });
          change = tempChanges.find((x) => x.key == recId);
        }

        let rows = editGridRef.current.instance.getVisibleRows();
        let currentRow = rows.find((x) => x.key == recId);
        Object.keys(currentRow.data).map((key) => {
          if (values[key] != currentRow.data[key]) {
            change.data[key] = values[key];
          }
        });
      } else {
        tempChanges.push({
          key: crypto.randomUUID(),
          data: values,
          type: "insert",
        });
      }

      editGridRef.current.instance.option("editing.changes", tempChanges);

      handleClose();
    };

    const values = useRef(null);

    const afterDelete = () => {
      if (typeof recId === typeof "") {
        let tempChanges =
          editGridRef.current.instance.option("editing.changes");
        editGridRef.current.instance.option(
          "editing.changes",
          tempChanges.filter((x) => x.key != recId)
        );
      } else {
        let ds = editGridRef.current.instance.option("dataSource");
        setGridDs(ds.filter((x) => x.id != recId));
      }
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowData,
      setModalLoading,
      recId,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      detailGridRef,
      false,
      customFetchData,
      customInsertOrUpdate,
      null,
      afterDelete
    );

    const validationSchema = yup.object().shape({});

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Teklif Proje Detay Girişi"
          maxWidth="sm"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              reset,
              values: formValues,
            }) => (
              <FormComponent
                onSubmit={handleSubmit}
                style={{ height: "720px" }}
              >
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(props) => {
                    values.current = props.values;
                  }}
                />
                <DialogContentWrapper>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Proje Aşaması"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="projeAsamasi"
                        rowData={rowData}
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Başlangıç Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="baslangicTarihi"
                        rowData={rowData}
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Bitiş Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="bitisTarihi"
                        rowData={rowData}
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper label="Açıklama" modalLoading={modalLoading}>
                      <Field
                        name="aciklama"
                        rowData={rowData}
                        component={MultilineTextInputForm}
                        readOnly={!yetki.kaydet}
                        minRows={3}
                        maxRows={4}
                      />
                    </FieldWrapper>
                  </Grid>
                  {/* </DialogContentTop> */}
                  <DialogActionsWrapper>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  </DialogActionsWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default TeklifProjeDetayRecord;
