import { Button } from "devextreme-react/data-grid";
import { TALEP_TEKLIF_DURUMU } from "../../../common/constants";
import { AppContext } from "../../../common/AppContext";

import React from "react";

export default function GridTeklifPdfButton(
  pdfDialogRef,
  goruntule = true,
  alertDialogRef
) {
  const context = React.useContext(AppContext);

  return (
    <Button
      key="teklifDosyasi"
      hint="Teklif Dosyası"
      icon="pdffile"
      visible={(e) => {
        return context.user?.bayiKullanicisi
          ? e.row.data.bayiTeklifVerilisTarihi != null && goruntule
          : context.user?.bagliOlduguBayiid > 0
          ? ((e.row.data.teklifVerilisTarihi != null &&
              e.row.data.bayiTeklifVerilisTarihi != null) ||
              e.row.data.teklifVerilisTarihi != null) &&
            goruntule
          : (e.row.data.teklifVerilisTarihi != null ||
              e.row.data.bayiTeklifVerilisTarihi != null) &&
            goruntule;
      }}
      onClick={(e) => {
        pdfDialogRef?.current?.openRecord(
          e.row.data.id,
          e.row.data.teklifDurumu ===
            TALEP_TEKLIF_DURUMU.MUSTERIYE_TEKLIF_VERILDI.Value,
          e.row.data.teklifVerilisTarihi
        );
      }}
    />
  );
}

// export default GridTeklifPdfButton;
