import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function MahalleInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  ilceInputRef,
  onChangeExtra,
  ...rest
}) {
  const ilceidValue =
    typeof ilceInputRef !== "undefined" && ilceInputRef?.current?.value !== ""
      ? ilceInputRef?.current?.value
      : rowData?.ilceid;
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "UlkeSehirIlce/MahalleListForSelect",
          fildsForText: ["mahalleAdi"],
          fieldForValue: "mahalleid",
          reqData: {
            ...{
              ilceid: ilceidValue,
            },
          },
          // fieldLabel: "mahalleAdi",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
