import Type from "../../entities/GorusmeAciklamalari";

const PageConfig = {
  title: "GorusmeAciklamalari",
  ekranKodu: "GorusmeAciklamalari_Res",
  type: Type,
  listRoute: "GorusmeAciklamalari/List",
  getByIdRoute: "GorusmeAciklamalari/GetById",
  insertRoute: "GorusmeAciklamalari/Insert",
  updateRoute: "GorusmeAciklamalari/Update",
  deleteRoute: "GorusmeAciklamalari/Delete",
};

export { PageConfig };
