import { Grid } from "@mui/material";
import SirketBilgileri from "./SirketBilgileri";
import LogoGuncelleme from "./LogoGuncelleme";

function Profile() {
  return (
    <Grid
      container
      sx={{ width: "100%", display: "flex", alignItems: "flex-start" }}
    >
      <SirketBilgileri></SirketBilgileri>
      <LogoGuncelleme></LogoGuncelleme>
    </Grid>
  );
}

export default Profile;
