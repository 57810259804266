import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ctheme } from "../../theme/theme";
import { capitalizeFirstLetter } from "../../common/constant-functions/capitalizeFirstLetter";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { AppContext } from "../../common/AppContext";

function DrawerListItem({ route }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open);
  };

  const context = React.useContext(AppContext);

  const slashSay = route.path.split("/").length - 1;

  return (
    <>
      {route.children ? (
        <>
          <ListItemButton onClick={handleToggle}>
            <ListItemIcon
              sx={{
                minWidth: 40,
              }}
            >
              {open ? (
                <ExpandMoreIcon color="primary" />
              ) : (
                <ChevronRightIcon color="primary" />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{
                color: route.drawerTextColor
                  ? route.drawerTextColor
                  : ctheme.colors.navyBlue,
              }}
              primary={capitalizeFirstLetter(route.text)}
            />
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {route?.children
                ?.filter(
                  (x) =>
                    x.yetkisizEkran ||
                    context?.user?.yetkiler?.find(
                      (y) => x.ekranKodu === y.ekranKodu && y.goruntule
                    )
                )
                .map((childRoute, index) => (
                  <DrawerListItem route={childRoute} key={index} />
                ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItemButton onClick={() => navigate(route.path)}>
          <ListItemIcon
            sx={{
              paddingLeft: route.level * 3,
              minWidth: 40,
              // fontSize: slashSay > 1 ? "0.8" : "1",
            }}
          >
            {route.icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: route.drawerTextColor
                ? route.drawerTextColor
                : ctheme.colors.navyBlue,

              paddingLeft: route.level * 3,
              // fontSize: slashSay > 1 ? "0.8" : "1",
            }}
            primary={capitalizeFirstLetter(route.text)}
          />
        </ListItemButton>
      )}
    </>
  );
}

export default DrawerListItem;
