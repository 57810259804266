import { FileUploader } from "devextreme-react";
import React, { useState } from "react";
import { HttpAuthorizedFile } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import ScrollDialog from "../dialogs/ScrollDialog";
import { loadingVisible } from "../../common/constant-functions/loadingVisible";
import { Typography } from "@mui/material";

const DosyaYukle = React.forwardRef(
  (
    {
      route,
      reqExtraData,
      recordRef,
      rowData,
      setRowData,
      handleCloseExtra,
      allowedFileExtensions = [
        ".pdf",
        ".xls",
        ".xlsx",
        ".png",
        ".jpg",
        ".jpeg",
        ".docx",
        ".jfif", //burdan sonrası yeni eklendi
        ".ods",
        ".odt",
        ".rar",
        ".txt",
        ".xslt",
        ".zip",
      ],
      modalTitle,
      sutunSiralamasi,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    React.useImperativeHandle(ref, () => ({
      openModal: handleClickOpen,
      closeModal: handleClose,
      isOpen: open,
    }));

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle={modalTitle ?? "Dosya Yükle"}
          // sx={{ width: "430px" }}
          maxWidth="sm"
        >
          {sutunSiralamasi && (
            <>
              <Typography variant="h6" align="center">
                Sütun Sıralaması
              </Typography>
              <Typography variant="subtitle2" sx={{ padding: "5px" }}>
                {sutunSiralamasi}
              </Typography>
            </>
          )}

          <FileUploader
            style={{
              cursor: "pointer",
              border: "1px dashed grey",
            }}
            readyToUploadMessage="Dosya yüklemeye hazır"
            selectButtonText="Dosya Seç"
            labelText="veya dosyayı buraya sürükle"
            uploadMode="useButtons"
            uploadButtonText="Yükle"
            uploadAbortedMessage="Yükleme işlemi iptal edildi"
            uploadedMessage="Dosya Yükleniyor"
            uploadFailedMessage="Dosya Yüklenemedi"
            invalidMaxFileSizeMessage="Dosya boyutu yükleme için uygun değil. Dosya boyutu en fazla 20mb olabilir."
            // {...(allowedFileExtensions && {
            //   allowedFileExtensions: allowedFileExtensions,
            // })}
            maxFileSize={20971521} // 20mb max
            uploadFile={(file, callback) => {
              loadingVisible(true);
              var FormData = require("form-data");
              var reqData = new FormData();
              reqData.append("file", file);

              reqExtraData?.forEach((item) => {
                reqData.append(item.name, item.value);
              });

              HttpAuthorizedFile.post(route, reqData)
                .then((res) => {
                  printToast(res.data);
                  if (res.data.success) {
                    setRowData && setRowData(res.data.data);
                  }
                  recordRef?.current?.openRecord(rowData.id);
                  handleClose();
                  handleCloseExtra && handleCloseExtra();
                })
                .catch((error) => printToast(error.response.data))
                .finally(() => loadingVisible(false));
            }}
          />
        </ScrollDialog>
      </>
    );
  }
);

export default DosyaYukle;
