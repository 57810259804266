import * as yup from "yup";
import { Field, Form } from "react-final-form";
import KullaniciInputForm from "../../components/inputs/custom-form-inputs/KullaniciInputForm";
import React from "react";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";

import useBasicDialog from "../../hooks/use-basic-dialog";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import useSubmit from "../../hooks/use-submit";
import KullaniciListMerkezVeBayiInputForm from "../../components/inputs/custom-form-inputs/KullaniciListMerkezVeBayiInputForm";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import {
  ATAMALI_GORUSME_DURUMU,
  ATAMALI_GORUSME_TIPI,
} from "../../common/constants";

const GorusmeAtamaDialog = React.forwardRef(
  ({ gorusmeid, gorusmeRecordRef }, ref) => {
    const { open, closeRecord } = useBasicDialog(ref);

    const afterSubmitFnc = () => {
      gorusmeRecordRef?.current?.handleClose();
      closeRecord();
      // masterDialogClose();
    };

    const handleSubmit = useSubmit(
      {
        insertRoute: "Gorusmeler/GorusmeAta",
      },
      null,
      null,
      afterSubmitFnc
    );

    // const validationSchema = yup.object().shape({
    //   atananKulanici: yup.number().required(),
    // });

    // const { validateFormValues } = usePageRecord();

    // const validate = validateFormValues(validationSchema);

    // const validationSchema = yup.object().shape({});

    // const validate = validateFormValues(validationSchema);

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Görüşme Atama"
        maxWidth="sm"
        canFullScreen={false}
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            gorusmeid: gorusmeid,
            gorusmeTipi: ATAMALI_GORUSME_TIPI.ATANAN_GORUSME.Value,
            gorusmeDurumu: ATAMALI_GORUSME_DURUMU.YONLENDIRME.Value,
          }}
          // validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit} style={{ height: "250px" }}>
              <DialogContentWrapper>
                <FieldWrapper label="Atanan Kullanıcı">
                  <Field
                    name="atananKullanici"
                    component={KullaniciListMerkezVeBayiInputForm}
                    // rowData={rowData}
                  />
                </FieldWrapper>
                <FieldWrapper label="Görüşme Detayı">
                  <Field
                    name="gorusmeDetayi"
                    component={MultilineTextInputForm}
                    minRows={5}
                    maxRows={5}
                    // rowData={rowData}
                  />
                </FieldWrapper>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <SaveButton
                  onClick={(e) => {
                    form.submit().then(() => {
                      closeRecord();
                    });
                  }}
                />
                <CancelButton
                  onClick={() => {
                    form.reset();
                  }}
                  disabled={submitting || pristine}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default GorusmeAtamaDialog;
