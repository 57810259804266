import { useEffect, useRef, useState } from "react";
import { PageConfig } from "./FiyatListeDetayConfig";
import FiyatListeDetayRecord from "./FiyatListeDetayRecord";
import EditGrid from "../../components/grid/EditGrid";
import { useField } from "react-final-form";

function FiyatListeDetay({
  ekranKodu,
  rowData,
  listGridRef,
  detailGridRef,
  editGridRef,
}) {
  const recordRef = useRef();
  const ref = useRef();

  const [gridDs, setGridDs] = useState(rowData.detay);

  useEffect(() => {
    setGridDs(rowData.detay);
  }, [rowData]);

  const openRecord = (id) => {
    ref.current.openRecord(id);
  };

  const listeid = useField("id").input.value;

  return (
    <>
      <EditGrid
        entities={PageConfig.type}
        openRecord={openRecord}
        height={"60vh"}
        editGridRef={editGridRef}
        dataSource={gridDs ?? []}
        filtrele={true}
      ></EditGrid>
      <FiyatListeDetayRecord
        ref={ref}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        detailGridRef={detailGridRef}
        editGridRef={editGridRef}
        listeNo={listeid}
        setGridDs={setGridDs}
      ></FiyatListeDetayRecord>
    </>
  );
}

export default FiyatListeDetay;
