import React from "react";
import { Grid } from "@mui/material";
import { Form, Field } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import PageConfig from "./BayiTalepYonlendirmeConfig";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import { TALEP_DURUMU } from "../../common/constants";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";

const BayiTalepYonlendirmeDialog = React.forwardRef(
  ({ ekranKodu, listGridRef, afterSubmitFnc }, ref) => {
    const initialState = {
      talepDurumu: TALEP_DURUMU.CALISMA_YAPILIYOR.Value,
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    const validationSchema = yup.object().shape({});

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Talep Yönlendirme"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
              errors,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Yönlendirme Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="yonlendirmeTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Başlangıç Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="baslangicTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>

                      <FieldWrapper label="Sorumlu" modalLoading={modalLoading}>
                        <Field
                          name="sorumlular"
                          rowData={rowData}
                          remoteDataAdaptor={{
                            fetchRoute:
                              "User/KullaniciListForTalepYonlendirmeSelect",
                            fildsForText: ["isimSoyisim"],
                            fieldForValue: "id",
                            reqData: {
                              talepId: rowData.talepId,
                            },
                          }}
                          component={MultipleAutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Termin Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="terminTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      {/* <FieldWrapper
                        label="Talep Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="talepDurumu"
                          items={getTalepDurumuArr()}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper> */}
                      <FieldWrapper
                        label="Hedef Süre (Dakika)"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="hedefSure"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                          numberInput={true}
                        />
                      </FieldWrapper>
                      {/* <FieldWrapper
                        label="Üst Görev"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="ustid"
                          component={TalepUstTalepInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper> */}
                    </Grid>
                  </DialogContentTop>
                  <Field
                    name="analizSonucu"
                    component={RichTextEditor}
                    readOnly={!yetki.kaydet}
                    label={"Analiz Sonucu "}
                    modalLoading={modalLoading}
                  />
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={() => {
                      handleDelete({ id: rowData.talepId });
                    }}
                    submitting={submitting}
                    pristine={pristine}
                    submitExtra={() => {
                      if (Object.keys(errors).length < 1) {
                        handleClose();
                      }
                    }}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);

export default BayiTalepYonlendirmeDialog;
