import React, { useEffect, useRef } from "react";

import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import { Skeleton, Typography } from "@mui/material";
import "../../../css/RichTextEditor.css";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];

function CustomRichTextEditorforMailTemplates({
  input,
  meta,
  label,
  height,
  minHeight,
  maxHeight,
  style,
  disardanOnChange,
  onChangeExtra,
  value,
  ...rest
}) {
  const editorRef = useRef(null);
  useEffect(() => {
    if (editorRef?.current) {
      let element = editorRef?.current?.instance?.element();
      if (meta.error && meta.touched) {
        element.classList.add("final-form-error");
      } else {
        element.classList.remove("final-form-error");
      }
    }
  }, [meta]);

  useEffect(() => {
    if (input?.value && editorRef?.current) {
      editorRef?.current?.instance?.option("value", input.value);
    }
  }, [input.value, editorRef.current]);

  useEffect(() => {
    if (disardanOnChange) {
      console.log("input.value", input.value);

      disardanOnChange(input.value);
    }
  }, [input.value]);

  return rest.modalLoading ? (
    <Skeleton width="100%" height="300px"></Skeleton>
  ) : (
    <HtmlEditor
      //{...input}
      ref={editorRef}
      className={meta.error && meta.touched && "final-form-error"}
      // value={input.value}
      // value={value} // Gelen değeri ayarlıyoruz
      // {...(height && { height: height })}
      onValueChanged={(e, newVal) => {
        input.onChange(e.value);
      }}
      style={{
        minHeight: minHeight ? minHeight : "555px",
        maxHeight: maxHeight,
        // maxHeight: maxHeight || "68vh",
        height: height,
        flexBasis: "100vh",
        flexGrow: 1,
        ...style,
      }}
      {...rest}
    >
      <MediaResizing enabled={true} />
      <ImageUpload tabs={["file"]} fileUploadMode="base64" />
      <Toolbar multiline={true}>
        <Item>
          <Typography color="#1875d2" variant="subtitle2">
            {label}
          </Typography>
        </Item>
        <Item name="undo" />
        <Item name="redo" />
        {/* <Item name="separator" /> */}
        <Item name="size" acceptedValues={sizeValues} />
        <Item name="font" acceptedValues={fontValues} />
        <Item name="header" acceptedValues={headerValues} />
        {/* <Item name="separator" /> */}
        <Item name="bold" />
        <Item name="italic" />
        <Item name="strike" />
        <Item name="underline" />
        <Item name="separator" />
        <Item name="alignLeft" />
        <Item name="alignCenter" />
        <Item name="alignRight" />
        <Item name="alignJustify" />
        <Item name="separator" />
        <Item name="orderedList" />
        <Item name="bulletList" />
        <Item name="separator" />
        <Item name="color" />
        <Item name="background" />
        <Item name="separator" />
        <Item name="link" />
        <Item name="image" />
        <Item name="separator" />
        <Item name="clear" />
        <Item name="codeBlock" />
        <Item name="blockquote" />
        <Item name="separator" />
        <Item name="insertTable" />
        <Item name="deleteTable" />
        <Item name="insertRowAbove" />
        <Item name="insertRowBelow" />
        <Item name="deleteRow" />
        <Item name="insertColumnLeft" />
        <Item name="insertColumnRight" />
        <Item name="deleteColumn" />
      </Toolbar>
    </HtmlEditor>
  );
}

export default CustomRichTextEditorforMailTemplates;
