import React, { useRef } from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Delete, Save } from "@mui/icons-material";
import DeleteDialog from "../../dialogs/custom/DeleteDialog";
import CancelButton from "./CancelButton";
import { Grid } from "@mui/material";

export default function RecordButton({
  yetki,
  form,
  handleDelete,
  submitting,
  pristine,
  deleteCondition = true,
  submitExtra,
  submitButtonText,
  submitCondition = true,
  submitDisabled = false,
  deleteDisabled = false,
  modalLoading = false,
  yetkisizseAc = false,
  cancelCondition = true,
  deleteButtonText
}) {
  const alertDialogRef = useRef();
  return (
    !modalLoading && (
      <Grid
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "start",
        }}
      >
        {(yetki?.kaydet || yetkisizseAc) && submitCondition && (
          <ButtonWrapper
            color="success"
            startIcon={<Save />}
            onClick={(e) => {
              form.submit();
              submitExtra && submitExtra();
            }}
            disabled={submitDisabled}
          >
            {submitButtonText ?? "Kaydet"}
          </ButtonWrapper>
        )}
        {(yetki?.sil || yetkisizseAc) && deleteCondition && (
          <ButtonWrapper
            color="error"
            startIcon={<Delete />}
            onClick={() => {
              alertDialogRef?.current?.openRecord();
            }}
            disabled={deleteDisabled}
          >
            {deleteButtonText ?? "Sil"}
          </ButtonWrapper>
        )}
        {(yetki?.kaydet || yetkisizseAc) && cancelCondition && (
          <CancelButton
            onClick={() => {
              form.reset();
            }}
            disabled={submitting || pristine}
          />
        )}
        <DeleteDialog
          alertDialogRef={alertDialogRef}
          onayBtnClick={() => {
            handleDelete();
          }}
        />
      </Grid>
    )
  );
}
