import React, { useContext, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
  Button,
  Scrolling,
  FilterRow,
} from "devextreme-react/data-grid";
import SmallButton from "../buttons/SmallButton";
import { Add } from "@mui/icons-material";
import DipToplamGrid from "./DipToplamGrid";
import SelectBox, { SelectBoxTypes } from "devextreme-react/select-box";
import { AppContext } from "../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../common/constants";

const EditGrid = ({
  entities,
  openRecord,
  height,
  editGridRef,
  dataSource,
  ekleButtonGizli,
  dipToplamDataSource,
  dipToplamEntities,
  editButtonsGizli,
  filtrele,
  editButtonOnClick,
  rowPrepared,
  editButtonVisible,
  ekleButtonText
}) => {
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: "black",
        backgroundColor: color,
        height: 3,
      }}
    />
  );

  const [currentFilter, setCurrentFilter] = useState("auto");

  const context = useContext(AppContext);
  return (
    <div id="data-grid-demo">
      <DataGrid
        dataSource={dataSource}
        keyExpr={entities.find((x) => x.isPrimaryKey).name}
        showBorders={true}
        height={height}
        ref={editGridRef}
        columnResizingMode={"widget"}
        onRowPrepared={rowPrepared ? rowPrepared : null}
        toolbar={{
          items: [
            // { widget: 'dxButton', location: 'after', options: { icon: 'refresh' } },
          ],
        }}
        allowColumnResizing={true}
        // showRowLines={true}
        columnAutoWidth={true}
      >
        {filtrele && (
          <FilterRow
            visible={true}
            applyFilter={currentFilter}
          // showAllText={"Tümü"}
          // resetOperationText={"Temizle"}
          // operationDescriptions={{
          //   contains: "İçerir",
          //   notContains: "İçermez",
          //   equal: "Eşittir",
          //   notEqual: "Eşit Değildir",
          //   startsWith: "İle Başlar",
          //   endsWith: "İle Biter",
          //   between: "Arasında",
          //   greaterThan: "Büyüktür",
          //   lessThan: "Küçüktür",
          //   lessThanOrEqual: "Küçük Eşit",
          //   greaterThanOrEqual: "Büyük Eşit",
          // }}
          />
        )}

        <Scrolling columnRenderingMode="virtual" />
        <Paging enabled={true} />
        <Editing
          mode="batch"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          newRowPosition="last"

        //   selectTextOnEditStart={selectTextOnEditStart}
        //   startEditAction={startEditAction}
        />

        {entities
          .filter((type) => {
            let gridVisible = type.visible?.includes("grid");
            if (gridVisible) {
              if (type.visibleConditions) {
                let innerVisible = true;
                for (let i = 0; i < type.visibleConditions?.length; i++) {
                  if (type.visibleConditions[i].isHarmonyAdmin) {
                    innerVisible =
                      context.user.yetkiGrubuTipi ===
                      YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;
                    if (!innerVisible) break;
                  }
                  if (type.visibleConditions[i].gizlenecekYetkiGrubuTipi) {
                    if (
                      Array.isArray(
                        type.visibleConditions[i].gizlenecekYetkiGrubuTipi
                      )
                    ) {
                      innerVisible = !type.visibleConditions[
                        i
                      ].gizlenecekYetkiGrubuTipi.includes(
                        context.user.yetkiGrubuTipi
                      );
                    } else {
                      innerVisible =
                        context.user.yetkiGrubuTipi !==
                        type.visibleConditions[i].gizlenecekYetkiGrubuTipi;
                    }
                    if (!innerVisible) break;
                  }
                }

                return innerVisible;
              } else {
                return true;
              }
            } else return false;
          })
          .map((i, index) => {
            return (
              <Column
                key={i.name}
                dataType={i.dataType}
                caption={i.label}
                dataField={i.dataField}
                name={i.name}
                visibleIndex={index}
                allowEditing={false}
                alignment={
                  i.alignment
                    ? i.alignment
                    : i.dataType === "number"
                      ? "right"
                      : i.dataType === "boolean"
                        ? "center"
                        : "left"
                }
                defaultSortOrder={i.firstSort ? i.firstSort : null}
                width={i.width ? i.width : null}
                minWidth={i.minWidth ? i.minWidth : 100}
                format={i.format ? i.format : null}
                filterOperations={i.dataType === "text" ? ["contains"] : null}
                visible={i.pageGridHidden ? false : true}
                {...(i.calculateSortValue && {
                  calculateSortValue: i.calculateSortValue,
                })}
                {...(i.defaultSortIndex && {
                  defaultSortIndex: i.defaultSortIndex,
                })}
                {...(i.sortingMethod && {
                  sortingMethod: i.sortingMethod,
                })}
                {...(i.cellRender && { cellRender: i.cellRender })}
                {...(i.customizeText && { customizeText: i.customizeText })} //todo excel e aktarırken muinin bileşenleri aktarılmadığı için cell render kullanılan fieldlara bu alanın da eklenmesi gerekir
                allowExporting={i.allowExporting ?? true}
                allowSorting={i.allowSorting ?? true}
              >
                {i.dataType === "select" ? (
                  <Lookup
                    key={i.dataField + "_lookup"}
                    dataSource={i.selectItems}
                    displayExpr="Text"
                    valueExpr="Value"
                  ></Lookup>
                ) : null}
              </Column>
            );
          })}

        <Column
          type="buttons"
          visibleIndex={0}
          fixed={true}
          fixedPosition={0}
          alignment={"center"}
          visible={!editButtonsGizli}
        // headerCellRender={(e) => {
        //   return externalColumnButton(e);
        // }}
        >
          <Button
            hint={"Düzenle"}
            icon={"edit"}
            onClick={(e) => {
              editButtonOnClick ? editButtonOnClick(e) : openRecord(e.row.key);
            }}
            visible={(e) => {
              return editButtonVisible ? editButtonVisible(e) : true;
            }}
          ></Button>
        </Column>
      </DataGrid>
      {dipToplamDataSource && (
        <>
          <ColoredLine />
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {ekleButtonGizli ? null : (
              <SmallButton
                style={{ height: "30px" }}
                startIcon={<Add />}
                onClick={() => {
                  openRecord(null);
                }}
              >
                {ekleButtonText ? ekleButtonText : "Ekle"}
              </SmallButton>
            )}
            <DipToplamGrid
              style={{ flex: "5", alignItems: "flex-start" }}
              entities={dipToplamEntities}
              dataSource={dipToplamDataSource}
              height={"11vh"}
            ></DipToplamGrid>
          </div>
        </>
      )}
      {dipToplamDataSource || ekleButtonGizli ? null : (
        <SmallButton
          startIcon={<Add />}
          onClick={() => {
            openRecord(null);
          }}
        >
          Ekle
        </SmallButton>
      )}
    </div>
  );
};
export default EditGrid;
