import React, { useRef, useState } from "react";
import { AppContext } from "../../common/AppContext";
import FileList from "./FileList";
import { loadingVisible } from "../../common/constant-functions/loadingVisible";
import { HttpAuthorized } from "../../common/common";
import { apiUrl } from "../../common/constants";
import { printToast } from "../../common/constant-functions/printToast";
import { Form } from "react-final-form";
import DialogContentWrapper from "../dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../dialogs/DialogActionsWrapper";
import ButtonWrapper from "../buttons/ButtonWrapper";
import { Delete, Download } from "@mui/icons-material";
import DosyaYukleButton from "../buttons/custom/DosyaYukleButton";
import DosyaYukle from "./DosyaYukle";
import CustomColoredButton from "../buttons/custom/CustomColoredButton";

const FileViewer = React.forwardRef(
  (
    {
      ekranKodu,
      routes,
      reqData,
      parentRowData,
      rowData,
      setRowData,
      modalLoading,
      height,
      deleteDisabled
    },
    ref
  ) => {
    const dosyaYukleRef = useRef();

    const [deleteFileIds, setDeleteFileIds] = useState([]);

    function download(file) {
      const decoded = window.atob(file.bytes);
      const byteCharacters = decoded.split("").map((c) => c.charCodeAt(0));
      const byteArray = new Uint8Array(byteCharacters);
      const blob = new Blob([byteArray], { type: file.mime });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = file.fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    }

    async function fetchData(recId, requestData, fetchUrl) {
      loadingVisible(true);
      HttpAuthorized.request({
        method: "POST",
        url: apiUrl + fetchUrl,
        data: {
          ...(recId > 0 && { id: recId }),
          ...(requestData && { ...requestData }),
        },
      })
        .then((res) => {
          if (fetchUrl === routes.allFilesDownloadRoute) {
            res?.data?.data?.forEach((file) => {
              download(file);
            });
          } else {
            download(res?.data?.data);
          }
        })
        .catch((error) => {
          printToast(error.response.data);
          console.log(error);
        })
        .finally(() => loadingVisible(false));
    }

    function downloadFile(fileId) {
      fetchData(fileId, null, routes.fileDownloadRoute);
    }

    function downloadAllFiles() {
      fetchData(null, reqData, routes.allFilesDownloadRoute);
    }

    const context = React.useContext(AppContext);

    const yetki =
      context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

    const handleSubmit = () => { };

    async function handleDelete() {
      loadingVisible(true);
      HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${routes.deleteFilesRoute}`,
        data: { masterId: reqData.id, idList: deleteFileIds },
      })
        .then((res) => {
          printToast(res.data);
          setRowData(res.data.data || {});
          setDeleteFileIds([]);
        })
        .catch((error) => {
          printToast(error.response.data);
          console.log(error);
        })
        .finally(() => loadingVisible(false));
    }

    return (
      <>
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          // validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <FileList
                  files={rowData}
                  modalLoading={modalLoading}
                  downloadFile={downloadFile}
                  setDeleteFileIds={setDeleteFileIds}
                  deleteFileIds={deleteFileIds}
                  height={height}
                  yetki={yetki}
                  deleteDisabled={deleteDisabled}
                ></FileList>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {yetki?.kaydet && (
                  <DosyaYukleButton
                    onClick={() => {
                      dosyaYukleRef?.current?.openModal();
                    }}
                  />
                )}
                {yetki?.kaydet && rowData?.length > 0 && (
                  <CustomColoredButton
                    startIcon={<Download />}
                    onClick={downloadAllFiles}
                    color="pink"
                  >
                    Tüm Dosyaları İndir
                  </CustomColoredButton>
                )}
                {yetki?.sil && deleteFileIds.length > 0 && (
                  <ButtonWrapper
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => {
                      handleDelete();
                      form.reset();
                    }}
                  >
                    Seçilen Dosyaları Sil
                  </ButtonWrapper>
                )}
              </DialogActionsWrapper>
            </form>
          )}
        />
        <DosyaYukle
          route={routes.fileUploadRoute}
          reqExtraData={[{ name: "id", value: parentRowData?.id }]}
          ref={dosyaYukleRef}
          //   recordRef={ref}
          rowData={parentRowData}
          setRowData={setRowData}
        ></DosyaYukle>
      </>
    );
  }
);
export default FileViewer;
