import React, { useRef, useEffect, useState } from "react";
import PageGrid from "../../components/grid/PageGrid";
import { PageConfig } from "./GorusmeAciklamalariConfig";
import GorusmeAciklamalariRecord from "./GorusmeAciklamalariRecord";
import { ATAMALI_GORUSME_TIPI } from "../../common/constants";
import { Button } from "devextreme-react/data-grid";

function GorusmeAciklamalari({ ekranKodu, rowData, gorusmeRecordRef }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const [isReady, setIsReady] = useState(false);

  const onRowPreparedExtra = (e) => {

    if (e.rowType === "data" && e.data.gorusmeDurumu === 6) {
      e.rowElement.style.backgroundColor = "#df8c8c"; // Kırmızı  renk
    }
  };

  useEffect(() => {
    if (rowData?.id) {
      setIsReady(true);
    }
  }, [rowData?.id]);

  const customColumnButton = [
    <Button
      hint={"Düzenle"}
      icon={"edit"}
      onClick={(e) => {
        recordRef?.current?.openRecord(e.row.key);
      }}
      visible={(e) => {
        return (
          e?.row?.data?.gorusmeTipi !==
          ATAMALI_GORUSME_TIPI.ATANAN_GORUSME.Value
        );
      }}
      key={`edit-button-Duzenle_forGorusmeAciklama`}
    ></Button>,
  ];
  {
    /* <Button
      key="analizSonucu"
      hint={"Analiz Sonucu"}
      icon={"textdocument"}
      onClick={async (e) => {
        isSonucuDialogRef?.current?.openRecord(e.row.data.talepId);
        // analizSonucuDialogRef?.current?.openRecord(e?.row?.data?.talepId);
      }}
    ></Button>, */
  }

  return (
    <>
      {isReady && (
        <PageGrid
          gridId="GorusmeAciklamalari"
          PageConfig={PageConfig}
          listGridRef={listGridRef}
          recordRef={recordRef}
          isCreatable
          editBtnVisible={false}
          CustomColumnButton={customColumnButton}
          ekranKodu={ekranKodu}
          reqData={{ gorusmeid: rowData?.id }}
          exportButtonVisible={false}
          height="50vh"
          gridClearButtonVisible={false}
          onRowPreparedExtra={onRowPreparedExtra}
        />
      )}
      {isReady && (
        <GorusmeAciklamalariRecord
          ref={recordRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          gorusmeidData={rowData}
          gorusmeRecordRef={gorusmeRecordRef}
        />
      )}
    </>
  );
}

export default React.memo(GorusmeAciklamalari, (prevProps, nextProps) => {
  return (
    prevProps.rowData?.id === nextProps.rowData?.id &&
    prevProps.ekranKodu === nextProps.ekranKodu
  );
});
