import Type from "../../entities/BekleyenGorev";

const PageConfig = {
  title: "Bekleyen Görevler",
  ekranKodu: "Gorev_Res",
  type: Type,
  listRoute: "BayiBekleyenGorev/List",
};

export default PageConfig;
