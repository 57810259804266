import Type from "../../entities/SirketKisiler";

const PageConfig = {
  title: "SirketKisiler",
  ekranKodu: "SirketKisiler_Res",
  type: Type,
  listRoute: "SirketKisiler/List",
  getByIdRoute: "SirketKisiler/GetById",
  insertRoute: "SirketKisiler/Insert",
  updateRoute: "SirketKisiler/Update",
  deleteRoute: "SirketKisiler/Delete",
};

export { PageConfig };
