import React, { useRef } from "react";
import usePageRecord from "../../hooks/use-page-record";
import PageConfig from "./BayiIsYorumConfig";
import * as yup from "yup";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import { Field, Form, FormSpy } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Grid } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import dayjs from "dayjs";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import PersonelInputForm from "../../components/inputs/custom-form-inputs/PersonelInputForm";
import { apiUrl, ISIN_DURUMU, ISLEM_TURU } from "../../common/constants";
import { HttpAuthorized } from "../../common/common";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";
import AnalizSonucuButton from "../../components/buttons/custom/AnalizSonucuButton";
import RecordButton from "../../components/buttons/custom/RecordButton";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import YorumlariGoruntuleButton from "../../components/buttons/custom/YorumlariGoruntuleButton";
import YorumDialog from "../Home/YorumDialog";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";

const BayiIsYorumDialog = React.forwardRef(
  ({ ekranKodu, listGridRef, yorumListRef }, ref) => {
    var initialState = {
      tarih: dayjs(),
      // isinDurumu: null,
      // isSonucuYayinlansin: true,
    };

    const afterSubmitFnc = () => {
      let talepId = yorumListRef?.current?.talepId;
      HttpAuthorized.post(apiUrl + "BayiIsYorum/List", {
        talepId: talepId,
      }).then((res) => {
        // yorumListRef.current.setIsEdit(false);
        yorumListRef?.current?.setYorumData({
          talepId: talepId,
          yorumlar: res.data.data,
        });
      });
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      context,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    const sirketInputRef = useRef();
    const iletisimKurulanKisiInputRef = useRef();
    const isinDurumuInputRef = useRef();
    const analizSonucuDialogRef = useRef();
    const yorumDialogRef = useRef();

    const validationSchema = yup.object().shape({
      isSonucuBayi: yup.string().when("isinDurumu", {
        is: ISIN_DURUMU.TAMAMLANDI.Value,
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
      islemTuru: yup.number().required(),
      isinDurumu: yup.number().required(),
      harcananSure: yup.number().required(),
    });

    const validate = validateFormValues(validationSchema);

    const yorumReadOnly =
      rowData.yorumYapanKullaniciId > 0
        ? !(rowData.yorumYapanKullaniciId === context?.user?.id)
        : false;

    const isSonucuYayinlansinVisible = context?.user?.bayiKullanicisi == false;

    // if (typeof rowData.yorumYapanKullaniciId === "undefined") {
    //   rowData.isSonucuYayinlansin = true;
    // }

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle={`İş Yorumu - ${rowData?.talepId} Nolu Talep`}
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
              errors,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(props) => {
                    if (
                      props.values?.isinDurumu === ISIN_DURUMU.YENI_IS.Value
                    ) {
                      form.change("isinDurumu", null);
                    }
                    values.current = props.values;
                  }}
                />
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      {/* <FieldWrapper label="No" modalLoading={modalLoading}>
                      <Field disabled name="id" component={TextInputForm} />
                    </FieldWrapper> */}
                      {/* <FieldWrapper
                      label="Rapor Kategorisi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(RAPOR_KATEGORI)}
                        name="raporKategori"
                        component={AutocomplateInputForm}
                        readOnly={true}
                      />
                    </FieldWrapper> */}

                      <FieldWrapper label="Tarih" modalLoading={modalLoading}>
                        <Field
                          name="tarih"
                          component={DateInputForm2}
                          readOnly={
                            !yetki.kaydet ||
                            rowData.tahsilEdildi === true ||
                            yorumReadOnly
                          }
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="İşlem Türü"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="islemTuru"
                          items={Object.values(ISLEM_TURU)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet || yorumReadOnly}
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        label="İşin Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="isinDurumu"
                          items={Object.values(ISIN_DURUMU)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet || yorumReadOnly}
                          ref={isinDurumuInputRef}
                          disabledOptionValues={[
                            ISIN_DURUMU.YENI_IS.Value,
                            ...(yetki.yonlendir
                              ? []
                              : [
                                  ISIN_DURUMU.YAZILIM_TESTI.Value,
                                  ISIN_DURUMU.TEST_EDILIYOR.Value,
                                  ISIN_DURUMU.CEVAP_BEKLENIYOR.Value,
                                  ISIN_DURUMU.GOREVI_YONLENDIR.Value,
                                ]),
                          ]}
                        />
                      </FieldWrapper>
                      {(isinDurumuInputRef?.current?.value ===
                        ISIN_DURUMU.TEST_EDILIYOR.Value ||
                        rowData.isinDurumu ===
                          ISIN_DURUMU.TEST_EDILIYOR.Value ||
                        isinDurumuInputRef?.current?.value ===
                          ISIN_DURUMU.YAZILIM_TESTI.Value ||
                        rowData.isinDurumu ===
                          ISIN_DURUMU.YAZILIM_TESTI.Value ||
                        isinDurumuInputRef?.current?.value ===
                          ISIN_DURUMU.CEVAP_BEKLENIYOR.Value ||
                        rowData.isinDurumu ===
                          ISIN_DURUMU.CEVAP_BEKLENIYOR.Value ||
                        isinDurumuInputRef?.current?.value ===
                          ISIN_DURUMU.GOREVI_YONLENDIR.Value ||
                        rowData.isinDurumu ===
                          ISIN_DURUMU.GOREVI_YONLENDIR.Value) && (
                        <FieldWrapper
                          label="Sorumlu"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="sorumlular"
                            rowData={rowData}
                            remoteDataAdaptor={{
                              fetchRoute:
                                "User/KullaniciListForTalepYonlendirmeSelect",
                              fildsForText: ["isimSoyisim"],
                              fieldForValue: "id",
                              reqData: {
                                talepId: rowData.talepId,
                              },
                            }}
                            component={MultipleAutocomplateInputForm}
                            readOnly={!yetki.kaydet || yorumReadOnly}
                          />
                        </FieldWrapper>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="İş Grubu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirketId"
                          rowData={rowData}
                          component={SirketInputForm}
                          readOnly={true || yorumReadOnly}
                          // inputRef={sirketInputRef}
                          // personelInputRef={iletisimKurulanKisiInputRef}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="İş İçin Harcanan Süre (Dakika)"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="harcananSure"
                          component={TextInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            rowData.tahsilEdildi === true ||
                            yorumReadOnly
                          }
                          numberInput={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="İletişim Kurulan Kişi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="iletisimKurulanKisiId"
                          rowData={rowData}
                          component={PersonelInputForm}
                          readOnly={
                            !yetki.kaydet ||
                            sirketInputRef?.current?.value === "" ||
                            yorumReadOnly
                          }
                          inputRef={iletisimKurulanKisiInputRef}
                          ekranKodu={ekranKodu}
                          sirketInputRef={sirketInputRef}
                        />
                      </FieldWrapper>
                    </Grid>
                  </DialogContentTop>

                  <Field
                    name="yorum"
                    component={RichTextEditor}
                    readOnly={!yetki.kaydet || yorumReadOnly}
                    label={
                      isinDurumuInputRef?.current?.value ===
                        ISIN_DURUMU.TEST_EDILIYOR.Value ||
                      isinDurumuInputRef?.current?.value ===
                        ISIN_DURUMU.YAZILIM_TESTI.Value ||
                      isinDurumuInputRef?.current?.value ===
                        ISIN_DURUMU.CEVAP_BEKLENIYOR.Value ||
                      isinDurumuInputRef?.current?.value ===
                        ISIN_DURUMU.GOREVI_YONLENDIR.Value
                        ? "Açıklama"
                        : "Yorum"
                    }
                    modalLoading={modalLoading}
                  />
                  {isinDurumuInputRef?.current?.value ===
                    ISIN_DURUMU.TAMAMLANDI.Value && (
                    <>
                      {isSonucuYayinlansinVisible ? (
                        <>
                          <FieldWrapper
                            label="İş Sonucu Yayınlansın"
                            modalLoading={modalLoading}
                            textWidth={200}
                            width={"100%"}
                          >
                            <Field
                              name="isSonucuYayinlansin"
                              component={CheckboxForm}
                              readOnly={!yetki.kaydet || yorumReadOnly}
                              // label={"İş Sonucu Yayınlansın"}
                              modalLoading={modalLoading}
                            />
                          </FieldWrapper>
                        </>
                      ) : null}
                      <Field
                        name="isSonucuBayi"
                        component={RichTextEditor}
                        readOnly={!yetki.kaydet || yorumReadOnly}
                        label={"İş Sonucu"}
                        modalLoading={modalLoading}
                      />
                    </>
                  )}
                </DialogContentWrapper>
                <DialogActionsWrapper
                  container
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    <AnalizSonucuButton
                      onClick={(e) => {
                        analizSonucuDialogRef?.current?.openRecord(
                          rowData?.talepId
                        );
                      }}
                      disabled={!rowData.analizSonucu}
                    ></AnalizSonucuButton>
                    <YorumlariGoruntuleButton
                      onClick={(e) => {
                        yorumDialogRef?.current?.openRecord(rowData.talepId);
                      }}
                    />
                  </Grid>
                  {!yorumReadOnly && (
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      submitExtra={() => {
                        if (Object.keys(errors).length < 1) {
                          handleClose();
                        }
                      }}
                      modalLoading={modalLoading}
                    />
                  )}
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <AnalizSonucuDialog
          ref={analizSonucuDialogRef}
          ekranKodu={ekranKodu}
          isEditable={false}
        ></AnalizSonucuDialog>
        <YorumDialog ref={yorumDialogRef} isIsYorum={true}></YorumDialog>
      </>
    );
  }
);

export default BayiIsYorumDialog;
