import React, { useState } from "react";
import validateFormValues from "../common/constant-functions/validateFormValues";

function useDialog(ref) {
  const [open, setOpen] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [id, setId] = useState(0);
  const [data, setData] = useState();

  const openRecord = (id, _showActionButtons, otherData) => {
    setOpen(true);
    setId(id);
    if (_showActionButtons === true || _showActionButtons === false)
      setShowActionButtons(_showActionButtons);
    otherData && setData(otherData);
  };

  const closeRecord = (id) => {
    setOpen(false);
    setId(0);
    setData();
    setShowActionButtons(true);
  };

  React.useImperativeHandle(ref, () => ({
    openRecord: openRecord,
    closeRecord: closeRecord,
    id: id,
    data: data,
  }));

  return {
    open,
    openRecord,
    closeRecord,
    validateFormValues,
    id,
    showActionButtons,
    data,
  };
}

export default useDialog;
