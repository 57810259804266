import React, { useRef } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import YouTubeIcon from "@mui/icons-material/YouTube";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FolderIcon from "@mui/icons-material/Folder";
import PopoverViaButton from "../buttons/PopoverViaButton";
import { Divider, IconButton } from "@mui/material";
import { ctheme } from "../../theme/theme";
import TalepDurumInfoDilog from "../../pages/Profile/TalepDurumInfoDialog";
import List from "@mui/material/List";
import NavigateListItem from "../buttons/custom/NavigateListItem";
import routes from "../../common/routes";
import FiberNewIcon from "@mui/icons-material/FiberNew";

export default function AppBarInfoButton() {
  const talepDurumuInfoDialogRef = useRef();

  return (
    <>
      <PopoverViaButton
        PButton={() => (
          <IconButton color="error" sx={{ mr: 2 }}>
            <ErrorOutlineIcon />
          </IconButton>
        )}
      >
        <List sx={{ p: 1 }}>
          <NavigateListItem
            onClick={() => {
              window.open(
                // "https://disk.yandex.com.tr/d/kGCuBgjqDOxu7w",
                "https://disk.yandex.com/d/GeexnMqq-JCQrg",
                "_blank"
              );
            }}
          >
            <FolderIcon
              sx={{ mr: 1, color: ctheme.colors.navyBlue }}
              fontSize="small"
            />
            Dokümanlar
          </NavigateListItem>
          <Divider />

          <Divider />
          <NavigateListItem
            onClick={() => {
              window.open("https://www.youtube.com/@harmonyerptr", "_blank");

              // window.location.href =
              //   "https://disk.yandex.com.tr/d/kGCuBgjqDOxu7w";
            }}
          >
            <YouTubeIcon sx={{ mr: 1, color: "red" }} />
            Youtube Kanalı
          </NavigateListItem>
          <Divider />
          <NavigateListItem
            onClick={() => {
              talepDurumuInfoDialogRef?.current?.openRecord();
            }}
          >
            <HelpOutlineIcon
              sx={{ mr: 1, color: ctheme.colors.navyBlue }}
              fontSize="small"
            />
            Talep Durumları Neyi İfade Eder?
          </NavigateListItem>
          <Divider />
          <NavigateListItem path={routes.yeniEklenenOzellikler.path}>
            <FiberNewIcon sx={{ mr: 1 }} />
            Yeni Eklenen Özellikler
          </NavigateListItem>
          <Divider />
        </List>
      </PopoverViaButton>

      <TalepDurumInfoDilog ref={talepDurumuInfoDialogRef}></TalepDurumInfoDilog>
    </>
  );
}
