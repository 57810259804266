import React from "react";
import TextField from "@mui/material/TextField";
import "dayjs/locale/en-gb";

export default function DateInputForm({
  input,
  meta,
  label,
  defaultValue = "",
  ...rest
}) {
  const formatDate = (timestamp) => {
    if (!timestamp) return ""; // Eğer timestamp yoksa boş döndür

    const timestampInMilliseconds = timestamp.valueOf();
    const date = new Date(timestampInMilliseconds);

    if (isNaN(date.getTime())) {
      return ""; // Geçersiz tarihlerde boş döndür
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <TextField
      {...input}
      sx={{
        width: "100%",
        input: {
          ...(!input?.value ? { color: "grey" } : {}),
        },
      }}
      label={label}
      onChange={(e) => input.onChange(e.target.value)}
      variant="standard"
      type="date"
      value={
        // input?.value ? dayjs(input?.value)?.format("YYYY-MM-DD") : defaultValue
        formatDate(input?.value)
      }
      // value={input?.value}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ readOnly: rest.readOnly }}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      {...rest}
    />
  );
}
