import React, { useEffect, useState } from "react";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import "./CustomFormInputs/animation.css";
import { colors } from "@mui/material";

function CustomDateInputFormForStates({
  input = {},
  fieldCompWidth,
  loading,
  isDisabled,
  meta,
  customProps,
  readOnly,
  onChangeExtra,
  value, // fiiliBaslangicTarihi bu prop'tan gelecek
  onChange, // Değer değiştiğinde dışarıya bildirmek için
  ...rest
}) {
  const minDate = new Date(1990, 0, 1);
  const [date, setDate] = useState(value || null); // State, dışarıdan gelen 'value' ile başlatılıyor

  useEffect(() => {
    setDate(value); // 'value' değiştiğinde state güncelleniyor
  }, [value]);

  const onValueChanged = (e) => {
    setDate(e.value); // Tarih değiştiğinde state güncelleniyor
    onChange && onChange(e.value); // Eğer dışarıdan bir 'onChange' fonksiyonu varsa, yeni değeri bildir
  };

  return (
    <>
      <DateBox
        readOnly={readOnly}
        disabled={isDisabled}
        name="date"
        id={input?.name + "DateInputFormId" || "defaultDateInputId"}
        type="date"
        displayFormat="shortdate"
        dateSerializationFormat="yyyy-MM-dd"
        focusStateEnabled={false}
        activeStateEnabled={false}
        stylingMode="underlined"
        showTodayButton={true}
        showClearButton={true}
        min={minDate}
        {...(customProps ? customProps : {})}
        style={{ width: "100%", colors: "red" }}
        onValueChanged={onValueChanged}
        value={date} // State'den gelen değer
        calendarOptions={{ showTodayButton: true }}
        {...rest}
      >
        <DateBoxButton name="clear" location="after" />
        <DateBoxButton name="dropDown" />
      </DateBox>
      <span id={input.name} className={`${input.name} examp2`}></span>
    </>
  );
}

export default CustomDateInputFormForStates;
