import { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import { PageConfig, PageConfigForIlgiliKisiler } from "./NotConfig";
import NotRecord from "./NotRecord";
import EditablePageGridWithList from "../../components/grid/EditablePageGridWithList";
import MainTab from "../../components/tab/MainTab";

function Not({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const listGridRefBK = useRef();
  const filterRefBK = useRef();
  const recordRefBK = useRef();
  return (
    <>
      <MainTab labels={["Not", "Bağlantılı Notlarım"]}>
        <>
          <PageFilter
            PageConfig={PageConfig}
            ref={filterRef}
            listGridRef={listGridRef}
          />
          <EditablePageGridWithList
            gridId="Not"
            PageConfig={PageConfig}
            listGridRef={listGridRef}
            filterRef={filterRef}
            // recordRef={recordRef}
            isModalCreatable={true}
            ekranKodu={ekranKodu}
            recordRef={recordRef}
            editBtnVisible={true}
            // minWidth={1550}
          />
          <NotRecord
            ref={recordRef}
            ekranKodu={ekranKodu}
            listGridRef={listGridRef}
          ></NotRecord>
        </>
        <>
          <PageFilter
            PageConfig={PageConfigForIlgiliKisiler}
            ref={filterRefBK}
            listGridRef={listGridRefBK}
          />
          <EditablePageGridWithList
            gridId="NotForBaglantiliKisiler"
            PageConfig={PageConfigForIlgiliKisiler}
            listGridRef={listGridRefBK}
            filterRef={filterRefBK}
            // recordRef={recordRef}
            isModalCreatable={true}
            ekranKodu={ekranKodu}
            recordRef={recordRefBK}
            editBtnVisible={true}
            // minWidth={1550}
          />
          <NotRecord
            ref={recordRefBK}
            ekranKodu={ekranKodu}
            listGridRef={listGridRefBK}
          ></NotRecord>
        </>
      </MainTab>
    </>
  );
}

export default Not;
