import Type from "../../entities/Talep";

const PageConfig = {
  title: "İç Talepler",
  ekranKodu: "AnaSayfa_Res",
  type: Type,
  listRoute: "Talep/List",
  getByIdRoute: "Talep/GetById",
  insertRoute: "Talep/Insert",
  updateRoute: "Talep/Update",
  deleteRoute: "Talep/Delete",
  deleteWithFilesRoute: "Talep/DeleteWithFiles",
};
export default PageConfig;
