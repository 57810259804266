import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    firstSort: "desc",
    width: ID_WIDTH,
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
  },
  {
    name: "harcananSure",
    label: "Harcanan Süre(dk)",
    dataType: "number",
    dataField: "harcananSure",
    visible: ["grid"],
  },
];

export default entity;

//destek geçmişi : yazılımsal destek
