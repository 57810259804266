import { useRef, useState, useEffect } from "react";
import PageGrid from "../../components/grid/PageGrid";
import { PageConfig } from "./GorusmelerConfig";
import GorusmelerRecord from "./GorusmelerRecord";
import React from "react";

function GorusmelerPotansiyelMusteriden({ ekranKodu, rowData }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (rowData?.id) {
      setIsReady(true);
    }
  }, [rowData?.id]);

  return (
    <>
      {isReady && (
        <PageGrid
          gridId="Gorusmeler"
          PageConfig={PageConfig}
          listGridRef={listGridRef}
          filterRef={filterRef}
          recordRef={recordRef}
          isCreatable
          ekranKodu={ekranKodu}
          reqData={{ potFirmaid: rowData?.id }}
          exportButtonVisible={false}
          height="28vh"
          gridClearButtonVisible={false}
        />
      )}
      {isReady && (
        <GorusmelerRecord
          ref={recordRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          potansiyelData={rowData}
        />
      )}
    </>
  );
}

// Apply React.memo properly
export default React.memo(
  GorusmelerPotansiyelMusteriden,
  (prevProps, nextProps) => {
    // Only re-render if rowData.id or ekranKodu changes
    return (
      prevProps.rowData?.id === nextProps.rowData?.id &&
      prevProps.ekranKodu === nextProps.ekranKodu
    );
  }
);
