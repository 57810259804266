import { useEffect, useRef, useState } from "react";
import { PageConfig } from "./TeklifDetayConfig";
import TeklifDetayRecord from "./TeklifDetayRecord";
import EditGrid from "../../components/grid/EditGrid";
import { useField } from "react-final-form";

function TeklifDetay({
  ekranKodu,
  rowData,
  listGridRef,
  detailGridRef,
  editGridRef,
  duzenlemeYapamaz,
}) {
  const recordRef = useRef();
  const ref = useRef();
  const fiyatListeid = useField("fiyatListeid").input.value;
  const teklifid = useField("id").input.value;
  const kullaniciSayisi = useField("kullaniciSayisi").input.value;

  const [gridDs, setGridDs] = useState(rowData.detay);

  useEffect(() => {
    setGridDs(rowData.detay);
  }, [rowData]);

  const openRecord = (id) => {
    ref.current.openRecord(id);
  };

  return (
    <>
      <EditGrid
        entities={PageConfig.type}
        openRecord={openRecord}
        height={"40vh"}
        editGridRef={editGridRef}
        dataSource={gridDs ?? []}
        dipToplamDataSource={rowData.dipToplam ?? []}
        dipToplamEntities={PageConfig.typeDipToplam}
        ekleButtonGizli={duzenlemeYapamaz || !rowData?.id}
        editButtonsGizli={duzenlemeYapamaz}
        ekleButtonText={"Satır Ekle"}
      ></EditGrid>

      <TeklifDetayRecord
        ref={ref}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        detailGridRef={detailGridRef}
        editGridRef={editGridRef}
        fiyatListeid={fiyatListeid}
        teklifid={teklifid}
        xkullaniciSayisi={kullaniciSayisi}
        setGridDs={setGridDs}
      ></TeklifDetayRecord>
    </>
  );
}

export default TeklifDetay;
