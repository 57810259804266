import { Comment, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useContext, useRef } from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import TalepDetayiDialog from "../Gorev/TalepDetayiDialog";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import useSubmit from "../../hooks/use-submit";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import { FileConfig } from "../Musteri-talep/MusteriTalepConfig";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import TemsilciAnaliziDialog from "./TemsilciAnaliziDialog";
import YorumDialog from "./YorumDialog";
import YorumlariGoruntuleButton from "../../components/buttons/custom/YorumlariGoruntuleButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import HomeRecord from "./HomeRecord";
import {
  ANALIZ_TIPI,
  EKRAN_KODU,
  YETKI_GRUBU_TIPI,
} from "../../common/constants";
import TalepGoruntuleButton from "../../components/buttons/custom/TalepGoruntuleButton";
import { AppContext } from "../../common/AppContext";
import DeleteDialog from "../../components/dialogs/custom/DeleteDialog";
import DeleteDescriptionDialog from "../Gorev/DeleteDescriptionDialog";
import PopoverButton from "../../components/buttons/PopoverButton";
import OnAnalizDialog from "./OnAnalizDialog";
import BayiTemsilciAnaliziDialog from "./BayiTemsilciAnaliziDialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TahminiTerminDialog from "../TahminiTermin/TahminiTerminDialog";
import IseBaslangicGirisiDialog from "../IseBaslangicGirisi/IseBaslangicGirisiDialog";

const AnalizSonucuDialog = React.forwardRef(
  (
    {
      ekranKodu,
      isEditable,
      setRowDataFnc,
      buttonVisible = false,
      listGridRef,
    },
    ref
  ) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(
        ref,
        buttonVisible
          ? "Talep/AnalizSonucuGetByIdForGorevPage"
          : "Talep/AnalizSonucuGetById"
      );

    const afterSubmitFnc = (res) => {
      setRowData(res || {});
      setRowDataFnc &&
        setRowDataFnc((prevState) => ({
          ...prevState,
          analizSonucu: res.analizSonucu,
        }));
    };

    const handleSubmit = useSubmit(
      { updateRoute: "Talep/AnalizSonucuUpdate" },
      afterSubmitFnc
    );

    const talepDetayiDialogRef = useRef();
    const temsilciAnaliziDialogRef = useRef();
    const yorumDialogRef = useRef();
    const talepRecordRef = useRef();
    const context = useContext(AppContext);
    const deleteDialogRef = useRef();
    const deleteDescriptionDialogRef = useRef();

    const testKlavuzuDialogRef = useRef();
    const analizSonucuDialogRef = useRef();
    const xtemsilciAnaliziDialogRef = useRef();
    const bayiTemsilciAnaliziDialogRef = useRef();
    const onAnalizDialogRef = useRef();
    const tahminiTerminVerDialogRef = useRef();
    const iseBaslangicGirisiDialogRef = useRef();

    const yetki =
      context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

    const handleDelete = () => {
      deleteDescriptionDialogRef?.current?.openRecord();
    };

    const testKlavuzuYapabilir =
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

    const AnalizSonucuGoruntule =
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

    const onAnalizYapabilir =
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

    const bayiTemsilciAnaliziEditable =
      (context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI.Value ||
        context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI_DESTEK.Value) &&
      yetki.kaydet;

    const temsilciAnaliziEditable =
      (context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
        context?.user?.yetkiGrubuTipi ===
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value) &&
      yetki.kaydet;

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={closeRecord}
          modalTitle="Analiz Sonucu Yapılacak Geliştirmeler"
          maxWidth="lg"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            //validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <Field
                    name="analizSonucu"
                    component={RichTextEditor}
                    readOnly={!isEditable}
                    modalLoading={modalLoading}
                  />
                </DialogContentWrapper>
                <DialogActionsWrapper
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    {buttonVisible && (
                      <PopoverButton
                        label="Analiz Verileri"
                        backColor="#7272cc"
                        hoverBackColor="#3232a8"
                      >
                        <>
                          <EkDosyaButton
                            reqData={{
                              id: rowData.id,
                            }}
                            routes={FileConfig}
                            ekranKodu={ekranKodu}
                            parentRowData={rowData}
                            disabled={!rowData?.id}
                          ></EkDosyaButton>
                          {onAnalizYapabilir && (
                            <CustomColoredButton
                              startIcon={<Comment />}
                              onClick={(e) => {
                                // if (!(submitting || pristine)) {
                                //   alertDialogRef?.current?.openRecord();
                                // } else {
                                onAnalizDialogRef?.current?.openRecord(
                                  rowData?.id
                                );
                                // }
                              }}
                              disabled={!rowData.id}
                              color="purple"
                            >
                              Aksiyon Analizi
                            </CustomColoredButton>
                          )}
                          {testKlavuzuYapabilir && (
                            <CustomColoredButton
                              startIcon={<Comment />}
                              onClick={(e) => {
                                // if (!(submitting || pristine)) {
                                //   alertDialogRef?.current?.openRecord();
                                // } else {
                                testKlavuzuDialogRef?.current?.openRecord(
                                  rowData?.id
                                );
                                // }
                              }}
                              disabled={!rowData.id}
                              color="brown"
                            >
                              Test Klavuzu
                            </CustomColoredButton>
                          )}
                          {/* {AnalizSonucuGoruntule && (
                            <AnalizSonucuButton
                              onClick={(e) => {
                                // if (!(submitting || pristine)) {
                                //   alertDialogRef?.current?.openRecord();
                                // } else {
                                analizSonucuDialogRef?.current?.openRecord(
                                  rowData?.id
                                );
                                // }
                              }}
                              disabled={!rowData.id}
                              buttonCaption="Aksiyon Analizi"
                              color="lightPink"
                            ></AnalizSonucuButton>
                          )} */}
                          <CustomColoredButton
                            startIcon={<Comment />}
                            onClick={(e) => {
                              // if (!(submitting || pristine)) {
                              //   alertDialogRef?.current?.openRecord();
                              // } else {
                              temsilciAnaliziDialogRef?.current?.openRecord(
                                rowData?.id
                              );
                              // }
                            }}
                            disabled={!rowData.id}
                            color="green"
                          >
                            Merkez Temsilci Analizi
                          </CustomColoredButton>
                          <CustomColoredButton
                            startIcon={<Comment />}
                            onClick={(e) => {
                              // if (!(submitting || pristine)) {
                              //   alertDialogRef?.current?.openRecord();
                              // } else {
                              bayiTemsilciAnaliziDialogRef?.current?.openRecord(
                                rowData?.id
                              );
                              // }
                            }}
                            disabled={!rowData.id}
                            color="brown"
                          >
                            Bayi Temsilci Analizi
                          </CustomColoredButton>

                          <CustomColoredButton
                            startIcon={<Comment />}
                            onClick={(e) => {
                              talepDetayiDialogRef?.current?.openRecord(
                                rowData.id
                              );
                            }}
                            color="purple"
                            disabled={!rowData.talepDetayiVarMi}
                          >
                            Müşteri Talep Açıklama
                          </CustomColoredButton>
                          {/* {onAnalizYapabilir && (
                            <CustomColoredButton
                              startIcon={<Comment />}
                              onClick={(e) => {
                                if (!(submitting || pristine)) {
                                  alertDialogRef?.current?.openRecord();
                                } else {
                                  onAnalizDialogRef?.current?.openRecord(
                                    rowData?.id
                                  );
                                }
                              }}
                              disabled={!rowData.id}
                              color="green"
                            >
                              Ön Analiz
                            </CustomColoredButton>
                          )} */}
                        </>
                      </PopoverButton>
                    )}

                    {buttonVisible && (
                      <>
                        {/* <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            temsilciAnaliziDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          color="grape"
                          //disabled={!rowData.yonlendirenAciklama}
                        >
                          Temsilci Analizi
                        </CustomColoredButton> */}
                        {/* <CustomColoredButton
                          startIcon={<Comment />}
                          onClick={(e) => {
                            yonlendirenAciklamaDialogRef?.current?.openRecord(
                              talepDetayId
                            );
                          }}
                          color="grape"
                          //disabled={!rowData.yonlendirenAciklama}
                        >
                          Yönlendiren Görevli Açıklaması
                        </CustomColoredButton> */}

                        <YorumlariGoruntuleButton
                          onClick={(e) => {
                            yorumDialogRef?.current?.openRecord(rowData.id);
                          }}
                          caption={"Görev Süreçleri"}
                        />
                        {/* {context.user.yetkiGrubuTipi ===
                          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value && (
                          <>
                            <TalepGoruntuleButton
                              onClick={(e) => {
                                talepRecordRef?.current?.openRecord(rowData.id);
                              }}
                              label="Asıl Kaydı Aç"
                            />
                            <CustomColoredButton
                              startIcon={<Delete />}
                              onClick={(e) => {
                                deleteDialogRef?.current?.openRecord();
                              }}
                              color="red"
                            >
                              Görevi Sil
                            </CustomColoredButton>
                          </>
                        )} */}
                        <PopoverButton
                          label="İşlemler"
                          backColor="#515151"
                          hoverBackColor="#1b1818"
                        >
                          <CustomColoredButton
                            startIcon={<CalendarMonthIcon />}
                            onClick={(e) => {
                              tahminiTerminVerDialogRef?.current?.openRecord(
                                rowData.id
                              );
                            }}
                            color="purple"
                          >
                            TAHMİNİ TERMİN VER
                          </CustomColoredButton>
                          <CustomColoredButton
                            startIcon={<AccessTimeIcon />}
                            onClick={(e) => {
                              iseBaslangicGirisiDialogRef?.current?.openRecord(
                                rowData.id
                              );
                            }}
                            color="teal"
                          >
                            İŞE BAŞLANGIÇ GİRİŞİ
                          </CustomColoredButton>
                          {context.user.yetkiGrubuTipi ===
                            YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && (
                            <>
                              <TalepGoruntuleButton
                                onClick={(e) => {
                                  talepRecordRef?.current?.openRecord(
                                    rowData.id
                                  );
                                }}
                                label="Asıl Kaydı Aç"
                                color="brown"
                              />
                              <CustomColoredButton
                                startIcon={<Delete />}
                                onClick={(e) => {
                                  deleteDialogRef?.current?.openRecord();
                                }}
                                color="red"
                              >
                                Görevi Sil
                              </CustomColoredButton>
                            </>
                          )}
                        </PopoverButton>
                      </>
                    )}
                  </Grid>
                  <Grid>
                    {isEditable && (
                      <SaveButton
                        onClick={(e) => {
                          form.submit();
                        }}
                      />
                    )}
                    {isEditable && (
                      <CancelButton
                        onClick={() => {
                          form.reset();
                        }}
                        disabled={submitting || pristine}
                      />
                    )}
                  </Grid>
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        {buttonVisible && (
          <>
            <TalepDetayiDialog ref={talepDetayiDialogRef}></TalepDetayiDialog>
            <TemsilciAnaliziDialog
              ref={temsilciAnaliziDialogRef}
              ekranKodu={ekranKodu}
              isEditable={false}
            ></TemsilciAnaliziDialog>
            <YorumDialog
              ref={yorumDialogRef}
              isIsYorum={true}
              bayiKullanicisi={context.user?.bayiKullanicisi}
            ></YorumDialog>
            <HomeRecord
              ref={talepRecordRef}
              ekranKodu={EKRAN_KODU.ANASAYFA.value}
            ></HomeRecord>
            <DeleteDialog
              alertDialogRef={deleteDialogRef}
              onayBtnClick={() => {
                handleDelete();
              }}
              title={"Talebe bağlı görevler siliniyor!"}
            />
            <DeleteDescriptionDialog
              ref={deleteDescriptionDialogRef}
              ekranKodu={ekranKodu}
              talepId={rowData.id}
              listGridRef={listGridRef}
              masterDialogClose={closeRecord}
            ></DeleteDescriptionDialog>
            <OnAnalizDialog
              ref={onAnalizDialogRef}
              ekranKodu={ekranKodu}
              isEditable={true}
              setRowDataFnc={setRowDataFnc}
              listGridRef={listGridRef}
              tip={ANALIZ_TIPI.AKSIYON_ANALIZI.Value}
            />
            <OnAnalizDialog
              ref={testKlavuzuDialogRef}
              ekranKodu={ekranKodu}
              isEditable={true}
              setRowDataFnc={setRowDataFnc}
              listGridRef={listGridRef}
              tip={ANALIZ_TIPI.TEST_KLAVUZU.Value}
            />

            <AnalizSonucuDialog
              ref={analizSonucuDialogRef}
              ekranKodu={ekranKodu}
              isEditable={
                context?.user?.yetkiGrubuTipi ===
                  YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && yetki.kaydet
              }
              setRowDataFnc={setRowDataFnc}
            />
            <TemsilciAnaliziDialog
              ref={xtemsilciAnaliziDialogRef}
              ekranKodu={ekranKodu}
              isEditable={temsilciAnaliziEditable}
              setRowDataFnc={setRowDataFnc}
              listGridRef={listGridRef}
            />
            <BayiTemsilciAnaliziDialog
              ref={bayiTemsilciAnaliziDialogRef}
              ekranKodu={ekranKodu}
              isEditable={bayiTemsilciAnaliziEditable}
              setRowDataFnc={setRowDataFnc}
              listGridRef={listGridRef}
            />
            <TahminiTerminDialog
              ref={tahminiTerminVerDialogRef}
              ekranKodu={ekranKodu}
              // setRowDataFnc={setRowDataFnc}
              listGridRef={listGridRef}
            />
            <IseBaslangicGirisiDialog
              ref={iseBaslangicGirisiDialogRef}
              ekranKodu={ekranKodu}
              // setRowDataFnc={setRowDataFnc}
              listGridRef={listGridRef}
            />
          </>
        )}
      </>
    );
  }
);

export default AnalizSonucuDialog;
