import Type from "../../entities/SirketBazliSureAnalizi";

const PageConfig = {
  title: "Müşteri Bazlı Süre Raporu",
  ekranKodu: "SirketBazliSureAnalizi_Res",
  type: Type,
  listRoute: "Rapor/SirketBazliSureAnaliziList",
};

export default PageConfig;
