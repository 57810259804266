import React, { useContext } from "react";
import { Form, Field } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import {
  TarihAtamaConfig,
  BayiTarihAtamaConfig,
} from "./TalepTarihAtamaConfig";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import { AppContext } from "../../common/AppContext";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";

const TalepTarihAtamaDialog = React.forwardRef(
  ({ ekranKodu, listGridRef, afterSubmitFnc }, ref) => {
    const context = useContext(AppContext);

    var lConfig = context.user?.anaBayiKullanicisi
      ? TarihAtamaConfig
      : BayiTarihAtamaConfig;

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      lConfig,
      listGridRef,
      ekranKodu,
      null,
      afterSubmitFnc
    );

    const validationSchema = yup.object().shape({});

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Tarih Atama"
          maxWidth="xs"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
              errors,
            }) => (
              <FormComponent
                onSubmit={handleSubmit}
                style={{ height: "250px" }}
              >
                <DialogContentWrapper>
                  <FieldWrapper
                    label="Başlangıç Tarihi"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="baslangicTarihi"
                      component={DateInputForm2}
                      readOnly={!yetki.kaydet}
                    />
                  </FieldWrapper>
                  <FieldWrapper
                    label="Termin Tarihi"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="terminTarihi"
                      component={DateInputForm2}
                      readOnly={!yetki.kaydet}
                    />
                  </FieldWrapper>
                  <FieldWrapper
                    label="Hedef Süre (Dakika)"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="hedefSure"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                      numberInput={true}
                    />
                  </FieldWrapper>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={() => {
                      handleDelete({ id: rowData.talepId });
                    }}
                    submitting={submitting}
                    pristine={pristine}
                    submitExtra={() => {
                      if (Object.keys(errors).length < 1) {
                        handleClose();
                      }
                    }}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);

export default TalepTarihAtamaDialog;
