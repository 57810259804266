import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Send } from "@mui/icons-material";
import { BAYI_TALEP_DURUMU } from "../../../common/constants";

export default function BayiTalebiOnaylaBtn({
  bayiTalebiMerkezeGonderDialogRef,
  rowData,
  yetki,
}) {
  return rowData.bayiTeklifTutari == null &&
    rowData.teklifTutari != null ? null : (
    <>
      <ButtonWrapper
        //   color="error"
        startIcon={<Send />}
        onClick={() => {
          bayiTalebiMerkezeGonderDialogRef?.current?.openRecord(rowData?.id);
        }}
        disabled={!(rowData.id > 0)}
      >
        {rowData.bayiTalepDurumu !== BAYI_TALEP_DURUMU.ONAYLANDI.Value &&
        rowData.bayiTalepDurumu !== BAYI_TALEP_DURUMU.REDDEDILDI.Value
          ? "Talebi Merkeze Gönder"
          : "Talep Merkeze İletildi"}
      </ButtonWrapper>
    </>
  );
}
