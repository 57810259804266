import AlertDialog from "../../components/dialogs/AlertDialog";
import useSubmit from "../../hooks/use-submit";

export default function TeklifiReddedildiDialog({
  teklifReddetAlertDialogRef,
  teklifReddiniGeriAlAlertDialogRef,
  rowData,
  setRowDataFnc,
  listGridRef,
  reddet
}) {
  const handleSubmit = useSubmit(
    { updateRoute: "TeklifMaster/TeklifiReddet" },
    setRowDataFnc,
    listGridRef
  );

  const handleSubmitReddiGeriAl = useSubmit(
    { updateRoute: "TeklifMaster/TeklifReddiGeriAl" },
    setRowDataFnc,
    listGridRef
  );

  return (
    reddet ? (<AlertDialog
      ref={teklifReddetAlertDialogRef}
      onayBtnClick={() => {
        handleSubmit({ id: rowData.id });
      }}
      dialogTitle={"Teklif Reddediliyor!"}
      contentText={
        "Teklifi reddedildi olarak işaretlemek istediğinize emin misiniz?"
      }
      dialogActionsVisible={true}
    ></AlertDialog>) : (<AlertDialog
      ref={teklifReddiniGeriAlAlertDialogRef}
      onayBtnClick={() => {
        handleSubmitReddiGeriAl({ id: rowData.id });
      }}
      dialogTitle={"Teklif Reddi geri alıyor!"}
      contentText={
        "Teklifi reddini geri almak istediğinize emin misiniz?"
      }
      dialogActionsVisible={true}
    ></AlertDialog>)
  );
}
