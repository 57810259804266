import {
  ATAMALI_GORUSME_DURUMU,
  ATAMALI_GORUSME_TIPI,
  GORUSME_DURUMU,
  GORUSME_TIPI,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import { DATE_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Kişi No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },

  {
    name: "gorusmeTarihi",
    label: "Görüşme Tarihi",
    dataType: "datetime",
    dataField: "gorusmeTarihi",
    visible: ["grid"],
    width: DATE_WIDTH,
  },
  {
    name: "gorusmeDurumu",
    label: "Görüşme Durumu",
    dataType: "select",
    dataField: "gorusmeDurumu",
    visible: ["grid"],
    selectItems: Object.values(ATAMALI_GORUSME_DURUMU),
  },
  {
    name: "gorusmeTipi",
    label: "Görüşme Tipi",
    dataType: "select",
    dataField: "gorusmeTipi",
    visible: ["grid"],
    selectItems: Object.values(ATAMALI_GORUSME_TIPI),
  },
  {
    name: "atayanKullanici",
    label: "Atayan Kullanıcı",
    dataType: "text",
    dataField: "atayanKullanici",
    visible: ["grid"],
  },
  {
    name: "atamaTarihi",
    label: "Atama Tarihi",
    dataType: "text",
    dataField: "atamaTarihi",
    visible: ["grid"],
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "kayitEdenKullaniciAdi",
    label: "Kayıt Eden Kullanıcı",
    dataType: "text",
    dataField: "kayitEdenKullaniciAdi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },

  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "datetime",
    dataField: "kayitTarihi",
    visible: ["grid"],
    width: DATE_WIDTH,
  },
  //filtre--------------------------------------------------------------------------------------------------
];

export default entity;
