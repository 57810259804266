import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function IlceInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  sehirInputRef,
  onChangeExtra,
  ...rest
}) {
  const sehiridValue =
    typeof sehirInputRef !== "undefined" && sehirInputRef?.current?.value !== ""
      ? sehirInputRef?.current?.value
      : rowData?.sehirid;
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        remoteDataAdaptor={{
          fetchRoute: "UlkeSehirIlce/IlcelerListForSelect",
          fildsForText: ["ilceAdi"],
          fieldForValue: "ilceid",
          reqData: {
            ...{
              sehirid: sehiridValue,
            },
          },
          // fieldLabel: "ilceAdi",
        }}
        ref={inputRef}
        input={input}
        onChangeExtra={onChangeExtra}
        {...rest}
      />
    </>
  );
}
