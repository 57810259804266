import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"25%"}
      textWidth={"75%"}
    >
      {children}
    </FieldWrapper>
  );
};

const KullaniciParametreleriDialog = React.forwardRef(
  ({ ekranKodu, isEditable, setRowDataFnc, listGridRef }, ref) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, "KullaniciParametreleri/GetById");

    const afterSubmitFnc = (res) => {
      setRowDataFnc((prevState) => {
        return {
          ...prevState,
          // temsilciAnalizi: res?.data?.data?.temsilciAnalizi,
          // talepDurumu: res?.data?.data?.talepDurumu,
        };
      });
    };

    const handleSubmit = useSubmit(
      { updateRoute: "KullaniciParametreleri/Insert" },
      setRowData,
      listGridRef,
      afterSubmitFnc
    );

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Kullanıcı Parametreleri"
        maxWidth="sm"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <CustomFieldWrapper
                  label="Teklifte Birim Fiyat Değiştirebilir"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="teklifteBirimFiyatDegistirebilir"
                    component={CheckboxForm}
                    readOnly={!isEditable}
                    modalLoading={modalLoading}
                  />
                </CustomFieldWrapper>
                <CustomFieldWrapper
                  label="Talep Teklifleri Mail Olarak Gönderilsin"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="talepTeklifleriGonderilsin"
                    component={CheckboxForm}
                    readOnly={!isEditable}
                    modalLoading={modalLoading}
                  />
                </CustomFieldWrapper>
                <CustomFieldWrapper
                  label="Talepleri Kontrol Etsin"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="talepleriKontrolEtsin"
                    component={CheckboxForm}
                    readOnly={!isEditable}
                    modalLoading={modalLoading}
                  />
                </CustomFieldWrapper>
                <CustomFieldWrapper
                  label="Tüm Tekliflerin Bilgilerini Değiştirebilir"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="tumTekliflerinBilgileriniDegistirebilir"
                    component={CheckboxForm}
                    readOnly={!isEditable}
                    modalLoading={modalLoading}
                  />
                </CustomFieldWrapper>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {isEditable && (
                  <SaveButton
                    onClick={(e) => {
                      form.submit().then(() => {
                        closeRecord();
                      });
                    }}
                  />
                )}
                {isEditable && (
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                )}
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default KullaniciParametreleriDialog;
