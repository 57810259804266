import { useEffect, useRef, useState } from "react";
import { PageConfig } from "./TeklifProjeDetayConfig";
import EditGrid from "../../components/grid/EditGrid";
import { useField } from "react-final-form";
import TeklifProjeDetayRecord from "./TeklifProjeDetayRecord";

function TeklifProjeDetay({
  ekranKodu,
  rowData,
  listGridRef,
  detailGridRef,
  editGridRef,
  duzenlemeYapamaz,
}) {
  const recordRef = useRef();
  const ref = useRef();
  // const fiyatListeid = useField("fiyatListeid").input.value;
  const masterid = useField("id").input.value;
  const teklifid = useField("teklifid").input.value;
  // const kullaniciSayisi = useField("kullaniciSayisi").input.value;

  const [gridDs, setGridDs] = useState(rowData.detay);

  useEffect(() => {
    setGridDs(rowData.detay);
  }, [rowData]);

  const openRecord = (id) => {
    ref.current.openRecord(id);
  };

  return (
    <>
      <EditGrid
        entities={PageConfig.type}
        openRecord={openRecord}
        height={"45vh"}
        editGridRef={editGridRef}
        dataSource={gridDs ?? []}
        // dipToplamDataSource={rowData.dipToplam ?? []}
        // dipToplamEntities={PageConfig.typeDipToplam}
        ekleButtonGizli={duzenlemeYapamaz}
        editButtonsGizli={duzenlemeYapamaz}
      ></EditGrid>

      <TeklifProjeDetayRecord
        ref={ref}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        detailGridRef={detailGridRef}
        editGridRef={editGridRef}
        teklifid={teklifid}
        masterid={masterid}
        setGridDs={setGridDs}
      ></TeklifProjeDetayRecord>
    </>
  );
}

export default TeklifProjeDetay;
