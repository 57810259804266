import {
  DATE_FORMAT,
  POTANSIYEL_MUSTERI_TIPI,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import { DATE_WIDTH, ID_WIDTH } from "../common/entityconstants";
import dayjs from "dayjs";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";
import CheckboxForm from "../components/inputs/form-inputs/CheckboxForm";

const entity = [
  {
    name: "id",
    label: "Potansiyel M. No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "musteriAdi",
    label: "Potansiyel M. Adı",
    dataType: "text",
    dataField: "musteriAdi",
    visible: ["grid"],
  },
  {
    name: "vergiNo",
    label: "Vergi No",
    dataType: "text",
    dataField: "vergiNo",
    visible: ["grid"],
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "kayitEdenStr",
    label: "Kayıt Eden",
    dataType: "text",
    dataField: "kayitEdenStr",
    visible: ["grid"],
  },
  {
    name: "adres",
    label: "Adres",
    dataType: "text",
    dataField: "adres",
    visible: ["grid"],
  },
  {
    name: "webSitesi",
    label: "Web Sitesi",
    dataType: "text",
    dataField: "webSitesi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
  {
    name: "sirketTel",
    label: "Şirket Tel",
    dataType: "text",
    dataField: "sirketTel",
    visible: ["grid"],
  },
  {
    name: "sirketMail",
    label: "Şirket Mail",
    dataType: "text",
    dataField: "sirketMail",
    visible: ["grid"],
  },
  {
    name: "programidStr",
    label: "Program",
    dataType: "text",
    dataField: "programidStr",
    visible: ["grid"],
  },
  {
    name: "referans",
    label: "Referans",
    dataType: "text",
    dataField: "referans",
    visible: ["grid"],
  },
  {
    name: "iletisimDurumuidStr",
    label: "İletişim Durumu",
    dataType: "text",
    dataField: "iletisimDurumuidStr",
    visible: ["grid"],
  },
  {
    name: "sonGorusmeTarihi",
    label: "Son Görüşme Tarihi",
    dataField: "sonGorusmeTarihi",
    visible: ["grid"],
    dataType: "date",
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "musteriTipi",
    label: "Müşteri Tipi",
    dataType: "select",
    dataField: "musteriTipi",
    visible: ["grid"],
    selectItems: Object.values(POTANSIYEL_MUSTERI_TIPI),
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
        ],
      },
    ],
  },
  {
    name: "aktif",
    label: "Aktif",
    dataType: "boolean",
    dataField: "aktif",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "musteriAdi",
    label: "Müşteri Adı",
    dataType: "text",
    dataField: "musteriAdi",
    visible: ["filter"],
  },
  {
    name: "vergiNo",
    label: "Vergi No",
    dataType: "text",
    dataField: "vergiNo",
    visible: ["filter"],
  },
  {
    name: "filtreKayitTarihi1",
    label: "Kayıt Tarihi-1",
    dataType: "date",
    dataField: "filtreKayitTarihi1",
    visible: ["filter"],
    defaultValue: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  },
  {
    name: "filtreKayitTarihi2",
    label: "Kayıt Tarihi-2",
    dataType: "date",
    dataField: "filtreKayitTarihi2",
    visible: ["filter"],
    defaultValue: dayjs().format("YYYY-MM-DD"),
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
  {
    name: "musteriTipi",
    label: "Müşteri Tipi",
    dataType: "select",
    dataField: "musteriTipi",
    visible: ["filter"],
    items: Object.values(POTANSIYEL_MUSTERI_TIPI),
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
  {
    name: "tumunuGetir",
    label: "Tümünü Getir",
    dataType: "custom",
    dataField: "tumunuGetir",
    visible: ["filter"],
    customComponent: CheckboxForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;
