import React, { useRef, useEffect, useState } from "react";
import PageGrid from "../../components/grid/PageGrid";
import { PageConfig } from "./SirketKisilerConfig";
import SirketKisilerRecord from "./SirketKisilerRecord";

function SirketKisiler({ ekranKodu, rowData }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (rowData?.id) {
      setIsReady(true);
    }
  }, [rowData?.id]);

  return (
    <>
      {isReady && (
        <PageGrid
          gridId="SirketKisiler"
          PageConfig={PageConfig}
          listGridRef={listGridRef}
          recordRef={recordRef}
          isCreatable
          ekranKodu={ekranKodu}
          reqData={{ sirketid: rowData?.id }}
          exportButtonVisible={false}
          height="28vh"
          gridClearButtonVisible={false}
        />
      )}
      {isReady && (
        <SirketKisilerRecord
          ref={recordRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          sirketidData={rowData}
        />
      )}
    </>
  );
}

// Apply React.memo properly
export default React.memo(SirketKisiler, (prevProps, nextProps) => {
  // Only re-render if rowData.id or ekranKodu changes
  return (
    prevProps.rowData?.id === nextProps.rowData?.id &&
    prevProps.ekranKodu === nextProps.ekranKodu
  );
});
