import React, { useRef } from "react";
import FileViewDialog from "../../file/FileViewDialog";
import CustomColoredButton from "./CustomColoredButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function SatisTeklifiOnayliPdfDosyasiButton({
  routes,
  reqData,
  label,
  ekranKodu,
  parentRowData,
  disabled,
  deleteDisabled,
  ...rest
}) {
  const satisTeklifOnayliPdfDialogRef = useRef();

  return (
    <>
      {parentRowData.dosyaVar ? (
        <CustomColoredButton
          startIcon={<PictureAsPdfIcon />}
          onClick={() =>
            satisTeklifOnayliPdfDialogRef?.current?.openRecord(null, reqData)
          }
          {...rest}
          disabled={disabled}
          color={"#008000"}
        // sx={{ padding: "2px" }}
        ></CustomColoredButton>
      ) : (
        <CustomColoredButton
          startIcon={<PictureAsPdfIcon />}
          onClick={() =>
            satisTeklifOnayliPdfDialogRef?.current?.openRecord(null, reqData)
          }
          {...rest}
          disabled={disabled}
          color={"#008000"}
        // sx={{ padding: "2px" }}
        ></CustomColoredButton>
      )}

      <FileViewDialog
        ekranKodu={ekranKodu}
        routes={routes}
        ref={satisTeklifOnayliPdfDialogRef}
        reqData={reqData}
        parentRowData={parentRowData}
        deleteDisabled={deleteDisabled}
      ></FileViewDialog>
    </>
  );
}
