import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function IndeterminateCheckbox({
  items,
  input,
  label,
  onChangeExtra,
  disabled,
}) {
  const handleChange = (value) => {
    if (input.value.includes(value)) {
      input.onChange(input.value.filter((item) => item !== value));
    } else {
      input.onChange([...input.value, value]);
    }
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {items.map((item) => (
        <FormControlLabel
          key={item.Value}
          label={item.Text}
          disabled={disabled}
          control={
            <Checkbox
              checked={input.value.includes(item.Value)}
              onChange={(event, newValue) => {
                handleChange(item.Value);
                onChangeExtra && onChangeExtra(newValue, input.name);
              }}
              disabled={disabled}
            />
          }
        />
      ))}
    </Box>
  );

  return (
    <div>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            disabled={disabled}
            checked={input.value.length === items.length}
            indeterminate={
              input.value.length > 0 && input.value.length < items.length
            }
            onChange={(event, newValue) => {
              if (input.value.length === items.length) {
                input.onChange([]);
              } else {
                input.onChange(items.map((item) => item.Value));
              }
              onChangeExtra && onChangeExtra(newValue, input.name);
            }}
          />
        }
      />
      {children}
    </div>
  );
}
