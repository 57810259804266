import { useRef } from "react";
import PageGrid from "../../components/grid/PageGrid";
import BayiBekleyenGorevConfig from "./BayiBekleyenGorevConfig";
import { Button } from "devextreme-react/data-grid";
import { HttpAuthorized } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import BayiIsYorumDialog from "./BayiIsYorumDialog";
import PageFilter from "../../components/filter/PageFilter";
import dayjs from "dayjs";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";
import MusteriTalepRecord from "../Musteri-talep/MusteriTalepRecord";

export default function BayiBekleyenGorev({
  ekranKodu,
  onSelectionChanged,
  yorumListRef,
  listGridRef,
}) {
  const yorumDialogRef = useRef();
  const filterRef = useRef();
  const analizSonucuDialogRef = useRef();
  const ustGorevDialogRef = useRef();

  const CustomColumnButton = [
    <Button
      key="comment"
      hint={"Yorum Yap"}
      icon="undo"
      onClick={async (e) => {
        yorumDialogRef?.current?.openRecord();
        yorumDialogRef?.current?.setModalLoading(true);
        HttpAuthorized.request({
          method: "POST",
          url: "BayiIsYorum/IsYorumMasterGetById",
          data: {
            id: e.row.key,
          },
        })
          .then((res) => {
            printToast(res.data);
            yorumDialogRef?.current?.setRowDataFnc({
              ...res.data.data,
              tarih: dayjs(),
            });
            yorumDialogRef?.current?.setModalLoading(false);
          })
          .catch((error) => {
            printToast(error.response.data);
            console.log(error);
          });
      }}
    ></Button>,
    <Button
      key="analizSonucu"
      hint={"Analiz Sonucu"}
      icon={"textdocument"}
      onClick={async (e) => {
        analizSonucuDialogRef?.current?.openRecord(e?.row?.data?.talepId);
      }}
    ></Button>,
    <Button
      key="ustGoreviAc"
      hint={"Üst Görevini Aç"}
      icon={"arrowup"}
      onClick={async (e) => {
        ustGorevDialogRef?.current?.openRecord(e?.row?.data?.ustid);
      }}
      visible={(e) => {
        return e?.row?.data?.ustid;
      }}
    ></Button>,
  ];

  return (
    <>
      <PageFilter
        PageConfig={BayiBekleyenGorevConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="BayiBekleyenGorev"
        PageConfig={BayiBekleyenGorevConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        //recordRef={isYorumDialogRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        height={"45vh"}
        CustomColumnButton={CustomColumnButton}
        allowSingleSelection={true}
        onSelectionChanged={onSelectionChanged}
        editBtnVisible={false}
        ustGorevDialogRef={ustGorevDialogRef}
      />
      <BayiIsYorumDialog
        ref={yorumDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        yorumListRef={yorumListRef}
      ></BayiIsYorumDialog>
      <AnalizSonucuDialog
        ref={analizSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={false}
        buttonVisible={true}
        listGridRef={listGridRef}
      ></AnalizSonucuDialog>
      <MusteriTalepRecord
        ref={ustGorevDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></MusteriTalepRecord>
    </>
  );
}
