import React, { useRef } from "react";
import EditablePageGridWithList from "../../components/grid/EditablePageGridWithList";
import PageFilter from "../../components/filter/PageFilter";
import YazilimsalTahsilatConfig from "./YazilimsalTahsilatConfig";
import PdfDialog from "../../components/pdf/PdfDialog";
import { TALEP_DURUMU } from "../../common/constants";
import { Button } from "devextreme-react/data-grid";
import GridTeklifPdfButton from "../../components/buttons/custom/GridTeklifPdfButton";
import { AppContext } from "../../common/AppContext";

export default function YazilimsalTahsilatTakip({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const pdfDialogRef = useRef();
  const onayliPdfDialogRef = useRef();

  const context = React.useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  const CustomColumnButton = [
    GridTeklifPdfButton(pdfDialogRef, yetki?.teklifGoruntule),
    <Button
      key="onayliTeklifDosyasi"
      hint="Onaylı Teklif Dosyası"
      icon="exportselected"
      visible={(e) => {
        return e.row.data.onayliTeklifDosyasiVarMi && yetki?.teklifGoruntule;
      }}
      onClick={(e) => {
        onayliPdfDialogRef?.current?.openRecord(
          e.row.data.id,
          e.row.data.talepDurumu === TALEP_DURUMU.TEKLIFE_CEVAP_BEKLENIYOR.Value
        );
      }}
    />,
  ];

  return (
    <>
      <PageFilter
        PageConfig={YazilimsalTahsilatConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <EditablePageGridWithList
        gridId="YazilimsalTahsilatTakip"
        PageConfig={YazilimsalTahsilatConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // recordRef={recordRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        hasTab={true}
        CustomColumnButton={CustomColumnButton}
      />
      <PdfDialog
        ref={pdfDialogRef}
        title="Teklif"
        url="Talep/TeklifPdf"
      ></PdfDialog>
      <PdfDialog
        ref={onayliPdfDialogRef}
        title="Onaylı Teklif"
        url="Talep/TeklifOnayliBelge"
      />
    </>
  );
}
