import Type from "../../entities/GorevDurumuRaporu";

const PageConfig = {
  title: "Görev Durumu Raporu",
  ekranKodu: "GorevDurumuRaporu_Res",
  type: Type,
  listRoute: "Rapor/GorevDurumuRaporuList",
};

export default PageConfig;
