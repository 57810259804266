const PageConfig = {
  title: "İş Yorumları",
  ekranKodu: "Gorev_Res",
  listRoute: "BayiIsYorum/List",
  getByIdRoute: "BayiIsYorum/GetById",
  insertRoute: "BayiIsYorum/Insert",
  updateRoute: "BayiIsYorum/Update",
  deleteRoute: "BayiIsYorum/Delete",
};

export default PageConfig;
