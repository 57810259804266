import Type from "../../entities/TeklifMaster";

const PageConfig = {
  title: "TeklifMaster",
  ekranKodu: "TeklifMaster_Res",
  type: Type,
  listRoute: "TeklifMaster/List",
  getByIdRoute: "TeklifMaster/GetById",
  insertRoute: "TeklifMaster/Insert",
  updateRoute: "TeklifMaster/Update",
  deleteRoute: "TeklifMaster/Delete",
  teklifiReddetRoute: "TeklifMaster/TeklifiReddet",
};

const FileConfig = {
  allFilesDownloadRoute: "TeklifMaster/TeklifOnayiDosyalariIndir",
  fileDownloadRoute: "TeklifMaster/TeklifOnayiDosyaIndir",
  filesInfoRoute: "TeklifMaster/TeklifOnayiDosyaBilgileri",
  fileUploadRoute: "TeklifMaster/TeklifeDosyaYukle",
  deleteFilesRoute: "TeklifMaster/TeklifOnayiDosyaSil",
};

const TeklifOnayiFileConfig = {
  allFilesDownloadRoute: "TeklifMaster/TeklifOnayiDosyalariIndir",
  fileDownloadRoute: "TeklifMaster/TeklifOnayiDosyaIndir",
  filesInfoRoute: "TeklifMaster/TeklifOnayiDosyaBilgileri",
  fileUploadRoute: "TeklifMaster/TeklifOnayiDosyaYukle",
  deleteFilesRoute: "TeklifMaster/TeklifOnayiDosyaSil",
};

export { PageConfig, FileConfig, TeklifOnayiFileConfig };
