import { ILGILI_MODUL } from "../common/constants";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import {
  ACIKLAMA_WIDTH,
  DATE_WIDTH,
  ID_WIDTH,
  ILGILI_MODUL_WIDTH,
  LONG_DATE_WIDTH,
} from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Talep No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "ilgiliModul",
    label: "İlgili Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["grid"],
    selectItems: Object.values(ILGILI_MODUL),
    width: ILGILI_MODUL_WIDTH,
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: ACIKLAMA_WIDTH
  },
  {
    name: "programaEklenmeTarihi",
    label: "Versiyon Tarihi",
    dataType: "date",
    dataField: "programaEklenmeTarihi",
    visible: ["grid"],
    firstSort: "desc",
    width: LONG_DATE_WIDTH,
    minWidth: LONG_DATE_WIDTH
  },
  {
    name: "talepTamamlanmaTarihi",
    label: "Tamamlanma Tarihi",
    dataType: "date",
    dataField: "talepTamamlanmaTarihi",
    visible: ["grid"],
    width: "200px",
    minWidth: "200px",
  },
  {
    name: "erpVersiyonBilgisi",
    label: "Versiyon Bilgisi",
    dataType: "text",
    dataField: "erpVersiyonBilgisi",
    visible: ["grid"],
    width: DATE_WIDTH,
    minWidth: DATE_WIDTH,
  },
  //todo
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "tarih1",
    label: "Versiyon Tarihi 1",
    dataType: "date",
    dataField: "tarih1",
    visible: ["filter"],
  },
  {
    name: "tarih2",
    label: "Versiyon Tarihi 2",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
  },
  {
    name: "ilgiliModul",
    label: "Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["filter"],
    items: Object.values(ILGILI_MODUL),
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["filter"],
  },
  {
    name: "talepTamamlanmaTarihi1",
    label: "Tamamlanma Tarihi 1",
    dataType: "date",
    dataField: "talepTamamlanmaTarihi1",
    visible: ["filter"],
  },
  {
    name: "talepTamamlanmaTarihi2",
    label: "Tamamlanma Tarihi 2",
    dataType: "date",
    dataField: "talepTamamlanmaTarihi2",
    visible: ["filter"],
  },
];

export default entity;
