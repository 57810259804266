import React, { useRef } from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./PotansiyelKisilerConfig";
import DinamikTanimlarInputForm from "../../components/inputs/custom-form-inputs/DinamikTanimlarInputForm";
import { DINAMIK_TANIM_TIPLERI } from "../../common/constants";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const PotansiyelKisilerRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, potansiyelidData }, ref) => {
    const initialState = { potansiyelid: potansiyelidData?.id };

    const gorevDinamikTanimInputRef = useRef();

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

    const validationSchema = yup.object().shape({
      adSoyad: yup.string().required(),
      // adSoyad: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Kişi"
          maxWidth="xs"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <FormComponent onSubmit={handleSubmit}> */}
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    <CustomFieldWrapper
                      label="Kişi No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Potansiyel No"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="potansiyelid"
                        component={TextInputForm}
                        //readOnly={!yetki.kaydet}
                        disabled
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Ad Soyad"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="adSoyad"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="T.C. Kimlik No"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="tckn"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Görevi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorevi"
                        rowData={rowData}
                        component={DinamikTanimlarInputForm}
                        inputRef={gorevDinamikTanimInputRef}
                        ekranKodu={ekranKodu}
                        readOnly={!yetki.kaydet}
                        tip={DINAMIK_TANIM_TIPLERI.POTANSIYEL_KISI_GOREVI.Value}
                        caption="Görev Tanımlama"
                        aciklamaCaption="Görev"
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Firma Yetkilisi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="firmaYetkilisi"
                        rowData={rowData}
                        component={CheckboxForm}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper label="Tel" modalLoading={modalLoading}>
                      <Field
                        name="tel"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Mail"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="mail"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
                {/* </FormComponent> */}
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default PotansiyelKisilerRecord;
