import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./ProjeSurecTakipConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import ProjeSurecTakipDetay from "./ProjeSurecTakipDetay";
import { AppContext } from "../../common/AppContext";
import ProgressBarInputForm from "../../components/inputs/custom-form-inputs/ProgressBarInputForm";

const ProjeSurecTakipRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, detailGridRef }, ref) => {
    const context = React.useContext(AppContext);

    const cariTipiInputRef = useRef();

    const initialState = {};
    const editGridRef = useRef();

    const handleSubmitPrepareData = (values) => {
      let aa = {
        ...values,
        detay: editGridRef.current.instance.getVisibleRows().map((row) => {
          return row.data;
        }),
      };

      return aa;
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      () => {
        editGridRef.current?.instance?.cancelEditData();
      },
      null,
      null,
      null,
      handleSubmitPrepareData
    );

    const validationSchema = yup.object().shape({
      //   listeKodu: yup.string().required(),
      //   listeAdi: yup.string().required(),
      //   baslangicTarihi: yup.date().required(),
      //   bitisTarihi: yup.date().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Proje Süreç Takip"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Teklif No"
                        modalLoading={modalLoading}
                      >
                        <Field
                          disabled
                          name="teklifid"
                          component={TextInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Şirket Kod"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirket_Kod"
                          component={TextInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Şirket Adı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirketAdi"
                          component={TextInputForm}
                          readOnly={true}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="İlerleme Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="ilerlemeDurumu"
                          component={ProgressBarInputForm}
                          rowData={rowData}
                          readOnly={true}
                        />
                      </FieldWrapper>
                    </Grid>
                  </DialogContentTop>
                  <DialogActionsWrapper>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                      deleteCondition={false}
                    />
                  </DialogActionsWrapper>
                  <DialogContentWrapper>
                    <ProjeSurecTakipDetay
                      ekranKodu={ekranKodu}
                      rowData={rowData}
                      editGridRef={editGridRef}
                    ></ProjeSurecTakipDetay>
                  </DialogContentWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default ProjeSurecTakipRecord;
