import { ID_WIDTH } from "../common/entityconstants";
import { URUN_PROGRAMLARI, URUN_TIP_MODULPAKET } from "../common/constants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "masterid",
    label: "Liste No",
    dataType: "number",
    dataField: "masterid",
    visible: ["grid"],
    width: ID_WIDTH,
  },
  {
    name: "program",
    label: "Program",
    dataType: "select",
    dataField: "program",
    visible: ["grid"],
    selectItems: Object.values(URUN_PROGRAMLARI),
  },
  {
    name: "urunTip",
    label: "Ürün Tipi",
    dataType: "select",
    dataField: "urunTip",
    visible: ["grid"],
    selectItems: Object.values(URUN_TIP_MODULPAKET),
  },
  {
    name: "urunKoduAdi",
    label: "Ürün/Paket Kodu-Adı",
    dataType: "text",
    dataField: "urunKoduAdi",
    visible: ["grid"],
    filterOperations: ["contains", "startsWith", "endsWith"],
  },
  // {
  //   name: "urunKodu",
  //   label: "Ürün/Paket Kodu",
  //   dataType: "text",
  //   dataField: "urunKodu",
  //   visible: ["grid"],
  // },
  // {
  //   name: "urunAdi",
  //   label: "Ürün/Paket Adı",
  //   dataType: "text",
  //   dataField: "urunAdi",
  //   visible: ["grid"],
  // },
  {
    name: "kullaniciSayisi1",
    label: "Kullanıcı Sayısı - 1",
    dataType: "number",
    dataField: "kullaniciSayisi1",
    visible: ["grid"],
  },
  {
    name: "kullaniciSayisi2",
    label: "Kullanıcı Sayısı - 2",
    dataType: "number",
    dataField: "kullaniciSayisi2",
    visible: ["grid"],
  },
  {
    name: "birimFiyat",
    label: "Birim Fiyat",
    dataType: "number",
    dataField: "birimFiyat",
    visible: ["grid"],
  },
  {
    name: "birim",
    label: "Para Birimi",
    dataType: "text",
    dataField: "birim",
    visible: ["grid"],
  },
  {
    name: "kdvOrani",
    label: "KDV Oranı",
    dataType: "number",
    dataField: "kdvOrani",
    visible: ["grid"],
  },
  {
    name: "kdvTutari",
    label: "KDV Tutarı",
    dataType: "number",
    dataField: "kdvTutari",
    visible: ["grid"],
  },
  {
    name: "kdVliBirimFiyat",
    label: "KDVli Birim Fiyat",
    dataType: "number",
    dataField: "kdVliBirimFiyat",
    visible: ["grid"],
  },
  {
    name: "kaydiOlusturan",
    label: "Kaydı Oluşturan",
    dataType: "text",
    dataField: "kaydiOlusturan",
    visible: ["grid"],
  },
  {
    name: "degisiklikTarihi",
    label: "Değişiklik Tarihi",
    dataType: "date",
    dataField: "degisiklikTarihi",
    visible: ["grid"],
  },
];

export default entity;
