import React, { useRef } from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import { apiUrl, PARA_BIRIMI } from "../../common/constants";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { HttpAuthorized } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";

const TeklifiDovizliyeCevirDialog = React.forwardRef(
  (
    {
      ekranKodu,
      isEditable,
      setRowDataFnc,
      listGridRef,
      setRowDataWithGetById,
    },
    ref
  ) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, "TeklifMaster/DovizKoduGetir");

    const afterSubmitFnc = (res) => {
      setRowDataFnc((prevState) => {
        return {
          ...prevState,
          dovizKod: res?.data?.data?.dovizKod,
          dovizKuru: res?.data?.data?.dovizKuru,
        };
      });
      setRowDataWithGetById(res?.data?.data?.id);
      // masterRef.current.open(rowData.id);
      // afterSubmit(rowData.id);
    };

    var lModalTitle = "Dövizli Teklife Çevir";

    const handleSubmit = useSubmit(
      {
        updateRoute: "TeklifMaster/DovizliTeklifeCevir",
        insertRoute: "TeklifMaster/DovizliTeklifeCevir",
      },
      setRowData,
      listGridRef,
      afterSubmitFnc
    );

    var dovizKuruSecimEnabled = useRef(null);

    dovizKuruSecimEnabled = rowData.dovizKod !== "TL";

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle={lModalTitle}
        maxWidth="sm"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit} style={{ height: "200px" }}>
              <DialogContentWrapper>
                <FieldWrapper label="Döviz Kod" modalLoading={modalLoading}>
                  <Field
                    name={"dovizKod"}
                    component={AutocomplateInputForm}
                    items={Object.values(PARA_BIRIMI)}
                    // readOnly={!isEditable}
                    modalLoading={modalLoading}
                    onChangeExtra={(old, newVal) => {
                      if (newVal?.Value === PARA_BIRIMI.TL.Value) {
                        form?.change("dovizKuru", 1);
                        dovizKuruSecimEnabled = false;
                      } else {
                        HttpAuthorized.request({
                          method: "POST",
                          url: `${apiUrl}TeklifMaster/DovizKuruGetir`,
                          data: {
                            teklifid: rowData?.id,
                            dovizKod: newVal.Value,
                          },
                        })
                          .then((res) => {
                            if (res.data.data !== null) {
                              form?.change(
                                "dovizKuru",
                                res.data.data.dovizKuru
                              );
                            } else {
                            }
                          })
                          .catch((error) => {
                            printToast(error.response.data);
                          });

                        dovizKuruSecimEnabled = true;
                      }
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper label="Döviz Kuru" modalLoading={modalLoading}>
                  <Field
                    name={"dovizKuru"}
                    component={NumberInputForm}
                    // readOnly={!isEditable}
                    modalLoading={modalLoading}
                    readOnly={!dovizKuruSecimEnabled}
                  />
                </FieldWrapper>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {isEditable && (
                  <SaveButton
                    onClick={(e) => {
                      form.submit().then(() => {
                        closeRecord();
                      });
                    }}
                  />
                )}
                {isEditable && (
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                )}
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default TeklifiDovizliyeCevirDialog;
