import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import TamamButton from "../../components/buttons/custom/TamamButton";
import useBasicDialog from "../../hooks/use-basic-dialog";

const YonlendirmeAciklamasiDialog = React.forwardRef(
  ({ listGridRef, rowData }, ref) => {
    const { open, closeRecord } = useBasicDialog(ref);

    const handleSubmit = () => {};
    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Yönlendirme Açıklaması"
        maxWidth="lg"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent>
              <DialogContentWrapper>
                <Field
                  name={"analizSonucu"}
                  component={RichTextEditor}
                  readOnly={true}
                  // modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <TamamButton
                  onClick={(e) => {
                    closeRecord();
                  }}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default YonlendirmeAciklamasiDialog;
