import Type from "../../entities/Yetki";

const PageConfig = {
  title: "KullaniciYetkiler",
  ekranKodu: "KullaniciYetki_Res",
  type: Type,
  listRoute: "KullaniciYetki/YetkiGrubuList",
  getByIdRoute: "KullaniciYetki/GetById",
  insertRoute: "KullaniciYetki/Insert",
  updateRoute: "KullaniciYetki/Update",
  deleteRoute: "KullaniciYetki/Delete",
};

export default PageConfig;
