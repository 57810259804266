import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import {
  EVET_HAYIR,
  TAHSILAT_DURUMU_INT,
  TUTAR_FORMAT,
  YETKI_GRUBU_TIPI,
} from "../common/constants";
import { LONG_DATE_WIDTH } from "../common/entityconstants";
import BayilerInputForm from "../components/inputs/custom-form-inputs/BayilerInputForm";

const entity = [
  {
    name: "id",
    label: "Talep No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    firstSort: "desc",
    width: "100px",
  },
  {
    name: "bayidenTahsilEdildi",
    label: "Tahsil Edildi",
    dataType: "boolean",
    dataField: "bayidenTahsilEdildi",
    visible: ["grid"],
    allowEditing: true,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "tahsilEdildi",
    label: "Tahsil Edildi",
    dataType: "boolean",
    dataField: "tahsilEdildi",
    visible: ["grid"],
    allowEditing: true,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "tahsilEdilenTutar",
    label: "Tahsil Edilen Tutar",
    dataType: "number",
    dataField: "tahsilEdilenTutar",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    allowEditing: true,
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "bayidenTahsilEdilenTutar",
    label: "Tahsil Edilen Tutar",
    dataType: "number",
    dataField: "bayidenTahsilEdilenTutar",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    allowEditing: true,
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "tahsilEdilenTutar",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Tahsil Edilen Tutar: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "bayidenTahsilEdilenTutar",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Tahsil Edilen Tutar: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "sirketAdi",
    label: "Firma Adı",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
  },
  {
    name: "analizSonucu",
    label: "Analiz Sonucu",
    dataType: "text",
    dataField: "analizSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: "400px",
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: "400px",
  },
  {
    name: "teklifTutari",
    label: "Teklif Tutarı",
    dataType: "number",
    dataField: "teklifTutari",
    visible: ["grid"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
    format: TUTAR_FORMAT,
  },
  {
    name: "bayiTeklifTutari",
    label: "Teklif Tutarı",
    dataType: "number",
    dataField: "bayiTeklifTutari",
    visible: ["grid"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
    format: TUTAR_FORMAT,
  },
  {
    column: "teklifTutari",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Teklif Tutarı: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "bayiTeklifTutari",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Teklif Tutarı: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "teklifKdvTutari",
    label: "Kdv Tutarı",
    dataType: "number",
    dataField: "teklifKdvTutari",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "bayiTeklifKdvTutari",
    label: "Kdv Tutarı",
    dataType: "number",
    dataField: "bayiTeklifKdvTutari",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "teklifKdvTutari",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Teklif Kdv Tutarı: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "bayiTeklifKdvTutari",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Teklif Kdv Tutarı: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "genelToplam",
    label: "Genel Toplam",
    dataType: "number",
    dataField: "genelToplam",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "bayiGenelToplam",
    label: "Genel Toplam",
    dataType: "number",
    dataField: "bayiGenelToplam",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "genelToplam",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Genel Tutar: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    column: "bayiGenelToplam",
    summaryType: "sum",
    valueFormat: TUTAR_FORMAT,
    displayFormat: "Toplam Genel Tutar: {0}",
    visible: ["summary"],
    visibleConditions: [
      {
        isTeklifGoruntule: true,
      },
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "talepEdenPersonelIdStr",
    label: "İşi Talep Eden",
    dataType: "text",
    dataField: "talepEdenPersonelIdStr",
    visible: ["grid"],
  },
  {
    name: "programaEklenmeTarihi",
    label: "İşin Bitiş Tarihi",
    dataType: "date",
    dataField: "programaEklenmeTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "baslangicTarihi",
    label: "İşin Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "bayiAdi",
    label: "Bayi Adı",
    dataType: "text",
    dataField: "bayiAdi",
    visible: ["grid"],
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },

  //todo filtre------------------
  {
    name: "sirketAdi",
    label: "Firma",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["filter"],
  },
  {
    name: "tahsilEdildi",
    label: "Tahsilat Durumu(Tutar)",
    dataType: "select",
    dataField: "tahsilEdildi",
    visible: ["filter"],
    items: Object.values(TAHSILAT_DURUMU_INT),
    defaultValue: TAHSILAT_DURUMU_INT.TAHSILAT_YAPILMADI.Value,
  },
  {
    name: "tahsilEdildiBool",
    label: "Tahsilat Edildi Durumu",
    dataType: "select",
    dataField: "tahsilEdildiBool",
    visible: ["filter"],
    items: Object.values(EVET_HAYIR),
  },
  {
    name: "bayiid",
    label: "Bayi",
    dataType: "custom",
    dataField: "bayiid",
    visible: ["filter"],
    customComponent: BayilerInputForm,
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
    ],
  },
];

export default entity;

// public string? OnayliTeklifDosyasi { get; set; }
