import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Save } from "@mui/icons-material";

export default function TamamButton({ onClick, disabled }) {
  return (
    <ButtonWrapper color="success" onClick={onClick} disabled={disabled}>
      Tamam
    </ButtonWrapper>
  );
}
