import * as React from "react";
import Popover from "@mui/material/Popover";
import { Stack } from "@mui/material";
import ButtonWrapper from "./ButtonWrapper";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function PopoverButton({
  children,
  label,
  disabled,
  backColor,
  hoverBackColor,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <ButtonWrapper
        color="secondary"
        startIcon={<MoreVertIcon />}
        onClick={handleClick}
        disabled={disabled}
        sx={
          backColor
            ? {
                backgroundColor: backColor,
                "&:hover": {
                  backgroundColor: hoverBackColor ? hoverBackColor : backColor,
                },
              }
            : undefined
        }
      >
        {label}
      </ButtonWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack direction="column" spacing={1} sx={{ p: 1 }}>
          {children}
        </Stack>
      </Popover>
    </>
  );
}
