import { useContext, useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import SirketRecord from "./SirketRecord";
import { PageConfig } from "./SirketConfig";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import { AppContext } from "../../common/AppContext";

function Sirket({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const context = useContext(AppContext);

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="Sirket"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable={context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value || context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value}
        ekranKodu={ekranKodu}
      />
      <SirketRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
      ></SirketRecord>
    </>
  );
}

export default Sirket;
