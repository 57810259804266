import React from "react";
import CustomColoredButton from "./CustomColoredButton";
import { Preview } from "@mui/icons-material";

export default function TalepGoruntuleButton({ onClick, label, color }) {
  return (
    <CustomColoredButton
      color={color ? color : "teal"}
      startIcon={<Preview />}
      onClick={onClick}
    >
      {label || "Talep Kaydını Görüntüle"}
    </CustomColoredButton>
  );
}
