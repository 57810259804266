import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import { ANALIZ_TIPI } from "../../common/constants";

const OnAnalizDialog = React.forwardRef(
  (
    { ekranKodu, isEditable, setRowDataFnc, listGridRef, tip, screenCapiton },
    ref
  ) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, "Talep/TalepAnalizleriGetById");

    const afterSubmitFnc = (res) => {
      setRowDataFnc((prevState) => {
        return {
          ...prevState,
          onAnaliz: res?.data?.data?.onAnaliz,
          testKlavuzu: res?.data?.data?.testKlavuzu,
        };
      });
    };

    var lModalTitle = "";
    var lFieldName = "";

    if (tip === ANALIZ_TIPI.AKSIYON_ANALIZI.Value) {
      lModalTitle = "Aksiyon Analizi";
      lFieldName = "onAnaliz";
    } else if (tip === ANALIZ_TIPI.TEST_KLAVUZU.Value) {
      lModalTitle = "Test Klavuzu";
      lFieldName = "testKlavuzu";
    }

    lModalTitle = screenCapiton ? screenCapiton : lModalTitle;

    const handleSubmit = useSubmit(
      { updateRoute: "Talep/TalepAnalizleriUpdate" },
      setRowData,
      listGridRef,
      afterSubmitFnc
    );

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle={lModalTitle}
        maxWidth="lg"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Field
                  name={lFieldName}
                  component={RichTextEditor}
                  readOnly={!isEditable}
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {isEditable && (
                  <SaveButton
                    onClick={(e) => {
                      form.submit().then(() => {
                        closeRecord();
                      });
                    }}
                  />
                )}
                {isEditable && (
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                )}
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default OnAnalizDialog;
