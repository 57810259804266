import { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import { PageConfig } from "./FiyatListeMasterConfig";
import FiyatListeMasterRecord from "./FiyatListeMasterRecord";

function FiyatListeMaster({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const recordRef = useRef();
  const detailGridRef = useRef();

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="FiyatListeMaster"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        recordRef={recordRef}
        isCreatable
        ekranKodu={ekranKodu}
      />
      <FiyatListeMasterRecord
        ref={recordRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        detailGridRef={detailGridRef}
      ></FiyatListeMasterRecord>
    </>
  );
}

export default FiyatListeMaster;
