import Type from "../../entities/IseBaslangicGirisi";

const PageConfig = {
  title: "IseBaslangicGirisi",
  ekranKodu: "IseBaslangicGirisi_Res",
  type: Type,
  listRoute: "IseBaslangicGirisi/List",
  getByIdRoute: "IseBaslangicGirisi/GetById",
  insertRoute: "IseBaslangicGirisi/Insert",
  updateRoute: "IseBaslangicGirisi/Update",
  deleteRoute: "IseBaslangicGirisi/Delete",
};

export default PageConfig;
