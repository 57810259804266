import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./FiyatListeMasterConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import DateTimeInputForm from "../../components/inputs/form-inputs/DateTimeInputForm";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import FiyatListeDetay from "./FiyatListeDetay";
import DosyaYukle from "../../components/file/DosyaYukle";
import DosyaYukleButton from "../../components/buttons/custom/DosyaYukleButton";

const FiyatListeMasterRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, detailGridRef }, ref) => {
    const initialState = { aktif: true };
    const editGridRef = useRef();
    const dosyaYukleRef = useRef();

    const handleSubmitPrepareData = (values) => {
      let aa = {
        ...values,
        detay: editGridRef.current.instance.getVisibleRows().map((row) => {
          return row.data;
        }),
      };

      return aa;
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      openRecord,
      recId,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      () => {
        editGridRef.current?.instance?.cancelEditData();
      },
      null,
      null,
      null,
      handleSubmitPrepareData
    );

    const validationSchema = yup.object().shape({
      listeKodu: yup.string().required(),
      listeAdi: yup.string().required(),
      baslangicTarihi: yup.date().required(),
      bitisTarihi: yup.date().required(),
    });

    const validate = validateFormValues(validationSchema);

    const refreshRecord = () => {
      openRecord(recId);
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Fiyat Listesi"
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Fiyat Listesi No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Liste Kodu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="listeKodu"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Liste Adı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="listeAdi"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Başlangıç Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="baslangicTarihi"
                          component={DateTimeInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Bitiş Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="bitisTarihi"
                          component={DateTimeInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Açıklama"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aciklama"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper label="Aktif" modalLoading={modalLoading}>
                        <Field
                          name="aktif"
                          component={CheckboxForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    </Grid>
                  </DialogContentTop>
                  <DialogActionsWrapper
                    sx={{
                      justifyContent: "space-between",
                    }}
                  >
                    <DosyaYukleButton
                      label={"Excel'den Al"}
                      onClick={() => {
                        dosyaYukleRef?.current?.openModal();
                      }}
                      disabled={!rowData?.id}
                    />
                    <DosyaYukle
                      route={"FiyatListeMaster/ExcelImport"}
                      ref={dosyaYukleRef}
                      reqExtraData={[{ name: "id", value: rowData?.id }]}
                      // reqExtraData={}
                      rowData={rowData}
                      setRowData={() => {}}
                      allowedFileExtensions={[".xls", ".xlsx"]}
                      modalTitle="Excel'den Al"
                      handleCloseExtra={() => {
                        openRecord(recId);
                      }}
                      sutunSiralamasi={
                        "Ürün Kodu - Kullanıcı Sayısı 1 -  Kullanıcı Sayısı 2 - Birim Fiyat - Birim - Kdv Oranı"
                      }
                    />
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  </DialogActionsWrapper>
                  <DialogContentWrapper>
                    <FiyatListeDetay
                      ekranKodu={ekranKodu}
                      rowData={rowData}
                      editGridRef={editGridRef}
                      listGridRef={editGridRef}
                      detailGridRef={editGridRef}
                      // masterOpenRecord={refreshRecord}
                    ></FiyatListeDetay>
                  </DialogContentWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default FiyatListeMasterRecord;
