import { ILGILI_MODUL, YETKI_GRUBU_TIPI } from "../common/constants";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { sorumluJoin } from "../common/constant-functions/sorumluJoin";
import {
  ID_WIDTH,
  ILGILI_MODUL_WIDTH,
  IS_SONUCU_WIDTH,
} from "../common/entityconstants";
import KullaniciInputForm from "../components/inputs/custom-form-inputs/KullaniciInputForm";

const entity = [
  {
    name: "id",
    label: "Talep No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "ilgiliModul",
    label: "İlgili Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["grid"],
    selectItems: Object.values(ILGILI_MODUL),
    width: ILGILI_MODUL_WIDTH,
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: IS_SONUCU_WIDTH,
  },
  {
    name: "sirketAdi",
    label: "Talep Eden Firma",
    dataType: "text",
    dataField: "sirketAdi",
    visible: ["grid"],
    width: "150px",
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
        ],
      },
    ],
  },
  {
    name: "talepEdenPersonelIdStr",
    label: "Talep Eden Personel",
    dataType: "text",
    dataField: "talepEdenPersonelIdStr",
    visible: ["grid"],
    width: "150px",
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
        ],
      },
    ],
  },
  {
    name: "firmaTemsilcisiStr",
    label: "Firma Temsilcisi",
    dataType: "text",
    dataField: "firmaTemsilcisiStr",
    visible: ["grid"],
  },
  // {
  //   name: "sorumlular",
  //   label: "Sorumlular",
  //   dataType: "text",
  //   dataField: "sorumlular",
  //   visible: ["grid"],
  //   // cellRender: (e) => SorumluChip(e?.value),
  //   customizeText: (cellData) => sorumluJoin(cellData?.value),
  //   allowSorting: false,
  //   width: "300px",
  //   visibleConditions: [
  //     {
  //       gizlenecekYetkiGrubuTipi: [
  //         YETKI_GRUBU_TIPI.BAYI.Value,
  //         YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
  //         YETKI_GRUBU_TIPI.MUSTERI.Value,
  //       ],
  //     },
  //   ],
  // },
  {
    name: "tamamlanmaTarihi",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "tamamlanmaTarihi",
    visible: ["grid"],
    firstSort: "desc",
    width: "100px",
  },
  //todo
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "tarih1",
    label: "Bitiş Tarihi 1",
    dataType: "date",
    dataField: "tarih1",
    visible: ["filter"],
  },
  {
    name: "tarih2",
    label: "Bitiş Tarihi 2",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
  },
  {
    name: "talepId",
    label: "Talep No",
    dataType: "number",
    dataField: "talepId",
    visible: ["filter"],
  },
  {
    name: "ilgiliModul",
    label: "Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["filter"],
    items: Object.values(ILGILI_MODUL),
  },
  {
    name: "sirket",
    label: "Talep Eden Firma",
    dataType: "text",
    dataField: "sirket",
    visible: ["filter"],
    gizlenecekYetkiGrubuTipi: [
      YETKI_GRUBU_TIPI.BAYI.Value,
      YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
      YETKI_GRUBU_TIPI.MUSTERI.Value,
    ],
  },

  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["filter"],
  },
  {
    name: "firmaTemsilcisi",
    label: "Firma Temsilcisi",
    dataType: "custom",
    dataField: "firmaTemsilcisi",
    visible: ["filter"],
    customComponent: KullaniciInputForm,
    reqData: { temsilci: true },
  },
];

export default entity;
