import { Grid } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useSubmit from "../../hooks/use-submit";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import { PageConfig } from "./GorusmelerConfig";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import useBasicDialog from "../../hooks/use-basic-dialog";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import GorusmeHatirlatma from "../GorusmeHatirlatmalari/GorusmeHatirlatma";

const GorusmeHatirlatmaDialog = React.forwardRef(
  ({ gorusmeRef, listGridRef, ekranKodu }, ref) => {
    const { open, closeRecord } = useBasicDialog(ref);

    const afterSubmitFnc = () => {
      closeRecord();
    };

    // if (gorusmeRef && gorusmeRef.id > 0) {
    //   var initialState = {
    //     gorusmeid: gorusmeRef.id,
    //   };
    // }

    // const handleSubmit = useSubmit(
    //   {
    //     insertRoute: PageConfig.hatirlatmaEkleRoute,
    //   },
    //   null,
    //   listGridRef
    // );

    return (
      <>
     
        <ScrollDialog
          open={open}
          handleClose={closeRecord}
          modalTitle="Görüşme Hatırlatma"
          maxWidth="md"
          fullWidth={true}
          draggable={false}
        >
           <GorusmeHatirlatma
       rowData={gorusmeRef}
       ekranKodu={ekranKodu}

      />
        </ScrollDialog>
      </>
    );
  }
);

export default GorusmeHatirlatmaDialog;
