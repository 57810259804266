import HomeIcon from "@mui/icons-material/Home";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CallIcon from "@mui/icons-material/Call";
import HandShakeIcon from "@mui/icons-material/Handshake";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PrivateRoute from "../components/route/PrivateRoute";
import GardiyanRoute from "../components/route/GardiyanRoute";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PaymentsIcon from "@mui/icons-material/Payments";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Not from "../pages/Not/Not";
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import KullaniciBilgileri from "../pages/Profile/kullanici-bilgileri/KullaniciBilgileri";
import Yardim from "../pages/Profile/Yardim";
import MusteriTalep from "../pages/Musteri-talep/MusteriTalep";
import Destek from "../pages/Destek/Destek";
import Sirket from "../pages/Sirket/Sirket";
import Urunler from "../pages/Urunler/Urunler";
import SirketBazliSureAnalizi from "../pages/Rapor/SirketBazliSureAnalizi";
import Login from "../pages/Login/Login";
import Yetki from "../pages/Yetki/Yetki";
import Kullanici from "../pages/Kullanici/Kullanici";
import DestekGecmisi from "../pages/Destek-gecmisi/DestekGecmisi";
import TahsilatTakip from "../pages/Tahsilat-takip/TahsilatTakip";
import KullaniciAnalizi from "../pages/Rapor/KullaniciAnalizi";
import Gorevler from "../pages/Gorev/Gorevler";
import IsSonucuRaporu from "../pages/Rapor/IsSonucuRaporu";
import YeniEklenenOzellikler from "../pages/Rapor/YeniEklenenOzellikler";
import Toplanti from "../pages/Toplanti/Toplanti";
import GroupsIcon from "@mui/icons-material/Groups";
import KontrolEdilecekIsler from "../pages/Kontrol-edilecek-isler/KontrolEdilecekIsler";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import FiyatListeMaster from "../pages/FiyatListesi/FiyatListeMaster";
import Bayiler from "../pages/Bayiler/Bayiler";
import { EKRAN_KODU as EKod } from "./constants";
import PotansiyelMusteri from "../pages/PotansiyelMusteri/PotansiyelMusteri";
import TeklifMaster from "../pages/Teklif/TeklifMaster";
import Gorusmeler from "../pages/Gorusmeler/Gorusmeler";
import ProjeSurecTakip from "../pages/ProjeSurecTakip/ProjeSurecTakip";
import GorevlerGantt from "../pages/GorevlerGantt/GorevlerGantt";
import { deepPurple, green, purple, teal } from "@mui/material/colors";
import MailOlaylariDuzenle from "../pages/MailOlaylariDuzenle/MailOlaylariDuzenle";
import { Email } from "@mui/icons-material";
import { Comment } from "@mui/icons-material";
import GorevDurumuRaporu from "../pages/Rapor/GorevDurumuRaporu";
import AtananGorusmeler from "../pages/AtananGorusmeler/AtananGorusmeler";

const routes = {
  notlarim: {
    text: "Notlarım",
    path: "/Notlarim",
    element: (
      <PrivateRoute>
        <Not ekranKodu={EKod.NOTLAR.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <PostAddIcon color="primary" />,
    ekranKodu: EKod.NOTLAR.value,
    level: 0,
  },
  home: {
    path: "/",
    text: "Müşteri Talepleri",
    element: (
      <PrivateRoute>
        <Home ekranKodu={EKod.ANASAYFA.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    // startIcon={<Comment />}
    icon: <Comment sx={{ color: deepPurple[400] }} />,
    drawerTextColor: "purple",
    ekranKodu: EKod.ANASAYFA.value,
    level: 0,
  },
  profil: {
    text: "Profil",
    path: "/profil",
    element: (
      <PrivateRoute>
        <Profile ekranKodu={EKod.PROFIL.value} />
      </PrivateRoute>
    ),
    hasSidebar: true,
    ekranKodu: EKod.PROFIL.value,
    level: 0,
  },
  kullaniciBilgileri: {
    text: "Kullanıcı Bilgileri",
    path: "/KullaniciBilgileri",
    element: (
      <PrivateRoute>
        <KullaniciBilgileri ekranKodu={EKod.KULLANICI_BILGILERI.value} />
      </PrivateRoute>
    ),
    hasSidebar: true,
    ekranKodu: EKod.KULLANICI_BILGILERI.value,
    level: 0,
  },
  yardim: {
    text: "Yardım",
    path: "/yardim",
    element: (
      <PrivateRoute>
        <Yardim ekranKodu={EKod.PROFIL.value} />
      </PrivateRoute>
    ),
    hasSidebar: true,
    ekranKodu: EKod.PROFIL.value,
    level: 0,
  },

  // kullanicilar: {
  //   text: "Kullanıcılar",
  //   path: "/Kullanicilar",
  //   element: (
  //     <PrivateRoute>
  //       <Kullanici ekranKodu="Kullanicilar_Res" />
  //     </PrivateRoute>
  //   ),
  //   showInSidebar: true,
  //   hasSidebar: true,
  //   icon: <PersonIcon color="success" />,
  //   ekranKodu: "Kullanicilar_Res",
  //   drawerTextColor: "green",
  // },
  // yetkiler: {
  //   text: "Yetkiler",
  //   path: "/Yetkiler",
  //   element: (
  //     <PrivateRoute>
  //       <Yetki ekranKodu="Yetki_Res" />
  //     </PrivateRoute>
  //   ),
  //   showInSidebar: true,
  //   hasSidebar: true,
  //   icon: <PersonIcon color="success" />,
  //   ekranKodu: "Yetki_Res",
  //   drawerTextColor: "green",
  // },
  musteriTalepleri: {
    text: "İç Talepler",
    path: "/Talepler",
    element: (
      <PrivateRoute>
        <MusteriTalep ekranKodu={EKod.MUSTERI_TALEP.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    // icon: <HourglassTopIcon color="primary" />,
    icon: <Comment sx={{ color: deepPurple[400] }} />,
    drawerTextColor: "purple",
    ekranKodu: EKod.MUSTERI_TALEP.value,
    level: 0,
  },
  // sirketler: {
  //   text: "Şirketler",
  //   path: "/Sirketler",
  //   element: (
  //     <PrivateRoute>
  //       <Sirket ekranKodu="Sirket_Res" />
  //     </PrivateRoute>
  //   ),
  //   showInSidebar: true,
  //   hasSidebar: true,
  //   icon: <HourglassTopIcon color="primary" />,
  //   ekranKodu: "Sirket_Res",
  // },
  toplantilar: {
    text: "Toplantılar",
    path: "/Toplantilar",
    element: (
      <PrivateRoute>
        <Toplanti ekranKodu={EKod.TOPLANTI.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <GroupsIcon color="primary" />,
    ekranKodu: EKod.TOPLANTI.value,
    level: 0,
  },
  destek: {
    path: "/Destek",
    text: "Danışmanlık Girişi",
    element: (
      <PrivateRoute>
        <Destek ekranKodu={EKod.DESTEK.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <SupportAgentIcon color="primary" />,
    ekranKodu: EKod.DESTEK.value,
    level: 0,
  },

  destekGecmisi: {
    path: "/DestekGecmisi",
    text: "Danışmanlık Geçmişi",
    element: (
      <PrivateRoute>
        <DestekGecmisi ekranKodu={EKod.DESTEK_GECMISI.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <SupportAgentIcon color="primary" />,
    ekranKodu: EKod.DESTEK_GECMISI.value,
    level: 0,
  },
  gorevler: {
    path: "/Gorevler",
    text: "Görevler",
    element: (
      <PrivateRoute>
        <Gorevler ekranKodu={EKod.GOREVLER.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <AssignmentIcon color="primary" />,
    ekranKodu: EKod.GOREVLER.value,
    level: 0,
  },
  kontrolEdilecekIsler: {
    path: "/KontrolEdilecekIsler",
    text: "Kontrol Edilecek İşler",
    element: (
      <PrivateRoute>
        <KontrolEdilecekIsler ekranKodu={EKod.KONTROL_EDILECEK_ISLER.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <AssignmentTurnedInIcon color="primary" />,
    ekranKodu: EKod.KONTROL_EDILECEK_ISLER.value,
    level: 0,
  },
  tahsilatTakip: {
    path: "/TahsilatTakip",
    text: "Tahsilat Takip",
    element: (
      <PrivateRoute>
        <TahsilatTakip ekranKodu={EKod.TAHSILAT_TAKIP.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <PaymentsIcon color="primary" />,
    ekranKodu: EKod.TAHSILAT_TAKIP.value,
    level: 0,
  },
  gorusmeler: {
    text: "Görüşmeler",
    path: "/Tanimlamalar/Gorusmeler",
    element: (
      <PrivateRoute>
        <Gorusmeler ekranKodu={EKod.GORUSMELER.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <CallIcon sx={{ color: green["A200"] }} />,
    ekranKodu: EKod.GORUSMELER.value,
    drawerTextColor: "#17B794",
    level: 0,
  },
  atananGorusmeler: {
    text: "Atanan Görüşmeler",
    path: "/Tanimlamalar/AtananGorusmeler",
    element: (
      <PrivateRoute>
        <AtananGorusmeler ekranKodu={EKod.ATANAN_GORUSMELER.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <CallIcon sx={{ color: green["A200"] }} />,
    ekranKodu: EKod.ATANAN_GORUSMELER.value,
    drawerTextColor: "#17B794",
    level: 0,
  },
  satisTeklifi: {
    text: "Teklifler",
    path: "/TeklifMaster",
    element: (
      <PrivateRoute>
        <TeklifMaster ekranKodu={EKod.TEKLIFMASTER.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <LocalOfferIcon sx={{ color: green["A200"] }} />,
    ekranKodu: EKod.TEKLIFMASTER.value,
    drawerTextColor: "#17B794",
    level: 0,
  },
  projeSurecTakip: {
    text: "Proje Süreç Takip",
    path: "/ProjeSurecTakip",
    element: (
      <PrivateRoute>
        <ProjeSurecTakip ekranKodu={EKod.PROJESURECTAKIP.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <PersonSearchIcon color="primary" />,
    ekranKodu: EKod.PROJESURECTAKIP.value,
    level: 0,
  },
  gorevlerGantt: {
    text: "Görevler Gantt",
    path: "/GorevlerGantt",
    element: (
      <PrivateRoute>
        <GorevlerGantt ekranKodu={EKod.GOREVLER_GANTT.value} />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <PointOfSaleIcon color="primary" />,
    ekranKodu: EKod.GOREVLER_GANTT.value,
    level: 0,
  },
  tanimlamalar: {
    path: "/Tanimlamalar",
    text: "Tanımlamalar",
    showInSidebar: true,
    hasSidebar: true,
    ekranKodu: EKod.TANIMLAMALAR.value,
    drawerTextColor: "#f34723",
    level: 0,
    children: [
      {
        text: "Kullanıcılar",
        path: "/Tanimlamalar/Kullanicilar",
        element: (
          <PrivateRoute>
            <Kullanici ekranKodu={EKod.KULLANICILAR.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <PersonIcon color="success" />,
        ekranKodu: EKod.KULLANICILAR.value,
        drawerTextColor: "green",
        level: 1,
      },
      {
        text: "Yetkiler",
        path: "/Tanimlamalar/Yetkiler",
        element: (
          <PrivateRoute>
            <Yetki ekranKodu={EKod.YETKILER.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <PersonIcon color="success" />,
        ekranKodu: EKod.YETKILER.value,
        drawerTextColor: "green",
        level: 1,
      },
      {
        text: "Müşteriler",
        path: "/Tanimlamalar/Sirketler",
        element: (
          <PrivateRoute>
            <Sirket ekranKodu={EKod.SIRKET.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <HourglassTopIcon color="primary" />,
        ekranKodu: EKod.SIRKET.value,
        level: 1,
      },
      {
        text: "Ürünler",
        path: "/Tanimlamalar/Urunler",
        element: (
          <PrivateRoute>
            <Urunler ekranKodu={EKod.URUNLER.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <ViewModuleIcon color="primary" />,
        ekranKodu: EKod.URUNLER.value,
        level: 1,
      },
      {
        text: "Bayiler",
        path: "/Tanimlamalar/Bayiler",
        element: (
          <PrivateRoute>
            <Bayiler ekranKodu={EKod.BAYILER.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <HandShakeIcon color="primary" />,
        ekranKodu: EKod.BAYILER.value,
        level: 1,
      },
      {
        text: "Potansiyel Müşteriler",
        path: "/Tanimlamalar/PotansiyelMusteri",
        element: (
          <PrivateRoute>
            <PotansiyelMusteri ekranKodu={EKod.POTANSIYELMUSTERI.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <PersonSearchIcon color="primary" />,
        ekranKodu: EKod.POTANSIYELMUSTERI.value,
        level: 1,
      },
      {
        text: "Fiyat Listeleri",
        path: "/Tanimlamalar/FiyatListeMaster",
        element: (
          <PrivateRoute>
            <FiyatListeMaster ekranKodu={EKod.FIYAT_LISTE_MASTER.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <PointOfSaleIcon color="primary" />,
        ekranKodu: EKod.FIYAT_LISTE_MASTER.value,
        level: 1,
      },
      {
        text: "Otomatik Mail Tanımları",
        path: "/Tanimlamalar/MailOlaylariDuzenleme",
        element: (
          <PrivateRoute>
            <MailOlaylariDuzenle ekranKodu={EKod.MAIL_OLAYLARI_DUZENLE.value} />
          </PrivateRoute>
        ),
        showInSidebar: true,
        hasSidebar: true,
        icon: <Email color="primary" />,
        ekranKodu: EKod.MAIL_OLAYLARI_DUZENLE.value,
        level: 1,
      },
    ],
  },
  raporlar: {
    path: "/Raporlar",
    text: "Raporlar",
    // element: <Root />,
    showInSidebar: true,
    hasSidebar: true,
    // icon: <AssignmentIcon color="primary" />,
    ekranKodu: EKod.RAPORLAR.value,
    drawerTextColor: "#f34723",
    level: 0,
    children: [
      {
        path: "/Raporlar/SirketBazliSureAnalizi",
        text: "Müşteri Bazlı Süre Raporu",
        element: (
          <PrivateRoute>
            <SirketBazliSureAnalizi
              ekranKodu={EKod.SIRKET_BAZLI_SURE_ANALIZI.value}
            />
          </PrivateRoute>
        ),
        icon: <AssignmentIcon color="primary" />,
        ekranKodu: EKod.SIRKET_BAZLI_SURE_ANALIZI.value,
        level: 1,
      },
      {
        path: "/Raporlar/KullaniciAnalizi",
        text: "Personel Analiz Raporu",
        element: (
          <PrivateRoute>
            <KullaniciAnalizi ekranKodu={EKod.KULLANICI_ANALIZI.value} />
          </PrivateRoute>
        ),
        icon: <AssignmentIndIcon color="primary" />,
        ekranKodu: EKod.KULLANICI_ANALIZI.value,
        level: 1,
      },
      {
        path: "/Raporlar/IsSonuclari",
        text: "Tamamlanan Görevler Raporu",
        element: (
          <PrivateRoute>
            <IsSonucuRaporu ekranKodu={EKod.IS_SONUCLARI_RAPORU.value} />
          </PrivateRoute>
        ),
        icon: <CheckCircleOutlineIcon color="primary" />,
        ekranKodu: EKod.IS_SONUCLARI_RAPORU.value,
        level: 1,
      },
      {
        path: "/Raporlar/GorevDurumuRaporu",
        text: "Görev Durumu Raporu",
        element: (
          <PrivateRoute>
            <GorevDurumuRaporu ekranKodu={EKod.GOREV_DURUMU_RAPORU.value} />
          </PrivateRoute>
        ),
        icon: <CheckCircleOutlineIcon color="primary" />,
        ekranKodu: EKod.GOREV_DURUMU_RAPORU.value,
        level: 1,
      },
    ],
  },
  yeniEklenenOzellikler: {
    path: "/YeniEklenenOzellikler",
    text: "Yeni Eklenen Özellikler",
    element: (
      <PrivateRoute>
        <YeniEklenenOzellikler />
      </PrivateRoute>
    ),
    showInSidebar: true,
    hasSidebar: true,
    icon: <PaymentsIcon color="primary" />,
    yetkisizEkran: true,
  },
  login: {
    text: "Login",
    path: "/Login",
    element: (
      <GardiyanRoute>
        <Login />
      </GardiyanRoute>
    ),
    showInSidebar: false,
    hasSidebar: false,
    yetkisizEkran: true,
  },
  error: {
    text: "404",
    path: "/404",
    element: <div>404 not found.</div>,
    showInSidebar: false,
    hasSidebar: false,
    yetkisizEkran: true,
  },
  // notfound: {
  //   text: "NotFound",
  //   path: "/*",
  //   element: <div>404 not found.</div>,
  //   showInSidebar: false,
  //   hasSidebar: false,
  //   yetkisizEkran: true,
  // },
};

export default routes;
