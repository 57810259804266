import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import { ANALIZ_TIPI } from "../../common/constants";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";

const TeklifOdemePlaniDialog = React.forwardRef(
  ({ ekranKodu, isEditable, setRowDataFnc, listGridRef }, ref) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, "TeklifMaster/OdemePlaniGetById");

    const afterSubmitFnc = (res) => {
      setRowDataFnc((prevState) => {
        return {
          ...prevState,
          // onAnaliz: res?.data?.data?.onAnaliz,
          // testKlavuzu: res?.data?.data?.testKlavuzu,
        };
      });
    };

    const handleSubmit = useSubmit(
      {
        updateRoute: "TeklifMaster/TeklifOdemePlaniUpdate",
        insertRoute: "TeklifMaster/TeklifOdemePlaniUpdate",
      },
      setRowData,
      listGridRef,
      afterSubmitFnc
    );

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle={"Ödeme Bilgileri"}
        maxWidth="sm"
        fullWidth={true}
        height={"40vh"}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <FieldWrapper
                  label="Adam/Gün Ücreti"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="adamGunUcreti"
                    component={NumberInputForm}
                    rowData={rowData}
                  />
                </FieldWrapper>
                <FieldWrapper
                  label="Adam/Saat Ücreti"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="adamSaatUcreti"
                    component={NumberInputForm}
                    rowData={rowData}
                  />
                </FieldWrapper>
                <FieldWrapper
                  label="Yıllık Lisans Üyelik Bedeli"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="yillikLisansUyelikBedeli"
                    component={NumberInputForm}
                    rowData={rowData}
                  />
                </FieldWrapper>
                <FieldWrapper
                  label="Yıllık Artış Oranı"
                  modalLoading={modalLoading}
                >
                  <Field
                    name="yillikArtisOrani"
                    component={NumberInputForm}
                    rowData={rowData}
                  />
                </FieldWrapper>
                <FieldWrapper label="Açıklama" modalLoading={modalLoading}>
                  <Field
                    name="aciklama"
                    component={MultilineTextInputForm}
                    rowData={rowData}
                    minRows={4}
                    maxRows={5}
                  />
                </FieldWrapper>
              </DialogContentWrapper>
              <DialogActionsWrapper>
                {isEditable && (
                  <SaveButton
                    onClick={(e) => {
                      form.submit().then(() => {
                        closeRecord();
                      });
                    }}
                  />
                )}
                {isEditable && (
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                )}
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default TeklifOdemePlaniDialog;
