import React from "react";
import IsKapatmaDialog from "../Musteri-talep/IsKapatmaDialog";
import TemsilciAnaliziDialog from "./TemsilciAnaliziDialog";
import TalepYonlendirmeDialog from "./TalepYonlendirmeDialog";
import BayiTalepYonlendirmeDialog from "../BayiTalep/BayiTalepYonlendirmeDialog";
import YorumDialog from "./YorumDialog";
import PdfDialog from "../../components/pdf/PdfDialog";
import AnalizSonucuDialog from "./AnalizSonucuDialog";
import TeklifRecord from "./TeklifRecord";
import BayiTeklifRecord from "./BayiTeklifRecord";
import {
  ANALIZ_TIPI,
  BAYI_TALEP_DURUMU,
  YETKI_GRUBU_TIPI,
} from "../../common/constants";
import { AppContext } from "../../common/AppContext";
import EMailDialog from "../Personel/EmailDialog";
import IsSonucuDialog from "./IsSonucuDialog";
import AlertDialog from "../../components/dialogs/AlertDialog";
import TeklifIptalDialog from "./TeklifIptalDialog";
import BayiTalebiMerkezeGonderDialog from "../Musteri-talep/BayiTalebiMerkezeGonderDialog";
import BayidenMusteriyeDirekTeklifRecord from "./BayidenMusteriyeDirekTeklifRecord";
import BayiTemsilciAnaliziDialog from "./BayiTemsilciAnaliziDialog";
import MusteriTalepDetayiDialog from "./MusteriTalepDetayiDialog";
import OnAnalizDialog from "./OnAnalizDialog";
import TalepTarihAtamaDialog from "./TalepTarihAtamaDialog";
import YonlendirmeAciklamasiDialog from "./YonlendirmeAciklamasiDialog";

export default function HomeRecordDialogs({
  ekranKodu,
  rowData,
  setRowDataFnc,
  listGridRef,
  teklifRecordRef,
  bayiTeklifRecordRef,
  yorumDialogRef,
  analizSonucuDialogRef,
  temsilciAnaliziDialogRef,
  talepYonlendirmeDialogRef,
  bayiTalepYonlendirmeDialogRef,
  teklifPdfDialogRef,
  isKapatmaDialogRef,
  onayliPdfDialogRef,
  destekAsimPdfDialogRef,
  recordRef,
  emailDialogRef,
  isSonucuDialogRef,
  analizSonucuAlertDialogRef,
  teklifIptalAlertDialogRef,
  bayiTalebiMerkezeGonderDialogRef,
  bayidenMusteriyeDirekTeklifRecordRef,
  bayiTemsilciAnaliziDialogRef,
  musteriTalepDetayiDialogRef,
  onAnalizDialogRef,
  testKlavuzuDialogRef,
  talepTarihAtamaDialogRef,
  yonlendirmeAciklamasiRef,
  aksiyonAnaliziAlertDialogRef,
}) {
  const context = React.useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  const bayiTemsilciAnaliziEditable =
    (context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI.Value ||
      context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI_DESTEK.Value) &&
    yetki.kaydet;

  const temsilciAnaliziEditable =
    (context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context?.user?.yetkiGrubuTipi ===
        YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value) &&
    yetki.kaydet;

  const talepDuzenlenemez =
    (context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI_DESTEK.Value) &&
    (rowData.bayiTalepDurumu === BAYI_TALEP_DURUMU.ONAYLANDI.Value ||
      rowData.bayiTalepDurumu === BAYI_TALEP_DURUMU.REDDEDILDI.Value) &&
    rowData.merkezdeIsAcildi === true;

  const onAnalizEditable =
    (context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context?.user?.yetkiGrubuTipi ===
        YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value) &&
    yetki.kaydet;

  return (
    <>
      <TeklifRecord
        ref={teklifRecordRef}
        ekranKodu={ekranKodu}
        talepId={rowData.id}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        yetki={yetki}
      />
      <BayiTeklifRecord
        ref={bayiTeklifRecordRef}
        ekranKodu={ekranKodu}
        talepId={rowData.id}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        yetki={yetki}
        duzenlemeYapamaz={talepDuzenlenemez}
      />
      <BayidenMusteriyeDirekTeklifRecord
        ref={bayidenMusteriyeDirekTeklifRecordRef}
        ekranKodu={ekranKodu}
        talepId={rowData.id}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        yetki={yetki}
        duzenlemeYapamaz={talepDuzenlenemez}
      />
      <AlertDialog
        ref={analizSonucuAlertDialogRef}
        dialogTitle="Dikkat!"
        contentText="Analiz sonucu girilmemiş."
        onayBtnText="Analiz sonucu gir"
        redBtnText="Kapat"
        onayBtnClick={() => {
          analizSonucuDialogRef?.current?.openRecord(rowData?.id);
        }}
      />
      <AlertDialog
        ref={aksiyonAnaliziAlertDialogRef}
        dialogTitle="Dikkat!"
        contentText="Aksiyon Analizi girilmemiş."
        onayBtnText="Aksiyon Analizi gir"
        redBtnText="Kapat"
        onayBtnClick={() => {
          onAnalizDialogRef?.current?.openRecord(rowData?.id);
        }}
      />
      <YorumDialog ref={yorumDialogRef} listGridRef={listGridRef} />
      <AnalizSonucuDialog
        ref={analizSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={
          context?.user?.yetkiGrubuTipi ===
            YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && yetki.kaydet
        }
        setRowDataFnc={setRowDataFnc}
      />
      <TemsilciAnaliziDialog
        ref={temsilciAnaliziDialogRef}
        ekranKodu={ekranKodu}
        isEditable={temsilciAnaliziEditable}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
      />
      <BayiTemsilciAnaliziDialog
        ref={bayiTemsilciAnaliziDialogRef}
        ekranKodu={ekranKodu}
        isEditable={bayiTemsilciAnaliziEditable && !talepDuzenlenemez}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
      />
      <TalepYonlendirmeDialog
        ref={talepYonlendirmeDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        afterSubmitFnc={() => recordRef.current.openRecord(rowData.id)}
      />
      <BayiTalepYonlendirmeDialog
        ref={bayiTalepYonlendirmeDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        afterSubmitFnc={() => recordRef.current.openRecord(rowData.id)}
      />
      <TalepTarihAtamaDialog
        ref={talepTarihAtamaDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        afterSubmitFnc={() => recordRef.current.openRecord(rowData.id)}
      />
      <PdfDialog
        ref={teklifPdfDialogRef}
        title="Teklif"
        url="Talep/TeklifPdf"
      />
      <PdfDialog
        ref={onayliPdfDialogRef}
        title="Onaylı Teklif"
        url="Talep/TeklifOnayliBelge"
      />
      <PdfDialog
        ref={destekAsimPdfDialogRef}
        title="Destek Aşım Detayı"
        url="DestekAsimTahsilatTakip/DestekAsimPdf"
      />
      <IsKapatmaDialog
        ref={isKapatmaDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        setRowDataFnc={setRowDataFnc}
      />
      <BayiTalebiMerkezeGonderDialog
        ref={bayiTalebiMerkezeGonderDialogRef}
        ekranKodu={ekranKodu}
        listGridRef={listGridRef}
        setRowDataFnc={setRowDataFnc}
        actionBtnVisible={!talepDuzenlenemez}
      />

      <EMailDialog ref={emailDialogRef} yetki={yetki} rowData={rowData} />
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={yetki.kaydet}
        listGridRef={listGridRef}
      />
      <TeklifIptalDialog
        teklifIptalAlertDialogRef={teklifIptalAlertDialogRef}
        rowData={rowData}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
      />
      <MusteriTalepDetayiDialog
        rowData={rowData}
        ref={musteriTalepDetayiDialogRef}
      />
      <OnAnalizDialog
        ref={onAnalizDialogRef}
        ekranKodu={ekranKodu}
        isEditable={onAnalizEditable}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        tip={ANALIZ_TIPI.AKSIYON_ANALIZI.Value}
      />
      <OnAnalizDialog
        ref={testKlavuzuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={true}
        setRowDataFnc={setRowDataFnc}
        listGridRef={listGridRef}
        tip={ANALIZ_TIPI.TEST_KLAVUZU.Value}
      />
      <YonlendirmeAciklamasiDialog
        ref={yonlendirmeAciklamasiRef}
        rowData={rowData}
      />
    </>
  );
}
