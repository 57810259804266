import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import BootstrapDialogTitle from "./BootsrapDialogTitle";
import DraggablePaper from "./DraggablePaper";
import CikisAlertDialog from "./CikisAlertDialog";

function ScrollDialog({
  children,
  modalTitle,
  open,
  handleClose,
  maxWidth,
  fullWidth = true,
  canFullScreen = true,
  draggable = true,
  height,
  ...rest
}) {
  const [fullScreen, setFullScreen] = useState(false);

  const cikisAlertDialogRef = useRef();
  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      // Dışarıya tıklamayla kapanmayı önler
      return;
    }
    cikisAlertDialogRef.current.openRecord();
    // cikisAlertDialogRef?.current?.openRecord();
    // handleClose(event); // Diğer nedenler için (Escape vb.) kapanmayı sağlar
  };

  return (
    <>
      <Dialog
        fullScreen={canFullScreen ? fullScreen : false}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        TransitionProps={{ timeout: 0 }}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={draggable ? DraggablePaper : null}
        PaperProps={{
          fullScreen: fullScreen,
          style: {
            height: fullScreen ? "auto" : height || "auto",
          },
        }}
        {...rest}
      >
        <BootstrapDialogTitle
          onClose={handleClose}
          setFullScreen={setFullScreen}
          canFullScreen={canFullScreen}
        >
          {modalTitle}
        </BootstrapDialogTitle>
        {children}
      </Dialog>
      <CikisAlertDialog
        ref={cikisAlertDialogRef}
        evetEvent={() => {
          // setIsFormDirty(false); // Form kaydedildiğinde durumu sıfırla
          handleClose(); // Dialogu kapat
          cikisAlertDialogRef.current.closeRecord(); // Onay dialogunu kapat
        }}
        hayirEvent={() => {
          cikisAlertDialogRef.current.closeRecord(); // Dialogu kapat
        }}
      ></CikisAlertDialog>
    </>
  );
}
export default ScrollDialog;
