import React from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import useDinamikTanimlarRecord from "../../hooks/use-dinamik-tanimlar-record";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const DinamikTanimlarRecord = React.forwardRef(
  (
    { ekranKodu, dinamikTanimlarRecordRef, tip, caption, aciklamaCaption },
    ref
  ) => {
    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = useDinamikTanimlarRecord(ref, dinamikTanimlarRecordRef, ekranKodu, tip);

    const validationSchema = yup.object().shape({
      aciklama: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle={caption ? caption : "Yeni Tanım Ekleme"}
          maxWidth="xs"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <FormComponent onSubmit={handleSubmit}> */}
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    {/* <CustomFieldWrapper
                      label="Kayıt No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper> */}
                    <CustomFieldWrapper
                      label={aciklamaCaption ? aciklamaCaption : "Tanım"}
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aciklama"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
                {/* </FormComponent> */}
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default DinamikTanimlarRecord;
