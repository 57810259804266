import React, { useState } from "react";
import { AppContext } from "../common/AppContext";
import { HttpAuthorized } from "../common/common";
import { loadingVisible } from "../common/constant-functions/loadingVisible";
import { printToast } from "../common/constant-functions/printToast";
import validateFormValues from "../common/constant-functions/validateFormValues";
import { apiUrl } from "../common/constants";

function usePageRecord(
  ref,
  PageConfig,
  listGridRef,
  ekranKodu,
  initialState,
  afterSubmitFnc,
  deleteCheck,
  customFetchData,
  customInsertOrUpdate,
  handleSubmitPrepareData,
  afterDeleteFunc
) {
  const [open, setOpen] = useState(false);
  const [recId, setRecId] = useState(0);
  const [rowData, setRowData] = useState(initialState || {});
  const [modalLoading, setModalLoading] = useState(true);

  const setRowDataFnc = (data) => setRowData(data);
  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setRecId(null);
    setRowData(initialState || {});
    setModalLoading(true);
  };
  React.useImperativeHandle(ref, () => ({
    setRecId: setRecId,
    recId: recId,
    openRecord: openRecord,
    setRowDataFnc: setRowDataFnc,
    setModalLoading: setModalLoading,
    handleClose: handleClose,
  }));

  async function fetchData(recId) {
    if (customFetchData) {
      customFetchData(recId);
    } else {
      HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${PageConfig.getByIdRoute}`,
        data: {
          id: recId,
        },
      })
        .then((res) => {
          printToast(res.data);
          setRowData(res.data.data || {});
          setModalLoading(false);
        })
        .catch((error) => {
          printToast(error.response.data);
          console.log(error);
        });
    }
  }

  async function insertOrUpdate(values) {
    if (customInsertOrUpdate) {
      customInsertOrUpdate(values);
    } else {
      loadingVisible(true);
      return HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${values.id && values.id != "undefined"
          ? PageConfig.updateRoute
          : PageConfig.insertRoute
          }`,
        data: handleSubmitPrepareData
          ? handleSubmitPrepareData(values)
          : {
            ...values,
          },
      })
        .then((res) => {
          printToast(res.data);
          if (res.data.success) {
            setRowData(res.data.data);
            listGridRef?.current?.instance?.refresh();
            afterSubmitFnc && afterSubmitFnc(res);
          } else {
            printToast(res.data.message);
          }

          // printToast(res.data);
        })
        .catch((error) => {
          printToast(error.response.data);
          console.log(error);
        })
        .finally(() => loadingVisible(false));
    }
  }

  async function handleDelete(reqData) {
    loadingVisible(true);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PageConfig.deleteRoute}`,
      data: reqData ? reqData : { id: rowData.id },
    })
      .then((res) => {
        let checked = false;
        if (deleteCheck) {
          checked = deleteCheck(res);
        }
        if (!checked) {
          printToast(res.data);
          if (res.data.success) {
            setRowData({});
            listGridRef?.current?.instance?.refresh();
            handleClose();
            afterDeleteFunc && afterDeleteFunc();
          }
        }
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  const handleSubmit = async (values) => {
    await insertOrUpdate(values);
  };

  function openRecord(recId) {
    if (recId > 0) {
      setRecId(recId);
      fetchData(recId);
    } else {
      if (typeof recId == "string" && recId) {
        setRecId(recId);
        fetchData(recId);
      } else {
        setRowData(initialState || {});
        setRecId(null);
        setModalLoading(false);
      }
    }
    handleClickOpen();
  }

  const context = React.useContext(AppContext);

  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  return {
    rowData,
    setRowData,
    recId,
    setRecId,
    open,
    handleClickOpen,
    handleClose,
    modalLoading,
    setModalLoading,
    handleDelete,
    handleSubmit,
    setRowDataFnc,
    validateFormValues,
    yetki,
    context,
    openRecord,
  };
}

export default usePageRecord;
