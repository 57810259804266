const TarihAtamaConfig = {
  title: "Talepler",
  ekranKodu: "AnaSayfa_Res",
  getByIdRoute: "TalepTarihAtama/GetById",
  insertRoute: "TalepTarihAtama/Insert", //inser ve update'i aynı route üzerinden yapar
  deleteRoute: "TalepTarihAtama/Delete",
};

const BayiTarihAtamaConfig = {
  title: "Talepler",
  ekranKodu: "AnaSayfa_Res",
  getByIdRoute: "BayiTalepTarihAtama/GetById",
  insertRoute: "BayiTalepTarihAtama/Insert", //inser ve update'i aynı route üzerinden yapar
  deleteRoute: "BayiTalepTarihAtama/Delete",
};

export { TarihAtamaConfig, BayiTarihAtamaConfig };
