import React, { useContext } from "react";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Stack } from "@mui/system";
import useBasicDialog from "../../hooks/use-basic-dialog";
import { Alert, AlertTitle } from "@mui/material";
import { AppContext } from "../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../common/constants";

const SirketBazliSureAnaliziSutunInfoDialog = React.forwardRef(({}, ref) => {
  const { open, closeRecord } = useBasicDialog(ref);

  const context = useContext(AppContext);

  const CustomAlert = ({ title, description }) => {
    return (
      <Alert variant="outlined" severity="info">
        <AlertTitle>{title} </AlertTitle>
        {[description]}
      </Alert>
    );
  };

  var merkez =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Sütunlar Neyi ifade eder?"
      maxWidth="lg"
    >
      <DialogContentWrapper>
        {merkez ? (
          <Stack direction="column" spacing={1}>
            <CustomAlert
              title="Harcanan Süre-Destek (Dakika): "
              description="Destek Menüsündeki kayıtlarda harcanan toplam süreyi ifade etmektedir. İşlem Türü Yazılımsal Hata Seçilen destek kayıtları bu süre içine dahil edilmez."
            />
            <CustomAlert
              title="Harcanan Süre-Teklifsiz Yazılım (Dakika): "
              description="Müşteri talepleri menüsünde açılan taleplere bağlı olarak görevler oluşturulmaktadır (Görevler Menüsü). Görevlerde harcanan toplam süre gelmektedir. Teklif verilerek yapılan taleplerin süresi bu süre için dahil edilmez. Talep Tipi, Hata Talebi olan talepler bu süreye dahil edilmez."
            />
            <CustomAlert
              title="Harcanan Süre-Teklifli (Dakika):"
              description="Müşteri talepleri menüsünde teklif verilerek çalışma yapılan talepler için harcanan toplam süreyi verir."
            />
            <CustomAlert
              title="Harcanan Süre-Hata Düzeltme (Dakika):"
              description="Destek ve Müşteri talepleri menülerinde hata tipli kayıtlar için harcanan toplam süreyi baz almaktadır. (Destek; İşlem Türü: Yazılımsal Hata,Müşteri talepleri; Talep Tipi: Hata Talebi)"
            />
            <CustomAlert
              title="Toplam Harcanan Süre(Dakika): "
              description="Firmanın tüm destek ve talepleri ile alakalı harcanan toplam süreyi baz almaktadır. (Hata ve Teklifli Yazılım Süreleri Dahil ) "
            />
            <CustomAlert
              title="Değerlendirmeye Alınan Süre (Dakika): "
              description=" (Harcanan Süre-Destek (Dakika) ile Harcanan Süre-Teklifsiz Yazılım (Dakika) sürelerinin toplamından İlk Proje Süresi(Belirlenen tarih aralığında harcanan süre) farkını ifade etmektedir."
            />
            <CustomAlert
              title="İlk Kurulum Proje Süresinde Harcanan Süre (Dakika):"
              description="Şirket tanımlama ekranı, anlaşma bilgileri ekranında girilen ilk kurulum proje bilgileri altındaki tarih aralığında harcanan süreyi ifade etmektedir."
            />
            <CustomAlert
              title="İlk Kurulum Proje Süresinde Harcanacak Süre (Dakika):"
              description="Şirket tanımlama ekranı, anlaşma bilgileri ekranında girilen ilk kurulum proje bilgileri, tarih aralığındaki harcanacak süreyi ifade etmektedir."
            />
            <CustomAlert
              title="Anlaşılan Süre (Dakika): "
              description="Şirketler Menüsü, Anlaşma bilgileri butonu ile açılan ekranda girilen aylık Standart süre alanındaki süre bilgisi gelmektedir. "
            />
            <CustomAlert
              title="Süre Aşımı(Dakika):"
              description="Değerlendirmeye Alınan Süre ile Anlaşılan süre arasındaki farkı vermektedir. "
            />
          </Stack>
        ) : (
          <Stack direction="column" spacing={1}>
            <CustomAlert
              title="Harcanan Süre-Destek (Dakika): "
              description="Destek Menüsündeki kayıtlarda harcanan toplam süreyi ifade etmektedir. İşlem Türü Yazılımsal Hata Seçilen destek kayıtları bu süre içine dahil edilmez. Bu sütundaki süre sadece bayinin destek süresini kapsamaktadır. "
            />
            <CustomAlert
              title="Harcanan Süre-Teklifsiz Yazılım (Dakika): "
              description="Merkeze iletilen teklifsiz taleplerin, Merkezde harcanan görev süresi ile bayini aynı iş için kendi ekibine açmış olduğu görev de harcadığı sürelerin toplamıdır.  "
            />
            <CustomAlert
              title="Harcanan Süre-Teklifli (Dakika):"
              description="Merkeze iletilen teklifli taleplerin, Merkezde harcanan görev süresi ile bayini aynı iş için kendi ekibine açmış olduğu görev de harcadığı sürelerin toplamıdır. "
            />
            <CustomAlert
              title="Harcanan Süre-Hata Düzeltme (Dakika):"
              description="Destek ve Müşteri talepleri menülerinde hata tipli kayıtlar için harcanan toplam süreyi baz almaktadır. Merkez ve Bayide Harcanan destek ve talep görevleri için harcanan toplam süreyi baz almaktadır. (Destek; İşlem Türü: Yazılımsal Hata,Müşteri talepleri; Talep Tipi: Hata Talebi ) "
            />
            <CustomAlert
              title="Toplam Harcanan Süre(Dakika): "
              description="Firma için harcanan tüm destek süreleri ve Talep Görev Sürelerinin toplamını İfade etmektedir. Bayinin destek kayıtları süresi ve talebin görevlerinin(Merkez+Bayi) süresinin toplamı.  "
            />
            <CustomAlert
              title="Değerlendirmeye Alınan Süre (Dakika): "
              description=" (Harcanan Süre-Destek (Dakika) ile Harcanan Süre-Teklifsiz Yazılım (Dakika) sürelerinin toplamından İlk Proje Süresi(Belirlenen tarih aralığında harcanan süre) farkını ifade etmektedir. "
            />
            <CustomAlert
              title="İlk Kurulum Proje Süresinde Harcanan Süre (Dakika):"
              description="Şirket tanımlama ekranı, anlaşma bilgileri ekranında girilen ilk kurulum proje bilgileri altındaki tarih aralığında harcanan süreyi ifade etmektedir."
            />
            <CustomAlert
              title="İlk Kurulum Proje Süresinde Harcanacak Süre (Dakika):"
              description="Şirket tanımlama ekranı, anlaşma bilgileri ekranında girilen ilk kurulum proje bilgileri, tarih aralığındaki harcanacak süreyi ifade etmektedir."
            />
            <CustomAlert
              title="Anlaşılan Süre (Dakika): "
              description="Şirketler Menüsü, Anlaşma bilgileri butonu ile açılan ekranda girilen aylık Standart süre alanındaki süre bilgisi gelmektedir. "
            />
            <CustomAlert
              title="Süre Aşımı(Dakika):"
              description="Değerlendirmeye Alınan Süre ile Anlaşılan süre arasındaki farkı vermektedir. "
            />
          </Stack>
        )}
      </DialogContentWrapper>
    </ScrollDialog>
  );
});
export default SirketBazliSureAnaliziSutunInfoDialog;
