import { YETKI_GRUBU_TIPI } from "../common/constants";
import { ID_WIDTH, LONG_DATE_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "sorumluidStr",
    label: "Sorumlu",
    dataType: "text",
    dataField: "sorumluidStr",
    visible: ["grid"],
    width: "180px",
  },
  {
    name: "fiiliBaslangicTarihi",
    label: "Fiili Başlangıç Tarihi",
    dataType: "date",
    dataField: "fiiliBaslangicTarihi",
    visible: ["grid"],
    width: "180px",
  },
  {
    name: "harcananSure",
    label: "Harcanan Süre",
    dataType: "number",
    dataField: "harcananSure",
    visible: ["grid"],
    width: "150px",
  },
  {
    name: "yapilanIslem",
    label: "Yapılan İşlem",
    dataType: "text",
    dataField: "yapilanIslem",
    visible: ["grid"],
  },
  {
    name: "Kayıt Tarihi",
    label: "kayitTarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
];

export default entity;
