import React, { useRef } from "react";
import MainTab from "../../components/tab/MainTab";
import { ctheme } from "../../theme/theme";
import { Field } from "react-final-form";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import HizmetForm from "./HizmetForm";
import PageGrid from "../../components/grid/PageGrid";
import PageConfig from "./SirketToplantiConfig";
import SirketKisiler from "../SirketKisiler/SirketKisiler";
import { Grid } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import UlkeInputForm from "../../components/inputs/custom-form-inputs/UlkeInputForm";
import SehirInputForm from "../../components/inputs/custom-form-inputs/SehirInputForm";
import IlceInputForm from "../../components/inputs/custom-form-inputs/IlceInputForm";
import MahalleInputForm from "../../components/inputs/custom-form-inputs/MahalleInputForm";
import DinamikTanimlarInputForm from "../../components/inputs/custom-form-inputs/DinamikTanimlarInputForm";
import { CALISAN_SAY_ARALIGI, DINAMIK_TANIM_TIPLERI, PARA_BIRIMI } from "../../common/constants";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";

const SirketTabs = React.memo(
  ({ rowData, ekranKodu, yetki, modalLoading, tabRef }) => {

    const tabLabels = [
      "Bağlantı Bilgileri",
      "Toplantılar",
      "Hizmet Formları",
      "KİŞİLER"
      , "DİĞER BİLGİLER"
    ];
    const listGridRef = useRef();

    const BaglantiBilgileriTab = (
      <Field
        name="baglantiBilgileri"
        component={RichTextEditor}
        readOnly={!yetki.kaydet}
        modalLoading={modalLoading}
      //label={"Bağlantı bilgileri"}
      />
    );

    const ToplantiTab = (
      <PageGrid
        gridId="SirketPageToplanti"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        height="43vh"
        reqData={{ sirketId: rowData.id }}
        columnBtnVisible={false}
        exportButtonVisible={false}
      />
    );

    const SirketKisilerTab = (
      <SirketKisiler rowData={rowData} ekranKodu={ekranKodu}></SirketKisiler>
    );

    const CustomFieldWrapper = ({ label, modalLoading, children, textWidth }) => {
      return (
        <FieldWrapper
          label={label}
          modalLoading={modalLoading}
          width={"75%"}
          textWidth={textWidth ? textWidth : "25%"}
        >
          {children}
        </FieldWrapper>
      );
    };

    const ulkeInputRef = useRef();
    const gorusmeRef = useRef();
    const sehirInputRef = useRef();
    const ilceInputRef = useRef();
    const mahalleInputRef = useRef();
    const sektorDinamikTanimInputRef = useRef();
    const programDinamikTanimInputRef = useRef();
    const iletisimDurumuDinamikTanimInputRef = useRef();

    const DigerBilgilerTab = (

      <>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFieldWrapper
            label="Ülke"
            modalLoading={modalLoading}
          >
            <Field
              name="ulkeid"
              component={UlkeInputForm}
              // ref={ulkeInputRef}
              inputRef={ulkeInputRef}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="Şehir"
            modalLoading={modalLoading}
          >
            <Field
              name="sehirid"
              component={SehirInputForm}
              inputRef={sehirInputRef}
              ulkeInputRef={ulkeInputRef}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="İlçe"
            modalLoading={modalLoading}
          >
            <Field
              name="ilceid"
              component={IlceInputForm}
              inputRef={ilceInputRef}
              sehirInputRef={sehirInputRef}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="Mahalle"
            modalLoading={modalLoading}
          >
            <Field
              name="mahalleid"
              component={MahalleInputForm}
              inputRef={mahalleInputRef}
              ilceInputRef={ilceInputRef}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>

        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFieldWrapper
            label="Sektör"
            modalLoading={modalLoading}
          >
            <Field
              name="sektor"
              rowData={rowData}
              component={DinamikTanimlarInputForm}
              inputRef={sektorDinamikTanimInputRef}
              ekranKodu={ekranKodu}
              readOnly={!yetki.kaydet}
              tip={DINAMIK_TANIM_TIPLERI.SEKTOR.Value}
              caption="Sektör Tanımlama"
              aciklamaCaption="Sektör"
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="Çalışan Sayısı Aralığı"
            modalLoading={modalLoading}
          >
            <Field
              items={Object.values(CALISAN_SAY_ARALIGI)}
              rowData={rowData}
              component={AutocomplateInputForm}
              name="calisanSayisiAraligi"
              readOnly={!yetki.kaydet}
            />
          </CustomFieldWrapper>
          <Grid
            container
            direction="row"
            spacing={0}
            style={{ Padding: 0 }}
          >
            <Grid item xs={8}>
              <CustomFieldWrapper
                label="Tahmini Yıllık Ciro"
                modalLoading={modalLoading}
                textWidth="45%"
              >
                <Field
                  name="tahminiCiro"
                  component={NumberInputForm}
                  readOnly={!yetki.kaydet}
                  rowData={rowData}
                />
              </CustomFieldWrapper>
            </Grid>
            <Grid item xs={4}>
              <CustomFieldWrapper
                label="Birim"
                modalLoading={modalLoading}
              >
                <Field
                  name="tahminiCiroBirimi"
                  items={Object.values(PARA_BIRIMI)}
                  rowData={rowData}
                  component={AutocomplateInputForm}
                  readOnly={!yetki.kaydet}
                />
              </CustomFieldWrapper>
            </Grid>
          </Grid>

          <CustomFieldWrapper
            label="Kullanılan Program"
            modalLoading={modalLoading}
          >
            <Field
              name="programid"
              rowData={rowData}
              component={DinamikTanimlarInputForm}
              inputRef={programDinamikTanimInputRef}
              ekranKodu={ekranKodu}
              readOnly={!yetki.kaydet}
              tip={DINAMIK_TANIM_TIPLERI.PROGRAM.Value}
              caption="Kullanılan Program Kaydı"
              aciklamaCaption="Kullanılan Program"
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="Referans Kişi/Kurum"
            modalLoading={modalLoading}
          >
            <Field
              name="referans"
              component={TextInputForm}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="İletişim Durumu"
            modalLoading={modalLoading}
          >
            <Field
              name="iletisimDurumuid"
              rowData={rowData}
              component={DinamikTanimlarInputForm}
              inputRef={iletisimDurumuDinamikTanimInputRef}
              ekranKodu={ekranKodu}
              readOnly={!yetki.kaydet}
              tip={DINAMIK_TANIM_TIPLERI.ILETISIM_DURUMU.Value}
              caption="İletişim Durumu Tanımlama"
              aciklamaCaption="İletişim Durumu"
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="Son Görüşme Tarihi"
            modalLoading={modalLoading}
          >
            <Field
              name="sonGorusmeTarihi"
              component={DateInputForm2}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="KEP Adresi"
            modalLoading={modalLoading}
          >
            <Field
              name="kepAdresi"
              component={TextInputForm}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="NACE Kodu"
            modalLoading={modalLoading}
          >
            <Field
              name="naceKodu"
              component={TextInputForm}
              readOnly={!yetki.kaydet}
              rowData={rowData}
            />
          </CustomFieldWrapper>
          <CustomFieldWrapper
            label="Açıklama"
            modalLoading={modalLoading}
          >
            <Field
              name="aciklama"
              component={MultilineTextInputForm}
              readOnly={!yetki.kaydet}
              rowData={rowData}
              modalLoading={modalLoading}
              minRows={4}
            />
          </CustomFieldWrapper>
        </Grid>
      </>
    )

    return (
      <MainTab
        ref={tabRef}
        labels={tabLabels}
        appBarColor={ctheme.colors.gridHeaderColor}
        sx={{ flexBasis: "100vh", flexGrow: 1 }}
      >
        {BaglantiBilgileriTab}
        {rowData.id > 0 && ToplantiTab}
        {rowData.id > 0 && (
          <HizmetForm ekranKodu={ekranKodu} parentRowData={rowData}></HizmetForm>
        )}
        {SirketKisilerTab}
        {DigerBilgilerTab}
      </MainTab>
    );
  });

export default SirketTabs;

// export default function SirketTabs({
//   rowData,
//   ekranKodu,
//   yetki,
//   modalLoading,
//   tabRef,
// }) {
//   let xDigerBilgiler = rowData.sirketDigerBilgiler;

//   console.log(xDigerBilgiler, "xDigerBilgiler");

//   console.log(yetki.kaydet, "yetki.kaydet");


//   const tabLabels = [
//     "Bağlantı Bilgileri",
//     "Toplantılar",
//     "Hizmet Formları",
//     "KİŞİLER"
//     , "DİĞER BİLGİLER"
//   ];
//   const listGridRef = useRef();

//   const BaglantiBilgileriTab = (
//     <Field
//       name="baglantiBilgileri"
//       component={RichTextEditor}
//       readOnly={!yetki.kaydet}
//       modalLoading={modalLoading}
//     //label={"Bağlantı bilgileri"}
//     />
//   );

//   const ToplantiTab = (
//     <PageGrid
//       gridId="SirketPageToplanti"
//       PageConfig={PageConfig}
//       listGridRef={listGridRef}
//       isCreatable={false}
//       ekranKodu={ekranKodu}
//       height="43vh"
//       reqData={{ sirketId: rowData.id }}
//       columnBtnVisible={false}
//       exportButtonVisible={false}
//     />
//   );

//   const SirketKisilerTab = (
//     <SirketKisiler rowData={rowData} ekranKodu={ekranKodu}></SirketKisiler>
//   );

//   const CustomFieldWrapper = ({ label, modalLoading, children, textWidth }) => {
//     return (
//       <FieldWrapper
//         label={label}
//         modalLoading={modalLoading}
//         width={"75%"}
//         textWidth={textWidth ? textWidth : "25%"}
//       >
//         {children}
//       </FieldWrapper>
//     );
//   };

//   const ulkeInputRef = useRef();
//   const gorusmeRef = useRef();
//   const sehirInputRef = useRef();
//   const ilceInputRef = useRef();
//   const mahalleInputRef = useRef();
//   const sektorDinamikTanimInputRef = useRef();
//   const programDinamikTanimInputRef = useRef();
//   const iletisimDurumuDinamikTanimInputRef = useRef();

//   const DigerBilgilerTab = (digerBilgiler) => (

//     <>
//       {/* <Grid item xs={12} sm={6} md={6}>
//         <CustomFieldWrapper
//           label="Ülke"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="ulkeid"
//             component={UlkeInputForm}
//             // ref={ulkeInputRef}
//             inputRef={ulkeInputRef}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="Şehir"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="sehirid"
//             component={SehirInputForm}
//             inputRef={sehirInputRef}
//             ulkeInputRef={ulkeInputRef}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="İlçe"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="ilceid"
//             component={IlceInputForm}
//             inputRef={ilceInputRef}
//             sehirInputRef={sehirInputRef}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="Mahalle"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="mahalleid"
//             component={MahalleInputForm}
//             inputRef={mahalleInputRef}
//             ilceInputRef={ilceInputRef}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="Sektör"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="sektor"
//             rowData={digerBilgiler}
//             component={DinamikTanimlarInputForm}
//             inputRef={sektorDinamikTanimInputRef}
//             ekranKodu={ekranKodu}
//             readOnly={!yetki.kaydet}
//             tip={DINAMIK_TANIM_TIPLERI.SEKTOR.Value}
//             caption="Sektör Tanımlama"
//             aciklamaCaption="Sektör"
//           />
//         </CustomFieldWrapper>
//       </Grid> */}
//       <Grid item xs={12} sm={6} md={6}>
//         {/* <CustomFieldWrapper
//           label="Web Sitesi"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="webSitesi"
//             component={TextInputForm}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper> */}
//         {/* <CustomFieldWrapper
//           label="Sektör"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="sektor"
//             rowData={digerBilgiler}
//             component={DinamikTanimlarInputForm}
//             inputRef={sektorDinamikTanimInputRef}
//             ekranKodu={ekranKodu}
//             readOnly={!yetki.kaydet}
//             tip={DINAMIK_TANIM_TIPLERI.SEKTOR.Value}
//             caption="Sektör Tanımlama"
//             aciklamaCaption="Sektör"
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="Çalışan Sayısı Aralığı"
//           modalLoading={modalLoading}
//         >
//           <Field
//             items={Object.values(CALISAN_SAY_ARALIGI)}
//             rowData={digerBilgiler}
//             component={AutocomplateInputForm}
//             name="calisanSayisiAraligi"
//             readOnly={!yetki.kaydet}
//           />
//         </CustomFieldWrapper>
//         <Grid
//           container
//           direction="row"
//           spacing={0}
//           style={{ Padding: 0 }}
//         >
//           <Grid item xs={8}>
//             <CustomFieldWrapper
//               label="Tahmini Yıllık Ciro"
//               modalLoading={modalLoading}
//               textWidth="45%"
//             >
//               <Field
//                 name="tahminiCiro"
//                 component={NumberInputForm}
//                 readOnly={!yetki.kaydet}
//                 rowData={digerBilgiler}
//               />
//             </CustomFieldWrapper>
//           </Grid>
//           <Grid item xs={4}>
//             <CustomFieldWrapper
//               label="Birim"
//               modalLoading={modalLoading}
//             >
//               <Field
//                 name="tahminiCiroBirimi"
//                 items={Object.values(PARA_BIRIMI)}
//                 rowData={digerBilgiler}
//                 component={AutocomplateInputForm}
//                 readOnly={!yetki.kaydet}
//               />
//             </CustomFieldWrapper>
//           </Grid>
//         </Grid>

//         <CustomFieldWrapper
//           label="Kullanılan Program"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="programid"
//             rowData={digerBilgiler}
//             component={DinamikTanimlarInputForm}
//             inputRef={programDinamikTanimInputRef}
//             ekranKodu={ekranKodu}
//             readOnly={!yetki.kaydet}
//             tip={DINAMIK_TANIM_TIPLERI.PROGRAM.Value}
//             caption="Kullanılan Program Kaydı"
//             aciklamaCaption="Kullanılan Program"
//           />
//         </CustomFieldWrapper>*/}
//         <CustomFieldWrapper
//           label="Referans Kişi/Kurum"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="referans"
//             component={TextInputForm}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         {/*  <CustomFieldWrapper
//           label="İletişim Durumu"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="iletisimDurumuid"
//             rowData={digerBilgiler}
//             component={DinamikTanimlarInputForm}
//             inputRef={iletisimDurumuDinamikTanimInputRef}
//             ekranKodu={ekranKodu}
//             readOnly={!yetki.kaydet}
//             tip={DINAMIK_TANIM_TIPLERI.ILETISIM_DURUMU.Value}
//             caption="İletişim Durumu Tanımlama"
//             aciklamaCaption="İletişim Durumu"
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="Son Görüşme Tarihi"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="sonGorusmeTarihi"
//             component={DateInputForm2}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="KEP Adresi"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="kepAdresi"
//             component={TextInputForm}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="NACE Kodu"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="naceKodu"
//             component={TextInputForm}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//           />
//         </CustomFieldWrapper>
//         <CustomFieldWrapper
//           label="Açıklama"
//           modalLoading={modalLoading}
//         >
//           <Field
//             name="aciklama"
//             component={MultilineTextInputForm}
//             readOnly={!yetki.kaydet}
//             rowData={digerBilgiler}
//             modalLoading={modalLoading}
//             minRows={4}
//           />
//         </CustomFieldWrapper> */}
//       </Grid>
//     </>
//   )

//   return (
//     <MainTab
//       ref={tabRef}
//       labels={tabLabels}
//       appBarColor={ctheme.colors.gridHeaderColor}
//       sx={{ flexBasis: "100vh", flexGrow: 1 }}
//     >
//       {BaglantiBilgileriTab}
//       {rowData.id > 0 && ToplantiTab}
//       {rowData.id > 0 && (
//         <HizmetForm ekranKodu={ekranKodu} parentRowData={rowData}></HizmetForm>
//       )}
//       {SirketKisilerTab}
//       {DigerBilgilerTab(xDigerBilgiler)}
//     </MainTab>
//   );
// }
