import React from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import CancelButton from "../../components/buttons/custom/CancelButton";
import SaveButton from "../../components/buttons/custom/SaveButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import TamamButton from "../../components/buttons/custom/TamamButton";

const UrunAciklamasiDialog = React.forwardRef(
  ({ ekranKodu, isEditable, setRowDataFnc, listGridRef }, ref) => {
    const { open, closeRecord, modalLoading, rowData, setRowData } =
      useFetchDialog(ref, "Urunler/UrunAciklamasiGetById");

    const afterSubmitFnc = (res) => {
      setRowDataFnc((prevState) => {
        return {
          ...prevState,
          // temsilciAnalizi: res?.data?.data?.temsilciAnalizi,
          // talepDurumu: res?.data?.data?.talepDurumu,
        };
      });
    };

    const handleSubmit = useSubmit({}, setRowData, listGridRef, afterSubmitFnc);

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Açıklama"
        maxWidth="md"
        fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Field
                  name="aciklama"
                  component={RichTextEditor}
                  readOnly={true}
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <TamamButton
                  onClick={(e) => {
                    closeRecord();
                  }}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    );
  }
);

export default UrunAciklamasiDialog;
