import Type from "../../entities/IsSonucuRaporu";

const PageConfig = {
  title: "İş Sonuçları",
  ekranKodu: "IsSonuclariRaporu_Res",
  type: Type,
  listRoute: "Rapor/IsSonucuRaporList",
};

export default PageConfig;
