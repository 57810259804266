import { ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Kişi No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "adSoyad",
    label: "Ad Soyad",
    dataType: "text",
    dataField: "adSoyad",
    visible: ["grid"],
  },
  {
    name: "tckn",
    label: "TCKN",
    dataType: "text",
    dataField: "tckn",
    visible: ["grid"],
  },

  {
    name: "tel",
    label: "Tel",
    dataType: "text",
    dataField: "tel",
    visible: ["grid"],
  },
  {
    name: "mail",
    label: "Mail",
    dataType: "text",
    dataField: "mail",
    visible: ["grid"],
  },
  {
    name: "goreviStr",
    label: "Görevi",
    dataType: "text",
    dataField: "goreviStr",
    visible: ["grid"],
  },
  {
    name: "firmaYetkilisi",
    label: "Firma Yetkilisi",
    dataType: "boolean",
    dataField: "firmaYetkilisi",
    visible: ["grid"],
  },
  //filtre--------------------------------------------------------------------------------------------------
];

export default entity;
