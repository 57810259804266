import Type from "../../entities/TeklifProjeDetay";

const PageConfig = {
  title: "TeklifProjeMaster",
  ekranKodu: "TeklifProjeMaster_Res",
  type: Type,
  listRoute: "TeklifProjeMaster/List",
  getByIdRoute: "TeklifProjeMaster/GetById",
  insertRoute: "TeklifProjeMaster/Insert",
  updateRoute: "TeklifProjeMaster/Update",
  deleteRoute: "TeklifProjeMaster/Delete",
  teklifiReddetRoute: "TeklifProjeMaster/TeklifiReddet",
};

// const FileConfig = {
//   allFilesDownloadRoute: "TeklifMaster/TeklifOnayiDosyalariIndir",
//   fileDownloadRoute: "TeklifMaster/TeklifOnayiDosyaIndir",
//   filesInfoRoute: "TeklifMaster/TeklifOnayiDosyaBilgileri",
//   fileUploadRoute: "TeklifMaster/TeklifOnayiDosyaYukle",
//   deleteFilesRoute: "TeklifMaster/TeklifOnayiDosyaSil",
// };

export { PageConfig };
