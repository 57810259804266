import Type from "../../entities/Not";

const PageConfig = {
  title: "Notlarım",
  ekranKodu: "Not_Res",
  type: Type,
  listRoute: "Not/List",
  getByIdRoute: "Not/GetById",
  insertRoute: "Not/Insert",
  updateRoute: "Not/Update",
  bulkUpdateRoute: "Not/BulkUpdate",
  deleteRoute: "Not/Delete",
};

const PageConfigForIlgiliKisiler = {
  title: "Notlarım",
  ekranKodu: "Not_Res",
  type: Type,
  listRoute: "Not/ListForIlgiliKisiler",
  getByIdRoute: "Not/GetById",
  insertRoute: "Not/Insert",
  updateRoute: "Not/Update",
  bulkUpdateRoute: "Not/BulkUpdateForIlgiliKisiler",
  deleteRoute: "Not/Delete",
};

export { PageConfig, PageConfigForIlgiliKisiler };
