import { useRef } from "react";
import PageConfig from "./KontrolEdilecekIslerConfig";
import PageGrid from "../../components/grid/PageGrid";
import PageFilter from "../../components/filter/PageFilter";
import { Button } from "devextreme-react/data-grid";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";
import IsSonucuDialog from "../Home/IsSonucuDialog";
import { HttpAuthorized } from "../../common/common";
import { apiUrl } from "../../common/constants";
import { printToast } from "../../common/constant-functions/printToast";

export default function KontrolEdilecekIsler({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  // const recordRef = useRef();
  const isSonucuDialogRef = useRef();

  const onRowPreparedExtra = (e) => {
    if (e.rowType === "data" && e.data.kontrolDurumu === true) {
      e.rowElement.style.backgroundColor = "#d3fdd3"; // Yeşil renk
    } else if (e.rowType === "data" && e.data.kendimKontrolEttim === true) {
      e.rowElement.style.backgroundColor = "#f3f687"; // Sarı renk
    }
  };

  const CustomColumnButton = [
    <Button
      key="analizSonucu"
      hint={"Analiz Sonucu"}
      icon={"textdocument"}
      onClick={async (e) => {
        isSonucuDialogRef?.current?.openRecord(e.row.data.talepId);
        // analizSonucuDialogRef?.current?.openRecord(e?.row?.data?.talepId);
      }}
    ></Button>,
    <Button
      key="KontrolEdildi"
      hint={"Kontrol Edildi Olarak İşaretle"}
      icon={"check"}
      onClick={async (e) => {
        HttpAuthorized.request({
          method: "POST",
          url: `${apiUrl}KontrolEdilecekIsler/KontrolEdildiOlarakIsaretle`,
          data: {
            id: e.row.data.talepId,
          },
        })
          .then(async (res) => {
            await listGridRef.current.instance.refresh();
          })
          .catch((error) => {
            printToast(error.response.data);
          });
      }}
      visible={(e) => {
        return (
          e.row.data.talepleriKontrolEdebilir === true &&
          e.row.data.kendimKontrolEttim !== true
        );
      }}
    ></Button>,
    <Button
      key="KontrolEdilmedi"
      hint={"Kontrol Edilmedi Olarak İşaretle"}
      icon={"close"}
      onClick={async (e) => {
        HttpAuthorized.request({
          method: "POST",
          url: `${apiUrl}KontrolEdilecekIsler/KontrolEdilmediOlarakIsaretle`,
          data: {
            id: e.row.data.talepId,
          },
        })
          .then(async (res) => {
            await listGridRef.current.instance.refresh();
          })
          .catch((error) => {
            printToast(error.response.data);
          });
      }}
      visible={(e) => {
        return (
          e.row.data.talepleriKontrolEdebilir === true &&
          e.row.data.kendimKontrolEttim === true
        );
      }}
    ></Button>,
  ];

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />
      <PageGrid
        gridId="FirmaTalep"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        // recordRef={recordRef}
        isCreatable={false}
        ekranKodu={ekranKodu}
        CustomColumnButton={CustomColumnButton}
        editBtnVisible={false}
        onRowPreparedExtra={onRowPreparedExtra}
      />
      <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={false}
      ></IsSonucuDialog>
      {/* <AnalizSonucuDialog
        ref={isSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={false}
        // buttonVisible={true}
      ></AnalizSonucuDialog> */}
    </>
  );
}
