import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import * as yup from "yup";
import { Save } from "@mui/icons-material";
import validateFormValues from "../../common/constant-functions/validateFormValues";
import useSubmit from "../../hooks/use-submit";
import useFetch from "../../hooks/use-fetch";
import { PageConfig } from "../Sirket/SirketConfig";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";

function SirketBilgileri() {
  const [rowData, setRowData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);

  const fetchData = useFetch(
    "Sirket/SirketBilgilerimGetById",
    setRowData,
    setModalLoading
  );

  const handleSubmit = useSubmit(PageConfig, setRowData);

  useEffect(() => {
    fetchData();
  }, []);

  const validationSchemaSirketBilgileri = yup.object().shape({
    sirketAdi: yup.string().required(),
  });

  const sirketBilgisiValidate = validateFormValues(
    validationSchemaSirketBilgileri
  );

  return (
    <Paper
      sx={{
        m: 1,
        minWidth: "470px",
        maxWidth: "470px",
      }}
    >
      <Typography align="center">ŞİRKET BİLGİLERİM</Typography>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        initialValues={rowData}
        validate={sirketBilgisiValidate}
        autoComplete={false}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid sx={{ p: 2 }}>
              <FieldWrapper label="Şirket Adı" modalLoading={modalLoading}>
                <Field name="sirketAdi" component={TextInputForm} type="text" />
              </FieldWrapper>
              <FieldWrapper label="Temsilci" modalLoading={modalLoading}>
                <Field
                  name="temsilciId"
                  rowData={rowData}
                  remoteDataAdaptor={{
                    fetchRoute: "User/KullaniciListForSelect",
                    fildsForText: ["isimSoyisim"],
                    fieldForValue: "id",
                    reqData: { temsilci: true },
                  }}
                  component={AutocomplateInputForm}
                  readOnly={true}
                />
              </FieldWrapper>

              <FieldWrapper
                label="Destek Sözleşmesi Başlangıç Tarihi"
                modalLoading={modalLoading}
              >
                <Field
                  name="destekSozBaslangic"
                  component={DateInputForm2}
                  readOnly={true}
                />
              </FieldWrapper>
              <FieldWrapper
                label="Destek Sözleşmesi Bitiş Tarihi"
                modalLoading={modalLoading}
              >
                <Field
                  name="destekSozBitis"
                  component={DateInputForm2}
                  readOnly={true}
                />
              </FieldWrapper>
              <FieldWrapper
                label="Aylık Standart Süre (dk)"
                modalLoading={modalLoading}
              >
                <Field
                  name="aylikStandartSure"
                  component={TextInputForm}
                  readOnly={true}
                  numberInput={true}
                />
              </FieldWrapper>

              <FieldWrapper
                label="Aylık Anlaşılan Standart Ücret"
                modalLoading={modalLoading}
              >
                <Field
                  name="aylikAnlasilanStandartUcret"
                  component={NumberInputForm}
                  readOnly={true}
                />
              </FieldWrapper>
              <FieldWrapper label="Lisans Kodu" modalLoading={modalLoading}>
                <Field
                  name="lisansKodu"
                  component={TextInputForm}
                  readOnly={true}
                />
              </FieldWrapper>

              <Button
                sx={{ width: "100%", mt: 3, p: 1 }}
                variant="contained"
                startIcon={<Save />}
                size="small"
                onClick={(e) => {
                  form.submit();
                }}
              >
                Güncelle
              </Button>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
}

export default SirketBilgileri;
