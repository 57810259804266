import React from "react";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import useDialog from "../../hooks/use-dialog";

const MusteriTalepDetayiDialog = React.forwardRef(({}, ref) => {
  const { open, closeRecord, id, data } = useDialog(ref);

  const handleSubmit = (formValues) => {
    // Form gönderildiğinde yapılacak işlemler
    // console.log("Form submitted:", formValues);
  };

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Müşteri Talep Detayı"
      maxWidth="lg"
      fullWidth={true}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...data,
          talepDetayi: data?.talepDetayi,
          // bayiAnalizSonucu: parseHtmlString(data?.analizSonucu),
        }}
        //validate={validate}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <FormComponent>
            <DialogContentWrapper>
              <Field
                name="talepDetayi"
                component={RichTextEditor}
                readOnly={true}
                // rowData={rowData}
                // modalLoading={modalLoading}
              />
            </DialogContentWrapper>
          </FormComponent>
        )}
      />
    </ScrollDialog>
  );
});

export default MusteriTalepDetayiDialog;
