import { Tooltip } from "@mui/material";
import { HttpAuthorized } from "../common/common";
import { getTalepDurumuArr } from "../common/constant-functions/getTalepDurumuArr";
import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import {
  DATE_FORMAT,
  ILGILI_MODUL,
  ONCELIK_DURUMU,
  TALEP_DURUMU,
  TALEP_TIPI,
  TUTAR_FORMAT,
  URUN_PROGRAMLARI,
} from "../common/constants";
import {
  ANALIZ_SONUCU_WIDTH,
  DATE_WIDTH,
  ID_WIDTH,
  IS_SONUCU_WIDTH,
  KDV_WIDTH,
  LONG_DATE_WIDTH,
  OKUNMAYAN_YORUM_WIDTH,
} from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Talep No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    // firstSort: "desc",
    width: ID_WIDTH,
  },
  {
    name: "okunmayanYorumSayisi",
    label: "Okunmayan Yorum",
    dataType: "number",
    dataField: "okunmayanYorumSayisi",
    visible: ["grid"],
    // cellRender: (e) => <MailBadge badgeContent={e?.value} />,
    alignment: "center",
    allowExporting: false,
    width: OKUNMAYAN_YORUM_WIDTH,
  },
  {
    name: "kayitTarihi",
    label: "Talep Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    format: DATE_FORMAT,
    visible: ["grid"],
    width: DATE_WIDTH,
    firstSort: "desc",
  },
  {
    name: "talepDurumu",
    label: "Talep Durumu",
    dataType: "select",
    dataField: "talepDurumu",
    visible: ["grid"],
    // cellRender: (e) => {
    //   let options = getTalepDurumuArr();
    //   let option = options.find((x) => x.Value == e.value);
    //   return <div style={{ color: option.Color }}>{option?.Text}</div>;
    // },
    cellRender: (e) => {
      let options = getTalepDurumuArr();
      let option = options.find((x) => x.Value === e.value);
      return (
        <Tooltip
          onOpen={(event) => {
            HttpAuthorized.request({
              method: "POST",
              url: "Talep/AnalizSonucuGetById",
              data: { id: e.data.id },
            })
              .then((res) => {
                event.target.title = res?.data?.data?.analizSonucu
                  ? "Analiz Sonucu: " +
                  parseHtmlString(res?.data?.data?.analizSonucu)
                  : "Analiz sonucu bulunamadı...";
              })
              .catch((error) => { });
          }}
        >
          <div style={{ color: option?.Color }}>{option?.Text}</div>
        </Tooltip>
      );
    },
  },
  {
    name: "talepBasligi",
    label: "Talep Konusu",
    dataType: "text",
    dataField: "talepBasligi",
    visible: ["grid"],
    cellRender: (e) => {
      return (
        <Tooltip
          onOpen={(event) => {
            HttpAuthorized.request({
              method: "POST",
              url: "Talep/TalepDetayiGetById",
              data: { id: e.data.id },
            })
              .then((res) => {
                event.target.title = res?.data?.data?.talepDetayi
                  ? "Talep Detayı: " +
                  parseHtmlString(res?.data?.data?.talepDetayi)
                  : "Talep detayı bulunamadı";
              })
              .catch((error) => { });
          }}
        >
          <div>{e.value}</div>
        </Tooltip>
      );
    },
  },
  // {
  //   name: "talepDetayi",
  //   label: "Talep Detayı",
  //   dataType: "text",
  //   dataField: "talepDetayi",
  //   visible: ["grid"],
  // },
  {
    name: "talepTipi",
    label: "Talep Tipi",
    dataType: "select",
    dataField: "talepTipi",
    visible: ["grid"],
    selectItems: TALEP_TIPI,
  },
  {
    name: "oncelik",
    label: "Öncelik Durumu",
    dataType: "select",
    dataField: "oncelik",
    visible: ["grid"],
    selectItems: ONCELIK_DURUMU,
  },
  {
    name: "kayitEdenKullaniciIdStr",
    label: "Kayıt Eden Kullanıcı",
    dataType: "text",
    dataField: "kayitEdenKullaniciIdStr",
    visible: ["grid"],
  },
  {
    name: "talepEdenPersonelIdStr",
    label: "Talep Eden Personel",
    dataType: "text",
    dataField: "talepEdenPersonelIdStr",
    visible: ["grid"],
  },
  {
    name: "ilgiliModul",
    label: "İlgili Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["grid"],
    selectItems: Object.values(ILGILI_MODUL),
  },
  {
    name: "platform",
    label: "Platform",
    dataType: "select",
    dataField: "platform",
    visible: ["grid"],
    selectItems: Object.values(URUN_PROGRAMLARI),
  },
  {
    name: "analizSonucu",
    label: "Analiz Sonucu Yapılacak Geliştirmeler",
    dataType: "text",
    dataField: "analizSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: ANALIZ_SONUCU_WIDTH,
  },
  {
    name: "teklifVerilisTarihi",
    label: "Teklif Veriliş Tarihi",
    dataType: "date",
    dataField: "teklifVerilisTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: LONG_DATE_WIDTH,
  },
  {
    name: "onayRedTarihi",
    label: "Onay Red Tarihi",
    dataType: "date",
    dataField: "onayRedTarihi",
    visible: ["grid"],
    format: DATE_FORMAT,
    width: DATE_WIDTH,
  },
  {
    name: "onayRedKullaniciIdStr",
    label: "Teklif Onay/Red Kullanıcı",
    dataType: "text",
    dataField: "onayRedKullaniciIdStr",
    visible: ["grid"],
  },
  {
    name: "teklifTutari",
    label: "Teklif Tutarı",
    dataType: "number",
    dataField: "teklifTutari",
    visible: ["grid"],
    format: TUTAR_FORMAT,
  },
  {
    name: "teklifKdvOrani",
    label: "Teklif Kdv Oranı (%)",
    dataType: "number",
    dataField: "teklifKdvOrani",
    visible: ["grid"],
    format: TUTAR_FORMAT,
    width: KDV_WIDTH,
  },
  {
    name: "terminTarihi",
    label: "Termin Tarihi",
    dataType: "date",
    dataField: "terminTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "talepTamamlanmaTarihi",
    label: "Tamamlanma Tarihi",
    dataType: "datetime",
    dataField: "talepTamamlanmaTarihi",
    visible: ["grid"],
    width: LONG_DATE_WIDTH,
  },
  {
    name: "isSonucu",
    label: "İş Sonucu",
    dataType: "text",
    dataField: "isSonucu",
    visible: ["grid"],
    customizeText: (e) => e?.value && parseHtmlString(e?.value),
    width: IS_SONUCU_WIDTH,
  },

  //filtre-----------------------------------

  {
    name: "tarih1",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "tarih1",
    visible: ["filter"],
  },
  {
    name: "tarih2",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
  },
  // {
  //   name: "talepDurumu",
  //   label: "Talep Durumu",
  //   dataType: "select",
  //   dataField: "talepDurumu",
  //   visible: ["filter"],
  //   items: TALEP_DURUMU_FILTER,
  // },
  {
    name: "id",
    label: "Talep No",
    dataType: "number",
    dataField: "id",
    visible: ["filter"],
  },
  {
    name: "ilgiliModul",
    label: "Modül",
    dataType: "select",
    dataField: "ilgiliModul",
    visible: ["filter"],
    items: Object.values(ILGILI_MODUL),
  },
  {
    name: "talepDurumDetay",
    label: "Talep Durum Detay",
    dataType: "select",
    dataField: "talepDurumDetay",
    visible: ["filter"],
    items: Object.values(TALEP_DURUMU),
  },
  {
    name: "talepBasligi",
    label: "Talep Konusu",
    dataType: "text",
    dataField: "talepBasligi",
    visible: ["filter"],
  },
  {
    name: "talepDetayi",
    label: "Talep Açıklama",
    dataType: "text",
    dataField: "talepDetayi",
    visible: ["filter"],
  },
  {
    name: "platform",
    label: "Plaftorm",
    dataType: "select",
    dataField: "platform",
    visible: ["filter"],
    items: Object.values(URUN_PROGRAMLARI),
  },
];

export default entity;
