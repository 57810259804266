import { useRef } from "react";
import PageFilter from "../../components/filter/PageFilter";
import PageGrid from "../../components/grid/PageGrid";
import PageConfig from "./GorevDurumuRaporuConfig";
import IsSonucuDialog from "../Home/IsSonucuDialog";
import GridIsSonucuButton from "../../components/buttons/custom/GridIsSonucuButton";
import GroupGrid from "../../components/grid/GroupGrid";
import AnalizSonucuDialog from "../Home/AnalizSonucuDialog";
import { Button } from "devextreme-react/data-grid";

export default function GorevDurumuRaporu({ ekranKodu }) {
  const listGridRef = useRef();
  const filterRef = useRef();
  const analizSonucuDialogRef = useRef();

  const CustomColumnButton = [
    <Button
      key="analizSonucu"
      hint={"Analiz Sonucu"}
      icon={"textdocument"}
      onClick={async (e) => {
        analizSonucuDialogRef?.current?.openRecord(e?.row?.data?.talepNo);
      }}
    />,
  ];

  const onRowPreparedExtra = (e) => {
    // if (e.rowType === "data" && e.data.renk === "K") {
    //   e.rowElement.style.color = "#d41022"; // Yeşil renk
    // } else
    if (e.rowType === "data" && e.data.renk === "S") {
      e.rowElement.style.color = "#c4c908"; // Sarı renk
    }
  };

  const onCellPreparedExtra = (e) => {
    if (
      e.rowType === "data" &&
      e.column.dataField === "atananTerminTarihi" &&
      e.data.renk === "K"
    ) {
      e.cellElement.style.color = "red";
      // e.rowElement.style.color = "#d41022"; // Yeşil renk
    }
  };

  return (
    <>
      <PageFilter
        PageConfig={PageConfig}
        ref={filterRef}
        listGridRef={listGridRef}
      />

      <GroupGrid
        gridId="Görev_Durumu_Raporu"
        PageConfig={PageConfig}
        listGridRef={listGridRef}
        filterRef={filterRef}
        ekranKodu={ekranKodu}
        isCreatable={false}
        CustomColumnButton={CustomColumnButton}
        editBtnVisible={false}
        groupAutoExpandAll={false}
        groupPanelVisible={false}
        onRowPreparedExtra={onRowPreparedExtra}
        onCellPreparedExtra={onCellPreparedExtra}
      />
      <AnalizSonucuDialog
        ref={analizSonucuDialogRef}
        ekranKodu={ekranKodu}
        isEditable={false}
        buttonVisible={true}
        listGridRef={listGridRef}
      ></AnalizSonucuDialog>
      {/* <IsSonucuDialog
        ref={isSonucuDialogRef}
        isEditable={false}
      ></IsSonucuDialog> */}
    </>
  );
}
