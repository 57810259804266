import { Close, Save } from "@mui/icons-material";
import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import * as yup from "yup";
import validateFormValues from "../../common/constant-functions/validateFormValues";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import useSubmit from "../../hooks/use-submit";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import { AppContext } from "../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../common/constants";

const IsSonucuDialog = React.forwardRef(({ listGridRef, isEditable }, ref) => {
  const context = useContext(AppContext);
  const { open, closeRecord, modalLoading, rowData, setRowData } =
    useFetchDialog(ref, "IsSonucu/GetById");

  const handleSubmit = useSubmit(
    { updateRoute: "IsSonucu/Update" },
    setRowData,
    listGridRef
  );

  const validationSchema = yup.object().shape({
    isSonucu: yup.string().required(),
  });

  const validate = validateFormValues(validationSchema);

  const isSonucuYayinlansinGozuksun =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="İş Sonucu"
      maxWidth="lg"
      fullWidth={true}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={rowData}
        validate={validate}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <FormComponent onSubmit={handleSubmit}>
            <DialogContentWrapper>
              {isSonucuYayinlansinGozuksun ? (
                <FieldWrapper
                  label="İş Sonucu Yayınlansın"
                  modalLoading={modalLoading}
                  textWidth={200}
                  width={"100%"}
                >
                  <Field
                    name="isSonucuYayinlansin"
                    component={CheckboxForm}
                    modalLoading={modalLoading}
                    disabled={!isEditable}
                  />
                </FieldWrapper>
              ) : null}
              <Field
                name="isSonucu"
                component={RichTextEditor}
                readOnly={!isEditable}
                label={"İş Sonucu"}
                modalLoading={modalLoading}
              />
            </DialogContentWrapper>
            <DialogActionsWrapper>
              {isEditable && (
                <ButtonWrapper
                  color="success"
                  startIcon={<Save />}
                  onClick={(e) => {
                    form.submit();
                  }}
                >
                  Kaydet
                </ButtonWrapper>
              )}
              {isEditable && (
                <ButtonWrapper
                  color="warning"
                  startIcon={<Close />}
                  onClick={() => {
                    form.reset();
                  }}
                  disabled={submitting || pristine}
                >
                  Vazgeç
                </ButtonWrapper>
              )}
            </DialogActionsWrapper>
          </FormComponent>
        )}
      />
    </ScrollDialog>
  );
});

export default IsSonucuDialog;
