import { Delete, Download } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

function FileList({
  files,
  modalLoading,
  downloadFile,
  deleteFileIds,
  setDeleteFileIds,
  height,
  yetki,
  deleteDisabled
}) {
  function deleteFile(id) {
    setDeleteFileIds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((fileId) => fileId !== id);
      } else {
        return [...prevState, id];
      }
    });
  }

  return (
    <div>
      {modalLoading ? (
        <>
          <Skeleton height={"70px"} />
          <Skeleton height={"70px"} />
        </>
      ) : (
        <>
          {Array.isArray(files) && files.length > 0 ? (
            <TableContainer component={Paper} sx={{ height: height }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Dosya Adı</TableCell>
                    <TableCell>Ekleyen Kullanıcı</TableCell>
                    <TableCell>Yetki Grubu</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(files) &&
                    files?.map((file, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Grid
                            container
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {
                              <Typography
                                style={
                                  deleteFileIds.includes(file.id)
                                    ? {
                                      textDecoration: "line-through",
                                      color: "red",
                                    }
                                    : {}
                                }
                              >
                                {file.fileName}
                              </Typography>
                            }
                            {/* <Grid>
                              <IconButton
                                aria-label="İndir"
                                color="success"
                                onClick={() => downloadFile(file.id)}
                              >
                                <Download />
                              </IconButton>
                              {yetki?.sil && (
                                <IconButton
                                  aria-label="Sil"
                                  color="error"
                                  onClick={() => deleteFile(file.id)}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </Grid> */}
                          </Grid>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {
                            <Typography
                              style={
                                deleteFileIds.includes(file.id)
                                  ? {
                                    textDecoration: "line-through",
                                    color: "red",
                                  }
                                  : {}
                              }
                            >
                              {file.ekleyenKullaniciAdi}
                            </Typography>
                          }
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {
                            <Typography
                              style={
                                deleteFileIds.includes(file.id)
                                  ? {
                                    textDecoration: "line-through",
                                    color: "red",
                                  }
                                  : {}
                              }
                            >
                              {file.ekleyenKullYetkiGrubu}
                            </Typography>
                          }
                        </TableCell>

                        <TableCell component="th" scope="row" align="right">
                          <Grid sx={{ justifyContent: "flex-end" }}>
                            <IconButton
                              aria-label="İndir"
                              color="success"
                              onClick={() => downloadFile(file.id)}
                            >
                              <Download />
                            </IconButton>
                            {yetki?.sil && (
                              <IconButton
                                aria-label="Sil"
                                color="error"
                                onClick={() => deleteFile(file.id)}
                                disabled={deleteDisabled}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>Dosya bulunamadı..</Typography>
          )}
        </>
      )}
    </div>
  );
}

export default FileList;
