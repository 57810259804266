import Type from "../../entities/GorusmeHatirlatmalari";

const PageConfig = {
  title: "GorusmeHatirlatmalari",
  ekranKodu: "GorusmeHatirlatmalari_Res",
  type: Type,
  listRoute: "GorusmeHatirlatmalari/List",
  getByIdRoute: "GorusmeHatirlatmalari/GetById",
  insertRoute: "GorusmeHatirlatmalari/Insert",
  updateRoute: "GorusmeHatirlatmalari/Update",
  deleteRoute: "GorusmeHatirlatmalari/Delete",
};

export { PageConfig };
