import { Alert, AlertTitle, Grid } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import useFetchDialog from "../../hooks/use-fetch-dialog";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import useSubmit from "../../hooks/use-submit";
import SaveButton from "../../components/buttons/custom/SaveButton";
import CancelButton from "../../components/buttons/custom/CancelButton";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import FormComponent from "../../components/forms/FormComponent";
import { AppContext } from "../../common/AppContext";
import DescriptionIcon from "@mui/icons-material/Description";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import DosyaYukle from "../../components/file/DosyaYukle";
import DosyaYukleButton from "../../components/buttons/custom/DosyaYukleButton";
import { AnlasmaBilgileriConfig } from "./SirketConfig";
import PdfDialog from "../../components/pdf/PdfDialog";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import * as yup from "yup";

const CustomAlert = ({ title, description }) => {
  return (
    <div style={{ paddingTop: "20px" }}>
      <Alert variant="outlined" severity="error" style={{ fontSize: "12px" }}>
        {[description]}
      </Alert>
    </div>
  );
};
const AnlasmaBilgileriDialog = React.forwardRef(({ listGridRef }, ref) => {
  const { open, closeRecord, modalLoading, rowData, setRowData } =
    useFetchDialog(ref, AnlasmaBilgileriConfig.getByIdRoute);

  const [errors, setErrors] = useState({}); // Hata mesajlarını saklamak için state

  const handleSubmit = useSubmit(
    { updateRoute: AnlasmaBilgileriConfig.updateRoute },
    setRowData,
    listGridRef
  );

  // const [isEditableSure, setIsEditableSure] = useState(false);
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");

  // useEffect(() => {
  //   if (
  //     baslangicTarihi !== null &&
  //     baslangicTarihi !== "" &&
  //     bitisTarihi !== null &&
  //     bitisTarihi !== ""
  //   ) {
  //     setIsEditableSure(true);
  //   } else {
  //     setIsEditableSure(false);
  //   }
  // }, [modalLoading, baslangicTarihi, bitisTarihi]); // Form açıldığında bu effect çalışır

  const handleDateChange = (name, value) => {
    if (name === "projeBaslangicTarihi") {
      setBaslangicTarihi(value);
    } else if (name === "projeBitisTarihi") {
      setBitisTarihi(value);
    }

    // Eğer her iki tarih alanı doluysa, 'projeToplamHarcanacakSure' düzenlenebilir olur
    // if (baslangicTarihi && bitisTarihi) {
    //   setIsEditableSure(true);
    // } else {
    //   setIsEditableSure(false);
    // }
  };

  const pdfDialogRef = useRef();
  const dosyaYukleRef = useRef();

  const context = useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find(
      (x) => x.ekranKodu === AnlasmaBilgileriConfig.ekranKodu
    ) ?? {};

  const validationSchema = yup.object().shape({
    // projeBaslangicTarihi: yup
    //   .date()
    //   .typeError("Geçersiz tarih formatı.")
    //   .test(
    //     "is-before",
    //     "Proje Başlangıç Tarihi, Destek Sözleşme Başlangıç Tarihinden önce olmalıdır.",
    //     function (value) {
    //       const { destekSozBaslangic } = this.parent; // Diğer alanlara erişim
    //       return (
    //         !value ||
    //         !destekSozBaslangic ||
    //         new Date(value) < new Date(destekSozBaslangic)
    //       );
    //     }
    //   ),

    // projeBitisTarihi: yup
    //   .date()
    //   .typeError("Geçersiz tarih formatı.")
    //   .test(
    //     "is-after",
    //     "Proje Bitiş Tarihi, Proje Başlangıç Tarihinden sonra olmalıdır.",
    //     function (value) {
    //       const { projeBaslangicTarihi } = this.parent; // Diğer alanlara erişim
    //       return (
    //         !value ||
    //         !projeBaslangicTarihi ||
    //         new Date(value) > new Date(projeBaslangicTarihi)
    //       );
    //     }
    //   ),

    destekSozBaslangic: yup.date().typeError("Geçersiz tarih formatı."),
  });

  const validateFormValues = (schema) => async (values) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
      setErrors("");
    } catch (err) {
      let errors = {};
      errors = err?.inner?.reduce((formError, innerError) => {
        return innerError.message;

        // return innerError.message setIn(formError, innerError.path, innerError.message);
      }, {});

      setErrors(errors);
      return errors;
    }
  };

  // const customValidateFormValues = (validationSchema) => {
  //   let aa = validateFormValues(validationSchema);
  //   setErrors(aa);
  // };

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Anlaşma Bilgileri"
        maxWidth="sm"

        // fullWidth={true}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12}>
                    <FieldWrapper
                      label="Destek Sözleşmesi Başlangıç Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="destekSozBaslangic"
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Aylık Standart Süre (dk)"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aylikStandartSure"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                        numberInput={true}
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      label="Destek Sözleşmesi Bitiş Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="destekSozBitis"
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                      />
                      {errors.destekSozBaslangic && (
                        <div className="error-message">
                          {errors.destekSozBaslangic}
                        </div>
                      )}{" "}
                      {/* Hata mesajı */}
                    </FieldWrapper>
                    {context.user.yetkiGrubuTipi ===
                      YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value && (
                      <FieldWrapper
                        label="Aylık Anlaşılan Standart Ücret"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aylikAnlasilanStandartUcret"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <div>
                      <h3>İlk Kurulum Proje Bilgileri</h3>
                      <hr />
                    </div>
                    <FieldWrapper
                      label="Proje Başlangıç Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="projeBaslangicTarihi"
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                        onChangeExtra={(newValue) => {
                          handleDateChange("projeBaslangicTarihi", newValue);
                        }}
                      />
                      {errors.projeBaslangicTarihi && (
                        <div className="error-message">
                          {errors.projeBaslangicTarihi}
                        </div>
                      )}{" "}
                      {/* Hata mesajı */}
                    </FieldWrapper>
                    <FieldWrapper
                      label="Proje Bitiş Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="projeBitisTarihi"
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                        onChangeExtra={(newValue) =>
                          handleDateChange("projeBitisTarihi", newValue)
                        }
                      />
                      {errors.projeBitisTarihi && (
                        <div className="error-message">
                          {errors.projeBitisTarihi}
                        </div>
                      )}{" "}
                      {/* Hata mesajı */}
                    </FieldWrapper>
                    <FieldWrapper
                      label="Projede Harcanacak Toplam Süre"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="projeToplamHarcanacakSure"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                        numberInput={true}
                      />
                    </FieldWrapper>
                    <CustomAlert
                      title="Harcanan Süre-Destek (Dakika): "
                      description="Şirket Bazlı Süre Raporunda 'İlk Kurulum Proje Bilgileri' altında belirlenen tarih aralığı ve harcanacak toplam süre, dikkate alınarak 'değerlendirmeye alınan süre'  bilgisi hesaplanacaktır."
                    />
                  </Grid>
                </DialogContentTop>

                {Object.keys(errors).length > 0 && (
                  <div style={{ color: "red" }}>
                    <CustomAlert title="Hata" description={errors} />
                  </div>
                )}
              </DialogContentWrapper>

              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <>
                    {rowData.sozlesmeVarMi && (
                      <CustomColoredButton
                        startIcon={<DescriptionIcon />}
                        onClick={(e) => {
                          pdfDialogRef?.current?.openRecord(rowData.id);
                        }}
                        color="grape"
                      >
                        Sözleşme
                      </CustomColoredButton>
                    )}

                    <DosyaYukleButton
                      onClick={() => {
                        dosyaYukleRef?.current?.openModal();
                      }}
                      label="Sözleşme Yükle"
                    />
                  </>
                </Grid>
                <Grid>
                  <SaveButton
                    onClick={(e) => {
                      form.submit();
                    }}
                  />
                  <CancelButton
                    onClick={() => {
                      form.reset();
                    }}
                    disabled={submitting || pristine}
                  />
                </Grid>
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <DosyaYukle
        route={AnlasmaBilgileriConfig.contractUploadRoute}
        reqExtraData={[{ name: "id", value: rowData?.id }]}
        ref={dosyaYukleRef}
        rowData={rowData}
        setRowData={setRowData}
      ></DosyaYukle>

      <PdfDialog
        ref={pdfDialogRef}
        title="Sözleşme"
        url={AnlasmaBilgileriConfig.contractGetByIdRoute}
      />
    </>
  );
});

export default AnlasmaBilgileriDialog;
