import React, { useEffect, useRef, useState } from "react";
import usePageRecord from "../../hooks/use-page-record";
import PageConfig from "./IseBaslangicGirisiConfig";
import * as yup from "yup";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { Grid, TextField } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import dayjs from "dayjs";
import { apiUrl } from "../../common/constants";
import { HttpAuthorized } from "../../common/common";
import EditGrid from "../../components/grid/EditGrid";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import SaveIcon from "@mui/icons-material/Save";
import CustomDateInputFormForStates from "../../components/inputs/form-inputs/CustomDateInputFormForStates";
import { red } from "@mui/material/colors";

const IseBaslangicGirisiDialog = React.forwardRef(
  ({ ekranKodu, listGridRef }, ref) => {
    var initialState = {
      // tarih: dayjs(),
      // isSonucuYayinlansin: true,
    };

    const [fiiliBaslangicTarihi, setFiiliBaslangicTarihi] = useState(null);
    const [harcananSure, setHarcananSure] = useState(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [yapilanIslem, setYapilanIslem] = useState(null);
    const [errors, setErrors] = useState({});

    const afterSubmitFnc = (id) => {
      ref?.current?.openRecord(id);
      listGridRef?.current?.instance?.refresh();
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      context,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    useEffect(() => {
      if (!open) {
        // Dialog kapanınca isEditMode'u sıfırla
        setIsEditMode(false);
        setFiiliBaslangicTarihi(null); // Gerekirse diğer state'leri de sıfırlayın
        setHarcananSure(null);
        setCurrentRowId(null);
        setYapilanIslem(null);
        setErrors(null);
      }
    }, [open]);

    const convertToLocalTime = (utcDateStr) => {
      return dayjs(utcDateStr).format("DD.MM.YYYY");
      // return new Date(utcDateStr).toLocaleDateString("tr-TR");
    };

    const editGridRef = useRef();

    const validationSchema = yup.object().shape({
      fiiliBaslangicTarihi: yup.date().required(),
      harcananSure: yup
        .number()
        .nullable()
        .test(
          "harcananSure-required-if-yapilanIslem",
          "Harcanan Süre zorunludur.",
          function (value) {
            const { yapilanIslem } = this.parent;
            if (yapilanIslem && !value) {
              return this.createError({
                path: "harcananSure",
                message: "Harcanan Süre zorunludur.",
              });
            }
            return true;
          }
        ),

      yapilanIslem: yup
        .string()
        .nullable()
        .test(
          "yapilanIslem-required-if-harcananSure",
          "Yapılan işlem alanı zorunludur.",
          function (value) {
            const { harcananSure } = this.parent;
            if (harcananSure && !value) {
              return this.createError({
                path: "yapilanIslem",
                message: "Yapılan işlem alanı zorunludur.",
              });
            }
            return true;
          }
        ),
    });

    // const validate = validateFormValues(validationSchema);

    const handleSave = async () => {
      try {
        await validationSchema.validate(
          {
            fiiliBaslangicTarihi,
            harcananSure,
            yapilanIslem,
          },
          { abortEarly: false }
        );

        const requestData = {
          fiiliBaslangicTarihi: fiiliBaslangicTarihi,
          harcananSure: harcananSure,
          talepid: rowData.talepid,
          id: currentRowId,
          isEditMode: isEditMode,
          yapilanIslem: yapilanIslem,
        };

        // API isteğini gönder
        const response = await HttpAuthorized.post(
          `${apiUrl}IseBaslangicGirisi/IseBaslangicGirisiEkle`,
          requestData
        );

        // Başarılıysa, formu sıfırla ve grid'i yenile
        if (response.data.success) {
          setFiiliBaslangicTarihi(null);
          setHarcananSure(null);
          afterSubmitFnc(rowData.talepid);
          setIsEditMode(false);
          setYapilanIslem(null);
          setCurrentRowId(null);
          setErrors(null);
          // editGridRef.current?.refresh(); // Grid'i yenile
        }
      } catch (error) {
        if (error.name === "ValidationError") {
          // Validation hatalarını işle
          const newErrors = error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {});
          setErrors(newErrors);
        } else {
          // Diğer hataları işleyin
          console.error("Kaydetme hatası:", error);
        }
      }
    };

    const editButtonOnClick = (e) => {
      const selectedDate = dayjs(e.row.data.fiiliBaslangicTarihi).format(
        "YYYY-MM-DD"
      );
      setFiiliBaslangicTarihi(selectedDate); // sadece tarihi ayarlayın, saat bilgisi yok
      setHarcananSure(e.row.data.harcananSure); // satırdaki süre
      setCurrentRowId(e.row.data.id); // satırın id'si
      setIsEditMode(true); // güncelleme moduna geç
      setYapilanIslem(e.row.data.yapilanIslem); // güncelleme moduna geç
    };

    const editButtonVisible = (e) => {
      return e.row.data.sorumluid === context.user.id;
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle={`İşe Başlangıç Girişi`}
          maxWidth="md"
          canFullScreen={false}
          draggable={false}
        >
          {/* {" "}
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <FormComponent onSubmit={handleSubmit}> */}
          <Grid
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              marginBottom: "25px",
            }}
          >
            <EditGrid
              entities={PageConfig.type}
              // openRecord={openRecord}
              height={"25vh"}
              editGridRef={editGridRef}
              dataSource={rowData.detay ?? []}
              ekleButtonGizli={true}
              editButtonOnClick={editButtonOnClick}
              rowPrepared={(info) => {
                if (info.rowType === "data" && info.data.id === currentRowId) {
                  info.rowElement.style.backgroundColor = "#b9b5ff"; // Satırın arka plan rengini ayarla
                  info.rowElement.style.color = "#000000"; // Metin rengini ayarla
                }
              }}
              editButtonVisible={editButtonVisible}
            ></EditGrid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            <Grid item xs={6} sm={6}>
              <FieldWrapper
                label="Fiili Başlangıç Tarihi"
                modalLoading={modalLoading}
                style={{
                  marginLeft: "15px",
                  marginBottom: "15px",
                  color: "red",
                }} // Soldan margin ekle
              >
                <CustomDateInputFormForStates
                  value={fiiliBaslangicTarihi} // Grid'den gelen tarih değeri
                  onChange={(newDate) => setFiiliBaslangicTarihi(newDate)} // Tarih değişince state'i güncelle
                />
              </FieldWrapper>
              <FieldWrapper
                label="Harcanan Süre"
                modalLoading={modalLoading}
                style={{ marginLeft: "15px" }} // Soldan margin ekle
              >
                <TextField
                  name="harcananSure"
                  type="number"
                  value={harcananSure ? harcananSure : ""}
                  onChange={(e) => setHarcananSure(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  style={{ marginBottom: "5px" }}
                />
                {errors?.harcananSure && (
                  <div style={{ color: "red" }}>{errors?.harcananSure}</div>
                )}
              </FieldWrapper>
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
            <Grid item xs={12}>
              <FieldWrapper
                label="Yapılan İşlem"
                modalLoading={modalLoading}
                style={{ marginLeft: "15px" }} // Genişlik ve margin
                textWidth={"17%"}
                width={"83%"}
              >
                <TextField
                  name="yapilanIslem"
                  type="text"
                  value={yapilanIslem ? yapilanIslem : ""}
                  onChange={(e) => setYapilanIslem(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3} // Minimum 3 satır
                  size="small"
                  sx={{ width: 665 }}
                  // fullWidth // Açıklama alanını tam genişlik yap
                  style={{ marginBottom: "5px" }}
                />
                {errors?.yapilanIslem && (
                  <div style={{ color: "red" }}>{errors?.yapilanIslem}</div>
                )}
              </FieldWrapper>
            </Grid>

            {/* Kaydet butonuna sağdan boşluk verelim */}
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", paddingRight: "15px" }}
            >
              <CustomColoredButton
                startIcon={<SaveIcon />}
                onClick={handleSave}
                disabled={fiiliBaslangicTarihi === null}
                color="green"
              >
                {isEditMode ? "Güncelle" : "Kaydet"}
              </CustomColoredButton>
            </Grid>
          </Grid>
          {/* </FormComponent>
            )}
           /> */}
        </ScrollDialog>
      </>
    );
  }
);

export default IseBaslangicGirisiDialog;
