import Type from "../../entities/KullaniciAnalizi";

const PageConfig = {
  title: "Kullanıcı Analizi",
  ekranKodu: "KullaniciAnalizi_Res",
  type: Type,
  listRoute: "Rapor/KullaniciAnaliziList",
};

export default PageConfig;
