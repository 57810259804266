import { DialogContent } from "@mui/material";
import React, { useRef, useState } from "react";
import { HttpAuthorized } from "../../common/common";
import { loadingVisible } from "../../common/constant-functions/loadingVisible";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import Yorum from "../Musteri-talep/Yorum";
import IsYorumList from "../Gorev/IsYorumList";

const YorumDialog = React.forwardRef(
  ({ listGridRef, isIsYorum = false, bayiKullanicisi = false }, ref) => {
    const [open, setOpen] = useState(false);
    const [yorumList, setYorumList] = useState([]);
    const yorumRef = useRef();

    const handleClose = () => {
      setOpen(false);
      setYorumList([]);
    };

    const openRecord = (id) => {
      setOpen(true);
      loadingVisible(true);
      HttpAuthorized.post(
        isIsYorum && bayiKullanicisi
          ? "BayiIsYorum/List"
          : isIsYorum && !bayiKullanicisi
          ? "IsYorum/List"
          : "Yorum/List",
        {
          talepId: id,
        }
      )
        .then(async (res) => {
          await yorumRef.current.setYorumData({
            talepId: id,
            yorumlar: res.data.data,
          });

          await listGridRef?.current?.instance?.refresh();
        })
        .finally(() => loadingVisible(false));
    };

    React.useImperativeHandle(ref, () => ({
      openRecord: openRecord,
    }));

    return (
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle={isIsYorum ? "Görev Süreçleri" : "Yorumlar"}
        maxWidth="lg"
        fullWidth={true}
        canFullScreen={false}
      >
        <DialogContent
          sx={{ padding: 0, overflow: "hidden" }}
          id="dialog-content"
        >
          {isIsYorum ? (
            <IsYorumList inDialog ref={yorumRef} yorumMaxHeight="150px" />
          ) : (
            <Yorum inDialog ref={yorumRef} />
          )}
        </DialogContent>
      </ScrollDialog>
    );
  }
);

export default YorumDialog;
