import React, { useEffect, useState } from "react";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import "./CustomFormInputs/animation.css";

function DateInputForm2({
  input = {},
  fieldCompWidth,
  loading,
  isDisabled,
  meta,
  customProps,
  readOnly,
  onChangeExtra,
  isFilterPage,
  ...rest
}) {
  const minDate = new Date(1990, 0, 1);
  const [date, setDate] = useState(input?.value || null);

  useEffect(() => {
    if (isFilterPage && input.onChange) {
      input.onChange(date);
    } else {
      if (input && input.onChange && date) {
        input.onChange(date); // Date değiştikçe onChange çağrılıyor
        onChangeExtra && onChangeExtra(date);
      }
    }
  }, [date]);

  useEffect(() => {
    if (input.value) {
      setDate(input.value);
    }
  }, [input.value]);

  const onValueChanged = (e) => {
    setDate(e.value);
    if (input && input.onChange) {
      input.onChange(e.value);
    }
    if (onChangeExtra) {
      onChangeExtra(e.value);
    }
  };

  return (
    <>
      <DateBox
        readOnly={readOnly}
        disabled={isDisabled}
        name="date"
        id={input?.name + "DateInputFormId" || "defaultDateInputId"}
        type="date"
        displayFormat="shortdate"
        dateSerializationFormat="yyyy-MM-dd"
        focusStateEnabled={false}
        activeStateEnabled={false}
        stylingMode="underlined"
        showTodayButton={true}
        showClearButton={true}
        min={minDate}
        {...(customProps ? customProps : {})}
        style={{ width: "100%" }}
        onValueChanged={onValueChanged}
        value={date}
        calendarOptions={{ showTodayButton: true }}
        {...rest}
      >
        <DateBoxButton name="clear" location="after" />
        <DateBoxButton name="dropDown" />
      </DateBox>
      <span id={input.name} className={`${input.name} examp2`}></span>
    </>
  );
}

export default DateInputForm2;
