import MainTab from "../../components/tab/MainTab";
import DestekAsimTahsilatTakip from "./DestekAsimTahsilatTakip";
import YazilimsalTahsilatTakip from "./YazilimsalTahsilatTakip";

export default function TahsilatTakip({ ekranKodu }) {
  return (
    <>
      <MainTab labels={["Yazılım Geliştirme", "Destek Aşım Ücretlendirme"]}>
        <YazilimsalTahsilatTakip ekranKodu={ekranKodu} />
        <DestekAsimTahsilatTakip ekranKodu={ekranKodu} />
      </MainTab>
    </>
  );
}
