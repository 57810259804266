import React, { useRef, useState } from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./GorusmeMailTemplateConfig";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import {
  GORUSME_DURUMU,
  GORUSME_MAIL_OLAYLARI,
  TEKLIF_MAIL_OLAYLARI,
} from "../../common/constants";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import GorusmeMailSutunlari from "./GorusmeMailSutunlari";
import CustomRichTextEditorforMailTemplates from "../../components/inputs/form-inputs/CustomRichTextEditorforMailTemplates";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const GorusmeMailTemplateRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, tipi }, ref) => {
    const afterSubmitFnc = () => {
      handleClose();
    };

    const gorusmeMailOlayiInputRef = useRef();
    const teklifMailOlayiInputRef = useRef();
    
    const initialState = { tipi: tipi };

    const [mailAciklamaValue, setMailAciklamaValue] = useState(""); // Yeni state

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    const validationSchema = yup.object().shape({
      // aciklama: yup.string().required(),
    });

    const [tempValues, setTempValues] = useState(""); // Seçilen değerleri saklamak için state

    const handleMailAciklamaUpdate = (value) => {
      var newMailAciklama = "";
      const currentMailAciklama = `${rowData.mailAciklama} ${tempValues}` || "";
      if (tempValues == "") {
        newMailAciklama = `${currentMailAciklama} ${value}`; // Yeni değeri oluştur
      } else {
        newMailAciklama = `${tempValues} ${value}`; // Yeni değeri oluştur
      }

      setTempValues(newMailAciklama);
      setMailAciklamaValue(newMailAciklama);
    };

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle={
            tipi === 1
              ? "Görüşme Mail Taslağı Oluşturma"
              : tipi === 2
              ? "Teklif Mail Taslağı Oluşturma"
              : ""
          }
          maxWidth="lg"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column" spacing={1} sx={{ width: "45%" }}>
                      <GorusmeMailSutunlari
                        onMailAciklamaUpdate={handleMailAciklamaUpdate}
                        tipi={tipi}
                        // olayid={ tipi = 1 ? gorusmeMailOlayiInputRef?.current?.value : tipi = 2 ? teklifMailOlayiInputRef?.current?.value : rowData?.mailOlayi}
                         olayid={ gorusmeMailOlayiInputRef?.current?.value}
                      ></GorusmeMailSutunlari>
                    </Stack>

                    <Stack direction="column" spacing={1} sx={{ width: "60%" }}>
                      {tipi === 1 ? (
                        <CustomFieldWrapper
                          label="Mail Olayı"
                          modalLoading={modalLoading}
                        >
                          <Field
                            items={Object.values(GORUSME_MAIL_OLAYLARI)}
                            rowData={rowData}
                            name="mailOlayi"
                            component={AutocomplateInputForm}
                            ref={gorusmeMailOlayiInputRef}
                          />
                        </CustomFieldWrapper>
                      ) : tipi === 2 ? (
                        <CustomFieldWrapper
                          label="Mail Olayı"
                          modalLoading={modalLoading}
                        >
                          <Field
                            items={Object.values(TEKLIF_MAIL_OLAYLARI)}
                            rowData={rowData}
                            name="mailOlayi"
                            component={AutocomplateInputForm}
                            ref={teklifMailOlayiInputRef}
                          />
                        </CustomFieldWrapper>
                      ) : null}
                      {(gorusmeMailOlayiInputRef?.current?.value !==
                        GORUSME_MAIL_OLAYLARI.GORUSME_HATIRLATMA.Value &&
                        rowData.mailOlayi !==
                          GORUSME_MAIL_OLAYLARI.GORUSME_HATIRLATMA.Value) && ( <>
                        <CustomFieldWrapper
                          label="Mail Gönderilecek Kişiler"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="mailGidecekKisiler"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        </>)}
                      <CustomFieldWrapper
                        label="Mail Konusu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="mailKonu"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Mail İçeriği"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="mailAciklama"
                          component={CustomRichTextEditorforMailTemplates}
                          readOnly={!yetki.kaydet}
                          minRows={5}
                          initialValue={mailAciklamaValue}
                          onChange={(newVal) => setMailAciklamaValue(newVal)}
                        />
                      </CustomFieldWrapper>
                    </Stack>
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
                {/* </FormComponent> */}
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default GorusmeMailTemplateRecord;
