import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { GorusmeFileConfig, PageConfig } from "./GorusmelerConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";
import DateTimeInputForm from "../../components/inputs/form-inputs/DateTimeInputForm";
import {
  GORUSME_TIPI,
  GORUSME_DURUMU,
  GORUSME_KAYNAK,
  YETKI_GRUBU_TIPI,
} from "../../common/constants";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import Typography from "@mui/material/Typography";
import TeklifMasterRecord from "../Teklif/TeklifMasterRecord";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import SellIcon from "@mui/icons-material/Sell";
import GorusmeHatirlatmaDialog from "./GorusmeHatirlatmaDialog";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import PotansiyelMusteriEklemeliInputForm from "../../components/inputs/custom-form-inputs/PotansiyelMusteriEklemeliInputForm";
import KullaniciInputForm from "../../components/inputs/custom-form-inputs/KullaniciInputForm";
import ReferansVerenInputForm from "../../components/inputs/custom-form-inputs/ReferansVerenInputForm";
import GorusmeAciklamalari from "../GorusmeAciklamalari/GorusmeAciklamalari";
import MainTab from "../../components/tab/MainTab";
import GorusmeHatirlatma from "../GorusmeHatirlatmalari/GorusmeHatirlatma";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import PotansiyelKisilerInputForm from "../../components/inputs/custom-form-inputs/PotansiyelKisilerInputForm";
import GorusmeAtamaDialog from "./GorusmeAtamaDialog";
import SendIcon from "@mui/icons-material/Send";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import NewTextInputForm from "../../components/inputs/form-inputs/NewTextInputForm";
import PopoverButton from "../../components/buttons/PopoverButton";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const GorusmelerRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, potansiyelData }, ref) => {
    const context = React.useContext(AppContext);

    const teklifVerRef = useRef();
    const gorusmeHatirlatmaRef = useRef();
    const gorusmeHatirlatmalariRef = useRef();
    const potFirmaIdRef = useRef();
    const gorusmeAtamaDialogRef = useRef();
    const gorusulenKisiTelRef = useRef();
    const gorusulenKisiMailRef = useRef();

    var initialState = {
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
      durum: 1,
    };

    if (potansiyelData && potansiyelData.id) {
      initialState = {
        ...initialState,
        potFirmaid: potansiyelData.id,
        potFirmaidStr: potansiyelData.musteriAdi,
      };
    }

    const merkezKullanici =
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value ||
      context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value;

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

    const masterDegistirir =
      rowData?.kayitEden && rowData?.kayitEden == context?.user?.id;

    const validationSchema = yup.object().shape({
      baslangicTarihi: yup.date().required(),
      tipi: yup.number().required(),
      potFirmaid: yup.number().required(),
      musteriTemsilcisi: yup.number().required(),
      // durum: yup.number().required(),
      durum: yup.number().when("teklifid", {
        is: (val) => val === null || val === undefined, // teklifid boşsa
        then: yup
          .number()
          .notOneOf(
            [
              GORUSME_DURUMU.TEKLIF_SURECINDE.Value,
              GORUSME_DURUMU.SATIS_GERCEKLESTI.Value,
            ],
            "Teklifi olmayan kayıtlarda durum " +
            GORUSME_DURUMU.TEKLIF_SURECINDE.Text +
            " veya " +
            GORUSME_DURUMU.SATIS_GERCEKLESTI.Text +
            " olamaz."
          )
          .required("Durum alanı zorunludur."),
        otherwise: yup.number().required("Durum alanı zorunludur."),
      }),
    });

    const memoizedRowData = React.useMemo(() => rowData, [rowData]);

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Görüşme"
          maxWidth="md"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomFieldWrapper
                        label="Görüşme No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Görüşme Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="baslangicTarihi"
                          component={DateTimeInputForm}
                          readOnly={!yetki.kaydet || !masterDegistirir}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Görüşme Tipi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          items={Object.values(GORUSME_TIPI)}
                          rowData={rowData}
                          name="tipi"
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet || !masterDegistirir}
                          disabled={!yetki.kaydet || !masterDegistirir}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Müşteri Adı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="potFirmaid"
                          rowData={rowData}
                          component={PotansiyelMusteriEklemeliInputForm}
                          ekranKodu={ekranKodu}
                          readOnly={!yetki.kaydet || !masterDegistirir}
                          disabled={!yetki.kaydet || !masterDegistirir}
                          inputRef={potFirmaIdRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Durum"
                        modalLoading={modalLoading}
                      >
                        <Field
                          items={Object.values(GORUSME_DURUMU)}
                          rowData={rowData}
                          name="durum"
                          component={AutocomplateInputForm}
                          disabled={
                            !rowData?.id ||
                            rowData?.teklifDurumu !== null ||
                            !masterDegistirir
                          }
                          readOnly={
                            !rowData?.id ||
                            rowData?.teklifDurumu !== null ||
                            !masterDegistirir
                          }
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Teklif No"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="teklifid"
                          component={TextInputForm}
                          readOnly={true}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Bayi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="bayiidStr"
                          component={TextInputForm}
                          readOnly={true}
                        />
                      </CustomFieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card
                        sx={{
                          border: "none",
                          boxShadow: "none",
                        }}
                      >
                        <CardHeader
                          sx={{ padding: "3px" }}
                          title={
                            <Typography
                              variant="subtitle1"
                              sx={{
                                textAlign: "center",
                                fontSize: "16px", // veya istediğiniz değer
                                fontWeight: "bold", // veya istediğiniz değer
                              }}
                            >
                              {"Görüşülen Kişi"}
                            </Typography>
                          }
                        />
                        {/* <CardHeader
                        title="Görüşülen Kişi"
                        sx={{
                          textAlign: "center",
                          typography: {
                            fontSize: "2px", // Font boyutunu ayarlayabilirsiniz
                            fontWeight: "bold", // Kalınlığı ayarlayabilirsiniz
                          },
                          padding: "3px",
                        }}
                      ></CardHeader> */}
                        <CardContent sx={{ padding: "3px" }}>
                          <CustomFieldWrapper
                            label="Ad Soyad"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="gorusulenKisiid"
                              component={PotansiyelKisilerInputForm}
                              ekranKodu={ekranKodu}
                              rowData={rowData}
                              readOnly={!yetki.kaydet || !masterDegistirir}
                              disabled={!yetki.kaydet || !masterDegistirir}
                              potansiyelIdRef={potFirmaIdRef}
                              telefonRef={gorusulenKisiTelRef}
                              mailRef={gorusulenKisiMailRef}
                            />
                          </CustomFieldWrapper>
                          <CustomFieldWrapper
                            label="Telefon"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="gorusulenKisiTel"
                              component={NewTextInputForm}
                              readOnly={true}
                              ref={gorusulenKisiTelRef}
                            />
                          </CustomFieldWrapper>
                          <CustomFieldWrapper
                            label="E-Posta"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="gorusulenKisiMail"
                              component={NewTextInputForm}
                              readOnly={true}
                              ref={gorusulenKisiMailRef}
                            />
                          </CustomFieldWrapper>
                        </CardContent>
                      </Card>
                      <CustomFieldWrapper
                        label="M. T."
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="musteriTemsilcisi"
                          component={KullaniciInputForm}
                          readOnly={!yetki.kaydet || !masterDegistirir}
                          disabled={!yetki.kaydet || !masterDegistirir}
                          rowData={rowData}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Referans Veren"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="referansVeren"
                          component={ReferansVerenInputForm}
                          readOnly={!yetki.kaydet || !masterDegistirir}
                          disabled={!yetki.kaydet || !masterDegistirir}
                          rowData={rowData}
                        />
                      </CustomFieldWrapper>
                      {merkezKullanici && (
                        <CustomFieldWrapper
                          label="Kaynak"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="kaynak"
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet || !masterDegistirir}
                            disabled={!yetki.kaydet || !masterDegistirir}
                            items={Object.values(GORUSME_KAYNAK)}
                            rowData={rowData}
                          />
                        </CustomFieldWrapper>
                      )}
                      {rowData?.atananKullaniciidStr === null ||
                        rowData?.atananKullaniciidStr === "" ||
                        typeof rowData?.atananKullaniciidStr === "undefined" ||
                        !merkezKullanici ? null : (
                        <>
                          <CustomFieldWrapper
                            label="Atanan Kullanıcı"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="atananKullaniciidStr"
                              component={TextInputForm}
                              readOnly={true}
                            />
                          </CustomFieldWrapper>
                          <CustomFieldWrapper
                            label="Atama Tarihi"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="atamaTarihi"
                              component={DateTimeInputForm}
                              readOnly={true}
                            />
                          </CustomFieldWrapper>
                        </>
                      )}
                    </Grid>
                  </DialogContentTop>
                  {memoizedRowData?.id ? (
                    <GorusmeAciklamalari
                      rowData={memoizedRowData}
                      ekranKodu={ekranKodu}
                      gorusmeRecordRef={ref}
                    ></GorusmeAciklamalari>
                  ) : null}

                  {/* <Field
                    label="Açıklama-1"
                    name="aciklama1"
                    component={MultilineTextInputForm}
                    type="text"
                    rowData={rowData}
                    modalLoading={modalLoading}
                    minRows={5}
                  />
                  <Field
                    label="Açıklama-2"
                    name="aciklama2"
                    component={MultilineTextInputForm}
                    type="text"
                    rowData={rowData}
                    modalLoading={modalLoading}
                    minRows={5}
                  />
                  <Field
                    label="Açıklama-3"
                    name="aciklama3"
                    component={MultilineTextInputForm}
                    type="text"
                    rowData={rowData}
                    modalLoading={modalLoading}
                    minRows={5}
                  /> */}
                </DialogContentWrapper>
                <DialogActionsWrapper
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    <PopoverButton
                      label="İşlemler"
                      backColor="#515151"
                      hoverBackColor="#1b1818"
                    >
                      {masterDegistirir ||
                        (rowData?.atananKullaniciid === context?.user?.id && (
                          <ButtonWrapper
                            color="primary"
                            startIcon={<SellIcon />}
                            onClick={(e) => {
                              {
                                rowData.teklifid
                                  ? teklifVerRef?.current?.openRecord(
                                    rowData.teklifid
                                  )
                                  : teklifVerRef?.current?.openRecord(null);
                              }
                              teklifVerRef?.current?.openRecord(null);
                            }}
                            disabled={!rowData.id}
                          >
                            {rowData.teklifid ? "Teklifi Göster" : "Teklif Ver"}
                          </ButtonWrapper>
                        ))}
                      <ButtonWrapper
                        color="primary"
                        startIcon={<AccessAlarmIcon />}
                        onClick={(e) => {
                          gorusmeHatirlatmaRef?.current?.openRecord(null);

                          // gorusmeHatirlatmalariRef?.current?.openRecord(null);
                        }}
                        disabled={!rowData.id}
                      >
                        Görüşme Hatırlat
                      </ButtonWrapper>
                      <EkDosyaButton
                        reqData={{
                          id: rowData.id,
                        }}
                        routes={GorusmeFileConfig}
                        ekranKodu={ekranKodu}
                        parentRowData={rowData}
                        disabled={!rowData?.id}
                      ></EkDosyaButton>
                      {merkezKullanici && masterDegistirir && (
                        <ButtonWrapper
                          color="primary"
                          startIcon={<SendIcon />}
                          onClick={(e) => {
                            gorusmeAtamaDialogRef?.current?.openRecord(null);

                            // gorusmeHatirlatmalariRef?.current?.openRecord(null);
                          }}
                          disabled={
                            !rowData.id || rowData.atayanKullaniciid !== null
                          }
                        >
                          Görüşmeyi Ata
                        </ButtonWrapper>
                      )}
                    </PopoverButton>
                  </Grid>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    deleteCondition={masterDegistirir}
                    submitCondition={masterDegistirir}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                    submitButtonText={"Görüşmeyi Kaydet"}
                    deleteButtonText={"Görüşmeyi Sil"}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <TeklifMasterRecord
          ref={teklifVerRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          gorusmeRef={rowData}
        // detailGridRef={detailGridRef}
        ></TeklifMasterRecord>
        <GorusmeHatirlatmaDialog
          ref={gorusmeHatirlatmaRef}
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          gorusmeRef={rowData}
        ></GorusmeHatirlatmaDialog>
        <GorusmeAtamaDialog
          ref={gorusmeAtamaDialogRef}
          gorusmeid={rowData?.id}
          gorusmeRecordRef={ref}
        ></GorusmeAtamaDialog>
        {/* <GorusmeHatirlatma
          ref={gorusmeHatirlatmalariRef}
          rowData={rowData}
          ekranKodu={ekranKodu}
        ></GorusmeHatirlatma> */}
      </>
    );
  }
);
export default GorusmelerRecord;
