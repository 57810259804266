import React from "react";
import ButtonWrapper from "../ButtonWrapper";
import { Close } from "@mui/icons-material";
import { TALEP_DURUMU } from "../../../common/constants";
import { toast } from "react-toastify";

export default function IsKapatmaBtn({
  isKapatmaDialogRef,
  rowData,
  yetki,
  talepDuzenlenemez,
}) {
  // toast.info("Filtreden şirket seçimi yapmalısınız.");

  return (
    <ButtonWrapper
      color="error"
      startIcon={<Close />}
      onClick={() => {
        {
          talepDuzenlenemez
            ? toast.warning(
              "Talep ile alakalı görev oluşturulmuştur. Temsilciniz ile iletişime geçiniz."
            )
            : isKapatmaDialogRef?.current?.openRecord(rowData?.id);
        }
      }}
      disabled={!(yetki?.sil && rowData.id > 0)}
    >
      {rowData.talepDurumu !== TALEP_DURUMU.YAPILMASI_UYGUN_GORULMEDI.Value
        ? "Talebi Reddet"
        : "Talep Reddetme Açıklaması"}
    </ButtonWrapper>
  );
}
