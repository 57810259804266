import { Comment } from "@mui/icons-material";
import React from "react";
import CustomColoredButton from "./CustomColoredButton";

export default function AnalizSonucuButton({
  disabled,
  onClick,
  buttonCaption,
}) {
  return (
    <CustomColoredButton
      startIcon={<Comment />}
      onClick={onClick}
      color="grape"
      disabled={disabled}
    >
      {buttonCaption ? buttonCaption : "Analiz Sonucu"}
    </CustomColoredButton>
  );
}
