import Type from "../../entities/UrunGrubu";

const PageConfig = {
  title: "DinamikTanimlar",
  ekranKodu: "DinamikTanimlar_Res",
  type: Type,
  listRoute: "DinamikTanimlar/List",
  getByIdRoute: "DinamikTanimlar/GetById",
  insertRoute: "DinamikTanimlar/Insert",
  updateRoute: "DinamikTanimlar/Update",
  deleteRoute: "DinamikTanimlar/Delete",
};

export { PageConfig };
