import { Grid } from "@mui/material";

import SifreGuncelleme from "./SifreGuncelleme";
import KullaniciBilgisiGuncelleme from "./KullaniciBilgisiGuncelleme";

function KullaniciBilgileri({ ekranKodu }) {
  return (
    <Grid
      container
      sx={{ width: "100%", display: "flex", alignItems: "flex-start" }}
    >
      <KullaniciBilgisiGuncelleme
        ekranKodu={ekranKodu}
      ></KullaniciBilgisiGuncelleme>
      <SifreGuncelleme></SifreGuncelleme>
    </Grid>
  );
}

export default KullaniciBilgileri;
