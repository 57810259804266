import * as React from "react";
import Box from "@mui/material/Box";

export default function CustomBox({ children, sx }) {
  return (
    <Box
      component="section"
      sx={{
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
