import React from "react";
import { HttpAuthorizedFile } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import useDialog from "../../hooks/use-dialog";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { FileUploader } from "devextreme-react";
import { loadingVisible } from "../../common/constant-functions/loadingVisible";

const TeklifOnayDialog = React.forwardRef(
  ({ ekranKodu, listGridRef, pdfDialogRef }, ref) => {
    const { open, closeRecord, id } = useDialog(ref);

    return (
      <ScrollDialog
        open={open}
        handleClose={closeRecord}
        modalTitle="Teklif Ver"
        maxWidth="sm"
      >
        <FileUploader
          style={{
            cursor: "pointer",
            border: "1px dashed grey",
          }}
          readyToUploadMessage="Dosya yüklemeye hazır"
          selectButtonText="Dosya Seç"
          labelText="veya dosyayı buraya sürükle"
          uploadMode="useButtons"
          uploadButtonText="Yükle"
          uploadAbortedMessage="Yükleme işlemi iptal edildi"
          uploadedMessage="Dosya Yüklendi"
          uploadFailedMessage="Dosya Yüklenemedi"
          maxFileSize={20971521} // 20mb max
          // allowedFileExtensions={[".jpg", ".jpeg", ".pdf", ".png"]}
          uploaded
          // onUploadError={(e) => {
          //   if (e.error?.statusText === 'File is too large') {
          //     alert('Dosya boyutu .');
          //   }
          // }}
          uploadFile={(file, callback) => {
            var FormData = require("form-data");
            var reqData = new FormData();
            reqData.append("file", file);
            reqData.append("id", id);
            loadingVisible(true);

            HttpAuthorizedFile.post("MusteriTalep/TeklifOnay", reqData)
              .then((res) => {
                closeRecord();
                listGridRef?.current?.instance?.refresh();
                pdfDialogRef?.current?.closeRecord();
                printToast(res.data);
              })
              .catch((error) => {
                printToast(error.response.data);
                console.log(error);
              })
              .finally(() => loadingVisible(false));
          }}
        />
      </ScrollDialog>
    );
  }
);
export default TeklifOnayDialog;
