import React, { useRef, useContext } from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import PageConfig from "./KullaniciConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import PasswordInputForm from "../../components/inputs/form-inputs/PasswordInputForm";
import YetkiGrubuInputForm from "../../components/inputs/custom-form-inputs/YetkiGrubuInputForm";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import { AppContext } from "../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import KullaniciYetkiRecord from "../KullaniciYetki/KullaniciYetkiRecord";
import PopoverButton from "../../components/buttons/PopoverButton";
import KullaniciParametreleriDialog from "./KullaniciParametreleriDialog";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const KullaniciRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const context = useContext(AppContext);
  var initialState = { aktif: true, aktifKullaniciBayiKullanicisi: false };

  if (
    context.user.yetkiGrubuTipi == YETKI_GRUBU_TIPI.BAYI_DESTEK.Value ||
    context.user.yetkiGrubuTipi == YETKI_GRUBU_TIPI.BAYI.Value
  ) {
    initialState = {
      ...initialState,
      aktifKullaniciBayiKullanicisi: true,
    };
  }

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const yetkiGrubuInputRef = useRef();
  const kullaniciYetkileriDialogRef = useRef();
  const detailGridRef = useRef();
  const kullaniciParametreleriDialogRef = useRef();

  const validationSchema = yup.object().shape({
    sirketId: yup.number().required(),
    kullaniciAdi: yup.string().required(),
    sifre: yup.string().required(),
    isim: yup.string().required(),
    soyisim: yup.string().required(),
    yetkiGrubuId: yup.number().when("aktifKullaniciBayiKullanicisi", {
      is: true,
      then: yup.number().nullable(),
      otherwise: yup.number().required(),
    }),
    // yetkiGrubuId: yup.number().required(),
    eMail: yup.string().email().nullable(),
  });

  const validate = validateFormValues(validationSchema);

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Kullanıcı"
        maxWidth="sm"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <Stack direction="column" spacing={1}>
                  <CustomFieldWrapper
                    label="Kullanıcı No"
                    modalLoading={modalLoading}
                  >
                    <Field disabled name="id" component={TextInputForm} />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper
                    label="Şirket Kodu"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="sirketId"
                      rowData={rowData}
                      remoteDataAdaptor={{
                        fetchRoute: "Sirket/ListForSelect",
                        fildsForText: ["sirket_Kod"],
                        fieldForValue: "id",
                        reqData: {},
                        fieldLabel: "sirket_Kod",
                      }}
                      component={AutocomplateInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper
                    label="Kullanıcı Adı"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="kullaniciAdi"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper label="Şifre" modalLoading={modalLoading}>
                    <Field
                      name="sifre"
                      component={PasswordInputForm}
                      readOnly={!yetki.kaydet}
                      sifreGorebilir={yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper label="Email" modalLoading={modalLoading}>
                    <Field
                      type="email"
                      name="eMail"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper
                    label="Telefon No"
                    modalLoading={modalLoading}
                  >
                    <Field
                      type="tel"
                      name="telefonNo"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper label="İsim" modalLoading={modalLoading}>
                    <Field
                      name="isim"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>

                  <CustomFieldWrapper
                    label="Soyisim"
                    modalLoading={modalLoading}
                  >
                    <Field
                      name="soyisim"
                      component={TextInputForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                  {context.user.yetkiGrubuTipi !==
                    YETKI_GRUBU_TIPI.BAYI_DESTEK.Value &&
                    context.user.yetkiGrubuTipi !==
                      YETKI_GRUBU_TIPI.BAYI.Value && (
                      <CustomFieldWrapper
                        label="Yetki Grubu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="yetkiGrubuId"
                          rowData={rowData}
                          component={YetkiGrubuInputForm}
                          inputRef={yetkiGrubuInputRef}
                          ekranKodu={ekranKodu}
                          readOnly={!yetki.kaydet}
                        />
                      </CustomFieldWrapper>
                    )}

                  <CustomFieldWrapper label="Aktif" modalLoading={modalLoading}>
                    <Field
                      name="aktif"
                      component={CheckboxForm}
                      readOnly={!yetki.kaydet}
                    />
                  </CustomFieldWrapper>
                </Stack>
              </DialogContentWrapper>
              {/* <DialogActionsWrapper>
              <RecordButton
                yetki={yetki}
                form={form}
                handleDelete={handleDelete}
                submitting={submitting}
                pristine={pristine}
                modalLoading={modalLoading}
              />
            </DialogActionsWrapper> */}
              <DialogActionsWrapper
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <PopoverButton
                  label="Kullanıcı İşlemleri"
                  backColor="#7272cc"
                  hoverBackColor="#3232a8"
                >
                  <CustomColoredButton
                    // startIcon={<WorkOutlineIcon />}
                    onClick={(e) => {
                      kullaniciYetkileriDialogRef?.current?.openRecord(
                        rowData.id
                      );
                    }}
                    color="purple"
                    disabled={!rowData?.id}
                  >
                    Kullanıcı Özel Yetkileri
                  </CustomColoredButton>
                  <CustomColoredButton
                    // startIcon={<WorkOutlineIcon />}
                    onClick={(e) => {
                      kullaniciParametreleriDialogRef?.current?.openRecord(
                        rowData.id
                      );
                    }}
                    color="green"
                    disabled={!rowData?.id}
                  >
                    Kullanıcı Parametreleri
                  </CustomColoredButton>
                </PopoverButton>
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <KullaniciYetkiRecord
        ekranKodu={ekranKodu}
        ref={kullaniciYetkileriDialogRef}
        detailGridRef={detailGridRef}
      ></KullaniciYetkiRecord>
      <KullaniciParametreleriDialog
        ref={kullaniciParametreleriDialogRef}
        ekranKodu={ekranKodu}
        isEditable={true}
        listGridRef={listGridRef}
      ></KullaniciParametreleriDialog>
    </>
  );
});
export default KullaniciRecord;
