import { useEffect, useState } from "react";
import FileViewer from "../../components/file/FileViewer";
import { HizmetFormFileConfig } from "./SirketConfig";
import { printToast } from "../../common/constant-functions/printToast";
import { HttpAuthorized } from "../../common/common";
import { apiUrl } from "../../common/constants";

export default function HizmetForm({ ekranKodu, parentRowData }) {
  const [rowData, setRowData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);

  async function fetchData() {
    HttpAuthorized.request({
      method: "POST",
      url: apiUrl + HizmetFormFileConfig.filesInfoRoute,
      data: {
        id: parentRowData.id,
      },
    })
      .then((res) => {
        setRowData(res?.data?.data || {});
        setModalLoading(false);
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FileViewer
      ekranKodu={ekranKodu}
      routes={HizmetFormFileConfig}
      reqData={{
        id: parentRowData.id,
      }}
      parentRowData={parentRowData}
      rowData={rowData}
      setRowData={setRowData}
      modalLoading={modalLoading}
      height="35vh"
    ></FileViewer>
  );
}
