import React, { useContext, useMemo, useRef } from "react";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { Field, Form } from "react-final-form";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import { Button, Grid, Skeleton, Typography } from "@mui/material";
import CustomDropZone from "../../components/file/CustomDropZone";
import {
  PageConfig,
  BaglantiBilgileriFileConfig,
  OtherConfig,
} from "./SirketConfig";
import EkDosyaButton from "../../components/buttons/custom/EkDosyaButton";
import SirketTabs from "./SirketTabs";
import RecordButton from "../../components/buttons/custom/RecordButton";
import PaketBilgileriDialog from "./PaketBilgileriDialog";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import { EKRAN_KODU, YETKI_GRUBU_TIPI, apiUrl } from "../../common/constants";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AnlasmaBilgileriDialog from "./AnlasmaBilgileriDialog";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import { AppContext } from "../../common/AppContext";
import { HttpAuthorized } from "../../common/common";

const SirketRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const context = useContext(AppContext);
  var initialState = { aktif: true };

  if (context.user?.anaBayiKullanicisi) {
    initialState = {
      ...initialState,
      lisansKodu: crypto.randomUUID().toUpperCase(),
    };
  }


  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
    setRowData,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const lisansKoduRef = useRef();
  const tabRef = useRef();
  const paketBilgileriDialogRef = useRef();
  const anlasmaBilgileriDialogRef = useRef();

  const bayiKodVisible = context.user?.anaBayiKullanicisi === true;

  // const initialState = {
  //   aktif: true,
  //   lisansKodu: bayiKodVisible ? "" : crypto.randomUUID().toUpperCase(),
  // };

  const validationSchema = yup.object().shape({
    sirket_Kod: yup.string().required(),
    sirketAdi: yup.string().required(),
    adres: yup.string().max(200, "Adres 200 karakterden fazla olamaz."),
    vergiNo: yup.string().max(11, "Vergi No 11 karakterden fazla olamaz."),
  });

  const destekKullanicisi =
    context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value ||
    context?.user?.yetkiGrubuTipi === YETKI_GRUBU_TIPI.BAYI_DESTEK.Value;

  const validate = validateFormValues(validationSchema);

  const setLogo = (res) => {
    if (res.data.success)
      setRowData((prevData) => ({ ...prevData, logo: res.data.data }));
  };

  async function lisansKoduVer() {
    var flisansKodu = crypto.randomUUID().toUpperCase();
    setRowData((prevData) => ({ ...prevData, lisansKodu: flisansKodu }));

    var FormData = require("form-data");
    var reqData = new FormData();
    reqData.append("lisansKodu", flisansKodu);
    reqData.append("sirketId", rowData.id);

    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${OtherConfig.lisansKoduVerConfig}`,
      data: reqData,
    });
  }




  const CustomFieldWrapper = ({ children, label }) => {
    return (
      <FieldWrapper
        label={label}
        modalLoading={modalLoading}
        textWidth="30%"
        width="70%"
      >
        {children}
      </FieldWrapper>
    );
  };

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle="Yeni Şirket"
        maxWidth="lg"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  {rowData.id && (
                    <Grid item xs={2}>
                      {!modalLoading ? (
                        <CustomDropZone
                          sx={{
                            maxWidth: "130px",
                            maxHeight: "170px",
                          }}
                          dropZoneText={
                            <Typography variant="caption">Logo seç</Typography>
                          }
                          route="Sirket/UploadLogo"
                          afterUpload={setLogo}
                          defaultImg={`data:image/jpeg;base64,${rowData?.logo}`}
                          reqExtraData={[
                            { name: "sirketId", value: rowData.id },
                          ]}
                        />
                      ) : (
                        <Skeleton
                          sx={{ width: "90px", height: "90px" }}
                        ></Skeleton>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={rowData.id ? 10 : 12}>
                    <DialogContentTop>
                      <Grid item xs={12} sm={6} md={6}>
                        <CustomFieldWrapper label="Şirket No">
                          <Field disabled name="id" component={TextInputForm} />
                        </CustomFieldWrapper>

                        <CustomFieldWrapper label="Şirket Adı">
                          <Field
                            name="sirketAdi"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Lisans Kodu"
                          modalLoading={modalLoading}
                        >
                          {bayiKodVisible == true &&
                            !destekKullanicisi &&
                            !rowData.lisansKodu ? (
                            <Grid container direction="row" spacing={2}>
                              <Grid item xs={9}>
                                <Field
                                  name="lisansKodu"
                                  component={TextInputForm}
                                  readOnly={true}
                                  inputRef={lisansKoduRef}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Button
                                  // sx={{ width: "100%" }}
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    lisansKoduVer();
                                  }}
                                >
                                  Kod Ver
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <Field
                              name="lisansKodu"
                              component={TextInputForm}
                              readOnly={true}
                              inputRef={lisansKoduRef}
                            />
                          )}
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Erp Versiyon Bilgisi"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="erpVersiyonBilgisi"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>

                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={6}>
                            <FieldWrapper
                              label="Aktif"
                              modalLoading={modalLoading}
                              textWidth="85%"
                            // width="73%"
                            >
                              <Field
                                name="aktif"
                                component={CheckboxForm}
                                // readOnly={!yetki.kaydet || destekKullanicisi}
                                disabled={!yetki.kaydet || destekKullanicisi}
                              />
                            </FieldWrapper>
                          </Grid>

                          <Grid item xs={6}>
                            <>
                              {!destekKullanicisi && bayiKodVisible && (
                                <FieldWrapper
                                  label="Bayi Kod"
                                  modalLoading={modalLoading}
                                // textWidth="27%"
                                // width="73%"
                                >
                                  <Field
                                    name="bayiid"
                                    rowData={rowData}
                                    remoteDataAdaptor={{
                                      fetchRoute:
                                        "Bayiler/BayilerListForSelect",
                                      fildsForText: ["bayiKod"],
                                      fieldForValue: "id",
                                      fieldLabel: "bayiKod",
                                      reqData: {},
                                    }}
                                    component={AutocomplateInputForm}
                                    readOnly={!yetki.kaydet}
                                  />
                                </FieldWrapper>
                              )}
                            </>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <CustomFieldWrapper
                          label="Şirket Kodu"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="sirket_Kod"
                            component={TextInputForm}
                            maxLength={50}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Temsilci"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="temsilciId"
                            rowData={rowData}
                            remoteDataAdaptor={{
                              fetchRoute: "User/KullaniciListForSelect",
                              fildsForText: ["isimSoyisim"],
                              fieldForValue: "id",
                              reqData: { temsilci: true },
                            }}
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet || destekKullanicisi}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Vergi No"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="vergiNo"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Web Sitesi"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="webSitesi"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>
                        <CustomFieldWrapper
                          label="Adres"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="adres"
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </CustomFieldWrapper>

                        {modalLoading ? (
                          <Skeleton width="100%"></Skeleton>
                        ) : (
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            sx={{ marginTop: rowData.id ? 0 : "1px" }}
                          >
                            <Grid item xs={6}>
                              <Button
                                sx={{ width: "100%" }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    lisansKoduRef?.current?.value
                                  );
                                }}
                              >
                                Kopyala
                              </Button>
                            </Grid>
                            {rowData.id && (
                              <Grid item xs={6}>
                                <Button
                                  sx={{ width: "100%" }}
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    paketBilgileriDialogRef?.current?.openRecord(
                                      rowData?.id
                                    );
                                  }}
                                >
                                  Kullanılan Paket Adı
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </DialogContentTop>
                  </Grid>
                </DialogContentTop>

                <SirketTabs
                  rowData={rowData}
                  ekranKodu={ekranKodu}
                  yetki={yetki}
                  modalLoading={modalLoading}
                  tabRef={tabRef}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper
                sx={{
                  justifyContent:
                    tabRef.current && tabRef.current.selectedTabIndex === 0
                      ? "space-between"
                      : "flex-end",
                }}
              >
                {tabRef.current && tabRef.current.selectedTabIndex === 0 && (
                  <Grid>
                    <EkDosyaButton
                      reqData={{
                        id: rowData.id,
                      }}
                      routes={BaglantiBilgileriFileConfig}
                      ekranKodu={ekranKodu}
                      parentRowData={rowData}
                      disabled={!rowData?.id}
                    ></EkDosyaButton>
                    {context?.user?.yetkiler.find(
                      (x) => x.ekranKodu === EKRAN_KODU.ANLASMA_BILGILERI.value
                    )?.goruntule && (
                        <CustomColoredButton
                          startIcon={<WorkOutlineIcon />}
                          onClick={(e) => {
                            anlasmaBilgileriDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          color="purple"
                          disabled={!rowData?.id}
                        >
                          Anlaşma Bilgileri
                        </CustomColoredButton>
                      )}
                  </Grid>
                )}
                <RecordButton
                  yetki={yetki}
                  form={form}
                  handleDelete={handleDelete}
                  submitting={submitting}
                  pristine={pristine}
                  modalLoading={modalLoading}
                />
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
      <PaketBilgileriDialog
        yetki={yetki}
        ref={paketBilgileriDialogRef}
      ></PaketBilgileriDialog>
      <AnlasmaBilgileriDialog
        ref={anlasmaBilgileriDialogRef}
        listGridRef={listGridRef}
      ></AnlasmaBilgileriDialog>
    </>
  );
});
export default SirketRecord;
