import { YETKI_GRUBU_TIPI } from "../common/constants";
import { ID_WIDTH } from "../common/entityconstants";

const entity = [
  {
    name: "id",
    label: "Goruşme Hat. No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "hatirlatmaTarihi",
    label: "Hatırlatma Tarihi",
    dataType: "datetime",
    dataField: "hatirlatmaTarihi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },

  {
    name: "mailGonderilecekKisiler",
    label: "Mail Gönderilecek Kişiler",
    dataType: "text",
    dataField: "mailGonderilecekKisiler",
    visible: ["grid"],
  },
  {
    name: "kayitTarihi",
    label: "Kayıt Tarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
  },
  {
    name: "kayitEdenStr",
    label: "Kayıt Eden Kull.",
    dataType: "text",
    dataField: "kayitEdenStr",
    visible: ["grid"],
  },
 
  //filtre--------------------------------------------------------------------------------------------------
];

export default entity;
