import { EKRAN_KODU } from "../../common/constants";
import Type from "../../entities/Gorusmeler";

const PageConfig = {
  title: "Gorusmeler",
  ekranKodu: "Gorusmeler_Res",
  type: Type,
  listRoute: "Gorusmeler/List",
  getByIdRoute: "Gorusmeler/GetById",
  insertRoute: "Gorusmeler/Insert",
  updateRoute: "Gorusmeler/Update",
  deleteRoute: "Gorusmeler/Delete",
  hatirlatmaEkleRoute: "Gorusmeler/HatirlatmaEkle",
  gorusmeAtamaRoute: "Gorusmeler/GorusmeAtama",
};

const GorusmeFileConfig = {
  ekranKodu: EKRAN_KODU.GORUSMELER.value,
  allFilesDownloadRoute: "Gorusmeler/DosyalariIndir",
  fileDownloadRoute: "Gorusmeler/DosyaIndir",
  filesInfoRoute: "Gorusmeler/DosyaBilgileri",
  fileUploadRoute: "Gorusmeler/DosyaYukle",
  deleteFilesRoute: "Gorusmeler/DeleteFiles",
};

export { PageConfig, GorusmeFileConfig };
