import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form, FormSpy } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./FiyatListeDetayConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import {
  PARA_BIRIMI,
  URUN_TIP_MODULPAKET,
  URUN_PROGRAMLARI,
} from "../../common/constants";
import UrunlerInputForm from "../../components/inputs/custom-form-inputs/UrunlerInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { AppContext } from "../../common/AppContext";
import dayjs from "dayjs";

const FiyatListeDetayRecord = React.forwardRef(
  (
    {
      ekranKodu,
      listGridRef,
      masterRef,
      detailGridRef,
      editGridRef,
      listeNo,
      setGridDs,
    },
    ref
  ) => {
    const context = React.useContext(AppContext);

    const initialState = {
      aktif: true,
      degisiklikTarihi: dayjs(),
      kayitEdenKullanici: context?.user?.id,
    };

    const urunKoduInputRef = useRef();
    const urunTipiRef = useRef();
    const programRef = useRef();

    const customFetchData = (id) => {
      let rows = editGridRef.current.instance.getVisibleRows();
      let currentRow = rows.find((x) => x.key == id);
      setRowData(currentRow.data);
      setModalLoading(false);
    };

    const customInsertOrUpdate = (values) => {
      var tempChanges = editGridRef.current.instance
        .option("editing.changes")
        .slice();
      if (recId) {
        let change = tempChanges.find((x) => x.key == recId);
        if (!change) {
          tempChanges.push({
            key: recId,
            data: {},
            type: "update",
          });
          change = tempChanges.find((x) => x.key == recId);
        }

        let rows = editGridRef.current.instance.getVisibleRows();
        let currentRow = rows.find((x) => x.key == recId);

        Object.keys(currentRow.data).map((key) => {
          if (values[key] != currentRow.data[key]) {
            change.data[key] = values[key];
          }
        });
      } else {
        tempChanges.push({
          key: crypto.randomUUID(),
          data: values,
          type: "insert",
        });
      }

      editGridRef.current.instance.option("editing.changes", tempChanges);

      handleClose();
    };

    const values = useRef(null);

    var urunPaketSecimEnabled = useRef(null);
    var urunTipSecimEnabled = useRef(null);

    urunPaketSecimEnabled = false;
    urunTipSecimEnabled = false;

    const afterDelete = () => {
      if (typeof recId === typeof "") {
        let tempChanges =
          editGridRef.current.instance.option("editing.changes");
        editGridRef.current.instance.option(
          "editing.changes",
          tempChanges.filter((x) => x.key != recId)
        );
      } else {
        let ds = editGridRef.current.instance.option("dataSource");
        setGridDs(ds.filter((x) => x.id != recId));
      }
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowData,
      setModalLoading,
      recId,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      detailGridRef,
      false,
      customFetchData,
      customInsertOrUpdate,
      null,
      afterDelete
    );

    const validationSchema = yup.object().shape({
      urunTip: yup.number().required(),
      urunid: yup.number().required(),
      program: yup.number().required(),
      kullaniciSayisi1: yup.number().required(),
      kullaniciSayisi2: yup.number().required(),
      birimFiyat: yup.number().required(),
      birim: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Fiyat Listesi Detay"
          maxWidth="sm"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            // subscription={{
            //   form: true,
            // }}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              reset,
              values: formValues,
            }) => {
              return (
                <FormComponent
                  onSubmit={handleSubmit}
                  style={{ height: "480px" }}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={(props) => {
                      if (
                        values?.current?.kdvOrani != props.values.kdvOrani ||
                        values?.current?.birimFiyat != props.values.birimFiyat
                      ) {
                        let xkdvTutari =
                          (props.values.birimFiyat * props.values.kdvOrani) /
                          100;
                        form.change("kdvTutari", xkdvTutari);

                        form.change(
                          "kdVliBirimFiyat",
                          xkdvTutari + props.values.birimFiyat
                        );
                      }
                      values.current = props.values;

                      // urunTipSecimEnabled = props.values.program !== null;
                      // urunPaketSecimEnabled = props.values.urunTip !== null;
                    }}
                  />
                  <DialogContentWrapper>
                    {/* <DialogContentTop> */}
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Fiyat Listesi Detay No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Liste No"
                        modalLoading={modalLoading}
                      >
                        <Field
                          disabled
                          name="masterid"
                          component={TextInputForm}
                          // defaultValue={masterRef?.id}
                          defaultValue={listeNo}
                        />
                      </FieldWrapper>
                      <FieldWrapper label="Program" modalLoading={modalLoading}>
                        <Field
                          name="program"
                          items={Object.values(URUN_PROGRAMLARI)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                          ref={programRef}
                          onChangeExtra={(old, newVal) => {
                            console.log("ewVal?.Valueprogram", newVal?.Value);
                            urunTipSecimEnabled = newVal?.Value !== null;
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Ürün Tipi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="urunTip"
                          items={Object.values(URUN_TIP_MODULPAKET)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                          disabled={!urunTipSecimEnabled}
                          ref={urunTipiRef}
                          onChangeExtra={(old, newVal) => {
                            urunPaketSecimEnabled = newVal?.Value !== null;
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper label="Ürün" modalLoading={modalLoading}>
                        <Field
                          name="urunid"
                          rowData={rowData}
                          component={UrunlerInputForm}
                          readOnly={!yetki.kaydet}
                          disabled={!urunPaketSecimEnabled}
                          inputRef={urunKoduInputRef}
                          ekranKodu={ekranKodu}
                          urunTipiRef={urunTipiRef}
                          programRef={programRef}
                          fieldsForText={["urunKodu", "urunAdi"]}
                          fieldLabel={"urunKoduAdi"}
                          onChangeExtra={(old, newVal) => {
                            if (newVal?.Value) {
                              // console.log(newVal, "newVal");

                              form?.change("urunKoduAdi", newVal?.Text);
                            }
                          }}
                        />
                      </FieldWrapper>
                      {/* <FieldWrapper label="Ürün Adı" modalLoading={modalLoading}>
                      <Field
                        name="urunid"
                        rowData={rowData}
                        component={UrunlerUrunAdiInputForm}
                        readOnly={!yetki.kaydet}
                        inputRef={urunAdiInputRef}
                        ekranKodu={ekranKodu}
                        urunTipiRef={urunTipiRef}
                        // onChangeExtra={urunAdiInputOnChangeExtra}

                        // urunBilgileriResultRef={"asd"}
                      />
                    </FieldWrapper> */}
                      <FieldWrapper
                        label="Kullanıcı Sayısı - 1"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kullaniciSayisi1"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Kullanıcı Sayısı - 2"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kullaniciSayisi2"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Birim Fiyat"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="birimFiyat"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                          // onChange={(e) => kdvTutariHesapla(e, "birimFiyat")}
                        />
                      </FieldWrapper>
                      <FieldWrapper label="Birim" modalLoading={modalLoading}>
                        <Field
                          name="birim"
                          items={Object.values(PARA_BIRIMI)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Kdv Oranı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kdvOrani"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                          // onChange={(e) => kdvTutariHesapla(e, "kdvOrani")}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Kdv Tutarı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kdvTutari"
                          component={NumberInputForm}
                          readOnly={true}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Kdv'li Birim Fiyat"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kdVliBirimFiyat"
                          component={NumberInputForm}
                          readOnly={true}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                    </Grid>
                    {/* </DialogContentTop> */}
                    <DialogActionsWrapper>
                      <RecordButton
                        yetki={yetki}
                        form={form}
                        handleDelete={handleDelete}
                        submitting={submitting}
                        pristine={pristine}
                        modalLoading={modalLoading}
                      />
                    </DialogActionsWrapper>
                  </DialogContentWrapper>
                </FormComponent>
              );
            }}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default FiyatListeDetayRecord;
