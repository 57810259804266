import Type from "../../entities/GorusmeMailTemplate";
import TypeTeklif from "../../entities/TeklifMailTemplate";

const PageConfig = {
  title: "GorusmeMailTemplate",
  ekranKodu: "GorusmeMailTemplate_Res",
  type: Type,
  listRoute: "GorusmeMailTemplate/List",
  getByIdRoute: "GorusmeMailTemplate/GetById",
  insertRoute: "GorusmeMailTemplate/Insert",
  updateRoute: "GorusmeMailTemplate/Update",
  deleteRoute: "GorusmeMailTemplate/Delete",
};

const PageConfigTeklif = {
  title: "GorusmeMailTemplate",
  ekranKodu: "GorusmeMailTemplate_Res",
  type: TypeTeklif,
  listRoute: "GorusmeMailTemplate/List",
  getByIdRoute: "GorusmeMailTemplate/GetById",
  insertRoute: "GorusmeMailTemplate/Insert",
  updateRoute: "GorusmeMailTemplate/Update",
  deleteRoute: "GorusmeMailTemplate/Delete",
};

export { PageConfig, PageConfigTeklif };
