import Type from "../../entities/Destek";

const PageConfig = {
  title: "Danışmanlık Girişi",
  ekranKodu: "Destek_Res",
  type: Type,
  listRoute: "Destek/DestekList",
  getByIdRoute: "Destek/GetById",
  insertRoute: "Destek/Insert",
  updateRoute: "Destek/Update",
  deleteRoute: "Destek/Delete",
};

export default PageConfig;
