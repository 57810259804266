import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { Field, Form, FormSpy } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./TeklifDetayConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import {
  URUN_TIP,
  PARA_BIRIMI,
  apiUrl,
  STOK_BIRIM,
  URUN_PROGRAMLARI,
} from "../../common/constants";
import UrunlerInputForm from "../../components/inputs/custom-form-inputs/UrunlerInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { AppContext } from "../../common/AppContext";
import dayjs from "dayjs";
import { HttpAuthorized } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import CustomBox from "../../components/box/CustomBox";
import ButtonWrapper from "../../components/buttons/ButtonWrapper";
import UrunAciklamasiDialog from "../Urunler/UrunAciklamasiDialog";

const TeklifDetayRecord = React.forwardRef(
  (
    {
      ekranKodu,
      listGridRef,
      detailGridRef,
      editGridRef,
      fiyatListeid,
      teklifid,
      xkullaniciSayisi,
      setGridDs,
    },
    ref
  ) => {
    const context = React.useContext(AppContext);

    const initialState = {
      aktif: true,
      degisiklikTarihi: dayjs(),
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
      degisiklikYapan: context?.user?.id,
      miktar: 1,
      kullaniciSayisi: xkullaniciSayisi,
    };

    const urunKoduInputRef = useRef();
    const urunTipiRef = useRef();
    const programRef = useRef();
    const urunAciklamasiDialogRef = useRef();
    const kullaniciSayisiRef = useRef();

    const customFetchData = (id) => {
      let rows = editGridRef.current.instance.getVisibleRows();
      let currentRow = rows.find((x) => x.key == id);
      setRowData(currentRow.data);
      setModalLoading(false);
    };

    const customInsertOrUpdate = (values) => {
      var tempChanges = editGridRef.current.instance
        .option("editing.changes")
        .slice();
      if (recId) {
        let change = tempChanges.find((x) => x.key == recId);
        if (!change) {
          tempChanges.push({
            key: recId,
            data: {},
            type: "update",
          });
          change = tempChanges.find((x) => x.key == recId);
        }

        let rows = editGridRef.current.instance.getVisibleRows();
        let currentRow = rows.find((x) => x.key == recId);
        Object.keys(currentRow.data).map((key) => {
          if (values[key] != currentRow.data[key]) {
            change.data[key] = values[key];
          }
        });
      } else {
        tempChanges.push({
          key: crypto.randomUUID(),
          data: values,
          type: "insert",
        });
      }

      editGridRef.current.instance.option("editing.changes", tempChanges);

      handleClose();
    };

    const values = useRef(null);
    var brFiyatEnabled = useRef(null);

    const kullBirimFiyatDegistirebilir =
      context.user?.kullaniciParametreleri?.teklifteBirimFiyatDegistirebilir;

    brFiyatEnabled = kullBirimFiyatDegistirebilir;

    // var urunPaketSecimEnabled = useRef(null);
    // var urunTipSecimEnabled = useRef(null);

    // urunPaketSecimEnabled = false;
    // urunTipSecimEnabled = false;

    const afterDelete = () => {
      if (typeof recId === typeof "") {
        let tempChanges =
          editGridRef.current.instance.option("editing.changes");
        editGridRef.current.instance.option(
          "editing.changes",
          tempChanges.filter((x) => x.key != recId)
        );
      } else {
        let ds = editGridRef.current.instance.option("dataSource");
        setGridDs(ds.filter((x) => x.id != recId));
      }
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowData,
      setModalLoading,
      recId,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      detailGridRef,
      false,
      customFetchData,
      customInsertOrUpdate,
      null,
      afterDelete
    );

    // useEffect(() => {
    //   if (
    //     rowData?.program !== null &&
    //     typeof rowData?.program !== "undefined"
    //   ) {
    //     urunTipSecimEnabled = true;
    //   } else {
    //     urunTipSecimEnabled = false;
    //   }
    // }, [rowData?.program]);

    // urunTipSecimEnabled =
    //   rowData?.program !== null && typeof rowData?.program !== "undefined";

    // urunPaketSecimEnabled =
    //   rowData?.urunTip !== null && typeof rowData?.urunTip !== "undefined";

    const validationSchema = yup.object().shape({
      ...(context.user.bayiKullanicisi && {
        iskontoOrani: yup.number().when("urunTip", {
          is: (urunTip) => urunTip && urunTip != 3,
          then: yup
            .number()
            .max(
              context?.user?.maxIskontoOrani,
              "İskonto oranı en fazla '" +
                context?.user?.maxIskontoOrani +
                "' olabilir."
            ),
          otherwise: yup.number(),
        }),
      }),
      miktar: yup.number().min(1),
      urunTip: yup.number().required(),
      program: yup.number().required(),
      kullaniciSayisi: yup.number().required(),
      birimFiyat: yup.number().when("urunTip", {
        is: (urunTip) => urunTip && urunTip != 3,
        then: yup.number().min(0.1, "Fiyat listesinde bu ürün bulunamadı."),
        otherwise: yup.number(),
      }),
    });

    const otherValidationSchema = yup.object().shape({});

    const validate = validateFormValues(validationSchema);

    const [isIskontoTutariManualChange, setisIskontoTutariManualChange] =
      useState(false);

    const [lastChangedField, setLastChangedField] = useState(null);

    const [urunTipiDolu, setUrunTipiDolu] = useState(false);
    const [urunKoduDolu, setUrunKoduDolu] = useState(false);
    const [programDolu, setProgramDolu] = useState(false);

    useEffect(() => {
      return () => {
        // setKullaniciSayisiDolu(false);
        setUrunTipiDolu(false);
        setUrunKoduDolu(false);
        setProgramDolu(false);
      };
    }, []);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Teklif Satırı Girişi"
          maxWidth="sm"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              reset,
              values: formValues,
            }) => (
              <FormComponent
                onSubmit={handleSubmit}
                style={{ height: "720px" }}
              >
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(props) => {
                    if (formValues.urunTip == URUN_TIP.BAGIMSIZ.Value) {
                      form.change(
                        "gosterilecekUrunAdi",
                        values?.current?.bagimsizUrunAdi
                      );
                    }
                    const iskontoTutariChanged =
                      values?.current?.iskontoTutari !==
                      props.values.iskontoTutari;
                    const iskontoOraniChanged =
                      values?.current?.iskontoOrani !==
                      props.values.iskontoOrani;

                    if (
                      iskontoTutariChanged &&
                      lastChangedField !== "iskontoOrani" &&
                      values?.current?.iskontoTutari
                    ) {
                      const newIskontoOrani =
                        (props.values.iskontoTutari /
                          (props.values.birimFiyat * props.values.miktar)) *
                        100;

                      form.change("iskontoOrani", newIskontoOrani); // İskonto oranını otomatik hesapla

                      const updatedMalTutari =
                        (props.values.birimFiyat
                          ? props.values.birimFiyat * props.values.miktar
                          : 0) - props.values.iskontoTutari; // Boşsa sıfır kullan

                      const updatedKdvTutari =
                        (updatedMalTutari *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                        100;

                      let updatedKdvliBrFiyat =
                        ((props.values.birimFiyat
                          ? props.values.birimFiyat
                          : 0) *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                          100 +
                        (props.values.birimFiyat ? props.values.birimFiyat : 0);
                      form.change("kdvliBirimFiyat", updatedKdvliBrFiyat);

                      form.change("kdvTutari", updatedKdvTutari);
                      form.change(
                        "genelToplam",
                        updatedMalTutari + updatedKdvTutari
                      );

                      setLastChangedField("iskontoTutari"); // Son değişiklik iskontoTutari olarak işaretle
                    }

                    // Eğer son manuel değişiklik iskontoOrani ise, iskontoTutari'yi hesapla

                    if (
                      iskontoOraniChanged &&
                      lastChangedField !== "iskontoTutari" &&
                      values?.current?.iskontoOrani
                    ) {
                      const newIskontoTutari =
                        (props.values.birimFiyat *
                          props.values.miktar *
                          props.values.iskontoOrani) /
                        100;

                      let xMalTutari =
                        (props.values?.birimFiyat
                          ? props.values.birimFiyat * props.values.miktar
                          : 0) - newIskontoTutari;

                      let xkdvTutari =
                        (xMalTutari *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                        100;
                      form.change("kdvTutari", xkdvTutari);

                      let xkdvliBirimFiyat =
                        ((props.values.birimFiyat
                          ? props.values.birimFiyat
                          : 0) *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                          100 +
                        (props.values.birimFiyat ? props.values.birimFiyat : 0);
                      form.change("kdvliBirimFiyat", xkdvliBirimFiyat);

                      form.change("genelToplam", xMalTutari + xkdvTutari);

                      form.change("iskontoTutari", newIskontoTutari); // İskonto tutarını otomatik hesapla
                      setLastChangedField("iskontoOrani"); // Son değişiklik iskontoOrani olarak işaretle
                    }
                    const isIskontoTutariEmpty = !props.values.iskontoTutari;

                    if (
                      values?.current?.kdvOrani != props.values.kdvOrani ||
                      values?.current?.birimFiyat != props.values.birimFiyat ||
                      values?.current?.miktar != props.values.miktar
                    ) {
                      let xIskontoTutari =
                        ((props.values.birimFiyat
                          ? props.values.birimFiyat * props.values.miktar
                          : 0) *
                          (props.values.iskontoOrani
                            ? props.values.iskontoOrani
                            : 0)) /
                        100;
                      form.change("iskontoTutari", xIskontoTutari);

                      let xMalTutari =
                        (props.values.birimFiyat
                          ? props.values.birimFiyat * props.values.miktar
                          : 0) - xIskontoTutari;

                      let xkdvTutari =
                        (xMalTutari *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                        100;
                      form.change("kdvTutari", xkdvTutari);

                      let xkdvliBirimFiyat =
                        ((props.values.birimFiyat
                          ? props.values.birimFiyat
                          : 0) *
                          (props.values.kdvOrani ? props.values.kdvOrani : 0)) /
                          100 +
                        (props.values.birimFiyat ? props.values.birimFiyat : 0);
                      form.change("kdvliBirimFiyat", xkdvliBirimFiyat);

                      form.change("genelToplam", xMalTutari + xkdvTutari);
                    }

                    values.current = props.values;
                  }}
                />
                <DialogContentWrapper>
                  {/* <DialogContentTop> */}
                  <Grid item xs={12} sm={6} md={6}>
                    {/* <FieldWrapper
                      label="Teklif Detay No"
                      modalLoading={modalLoading}
                      rowData={rowData}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </FieldWrapper>
                    <FieldWrapper label="Teklif No" modalLoading={modalLoading}>
                      <Field
                        disabled
                        name="masterid"
                        component={TextInputForm}
                        defaultValue={teklifid}
                        rowData={rowData}
                      />
                    </FieldWrapper> */}

                    <CustomBox
                      sx={{ p: 1, borderRadius: 3, bgcolor: "#e4ecef" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center", // Dikey hizalamayı sağlamak için
                        }}
                      >
                        <p
                          style={{
                            // fontSize: "15px",
                            fontWeight: "bold",
                            textAlign: "center",
                            flex: 1, // Ortada tutmak için esneklik verir
                            marginLeft: 100,
                          }}
                        >
                          Ürün Seçimi
                        </p>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          <ButtonWrapper
                            color="success"
                            // startIcon={<Save />}
                            onClick={(e) => {
                              urunAciklamasiDialogRef?.current?.openRecord(
                                urunKoduInputRef?.current?.value
                                // rowData?.urunid
                              );
                            }}
                            disabled={
                              (rowData?.urunid === null ||
                                typeof rowData?.urunid === "undefined") &&
                              (urunKoduInputRef?.current?.value === null ||
                                typeof urunKoduInputRef?.current?.value ===
                                  "undefined" ||
                                urunKoduInputRef?.current?.value === "")
                            }
                          >
                            PAKET İÇERİĞİ
                          </ButtonWrapper>
                        </div>
                      </div>
                      {/* <hr /> */}
                      <FieldWrapper label="Program" modalLoading={modalLoading}>
                        <Field
                          name="program"
                          items={Object.values(URUN_PROGRAMLARI)}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                          ref={programRef}
                          rowData={rowData}
                          onChangeExtra={(old, newVal) => {
                            newVal?.Value
                              ? setProgramDolu(true)
                              : setProgramDolu(false);

                            if (
                              newVal?.Value ===
                              URUN_PROGRAMLARI.HARMONYCLOUD.Value
                            ) {
                              form?.change("miktar", xkullaniciSayisi);
                            } else {
                              form?.change("miktar", 1);
                            }

                            // urunTipSecimEnabled = newVal?.Value !== null;
                          }}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Ürün Tipi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="urunTip"
                          items={Object.values(URUN_TIP)}
                          component={AutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                          // readOnly={!yetki.kaydet || !urunTipSecimEnabled}
                          ref={urunTipiRef}
                          rowData={rowData}
                          onChangeExtra={(old, newVal) => {
                            if (
                              newVal?.Value === URUN_TIP.BAGIMSIZ.Value &&
                              old !== URUN_TIP.BAGIMSIZ.Value
                            ) {
                              form?.change("birimFiyat", 0);
                              form?.change("dovizKod", null);
                              form?.change("kdvOrani", 0);
                            }

                            newVal?.Value
                              ? setUrunTipiDolu(true)
                              : setUrunTipiDolu(false);

                            brFiyatEnabled =
                              newVal?.Value === URUN_TIP.BAGIMSIZ.Value ||
                              kullBirimFiyatDegistirebilir === true;

                            // urunPaketSecimEnabled = newVal?.Value !== null;
                          }}
                        />
                      </FieldWrapper>
                      {formValues.urunTip != URUN_TIP.BAGIMSIZ.Value ? (
                        <FieldWrapper
                          label="Ürün/Paket Adı"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="urunid"
                            rowData={rowData}
                            component={UrunlerInputForm}
                            readOnly={!yetki.kaydet}
                            // readOnly={!yetki.kaydet || !urunPaketSecimEnabled}
                            inputRef={urunKoduInputRef}
                            ekranKodu={ekranKodu}
                            urunTipiRef={urunTipiRef}
                            programRef={programRef}
                            fieldsForText={["urunKodu", "urunAdi"]}
                            fieldLabel={"gosterilecekUrunAdi"}
                            onChangeExtra={(old, newVal) => {
                              newVal?.Value
                                ? setUrunKoduDolu(true)
                                : setUrunKoduDolu(false);

                              if (
                                newVal?.Value &&
                                fiyatListeid &&
                                (formValues.program != 1 ||
                                  (formValues.program === 1 &&
                                    kullaniciSayisiRef?.current?.value !==
                                      null &&
                                    typeof kullaniciSayisiRef?.current
                                      ?.value !== "undefined")) &&
                                urunTipiDolu !== false &&
                                programDolu !== false
                              ) {
                                HttpAuthorized.request({
                                  method: "POST",
                                  url: `${apiUrl}FiyatListeMaster/UrunFiyatGetir`,
                                  data: {
                                    urunId: newVal.Value,
                                    fiyatListesiId: fiyatListeid,
                                    kullaniciSayisi:
                                      kullaniciSayisiRef?.current?.value,
                                  },
                                })
                                  .then((res) => {
                                    if (res.data.data !== null) {
                                      form?.change(
                                        "birimFiyat",
                                        res.data.data.birimFiyat
                                      );
                                      form?.change(
                                        "dovizKod",
                                        res.data.data.birim
                                      );
                                      form?.change(
                                        "kdvOrani",
                                        res.data.data.kdvOrani
                                      );

                                      brFiyatEnabled =
                                        kullBirimFiyatDegistirebilir;
                                    } else {
                                      // otherValidationSchema.validate(rowData);
                                      // validateFormValues(otherValidationSchema);
                                      form?.change("birimFiyat", 0);
                                      form?.change("dovizKod", null);
                                      form?.change("kdvOrani", 0);

                                      brFiyatEnabled =
                                        kullBirimFiyatDegistirebilir;
                                    }
                                  })
                                  .catch((error) => {
                                    printToast(error.response.data);
                                  });
                              }
                            }}
                            // onChangeExtra={(old, newVal) => {
                            //   formValues.urunid != null
                            //     ? setUrunKoduDolu(true)
                            //     : setUrunKoduDolu(false);
                            // }}
                          />
                        </FieldWrapper>
                      ) : (
                        <FieldWrapper
                          label="Hizmet Adı"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="bagimsizUrunAdi"
                            rowData={rowData}
                            component={TextInputForm}
                            readOnly={!yetki.kaydet}
                          />
                        </FieldWrapper>
                      )}
                      {formValues.program != 1 ? null : (
                        <>
                          {/* <Grid
                            container
                            direction="row"
                            justifyContent={"space-between"}
                            // spacing={10}
                          >
                            <Grid item xs={9}> */}
                          <FieldWrapper
                            label="Kullanıcı Sayısı"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="kullaniciSayisi"
                              rowData={rowData}
                              component={NumberInputForm}
                              readOnly={!yetki.kaydet}
                              inputRef={kullaniciSayisiRef}
                              onBlurExtra={() => {
                                if (
                                  fiyatListeid &&
                                  kullaniciSayisiRef?.current?.value !== null &&
                                  typeof kullaniciSayisiRef?.current?.value !==
                                    "undefined" &&
                                  urunTipiDolu !== false &&
                                  urunKoduDolu !== false &&
                                  programDolu !== false
                                ) {
                                  HttpAuthorized.request({
                                    method: "POST",
                                    url: `${apiUrl}FiyatListeMaster/UrunFiyatGetir`,
                                    data: {
                                      urunId:
                                        form?.getFieldState("urunid").value,
                                      fiyatListesiId: fiyatListeid,
                                      kullaniciSayisi:
                                        kullaniciSayisiRef?.current?.value
                                          .replace(".", "")
                                          .replace(",", ""),
                                    },
                                  })
                                    .then((res) => {
                                      if (res.data.data !== null) {
                                        form?.change(
                                          "birimFiyat",
                                          res.data.data.birimFiyat
                                        );
                                        form?.change(
                                          "dovizKod",
                                          res.data.data.birim
                                        );
                                        form?.change(
                                          "kdvOrani",
                                          res.data.data.kdvOrani
                                        );

                                        brFiyatEnabled =
                                          kullBirimFiyatDegistirebilir;
                                      } else {
                                        // otherValidationSchema.validate(rowData);
                                        // validateFormValues(otherValidationSchema);
                                        form?.change("birimFiyat", 0);
                                        form?.change("dovizKod", null);
                                        form?.change("kdvOrani", 0);

                                        brFiyatEnabled =
                                          kullBirimFiyatDegistirebilir;
                                      }
                                    })
                                    .catch((error) => {
                                      printToast(error.response.data);
                                    });
                                }
                              }}
                            />
                          </FieldWrapper>
                          {/* </Grid>
                            <Grid item xs={3}> */}
                          {/* <ButtonWrapper
                                color="primary"
                                // startIcon={<Save />}
                                onClick={(e) => {
                                  HttpAuthorized.request({
                                    method: "POST",
                                    url: `${apiUrl}FiyatListeMaster/UrunFiyatGetir`,
                                    data: {
                                      urunId:
                                        form?.getFieldState("urunid").value,
                                      fiyatListesiId: fiyatListeid,
                                      kullaniciSayisi:
                                        form?.getFieldState("kullaniciSayisi")
                                          .value,
                                    },
                                  })
                                    .then((res) => {
                                      if (res.data.data !== null) {
                                        form?.change(
                                          "birimFiyat",
                                          res.data.data.birimFiyat
                                        );
                                        form?.change(
                                          "dovizKod",
                                          res.data.data.birim
                                        );
                                        form?.change(
                                          "kdvOrani",
                                          res.data.data.kdvOrani
                                        );

                                        brFiyatEnabled =
                                          kullBirimFiyatDegistirebilir;
                                      } else {
                                        // otherValidationSchema.validate(rowData);
                                        // validateFormValues(otherValidationSchema);
                                        form?.change("birimFiyat", 0);
                                        form?.change("dovizKod", null);
                                        form?.change("kdvOrani", 0);

                                        brFiyatEnabled =
                                          kullBirimFiyatDegistirebilir;
                                      }
                                    })
                                    .catch((error) => {
                                      printToast(error.response.data);
                                    });
                                }}
                                disabled={
                                  kullaniciSayisiRef?.current?.value === null ||
                                  typeof kullaniciSayisiRef?.current?.value ===
                                    "undefined" ||
                                  urunTipiDolu === false ||
                                  urunKoduDolu === false ||
                                  programDolu === false
                                }
                              >
                                LİSTEDEN GETİR
                              </ButtonWrapper> */}
                          {/* </Grid>
                          </Grid> */}
                        </>
                      )}

                      <FieldWrapper
                        label="Açıklama"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aciklama"
                          rowData={rowData}
                          type="text"
                          component={MultilineTextInputForm}
                          readOnly={!yetki.kaydet}
                          minRows={3}
                          maxRows={4}
                        />
                      </FieldWrapper>
                    </CustomBox>
                    <p />
                    <CustomBox
                      sx={{ p: 1, borderRadius: 3, bgcolor: "#e4ecef" }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "15px;",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Fiyat Bilgileri
                        </p>

                        <FieldWrapper
                          label="Birim Fiyat"
                          modalLoading={modalLoading}
                          color={
                            yetki.kaydet && brFiyatEnabled
                              ? "#1eb91e"
                              : undefined
                          }
                        >
                          <Field
                            name="birimFiyat"
                            component={NumberInputForm}
                            readOnly={!yetki.kaydet}
                            rowData={rowData}
                            disabled={!brFiyatEnabled}

                            // onChange={(e) => tutarlariHesapla(e, "birimFiyat")}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Para Birimi"
                          modalLoading={modalLoading}
                          color={
                            yetki.kaydet && brFiyatEnabled
                              ? "#1eb91e"
                              : undefined
                          }
                        >
                          <Field
                            name="dovizKod"
                            items={Object.values(PARA_BIRIMI)}
                            rowData={rowData}
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet}
                            disabled={!brFiyatEnabled}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Satış Adedi"
                          modalLoading={modalLoading}
                          color={yetki.kaydet ? "#1eb91e" : undefined}
                        >
                          <Field
                            name="miktar"
                            rowData={rowData}
                            component={NumberInputForm}
                            readOnly={!yetki.kaydet}
                            // disabled={!brFiyatEnabled}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Çalışma Birimi"
                          modalLoading={modalLoading}
                          color={yetki.kaydet ? "#1eb91e" : undefined}
                        >
                          <Field
                            name="stokBirim"
                            items={Object.values(STOK_BIRIM)}
                            rowData={rowData}
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet}

                            // disabled={!brFiyatEnabled}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Kdv Oranı"
                          modalLoading={modalLoading}
                          color={
                            yetki.kaydet && brFiyatEnabled
                              ? "#1eb91e"
                              : undefined
                          }
                        >
                          <Field
                            name="kdvOrani"
                            component={NumberInputForm}
                            readOnly={!yetki.kaydet}
                            rowData={rowData}
                            disabled={!brFiyatEnabled}

                            // onChange={(e) => tutarlariHesapla(e, "kdvOrani")}
                          />
                        </FieldWrapper>

                        <FieldWrapper
                          label="Kdv Tutarı"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="kdvTutari"
                            component={NumberInputForm}
                            readOnly={true}
                            rowData={rowData}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Kdv'li Birim Fiyat"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="kdvliBirimFiyat"
                            component={NumberInputForm}
                            readOnly={true}
                            rowData={rowData}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="İskonto Oranı"
                          modalLoading={modalLoading}
                          color={yetki.kaydet ? "#1eb91e" : undefined}
                        >
                          <Field
                            name="iskontoOrani"
                            component={NumberInputForm}
                            readOnly={!yetki.kaydet}
                            onFocus={() => {
                              setLastChangedField("iskontoOrani");
                            }}
                            rowData={rowData}

                            // onChange={(e) => tutarlariHesapla(e, "iskontoOrani")}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="İskonto Tutarı"
                          modalLoading={modalLoading}
                          color={yetki.kaydet ? "#1eb91e" : undefined}
                        >
                          <Field
                            name="iskontoTutari"
                            component={NumberInputForm}
                            onFocus={() => setLastChangedField("iskontoTutari")}
                            readOnly={!yetki.kaydet}
                            rowData={rowData}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          label="Genel Toplam"
                          modalLoading={modalLoading}
                          // color="#1eb91e"
                        >
                          <Field
                            name="genelToplam"
                            component={NumberInputForm}
                            readOnly={true}
                            rowData={rowData}
                          />
                        </FieldWrapper>
                      </div>
                    </CustomBox>
                  </Grid>
                  {/* </DialogContentTop> */}
                  <DialogActionsWrapper>
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  </DialogActionsWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
        <UrunAciklamasiDialog
          ref={urunAciklamasiDialogRef}
          ekranKodu={ekranKodu}
          isEditable={false}
        ></UrunAciklamasiDialog>
      </>
    );
  }
);
export default TeklifDetayRecord;
