import React from "react";
import { Grid } from "@mui/material";
import { Form, Field } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import usePageRecord from "../../hooks/use-page-record";
import * as yup from "yup";
import { PageConfig } from "./NotConfig";
import RichTextEditor from "../../components/inputs/form-inputs/RichTextEditor";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import MultipleAutocomplateInputForm from "../../components/inputs/form-inputs/MultipleAutoComplateInputForm";
import dayjs from "dayjs";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import SirketInputForm from "../../components/inputs/custom-form-inputs/SirketInputForm";
import { AppContext } from "../../common/AppContext";
import { YETKI_GRUBU_TIPI } from "../../common/constants";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";

const NotRecord = React.forwardRef(({ ekranKodu, listGridRef }, ref) => {
  const context = React.useContext(AppContext);
  const initialState = {
    kayitTarihi: dayjs(),
  };

  const {
    rowData,
    open,
    handleClose,
    modalLoading,
    handleSubmit,
    handleDelete,
    validateFormValues,
    yetki,
  } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

  const validationSchema = yup.object().shape({});

  const validate = validateFormValues(validationSchema);

  const musteri =
    context.user.yetkiGrubuTipi === YETKI_GRUBU_TIPI.MUSTERI.Value;

  return (
    <>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        modalTitle={rowData.id ? "Not Düzenleme" : "Yeni Not"}
        maxWidth="lg"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={rowData}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <FormComponent onSubmit={handleSubmit}>
              <DialogContentWrapper>
                <DialogContentTop>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldWrapper
                      label="Kayıt Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="kayitTarihi"
                        component={DateInputForm2}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                    {!musteri ? (
                      <FieldWrapper
                        label="İlgili Müşteri"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirketId"
                          rowData={rowData}
                          component={SirketInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    ) : null}

                    <FieldWrapper
                      label="Tamamlandı"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="tamamlandi"
                        component={CheckboxForm}
                        readOnly={!yetki.kaydet}
                      />
                    </FieldWrapper>
                  </Grid>
                  {!musteri ? (
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="İlgili Kişiler"
                        modalLoading={modalLoading}
                        width="75%"
                        textWidth="25%"
                      >
                        <Field
                          name="ilgiliKisiler"
                          rowData={rowData}
                          remoteDataAdaptor={{
                            fetchRoute: "User/KullaniciListForSelect",
                            fildsForText: ["isimSoyisim"],
                            fieldForValue: "id",
                            reqData: {
                              getSirket_KodFromSession: true,
                            },
                          }}
                          component={MultipleAutocomplateInputForm}
                          readOnly={!yetki.kaydet}
                        />
                      </FieldWrapper>
                    </Grid>
                  ) : null}
                </DialogContentTop>
                <Field
                  name="aciklama"
                  component={RichTextEditor}
                  readOnly={!yetki.kaydet}
                  label={"Açıklama"}
                  style={{ margin: "10px 1px" }}
                  modalLoading={modalLoading}
                />
              </DialogContentWrapper>
              <DialogActionsWrapper>
                <Grid>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={() => {
                      handleDelete({ id: rowData.id });
                    }}
                    submitting={submitting}
                    pristine={pristine}
                    submitExtra={() => {
                      handleClose();
                    }}
                    modalLoading={modalLoading}
                  />
                </Grid>
              </DialogActionsWrapper>
            </FormComponent>
          )}
        />
      </ScrollDialog>
    </>
  );
});

export default NotRecord;
