import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import DinamikTanimlarRecord from "../../../pages/DinamikTanimlar/DinamikTanimlarRecord";

export default function DinamikTanimlarInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  fieldLabelRef,
  tip,
  caption,
  aciklamaCaption,
  ...rest
}) {
  const dinamikTanimlarDialogRef = useRef();
  return (
    <>
      <AutocomplateInputForm
        readOnly={readOnly}
        rowData={rowData}
        insertButtonRef={dinamikTanimlarDialogRef}
        remoteDataAdaptor={{
          fetchRoute: "DinamikTanimlar/DinamikTanimlarListForSelect",
          fildsForText: ["aciklama"],
          fieldForValue: "id",
          reqData: {
            ...{
              tip: tip,
            },
          },

          fieldLabel: fieldLabelRef,
        }}
        ref={inputRef}
        input={input}
        {...rest}
      />
      <DinamikTanimlarRecord
        ref={dinamikTanimlarDialogRef}
        ekranKodu={ekranKodu}
        dinamikTanimlarInputRef={inputRef}
        tip={tip}
        caption={caption}
        aciklamaCaption={aciklamaCaption}
      ></DinamikTanimlarRecord>
    </>
  );
}
