import { Divider, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  apiUrl,
  EKRAN_KODU,
  ISIN_DURUMU_STR,
  ISLEM_TURU,
} from "../../common/constants";
import { DataGrid, HtmlEditor } from "devextreme-react";
import { AppContext } from "../../common/AppContext";
import { HttpAuthorized } from "../../common/common";
import { printToast } from "../../common/constant-functions/printToast";
import { Column } from "devextreme-react/data-grid";

const GorusmeMailSutunlari = React.forwardRef(
  ({ onMailAciklamaUpdate, tipi, olayid }, ref) => {
    const context = useContext(AppContext);
    const [sutunlar, setSutunlar] = useState([]);
    const editorRef = useRef(null);

    // Burada referansı ayarlıyoruz
    // useImperativeHandle(ref, () => ({
    //   setRichTextEditorRef: (newRef) => {
    //     editorRef.current = newRef;
    //   },
    // }));

    const handleRowDoubleClick = (e) => {
      onMailAciklamaUpdate("<p>%" + e.data.sutunAdi + "%</p>");
    };

    useEffect(() => {
      console.log("tekrar girdi");
      HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${"GorusmeMailTemplate/GorusmeSutunlariniGetir"}`,
        data: {
          tipi: tipi,
          olayid: olayid
        },
      })
        .then((res) => {
          setSutunlar(res.data.data);
        })
        .catch((error) => {
          printToast(error.response.data);
          console.log(error);
        });
    }, [olayid]);

    return (
      <DataGrid
        dataSource={sutunlar}
        keyExpr="id"
        showBorders={true}
        onRowDblClick={(e) => handleRowDoubleClick(e)}
        hoverStateEnabled={true}
        selection={{ mode: "single" }}
      >
         <Column
          dataField="tabloAdi"
          caption="Tablo"
          width={150}/>
        <Column dataField="sutunAdi" caption="Alan Adı" width={150} />
        <Column
          dataField="sutunAciklamasi"
          caption="Alan Açıklaması"
          width={150}
        />
      </DataGrid>
    );
  }
);

export default GorusmeMailSutunlari;
