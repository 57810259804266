import React, { useRef } from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./GorusmeAciklamalariConfig";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import DateTimeInputForm from "../../components/inputs/form-inputs/DateTimeInputForm";
import { GORUSME_DURUMU, GORUSME_TIPI } from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import { AppContext } from "../../common/AppContext";
import NewTextInputForm from "../../components/inputs/form-inputs/NewTextInputForm";
import PotansiyelKisilerInputForm from "../../components/inputs/custom-form-inputs/PotansiyelKisilerInputForm";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const GorusmeAciklamalariRecord = React.forwardRef(
  (
    { ekranKodu, listGridRef, gorusmeidData, closeRecord, gorusmeRecordRef },
    ref
  ) => {
    const context = React.useContext(AppContext);
    const initialState = { gorusmeid: gorusmeidData?.id };

    const gorusulenKisiMailRef = useRef();
    const gorusulenKisiTelRef = useRef();

    const afterSubmitFnc = () => {
      gorusmeRecordRef?.current?.openRecord(gorusmeidData?.id);
      handleClose();
    };

    const xpotFirmaid = gorusmeidData?.potFirmaid;

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    const kayitDegistirebilir =
      typeof rowData?.kayitEden === "undefined" ||
      rowData?.kayitEden == context?.user?.id;

    const validationSchema = yup.object().shape({
      aciklama: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Görüşme Detay Bilgi Girişi"
          maxWidth="sm"
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <FormComponent onSubmit={handleSubmit}> */}
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    {/* <CustomFieldWrapper label="No" modalLoading={modalLoading}>
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper> */}
                    {/* <CustomFieldWrapper
                      label="Görüşme No"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorusmeid"
                        component={TextInputForm}
                        //readOnly={!yetki.kaydet}
                        disabled
                      />
                    </CustomFieldWrapper> */}
                    <CustomFieldWrapper
                      label="Görüşme Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorusmeTarihi"
                        component={DateTimeInputForm}
                        readOnly={!yetki.kaydet || !kayitDegistirebilir}
                        disabled={!yetki.kaydet || !kayitDegistirebilir}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Görüşme Tipi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(GORUSME_TIPI)}
                        rowData={rowData}
                        name="gorusmeTipi"
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet || !kayitDegistirebilir}
                        disabled={!yetki.kaydet || !kayitDegistirebilir}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Görüşme Durumu"
                      modalLoading={modalLoading}
                    >
                      <Field
                        items={Object.values(GORUSME_DURUMU)}
                        rowData={rowData}
                        name="gorusmeDurumu"
                        component={AutocomplateInputForm}
                        readOnly={!yetki.kaydet || !kayitDegistirebilir}
                        disabled={!yetki.kaydet || !kayitDegistirebilir}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Ad Soyad"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorusmeKisiid"
                        component={PotansiyelKisilerInputForm}
                        fieldLabelVal="gorusmeKisiidStr"
                        ekranKodu={ekranKodu}
                        rowData={rowData}
                        readOnly={!yetki.kaydet || !kayitDegistirebilir}
                        disabled={!yetki.kaydet || !kayitDegistirebilir}
                        // potansiyelIdRef={potFirmaIdRef}
                        potFirmaid={xpotFirmaid}
                        telefonRef={gorusulenKisiTelRef}
                        mailRef={gorusulenKisiMailRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Telefon"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorusmeKisiTel"
                        component={NewTextInputForm}
                        readOnly={true}
                        ref={gorusulenKisiTelRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="E-Posta"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorusmeKisiMail"
                        component={NewTextInputForm}
                        readOnly={true}
                        ref={gorusulenKisiMailRef}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Açıklama"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aciklama"
                        component={MultilineTextInputForm}
                        minRows={5}
                        readOnly={!yetki.kaydet || !kayitDegistirebilir}
                        disabled={!yetki.kaydet || !kayitDegistirebilir}
                      />
                    </CustomFieldWrapper>
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  {kayitDegistirebilir ? (
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                    />
                  ) : null}
                </DialogActionsWrapper>
                {/* </FormComponent> */}
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default GorusmeAciklamalariRecord;
