import { YETKI_GRUBU_TIPI } from "../common/constants";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
  {
    name: "sorumluidStr",
    label: "Sorumlu",
    dataType: "text",
    dataField: "sorumluidStr",
    visible: ["grid"],
    width: "180px",
  },
  {
    name: "hedeflenenTerminTarihi",
    label: "Hedeflenen Termin Tarihi",
    dataType: "date",
    dataField: "hedeflenenTerminTarihi",
    visible: ["grid"],
    width: "180px",
  },
  {
    name: "hedeflenenSure",
    label: "Hedeflenen Süre",
    dataType: "number",
    dataField: "hedeflenenSure",
    visible: ["grid"],
    width: "150px",
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },
  {
    name: "Kayıt Tarihi",
    label: "kayitTarihi",
    dataType: "date",
    dataField: "kayitTarihi",
    visible: ["grid"],
    visibleConditions: [
      {
        gizlenecekYetkiGrubuTipi: [
          YETKI_GRUBU_TIPI.BAYI.Value,
          YETKI_GRUBU_TIPI.BAYI_DESTEK.Value,
          YETKI_GRUBU_TIPI.MUSTERI.Value,
          YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value,
          YETKI_GRUBU_TIPI.HARMONY_DESTEK.Value,
        ],
      },
    ],
  },
];

export default entity;
