import React, { useRef } from "react";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./PotansiyelMusteriConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";
import UlkeInputForm from "../../components/inputs/custom-form-inputs/UlkeInputForm";
import SehirInputForm from "../../components/inputs/custom-form-inputs/SehirInputForm";
import IlceInputForm from "../../components/inputs/custom-form-inputs/IlceInputForm";
import MahalleInputForm from "../../components/inputs/custom-form-inputs/MahalleInputForm";
import {
  apiUrl,
  CALISAN_SAY_ARALIGI,
  DINAMIK_TANIM_TIPLERI,
  PARA_BIRIMI,
  POTANSIYEL_MUSTERI_TIPI,
} from "../../common/constants";
import AutocomplateInputForm from "../../components/inputs/form-inputs/AutocomplateInputForm";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import { Button, Grid } from "@mui/material";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import DinamikTanimlarInputForm from "../../components/inputs/custom-form-inputs/DinamikTanimlarInputForm";
import MainTab from "../../components/tab/MainTab";
import PotansiyelKisiler from "../PotansiyelKisiler/PotansiyelKisiler";
import GorusmelerPotansiyelMusteriden from "../Gorusmeler/GorusmelerPotansiyelMusteriden";
import MultilineTextInputForm from "../../components/inputs/form-inputs/MultilineTextInputForm";
import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import { HttpAuthorized } from "../../common/common";

const CustomFieldWrapper = ({ label, modalLoading, children, textWidth }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={textWidth ? textWidth : "25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const PotansiyelMusteriRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, gorusmeRef }, ref) => {
    const context = React.useContext(AppContext);

    const initialState = {
      aktif: true,
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
      ulkeid: 212,
      ulkeidStr: "Türkiye",
    };

    const ulkeInputRef = useRef();
    const sehirInputRef = useRef();
    const ilceInputRef = useRef();
    const mahalleInputRef = useRef();
    const vergiNoRef = useRef();

    const programDinamikTanimInputRef = useRef();
    const iletisimDurumuDinamikTanimInputRef = useRef();
    const sektorDinamikTanimInputRef = useRef();

    const musteriTipiVisible = context?.user?.anaBayiKullanicisi === true;

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowData
    } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

    const validationSchema = yup.object().shape({
      vergiNo: yup
        .string()
        .required()
        .max(11, "Vergi No alanı 11 karakterden büyük olamaz!"),
      musteriAdi: yup.string().required("Müşteri adı girilmesi zorunludur."),
    });


    const memoizedRowData = React.useMemo(() => rowData, [rowData]);

    async function turmobdanBilgileriGetir(form) {
      HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${'PotansiyelMusteri/TurmobdanAl'}`,
        data: { vergiNo: vergiNoRef?.current?.value },
      }).then((res) => {

        setRowData(res.data.data);
        ulkeInputRef.directText = res.data.data.ulkeidStr;
        sehirInputRef.directText = res.data.data.sehiridStr;
        ilceInputRef.directText = res.data.data.ilceidStr;
        mahalleInputRef.directText = res.data.data.mahalleidStr;

        form?.change("aktif", true);
        form?.change("kayitTarihi", dayjs());
        form?.change("kayitEden", context?.user?.id);
      })
        .catch((error) => { });;
    }

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Potansiyel Müşteri"
          maxWidth="lg"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    {/* <Stack direction="column" spacing={1}> */}
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomFieldWrapper
                        label="Potansiyel M. No"
                        modalLoading={modalLoading}
                      >
                        <Field disabled name="id" component={TextInputForm} />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Kayıt Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kayitTarihi"
                          disabled
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Müşteri Adı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="musteriAdi"
                          subscription={{ value: true }}
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <Grid
                        container
                        direction="row"
                        spacing={0}
                        style={{ Padding: 0 }}
                      >
                        <Grid item xs={7}>
                          <CustomFieldWrapper
                            label="Vergi No"
                            modalLoading={modalLoading}
                            textWidth={"55%"}
                          >
                            <Field
                              name="vergiNo"
                              component={TextInputForm}
                              readOnly={!yetki.kaydet && !gorusmeRef}
                              inputRef={vergiNoRef}
                              disabled={rowData?.id}
                            />
                          </CustomFieldWrapper>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            // sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => {
                              turmobdanBilgileriGetir(form);
                              // lisansKoduVer();
                            }}
                          >
                            Turmob'dan getir
                          </Button>
                        </Grid>
                      </Grid>

                      <CustomFieldWrapper
                        label="Ülke"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="ulkeid"
                          component={UlkeInputForm}
                          // ref={ulkeInputRef}
                          inputRef={ulkeInputRef}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          rowData={rowData}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Şehir"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sehirid"
                          component={SehirInputForm}
                          inputRef={sehirInputRef}
                          ulkeInputRef={ulkeInputRef}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          rowData={rowData}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="İlçe"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="ilceid"
                          component={IlceInputForm}
                          inputRef={ilceInputRef}
                          sehirInputRef={sehirInputRef}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          rowData={rowData}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Mahalle"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="mahalleid"
                          component={MahalleInputForm}
                          inputRef={mahalleInputRef}
                          ilceInputRef={ilceInputRef}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          rowData={rowData}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Adres"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="adres"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Şirket Tel"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirketTel"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Şirket Mail"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sirketMail"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>

                      {musteriTipiVisible ? (
                        <CustomFieldWrapper
                          label="Müşteri Tipi"
                          modalLoading={modalLoading}
                        >
                          <Field
                            name="musteriTipi"
                            items={Object.values(POTANSIYEL_MUSTERI_TIPI)}
                            rowData={rowData}
                            component={AutocomplateInputForm}
                            readOnly={!yetki.kaydet && !gorusmeRef}
                          />
                        </CustomFieldWrapper>
                      ) : null}
                      <CustomFieldWrapper
                        label="Aktif"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aktif"
                          component={CheckboxForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomFieldWrapper
                        label="Web Sitesi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="webSitesi"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Sektör"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sektor"
                          rowData={rowData}
                          component={DinamikTanimlarInputForm}
                          inputRef={sektorDinamikTanimInputRef}
                          ekranKodu={ekranKodu}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          tip={DINAMIK_TANIM_TIPLERI.SEKTOR.Value}
                          caption="Sektör Tanımlama"
                          aciklamaCaption="Sektör"
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Çalışan Sayısı Aralığı"
                        modalLoading={modalLoading}
                      >
                        <Field
                          items={Object.values(CALISAN_SAY_ARALIGI)}
                          rowData={rowData}
                          component={AutocomplateInputForm}
                          name="calisanSayisiAraligi"
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <Grid
                        container
                        direction="row"
                        spacing={0}
                        style={{ Padding: 0 }}
                      >
                        <Grid item xs={8}>
                          <CustomFieldWrapper
                            label="Tahmini Yıllık Ciro"
                            modalLoading={modalLoading}
                            textWidth="45%"
                          >
                            <Field
                              name="tahminiCiro"
                              component={NumberInputForm}
                              readOnly={!yetki.kaydet && !gorusmeRef}
                              rowData={rowData}
                            />
                          </CustomFieldWrapper>
                        </Grid>
                        <Grid item xs={4}>
                          <CustomFieldWrapper
                            label="Birim"
                            modalLoading={modalLoading}
                          >
                            <Field
                              name="tahminiCiroBirimi"
                              items={Object.values(PARA_BIRIMI)}
                              rowData={rowData}
                              component={AutocomplateInputForm}
                              readOnly={!yetki.kaydet && !gorusmeRef}
                            />
                          </CustomFieldWrapper>
                        </Grid>
                      </Grid>

                      <CustomFieldWrapper
                        label="Kullanılan Program"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="programid"
                          rowData={rowData}
                          component={DinamikTanimlarInputForm}
                          inputRef={programDinamikTanimInputRef}
                          ekranKodu={ekranKodu}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          tip={DINAMIK_TANIM_TIPLERI.PROGRAM.Value}
                          caption="Kullanılan Program Kaydı"
                          aciklamaCaption="Kullanılan Program"
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Referans Kişi/Kurum"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="referans"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="İletişim Durumu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="iletisimDurumuid"
                          rowData={rowData}
                          component={DinamikTanimlarInputForm}
                          inputRef={iletisimDurumuDinamikTanimInputRef}
                          ekranKodu={ekranKodu}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          tip={DINAMIK_TANIM_TIPLERI.ILETISIM_DURUMU.Value}
                          caption="İletişim Durumu Tanımlama"
                          aciklamaCaption="İletişim Durumu"
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Son Görüşme Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="sonGorusmeTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="KEP Adresi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="kepAdresi"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="NACE Kodu"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="naceKodu"
                          component={TextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                        />
                      </CustomFieldWrapper>
                      <CustomFieldWrapper
                        label="Açıklama"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="aciklama"
                          component={MultilineTextInputForm}
                          readOnly={!yetki.kaydet && !gorusmeRef}
                          rowData={rowData}
                          modalLoading={modalLoading}
                          minRows={4}
                        />
                      </CustomFieldWrapper>
                    </Grid>
                  </DialogContentTop>
                  <MainTab labels={["Kişiler", "Görüşme Takip"]}>
                    <>
                      <PotansiyelKisiler
                        rowData={memoizedRowData}
                        ekranKodu={ekranKodu}
                      ></PotansiyelKisiler>
                    </>
                    <>
                      <GorusmelerPotansiyelMusteriden
                        ekranKodu={ekranKodu}
                        rowData={memoizedRowData}
                      ></GorusmelerPotansiyelMusteriden>
                    </>
                  </MainTab>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                    yetkisizseAc={typeof gorusmeRef !== "undefined"}
                  />
                </DialogActionsWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default PotansiyelMusteriRecord;
