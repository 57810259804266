import React, { useRef } from "react";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./GorusmeHatirlatmaConfig";
import DinamikTanimlarInputForm from "../../components/inputs/custom-form-inputs/DinamikTanimlarInputForm";
import { DINAMIK_TANIM_TIPLERI } from "../../common/constants";
import CheckboxForm from "../../components/inputs/form-inputs/CheckboxForm";
import DateTimeInputForm from "../../components/inputs/form-inputs/DateTimeInputForm";

const CustomFieldWrapper = ({ label, modalLoading, children }) => {
  return (
    <FieldWrapper
      label={label}
      modalLoading={modalLoading}
      width={"75%"}
      textWidth={"25%"}
    >
      {children}
    </FieldWrapper>
  );
};

const GorusmeHatirlatmaRecord = React.forwardRef(
  ({ ekranKodu, listGridRef, gorusmeidData }, ref) => {
    const initialState = { gorusmeid: gorusmeidData?.id };


    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
    } = usePageRecord(ref, PageConfig, listGridRef, ekranKodu, initialState);

    // console.log(rowData, "rowData");

    const validationSchema = yup.object().shape({
      // adSoyad: yup.string().required(),
      // adSoyad: yup.string().required(),
    });

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Yeni Görüşme Hatırlatması"
          maxWidth="md"
          fullWidth={true}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <FormComponent onSubmit={handleSubmit}> */}
                <DialogContentWrapper>
                  <Stack direction="column" spacing={1}>
                    <CustomFieldWrapper
                      label="Görüşme Hatırlatma No"
                      modalLoading={modalLoading}
                    >
                      <Field disabled name="id" component={TextInputForm} />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Görüşme No"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="gorusmeid"
                        component={TextInputForm}
                        //readOnly={!yetki.kaydet}
                        disabled
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Hatırlatma Tarihi"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="hatirlatmaTarihi"
                        component={DateTimeInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Açıklama"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="aciklama"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    <CustomFieldWrapper
                      label="Mail Gönderilecek Kişiler"
                      modalLoading={modalLoading}
                    >
                      <Field
                        name="mailGonderilecekKisiler"
                        component={TextInputForm}
                        readOnly={!yetki.kaydet}
                      />
                    </CustomFieldWrapper>
                    
                    
                  </Stack>
                </DialogContentWrapper>
                <DialogActionsWrapper>
                  <RecordButton
                    yetki={yetki}
                    form={form}
                    handleDelete={handleDelete}
                    submitting={submitting}
                    pristine={pristine}
                    modalLoading={modalLoading}
                  />
                </DialogActionsWrapper>
                {/* </FormComponent> */}
              </form>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default GorusmeHatirlatmaRecord;
