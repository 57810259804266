import Type from "../../entities/Gorev";

const PageConfig = {
  title: "Görevler",
  ekranKodu: "Gorev_Res",
  type: Type,
  listRoute: "BayiGorev/List",
};

export default PageConfig;
