import React from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";

export default function KullaniciListMerkezVeBayiInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  sirketInputRef,
  reqData,
  ...rest
}) {
  return (
    <AutocomplateInputForm
      readOnly={readOnly}
      rowData={rowData}
      remoteDataAdaptor={{
        fetchRoute: "User/KullaniciListMerkezVeBayiForSelect",
        fildsForText: ["isimSoyisim"],
        fieldForValue: "id",
        reqData: {},
      }}
      ref={inputRef}
      input={input}
      {...rest}
    />
  );
}
