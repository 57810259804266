import { parseHtmlString } from "../common/constant-functions/parseHtmlString";
import { GOREV_DURUMU_FOR_GOREV_DURUMU_RAPORU } from "../common/constants";
import { ID_WIDTH } from "../common/entityconstants";
import ScrollableCell from "../components/grid/ScrollableCell";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "kisiidStr",
    label: "Kullanıcı",
    dataType: "text",
    dataField: "kisiidStr",
    visible: ["grid"],
    gruplanacak: true,
  },
  // {
  //   name: "id",
  //   label: "Talep Noxx",
  //   dataType: "number",
  //   dataField: "id",
  //   visible: ["grid"],
  //   isPrimaryKey: true,
  //   width: ID_WIDTH,
  // },
  {
    name: "talepNo",
    label: "Talep No",
    dataType: "number",
    dataField: "talepNo",
    visible: ["grid"],
    // isPrimaryKey: true,
    // width: ID_WIDTH,
  },
  {
    name: "analizSonucu",
    label: "Talep Açıklama",
    dataType: "text",
    dataField: "analizSonucu",
    visible: ["grid"],
    cellRender: (e) => e?.value && ScrollableCell(parseHtmlString(e?.value)),
    // cellRender: (e) => e?.value && parseHtmlString(e?.value),
    width: "300px",
  },
  {
    name: "talepEdenFirmaidStr",
    label: "Talep Eden Firma",
    dataType: "text",
    dataField: "talepEdenFirmaidStr",
    visible: ["grid"],
  },
  {
    name: "gorevDurumuStr",
    label: "Görev Durumu",
    dataType: "text",
    dataField: "gorevDurumuStr",
    visible: ["grid"],
  },
  {
    name: "ilkYonlendirmeTarihi",
    label: "İlk Yönlendirme Tarihi",
    dataType: "date",
    dataField: "ilkYonlendirmeTarihi",
    visible: ["grid"],
  },
  {
    name: "atananBaslangicTarihi",
    label: "Atanan Başlangıç Tarihi",
    dataType: "date",
    dataField: "atananBaslangicTarihi",
    visible: ["grid"],
  },
  {
    name: "fiiliBaslangicTarihi",
    label: "Fiili Başlangıç Tarihi",
    dataType: "date",
    dataField: "fiiliBaslangicTarihi",
    visible: ["grid"],
  },
  {
    name: "atananTerminTarihi",
    label: "Atanan Termin Tarihi",
    dataType: "date",
    dataField: "atananTerminTarihi",
    visible: ["grid"],
  },
  {
    name: "atananSure",
    label: "Atanan Süre",
    dataType: "number",
    dataField: "atananSure",
    visible: ["grid"],
  },
  {
    name: "tahminiTerminTarihi",
    label: "Tahmini Termin Tarihi",
    dataType: "date",
    dataField: "tahminiTerminTarihi",
    visible: ["grid"],
  },
  {
    name: "tahminiSure",
    label: "Tahmini Süre",
    dataType: "number",
    dataField: "tahminiSure",
    visible: ["grid"],
  },
  {
    name: "toplamHarcananSure",
    label: "Harcanan Toplam Süre",
    dataType: "number",
    dataField: "toplamHarcananSure",
    visible: ["grid"],
  },
  {
    name: "yonlendirmeSayisi",
    label: "Yönlendirme Sayısı",
    dataType: "number",
    dataField: "yonlendirmeSayisi",
    visible: ["grid"],
  },

  // {
  //   name: "kisiid",
  //   label: "Kullanıcıx",
  //   dataType: "number",
  //   dataField: "kisiid",
  //   visible: ["grid"],
  //   gruplanacak: true,
  // },

  //todo
  //filtre--------------------------------------------------------------------------------------------------
  {
    name: "gorevDurumu",
    label: "Görev Durumu",
    dataType: "select",
    dataField: "gorevDurumu",
    visible: ["filter"],
    items: Object.values(GOREV_DURUMU_FOR_GOREV_DURUMU_RAPORU),
    defaultValue: GOREV_DURUMU_FOR_GOREV_DURUMU_RAPORU.DEVAM_EDIYOR.Value,
  },
  {
    name: "firma",
    label: "Firma",
    dataType: "custom",
    dataField: "firma",
    visible: ["filter"],
    customComponent: SirketInputForm,
    // customComponentParams: {},
    // gizlenecekYetkiGrubuTipi: [YETKI_GRUBU_TIPI.MUSTERI.Value],
  },
  {
    name: "atananTerminTarihi1",
    label: "Atanan Termin Tarihi 1",
    dataType: "date",
    dataField: "atananTerminTarihi1",
    visible: ["filter"],
  },
  {
    name: "atananTerminTarihi2",
    label: "Atanan Termin Tarihi 2",
    dataType: "date",
    dataField: "atananTerminTarihi2",
    visible: ["filter"],
  },
  {
    name: "tarih2",
    label: "Bitiş Tarihi 2",
    dataType: "date",
    dataField: "tarih2",
    visible: ["filter"],
  },
  {
    name: "talepDetayi",
    label: "Talep Detayı",
    dataType: "text",
    dataField: "talepDetayi",
    visible: ["filter"],
  },
];

export default entity;
