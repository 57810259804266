import React, { useRef } from "react";
import MainTab from "../../components/tab/MainTab";
import { AppContext } from "../../common/AppContext";
import GorusmeMailTemplate from "./GorusmeMailTemplate";

export default function MailOlaylariDuzenle({ ekranKodu }) {
  const context = React.useContext(AppContext);
  const listGridRef = useRef();
  // console.log(context.user.bayiKullanicisi, "context.user.bayiKullanicisi");

  return (
    <>
      <MainTab labels={["Görüşmeler", "Teklifler"]}>
        <GorusmeMailTemplate
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          tipi={1}
        ></GorusmeMailTemplate>
        <GorusmeMailTemplate
          ekranKodu={ekranKodu}
          listGridRef={listGridRef}
          tipi={2}
        ></GorusmeMailTemplate>
      </MainTab>
    </>
  );
}
