import Type from "../../entities/TeklifProjeDetay";

const PageConfig = {
  title: "TeklifProjeDetay",
  ekranKodu: "TeklifProjeDetay_Res",
  type: Type,
  listRoute: "TeklifProjeDetay/List",
  getByIdRoute: "TeklifProjeDetay/GetById",
  insertRoute: "TeklifProjeDetay/Insert",
  updateRoute: "TeklifProjeDetay/Update",
  deleteRoute: "TeklifProjeMaster/DeleteDetay",
};

export { PageConfig };
