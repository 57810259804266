import React, { useRef } from "react";
import PageviewIcon from "@mui/icons-material/Pageview";
import FileViewDialog from "../../file/FileViewDialog";
import CustomColoredButton from "./CustomColoredButton";

export default function EkDosyaButton({
  routes,
  reqData,
  label,
  ekranKodu,
  parentRowData,
  disabled,
  deleteDisabled,
  ...rest
}) {
  const dosyaGoruntulemeDialogRef = useRef();
  return (
    <>
      <CustomColoredButton
        sx={{ mr: 1 }}
        startIcon={<PageviewIcon />}
        onClick={() =>
          dosyaGoruntulemeDialogRef?.current?.openRecord(null, reqData)
        }
        {...rest}
        color="green"
        disabled={disabled}
      >
        {label ? label : "Dosyalar"}
      </CustomColoredButton>
      <FileViewDialog
        ekranKodu={ekranKodu}
        routes={routes}
        ref={dosyaGoruntulemeDialogRef}
        reqData={reqData}
        parentRowData={parentRowData}
        deleteDisabled={deleteDisabled}
      ></FileViewDialog>
    </>
  );
}
