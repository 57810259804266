import ProgressBar from "../components/forms/ProgressBar";
import { ID_WIDTH } from "../common/entityconstants";
import SirketInputForm from "../components/inputs/custom-form-inputs/SirketInputForm";

const entity = [
  {
    name: "id",
    label: "No",
    dataType: "number",
    dataField: "id",
    visible: ["grid"],
    isPrimaryKey: true,
    width: ID_WIDTH,
  },
  {
    name: "projeAsamasi",
    label: "Proje Aşaması",
    dataType: "text",
    dataField: "projeAsamasi",
    visible: ["grid"],
  },
  {
    name: "baslangicTarihi",
    label: "Başlangıç Tarihi",
    dataType: "date",
    dataField: "baslangicTarihi",
    visible: ["grid"],
  },
  {
    name: "bitisTarihi",
    label: "Bitiş Tarihi",
    dataType: "date",
    dataField: "bitisTarihi",
    visible: ["grid"],
  },
  {
    name: "aciklama",
    label: "Açıklama",
    dataType: "text",
    dataField: "aciklama",
    visible: ["grid"],
  },

  //filtre--------------------------------------------------------------------------------------------------
];

export default entity;
