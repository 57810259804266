import React, { useRef } from "react";
import AutocomplateInputForm from "../form-inputs/AutocomplateInputForm";
import DinamikTanimlarRecord from "../../../pages/DinamikTanimlar/DinamikTanimlarRecord";
import PotansiyelKisilerRecord from "../../../pages/PotansiyelKisiler/PotansiyelKisilerRecord";

export default function PotansiyelKisilerInputForm({
  ekranKodu,
  rowData,
  readOnly,
  inputRef,
  input,
  reqData,
  fieldLabelRef,
  potansiyelIdRef,
  telefonRef,
  mailRef,
  potFirmaid,
  fieldLabelVal,
  // potansiyelid,
  ...rest
}) {
  const potansiyelKisilerDialogRef = useRef();
  const recordRef = useRef();

  const potansiyelid =
    typeof potansiyelIdRef !== "undefined" &&
      potansiyelIdRef?.current?.value !== ""
      ? potansiyelIdRef?.current?.value
      : potFirmaid
        ? potFirmaid
        : rowData?.potFirmaid;

  return (
    <>
      <AutocomplateInputForm
        ref={inputRef}
        readOnly={readOnly}
        rowData={rowData}
        insertButtonRef={potansiyelKisilerDialogRef}
        remoteDataAdaptor={{
          fetchRoute: "PotansiyelKisiler/ListForSelect",
          fildsForText: ["adSoyad"],
          fieldForValue: "id",
          reqData: { id: potansiyelid },
          fieldLabel: fieldLabelVal ? fieldLabelVal : "gorusulenKisiidStr",
        }}
        input={input}
        telefonRef={telefonRef}
        mailRef={mailRef}
        {...rest}
      />
      <PotansiyelKisilerRecord
        ref={potansiyelKisilerDialogRef}
        ekranKodu={ekranKodu}
        // listGridRef={listGridRef}
        potansiyelidData={{ id: potansiyelid }}
      />
      {/* <DinamikTanimlarRecord
        ref={dinamikTanimlarDialogRef}
        ekranKodu={ekranKodu}
        dinamikTanimlarInputRef={inputRef}
        tip={tip}
        caption={caption}
        aciklamaCaption={aciklamaCaption}
      ></DinamikTanimlarRecord>  */}
    </>
  );
}
