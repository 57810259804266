import React from "react";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import { Stack } from "@mui/system";
import useBasicDialog from "../../hooks/use-basic-dialog";
import { Alert, AlertTitle } from "@mui/material";

const TalepDurumInfoDilog = React.forwardRef(({}, ref) => {
  const { open, closeRecord } = useBasicDialog(ref);

  const CustomAlert = ({ title, description }) => {
    return (
      <Alert variant="outlined" severity="info">
        <AlertTitle>{title} </AlertTitle>
        {[description]}
      </Alert>
    );
  };

  return (
    <ScrollDialog
      open={open}
      handleClose={closeRecord}
      modalTitle="Talep Durumları Neyi İfade Eder?"
      maxWidth="lg"
    >
      <DialogContentWrapper>
        <Stack direction="column" spacing={1}>
          <CustomAlert
            title="Yeni Talep:"
            description=" Yeni oluşturulan talebi ifade etmektedir. Talep üzerinde Bayi/Merkez herhangi işlem (analiz, görevlendirme, yazılıma gönderme vb.) yapılmadığını da ifade eder."
          />
          <CustomAlert
            title="Çalışma Yapılıyor: "
            description="Talep ile Bayi/Merkez ilgilendiği, işlem yaptığı yani iş analizi, görevlendirme, yazılıma gönderme vb. süreçlerden biri veya tümü için çalışma yapıldığını ifade eder."
          />
          <CustomAlert
            title="Merkeze İletildi:"
            description="Bayinin kendi taleplerini veya müşteri taleplerinin ana merkeze iletildiğini ifade etmektedir. (Merkeze iletilen taleplerden sonraki talep durumları merkezin talep ile alakalı yaptığı çalışmaları baz alarak yenilenir.)"
          />
          <CustomAlert
            title="Güncelleme Bekliyor: "
            description="Talep ile alakalı iş süreçlerin tamamlandığı yapılan çalışmaların programa/projeye uygulanacağını ve güncelleme beklediğini ifade etmektedir."
          />
          <CustomAlert
            title="Tamamlandı: "
            description="Talep ile alakalı tüm süreçlerin tamamlandığı ve güncelleme ile programa uygulandığını ifade etmektedir."
          />
          <CustomAlert
            title="Yapılması Uygun Görülmedi: "
            description="Bayi/Merkez tarafından talebin ERP projesi ve genel süreç ile alakalı teknik, mantık vb. sebeplerden dolayı herhangi çalışma yapılmayacağını ifade etmektedir."
          />
          <CustomAlert
            title="Teklife Cevap Bekleniyor: "
            description="Bayi/Merkez tarafından talebe teklif verildiğini ifade etmektedir. (Teklif onay işlemi teklif çıktısı ekranındaki çıktının yetkili kişilerce onaylı halinin imzalanarak talebe yüklenmesi süreci ile ilerler.)"
          />
          <CustomAlert
            title="Teklif Reddedildi:"
            description="Bayi/Merkez tarafından talebe verilen teklifin Müşteri tarafından teklif çıktısı ekranı üzerinden reddedilmesi sonrası gerçekleşen talep durumudur."
          />
          <CustomAlert
            title="Onaylı Teklif Yapılıyor:"
            description="Müşteri tarafından onaylanan teklifli iş için, işlem yapıldığı yani iş analizi, görevlendirme, yazılıma gönderme vb. süreçlerden biri veya tümü için çalışma yapıldığını ifade eder."
          />
          <CustomAlert
            title="Onaylı Teklif Tamamlandı:"
            description="Müşteri tarafından onaylanan Talep ile alakalı tüm süreçlerin tamamlandığı ve güncelleme ile programa uygulandığını ifade etmektedir."
          />
        </Stack>
      </DialogContentWrapper>
    </ScrollDialog>
  );
});
export default TalepDurumInfoDilog;
