import Type from "../../entities/PotansiyelKisiler";

const PageConfig = {
  title: "PotansiyelKisiler",
  ekranKodu: "PotansiyelKisiler_Res",
  type: Type,
  listRoute: "PotansiyelKisiler/List",
  getByIdRoute: "PotansiyelKisiler/GetById",
  insertRoute: "PotansiyelKisiler/Insert",
  updateRoute: "PotansiyelKisiler/Update",
  deleteRoute: "PotansiyelKisiler/Delete",
};

export { PageConfig };
