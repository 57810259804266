import React, { useRef } from "react";
import DataGrid, {
  Column,
  Lookup,
  Scrolling,
  Paging,
  Editing,
  Pager,
  Button,
  Export,
  TotalItem,
  Summary,
  StateStoring,
} from "devextreme-react/data-grid";
import { Grid, Paper, Stack } from "@mui/material";
import { Add, Close, Save } from "@mui/icons-material";
import "../../css/PageGrid.css";
import { AppContext } from "../../common/AppContext";
import { printToast } from "../../common/constant-functions/printToast";
import {
  APP_BAR_HEIGHT,
  FILTER_HEIGHT,
  TAB_HEIGHT,
  YETKI_GRUBU_TIPI,
  apiUrl,
} from "../../common/constants";
import { HttpAuthorized } from "../../common/common";
import { findPrimaryKey } from "../../common/constant-functions/findPrimaryKey";
import CustomStore from "devextreme/data/custom_store";
import { loadingVisible } from "../../common/constant-functions/loadingVisible";
import ExportButton from "../buttons/ExportButton";
import SmallButton from "../buttons/SmallButton";
import getTeklifGoruntule from "../../common/constant-functions/getTeklifGoruntule";
import GridClearButton from "../buttons/custom/GridClearButton";
import useGridState from "../../hooks/use-grid-state";

export default function EditablePageGridWithList({
  ekranKodu,
  listGridRef,
  PageConfig,
  filterRef,
  isModalCreatable = false,
  columnBtnVisible = true,
  CustomColumnButton = [],
  height,
  recordRef,
  isRowCreatable = false,
  editBtnVisible = false,
  hasTab = false,
  gridId,
  CustomHeaderButtons = [],
}) {
  const id = gridId + "_PageGrid";
  const context = React.useContext(AppContext);
  const yetki =
    context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu) ?? {};

  const { saveState, loadState } = useGridState(
    PageConfig.type,
    listGridRef,
    id
  );

  const changesRef = useRef([]);

  const store = new CustomStore({
    key: findPrimaryKey(PageConfig.type),
    load(loadOptions) {
      let gridSort;
      if (loadOptions.sort) {
        gridSort = loadOptions.sort.map((i) => {
          return {
            propertyName: i.selector,
            order: i.desc ? "desc" : "asc",
          };
        });
      }
      return HttpAuthorized.request({
        method: "POST",
        url: `${apiUrl}${PageConfig.listRoute}`,
        data: {
          Criteria: filterRef?.current?.filterData ?? {},
          GridCriterias: {
            sortModel: loadOptions.sort ? gridSort : null,
            filterModel: [],
          },
          Pagination: loadOptions.isLoadingAll
            ? {
                rowOffset: 0,
                maxRowsPerPage: listGridRef.current.instance.totalCount(),
              }
            : {
                rowOffset: loadOptions.skip,
                maxRowsPerPage: loadOptions.take,
              },
        },
      })
        .then((res) => {
          printToast(res.data);

          return {
            data: res.data.data.list,
            totalCount: res.data.data.pagination.totalRowCount,
          };
        })
        .catch((error) => {
          printToast(error.response.data);
          return {
            data: [],
            totalCount: 0,
          };
        });
    },
  });

  const onChangesChange = (e) => {
    changesRef.current = e;
  };

  async function insertOrUpdate(values) {
    loadingVisible(true);
    HttpAuthorized.request({
      method: "POST",
      url: `${apiUrl}${PageConfig.bulkUpdateRoute}`,
      data: values,
    })
      .then(async (res) => {
        printToast(res.data);
        changesRef.current = [];
        await listGridRef.current.instance.refresh();
        await listGridRef.current.instance.cancelEditData();
      })
      .catch((error) => {
        printToast(error.response.data);
        console.log(error);
      })
      .finally(() => loadingVisible(false));
  }

  return (
    <Paper sx={{ backgroundColor: "transparent" }}>
      <Stack
        direction="row"
        sx={{
          mb: "3px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        spacing={1}
      >
        <Grid>
          {isModalCreatable &&
            context?.user?.yetkiler.find((x) => x.ekranKodu === ekranKodu)
              ?.kaydet && (
              <SmallButton
                startIcon={<Add />}
                onClick={() => {
                  recordRef?.current?.openRecord(null);
                }}
              >
                Ekle
              </SmallButton>
            )}

          {isRowCreatable && yetki?.kaydet && (
            <SmallButton
              startIcon={<Add />}
              onClick={() => {
                listGridRef.current.instance.addRow();
              }}
            >
              Satır Ekle
            </SmallButton>
          )}
          {yetki?.kaydet && (
            <SmallButton
              color="success"
              startIcon={<Save />}
              onClick={async () => {
                // const updateList = changesRef.current
                //   .filter((change) => change.type === "update")
                //   .map(({ key, data }) => ({ id: key, ...data }));

                const updateList = changesRef.current
                  .filter((change) => change.type === "update")
                  .map(({ key, data }) => {
                    if (typeof key === "number") {
                      return { id: key, ...data };
                    } //else if (typeof key === "object") {
                    return { ...key, ...data };
                    //}
                  });

                const insertList = changesRef.current
                  .filter((change) => change.type === "insert")
                  .map(({ data }) => ({ ...data }));
                // const removeList = changesRef.current
                //   .filter((change) => change.type === "remove")
                //   .map(({ key }) => key);

                // console.log("updateList", JSON.stringify(updateList));
                // console.log("insertList", JSON.stringify(insertList));
                // console.log("removeList", JSON.stringify(removeList));

                await insertOrUpdate([...insertList, ...updateList]);
              }}
            >
              Kaydet
            </SmallButton>
          )}

          {yetki?.kaydet && (
            <SmallButton
              color="warning"
              startIcon={<Close />}
              onClick={() => {
                listGridRef.current.instance.cancelEditData();
              }}
            >
              Vazgeç
            </SmallButton>
          )}

          <ExportButton
            pageConfig={PageConfig}
            gridRef={listGridRef}
          ></ExportButton>

          {CustomHeaderButtons}
        </Grid>
        <Grid>
          <GridClearButton gridRef={listGridRef}></GridClearButton>
        </Grid>
      </Stack>

      <Grid variant="outlined">
        <DataGrid
          id={id}
          height={
            height
              ? height
              : hasTab
              ? `calc(100vh - ${APP_BAR_HEIGHT} - ${FILTER_HEIGHT} - ${TAB_HEIGHT}`
              : `calc(100vh - ${APP_BAR_HEIGHT} - ${FILTER_HEIGHT} )`
          }
          dataSource={store}
          showBorders={true}
          columnAutoWidth={true}
          remoteOperations={{
            paging: true,
            filtering: true,
            sorting: true,
            grouping: true,
            summary: false,
          }}
          ref={listGridRef}
          onToolbarPreparing={(e) => {
            e.toolbarOptions.visible = false;
          }}
          // onSaving={(e) => {
          //   console.log("grid save: ", e);
          //   console.log("changes: ", e.changes);
          // }}
          allowColumnReordering={true}
          allowColumnResizing={true}
        >
          <Export enabled={true} />
          {/* <Scrolling mode="standard" /> */}
          <Scrolling columnRenderingMode="virtual" />
          <Editing
            mode="batch"
            allowUpdating={true}
            allowAdding={true}
            // allowDeleting={true}
            startEditAction="click"
            autoSaveOnEndEdit={true}
            selectTextOnEditStart={true}
            onChangesChange={onChangesChange}
          />
          <StateStoring
            enabled={true}
            type="custom"
            storageKey={id}
            customLoad={loadState}
            customSave={saveState}
          />

          <Column
            type="buttons"
            visibleIndex={0}
            fixed={true}
            fixedPosition={0}
            alignment={"center"}
            visible={columnBtnVisible}
          >
            <Button
              hint={"düzenle"}
              icon={"edit"}
              onClick={(e) => {
                recordRef?.current?.openRecord(e.row.key);
              }}
              visible={editBtnVisible}
            ></Button>
            {CustomColumnButton}
          </Column>

          {PageConfig.type
            .filter((type) => {
              let gridVisible = type.visible?.includes("grid");
              if (gridVisible) {
                if (type.visibleConditions) {
                  let innerVisible = true;
                  for (let i = 0; i < type.visibleConditions?.length; i++) {
                    if (type.visibleConditions[i].isTeklifGoruntule) {
                      innerVisible = getTeklifGoruntule(
                        ekranKodu,
                        context.user.yetkiler
                      );
                      if (!innerVisible) break;
                    }

                    if (type.visibleConditions[i].isHarmonyAdmin) {
                      innerVisible =
                        context.user.yetkiGrubuTipi ===
                        YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;
                      if (!innerVisible) break;
                    }

                    // if (type.visibleConditions[i].gizlenecekYetkiGrubuTipi) {
                    //   innerVisible =
                    //     context.user.yetkiGrubuTipi !==
                    //     type.visibleConditions[i].gizlenecekYetkiGrubuTipi;
                    //   if (!innerVisible) break;
                    // }

                    if (type.visibleConditions[i].gizlenecekYetkiGrubuTipi) {
                      if (
                        Array.isArray(
                          type.visibleConditions[i].gizlenecekYetkiGrubuTipi
                        )
                      ) {
                        innerVisible = !type.visibleConditions[
                          i
                        ].gizlenecekYetkiGrubuTipi.includes(
                          context.user.yetkiGrubuTipi
                        );
                      } else {
                        innerVisible =
                          context.user.yetkiGrubuTipi !==
                          type.visibleConditions[i].gizlenecekYetkiGrubuTipi;
                      }
                      if (!innerVisible) break;
                    }
                  }

                  /* type.visibleConditions.map((x) => {
                    if (x.isHarmonyAdmin) {
                      innerVisible =
                        context.user.yetkiGrubuTipi ===
                        YETKI_GRUBU_TIPI.HARMONY_ADMIN.Value;
                        if (!innerVisible) exit;
                    }
                  }); */

                  return innerVisible;
                } else {
                  return true;
                }
              } else return false;
            })
            .map((i, index) => {
              return (
                <Column
                  key={i.name}
                  name={i.name}
                  dataType={i.dataType}
                  caption={i.label}
                  dataField={i.dataField}
                  visibleIndex={index}
                  alignment={
                    i.dataType === "number"
                      ? "right"
                      : i.dataType === "boolean"
                      ? "center"
                      : "left"
                  }
                  defaultSortOrder={i.firstSort ? i.firstSort : null}
                  {...(i.defaultSortIndex && {
                    defaultSortIndex: i.defaultSortIndex,
                  })}
                  width={i.width ? i.width : null}
                  minWidth={i.minWidth ? i.minWidth : 50}
                  format={i.format ? i.format : null}
                  visible={i.pageGridHidden ? false : true}
                  {...(i.cellRender && { cellRender: i.cellRender })}
                  {...(i.customizeText && { customizeText: i.customizeText })} //todo excel e aktarırken muinin bileşenleri aktarılmadığı için cell render kullanılan fieldlara bu alanın da eklenmesi gerekir
                  allowEditing={i?.allowEditing ?? false}
                >
                  {i.dataType === "select" ? (
                    <Lookup
                      key={i.dataField + "_lookup"}
                      dataSource={i.selectItems}
                      displayExpr="Text"
                      valueExpr="Value"
                    ></Lookup>
                  ) : null}
                </Column>
              );
            })}

          {PageConfig.type.filter((type) => type.visible?.includes("summary"))
            .length > 0 && (
            <Summary>
              {PageConfig.type
                .filter((type) => type.visible?.includes("summary"))
                .map((i, index) => {
                  return (
                    <TotalItem
                      count={store.totalCount}
                      key={index}
                      column={i.column}
                      summaryType={i.summaryType}
                      alignment={"right"}
                      customizeText={i.customizeText}
                      // showInColumn={i.showInColumn}
                      valueFormat={i.valueFormat}
                      displayFormat={i.displayFormat}
                    />
                  );
                })}
            </Summary>
          )}

          <Paging defaultPageSize={12} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={[15, 25, 50]}
            displayMode="full"
          />
        </DataGrid>
      </Grid>
    </Paper>
  );
}
