import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { Field, Form } from "react-final-form";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import TextInputForm from "../../components/inputs/form-inputs/TextInputForm";
import usePageRecord from "../../hooks/use-page-record";
import { PageConfig } from "./TeklifProjeMasterConfig";
import DialogContentWrapper from "../../components/dialogs/DialogContentWrapper";
import DialogActionsWrapper from "../../components/dialogs/DialogActionsWrapper";
import * as yup from "yup";
import RecordButton from "../../components/buttons/custom/RecordButton";
import FormComponent from "../../components/forms/FormComponent";
import DialogContentTop from "../../components/dialogs/DialogContentTop";
import NumberInputForm from "../../components/inputs/form-inputs/NumberInputForm";
import dayjs from "dayjs";
import { AppContext } from "../../common/AppContext";

import DateInputForm2 from "../../components/inputs/form-inputs/DateInputForm2";
import TeklifProjeDetay from "./TeklifProjeDetay";

const TeklifProjeMasterRecord = React.forwardRef(
  ({ ekranKodu, listGridRef }, ref) => {
    const context = React.useContext(AppContext);

    var initialState = {
      kayitTarihi: dayjs(),
      kayitEden: context?.user?.id,
    };

    const editGridRef = useRef();

    const handleSubmitPrepareData = (values) => {
      let aa = {
        ...values,
        detay: editGridRef.current.instance.getVisibleRows().map((row) => {
          if (row.data.masterid == "undefined" || row.data.masterid == "") {
            row.data.masterid = 0;
          }
          return row.data;
        }),
        dipToplam: [],
      };

      return aa;
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      setRowDataFnc,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      () => {
        editGridRef.current?.instance?.cancelEditData();
      },
      null,
      null,
      null,
      handleSubmitPrepareData
    );

    const setRowDataWithGetById = (id) => {
      ref?.current?.openRecord(id);
    };

    const validationSchema = yup.object().shape({});

    const validate = validateFormValues(validationSchema);

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle="Teklif Proje Bilgileri"
          maxWidth="md"
          height={"78vh"}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={rowData}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              reset,
            }) => (
              <FormComponent onSubmit={handleSubmit}>
                <DialogContentWrapper>
                  <DialogContentTop>
                    <Grid item xs={12} sm={6} md={6}>
                      <FieldWrapper
                        label="Teklif No"
                        modalLoading={modalLoading}
                      >
                        <Field
                          disabled
                          name="teklifid"
                          component={TextInputForm}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Proje Süresi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="projeSuresi"
                          component={NumberInputForm}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Proje Baslangıç Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="projeBaslangicTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        label="Proje Bitiş Tarihi"
                        modalLoading={modalLoading}
                      >
                        <Field
                          name="projeBitisTarihi"
                          component={DateInputForm2}
                          readOnly={!yetki.kaydet}
                          rowData={rowData}
                        />
                      </FieldWrapper>
                    </Grid>
                  </DialogContentTop>
                  <DialogActionsWrapper
                    sx={{
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Grid> */}
                    {/* <ButtonWrapper
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={(e) => {
                          form.submit();
                          pdfDialogRef?.current?.openRecord(rowData.id);
                        }}
                        disabled={!rowData.id}
                      >
                        Yazdır
                      </ButtonWrapper> */}
                    {/* <PopoverButton
                        label="İşlemler"
                        disabled={
                          !rowData?.id || duzenlemeYapamaz || teklifDuzenlenemez
                        }
                      >
                        <SatisTeklifiOnayliPdfDosyasiButton
                          routes={FileConfig}
                          reqData={{
                            id: rowData.id,
                          }}
                          ekranKodu={ekranKodu}
                          parentRowData={rowData}
                          disabled={
                            !rowData?.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          {" "}
                          Teklife Onaylı Dosya Yükle
                        </SatisTeklifiOnayliPdfDosyasiButton>
                        <CustomColoredButton
                          startIcon={<DoNotDisturbIcon />}
                          // routes={FileConfig}
                          // reqData={{
                          //   id: rowData.id,
                          // }}
                          onClick={() => {
                            teklifReddetAlertDialogRef?.current?.openRecord();
                          }}
                          ekranKodu={ekranKodu}
                          parentRowData={rowData}
                          disabled={
                            !rowData?.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                          color="red"
                        >
                          Teklifi Reddet
                        </CustomColoredButton>
                        <ButtonWrapper
                          color="primary"
                          startIcon={<DiscountIcon />}
                          onClick={(e) => {
                            topluIskontoDialogRef?.current?.openRecord();
                            // handleChangeAllItems();
                          }}
                          disabled={
                            !rowData.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          Toplu İskonto
                        </ButtonWrapper>
                        <ButtonWrapper
                          startIcon={<CurrencyExchangeIcon />}
                          color="warning"
                          onClick={(e) => {
                            teklifiDovizliyeCevirDialogRef?.current?.openRecord(
                              rowData.id
                            );
                          }}
                          disabled={
                            !rowData.id ||
                            duzenlemeYapamaz ||
                            teklifDuzenlenemez
                          }
                        >
                          DÖVİZLİ TEKLİFE ÇEVİR
                        </ButtonWrapper>
                      </PopoverButton> */}
                    {/* </Grid> */}
                    <RecordButton
                      yetki={yetki}
                      form={form}
                      handleDelete={handleDelete}
                      submitting={submitting}
                      pristine={pristine}
                      modalLoading={modalLoading}
                      // submitCondition={!duzenlemeYapamaz && !teklifDuzenlenemez}
                      // deleteCondition={!duzenlemeYapamaz && !teklifDuzenlenemez}
                      // cancelCondition={!duzenlemeYapamaz && !teklifDuzenlenemez}
                    />
                  </DialogActionsWrapper>
                  <DialogContentWrapper>
                    <TeklifProjeDetay
                      ekranKodu={ekranKodu}
                      rowData={rowData}
                      editGridRef={editGridRef}
                      listGridRef={editGridRef}
                      detailGridRef={editGridRef}
                      // duzenlemeYapamaz={duzenlemeYapamaz || teklifDuzenlenemez}
                    ></TeklifProjeDetay>
                  </DialogContentWrapper>
                </DialogContentWrapper>
              </FormComponent>
            )}
          />
        </ScrollDialog>
      </>
    );
  }
);
export default TeklifProjeMasterRecord;
