import React, { useEffect, useRef, useState } from "react";
import usePageRecord from "../../hooks/use-page-record";
import PageConfig from "./TahminiTerminConfig";
import * as yup from "yup";
import ScrollDialog from "../../components/dialogs/ScrollDialog";
import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import FieldWrapper from "../../components/inputs/form-inputs/FieldWrapper";
import dayjs from "dayjs";
import { apiUrl } from "../../common/constants";
import { HttpAuthorized } from "../../common/common";
import EditGrid from "../../components/grid/EditGrid";
import CustomColoredButton from "../../components/buttons/custom/CustomColoredButton";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import CustomDateInputFormForStates from "../../components/inputs/form-inputs/CustomDateInputFormForStates";

const TahminiTerminDialog = React.forwardRef(
  ({ ekranKodu, listGridRef }, ref) => {
    var initialState = {
      // tarih: dayjs(),
      // isSonucuYayinlansin: true,
    };

    const [tahminiTerminTarihi, setTahminiTerminTarihi] = useState(null);
    const [tahminiSure, setTahminiSure] = useState(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [aciklama, setAciklama] = useState(null);

    const afterSubmitFnc = (id) => {
      ref?.current?.openRecord(id);
    };

    const {
      rowData,
      open,
      handleClose,
      modalLoading,
      handleSubmit,
      handleDelete,
      validateFormValues,
      yetki,
      context,
    } = usePageRecord(
      ref,
      PageConfig,
      listGridRef,
      ekranKodu,
      initialState,
      afterSubmitFnc
    );

    useEffect(() => {
      if (!open) {
        // Dialog kapanınca isEditMode'u sıfırla
        setIsEditMode(false);
        setTahminiTerminTarihi(null); // Gerekirse diğer state'leri de sıfırlayın
        setTahminiSure(null);
        setCurrentRowId(null);
        setAciklama(null);
      }
    }, [open]);

    const convertToLocalTime = (utcDateStr) => {
      return dayjs(utcDateStr).format("DD.MM.YYYY");
      // return new Date(utcDateStr).toLocaleDateString("tr-TR");
    };

    const editGridRef = useRef();

    const validationSchema = yup.object().shape({});

    const handleSave = async () => {
      try {
        const requestData = {
          tahminiTerminTarihi: tahminiTerminTarihi,
          tahminiSure: tahminiSure,
          talepid: rowData.talepid,
          id: currentRowId,
          isEditMode: isEditMode,
          aciklama: aciklama,
        };

        // API isteğini gönder
        const response = await HttpAuthorized.post(
          `${apiUrl}TahminiTermin/TahminiTerminEkle`,
          requestData
        );

        // Başarılıysa, formu sıfırla ve grid'i yenile
        if (response.data.success) {
          setTahminiTerminTarihi(null);
          setTahminiSure(null);
          afterSubmitFnc(rowData.talepid);
          setIsEditMode(false);
          setAciklama(null);
          setCurrentRowId(null);
          // editGridRef.current?.refresh(); // Grid'i yenile
        }
      } catch (error) {
        console.error("Kaydetme hatası:", error);
      }
    };

    const editButtonOnClick = (e) => {
      // if ( > e.row.Data.kayitTarihi.setDate(e.row.Data.kayitTarihi.getDate() + 2))
      const currentDate = new Date();
      const kayitTarihi = new Date(e.row.data.kayitTarihi);

      const timeDiff = currentDate - kayitTarihi;
      const diffInDays = timeDiff / (1000 * 60 * 60 * 24);

      if (diffInDays > 2) {
        toast.info(
          "Termin tarihi girdiğiniz tarih ile şimdiki tarih arasında en fazla 2 gün olabilir. Kayıt Düzenlenemez.",
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );

        return;
      }

      const selectedDate = dayjs(e.row.data.hedeflenenTerminTarihi).format(
        "YYYY-MM-DD"
      );
      setTahminiTerminTarihi(selectedDate); // sadece tarihi ayarlayın, saat bilgisi yok
      setTahminiSure(e.row.data.hedeflenenSure); // satırdaki süre
      setCurrentRowId(e.row.data.id); // satırın id'si
      setIsEditMode(true); // güncelleme moduna geç
      setAciklama(e.row.data.aciklama); // güncelleme moduna geç
    };

    const editButtonVisible = (e) => {
      return e.row.data.sorumluid === context.user.id;
    };

    return (
      <>
        <ScrollDialog
          open={open}
          handleClose={handleClose}
          modalTitle={`Tahmini Termin Ver`}
          maxWidth="md"
          canFullScreen={false}
          draggable={false}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ marginTop: "2px" }}
          >
            <Grid item sx={{ paddingTop: "2px !important" }}>
              <Card
                sx={{
                  width: 200,
                  height: 70,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  border: "1px solid black",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ fontWeight: "bolder" }}
                  >
                    Atanan Başlangıç Tarihi
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    sx={{ marginTop: "8px" }}
                  >
                    {rowData.baslangicTarihi
                      ? convertToLocalTime(rowData.baslangicTarihi)
                      : "Tarih Yok"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sx={{ paddingTop: "2px !important" }}>
              <Card
                sx={{
                  width: 200,
                  height: 70,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  border: "1px solid black",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ fontWeight: "bolder" }}
                  >
                    Atanan Termin Tarihi
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    sx={{ marginTop: "8px" }}
                  >
                    {rowData.terminTarihi
                      ? convertToLocalTime(rowData.terminTarihi)
                      : "Tarih Yok"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sx={{ paddingTop: "2px !important" }}>
              <Card
                sx={{
                  width: 200,
                  height: 70,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  border: "1px solid black",
                  marginBottom: "10px",
                  marginRight: "0px",
                }}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ fontWeight: "bolder" }}
                  >
                    Atanan Süre
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    sx={{ marginTop: "8px" }}
                  >
                    {rowData.hedefSure
                      ? rowData.hedefSure
                      : "Hedef Süre belirlenmemiş"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              marginBottom: "25px",
            }}
          >
            <EditGrid
              entities={PageConfig.type}
              // openRecord={openRecord}
              height={"25vh"}
              editGridRef={editGridRef}
              dataSource={rowData.detay ?? []}
              ekleButtonGizli={true}
              editButtonOnClick={editButtonOnClick}
              rowPrepared={(info) => {
                if (info.rowType === "data" && info.data.id === currentRowId) {
                  info.rowElement.style.backgroundColor = "#b9b5ff"; // Satırın arka plan rengini ayarla
                  info.rowElement.style.color = "#000000"; // Metin rengini ayarla
                }
              }}
              editButtonVisible={editButtonVisible}
            ></EditGrid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            <Grid item xs={6} sm={6}>
              <FieldWrapper
                label="Tahmini Termin Tarihi"
                modalLoading={modalLoading}
                style={{ marginLeft: "15px" }} // Soldan margin ekle
              >
                <CustomDateInputFormForStates
                  value={tahminiTerminTarihi} // Grid'den gelen tarih değeri
                  onChange={(newDate) => setTahminiTerminTarihi(newDate)} // Tarih değişince state'i güncelle
                />
                {/* <TextField
                  name="tahminiTerminTarihi"
                  type="date"
                  value={
                    tahminiTerminTarihi
                      ? dayjs(tahminiTerminTarihi).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) =>
                    setTahminiTerminTarihi(
                      dayjs(e.target.value).format("YYYY-MM-DD")
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  style={{ marginBottom: "5px" }}
                /> */}
              </FieldWrapper>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FieldWrapper
                label="Tahmini Süre"
                modalLoading={modalLoading}
                style={{ marginLeft: "15px" }} // Soldan margin ekle
              >
                <TextField
                  name="tahminiSure"
                  type="number"
                  value={tahminiSure ? tahminiSure : ""}
                  onChange={(e) => setTahminiSure(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  style={{ marginBottom: "5px" }}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper
                label="Açıklama"
                modalLoading={modalLoading}
                style={{ marginLeft: "15px" }} // Genişlik ve margin
                textWidth={"17%"}
                width={"83%"}
              >
                <TextField
                  name="aciklama"
                  type="text"
                  value={aciklama ? aciklama : ""}
                  onChange={(e) => setAciklama(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3} // Minimum 3 satır
                  size="small"
                  sx={{ width: 665 }}
                  // fullWidth // Açıklama alanını tam genişlik yap
                  style={{ marginBottom: "5px" }}
                />
              </FieldWrapper>
            </Grid>

            {/* Kaydet butonuna sağdan boşluk verelim */}
            <Grid
              item
              xs={12}
              style={{ textAlign: "right", paddingRight: "15px" }}
            >
              <CustomColoredButton
                startIcon={<SaveIcon />}
                onClick={handleSave}
                disabled={tahminiTerminTarihi === null || tahminiSure === null}
                color="green"
              >
                {isEditMode ? "Güncelle" : "Kaydet"}
              </CustomColoredButton>
            </Grid>
          </Grid>
        </ScrollDialog>
      </>
    );
  }
);

export default TahminiTerminDialog;
